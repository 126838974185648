import { taskFaxStatuses } from '../../globalUtils/utils';
import deliveryInProgressImg from '../../images/deliveryInProgress.svg';
import deliveryFailedImg from '../../images/caution.svg';
import successfulDeliveryImg from '../../images/CheckCircleOutlineIcon.svg';
import { TaskFaxType } from '../../redux/types';

/**
 * Extracted from legacy code
 * @see FaxStatus
 */
export function FaxStatusIcon({ faxStatus }: { faxStatus?: TaskFaxType['status'] }) {
  const deliveryInProgress = faxStatus?.name?.toLowerCase() === taskFaxStatuses.DELIVERY_IN_PROGRESS;
  const failedDelivery = faxStatus?.name?.toLowerCase() === taskFaxStatuses.FAILED_DELIVERY;
  const successfulDelivery = faxStatus?.name?.toLowerCase() === taskFaxStatuses.SUCCESSFUL_DELIVERY;

  const faxStatusImage = () => {
    if (deliveryInProgress) {
      return deliveryInProgressImg;
    } else if (failedDelivery) {
      return deliveryFailedImg;
    } else if (successfulDelivery) {
      return successfulDeliveryImg;
    } else {
      return undefined;
    }
  };

  return <img alt="" src={faxStatusImage()} />;
}
