import { Dispatch } from 'redux';
import { getMedications } from '../../api/medicationsApi';
import { GET_MEDICATIONS_LOADING, GET_MEDICATIONS_SUCCESS, GET_MEDICATIONS_ERROR } from '../constants/referralTypes';

const getMedicationsLoading = (payload: boolean) => ({
  type: GET_MEDICATIONS_LOADING,
  payload,
});

const getMedicationsSuccess = (payload: any) => ({
  type: GET_MEDICATIONS_SUCCESS,
  payload,
});

const getMedicationsError = (payload: any) => ({
  type: GET_MEDICATIONS_ERROR,
  payload,
});

export const getMedicationsAction = () => async (dispatch: Dispatch) => {
  try {
    dispatch(getMedicationsLoading(true));
    const response = await getMedications();
    dispatch(getMedicationsSuccess(response.data));
    dispatch(getMedicationsLoading(false));
  } catch (e) {
    dispatch(getMedicationsError(e));
    dispatch(getMedicationsLoading(false));
  }
};
