import { BaseAction, CommentsState } from '../types';
import {
  CREATE_COMMENTS_LOADING,
  FETCH_COMMENTS_LOADING,
  LOAD_COMMENT_CREATION_ERROR,
  LOAD_COMMENT_CREATION_RESPONSE_TO_STORE,
  LOAD_COMMENTS_ERROR,
  LOAD_COMMENTS_TO_STORE,
  LOAD_NEW_COMMENT_FROM_SOCKET,
  CLEAR_TAGS,
  EDIT_COMMENT_LOADING,
  SET_COMMENT_BEING_EDITED,
  EDIT_COMMENT_SUCCESS,
  EDIT_COMMENT_ERROR,
} from '../constants/commentsTypes';

const initialState: CommentsState = {
  clearTags: false,
  commentsList: {
    data: [],
    loading: false,
    error: null,
  },
  commentCreation: {
    loading: false,
    data: {},
    error: null,
  },
  editCommentStore: {
    loading: false,
    error: undefined,
  },
};
const commentsReducer = (state: CommentsState = initialState, action: BaseAction): CommentsState => {
  switch (action.type) {
    case FETCH_COMMENTS_LOADING:
      return {
        ...state,
        commentsList: {
          ...state.commentsList,
          loading: action.payload,
        },
      };
    case LOAD_COMMENTS_TO_STORE:
      return {
        ...state,
        commentsList: {
          ...state.commentsList,
          data: action.payload,
        },
      };
    case LOAD_COMMENTS_ERROR:
      return {
        ...state,
        commentsList: {
          ...state.commentsList,
          error: action.payload,
        },
      };
    case CREATE_COMMENTS_LOADING:
      return {
        ...state,
        commentCreation: {
          ...state.commentCreation,
          loading: action.payload,
        },
      };
    case LOAD_COMMENT_CREATION_RESPONSE_TO_STORE:
      return {
        ...state,
        commentCreation: {
          ...state.commentCreation,
          data: action.payload,
        },
      };
    case LOAD_COMMENT_CREATION_ERROR:
      return {
        ...state,
        commentCreation: {
          ...state.commentCreation,
          error: action.payload,
        },
      };
    case LOAD_NEW_COMMENT_FROM_SOCKET:
      const commentsWithoutNewSocketComment = state.commentsList.data.filter(
        (comment) => comment.id !== action.payload.id,
      );
      return {
        ...state,
        commentsList: {
          ...state.commentsList,
          data: [...commentsWithoutNewSocketComment, action.payload],
        },
      };
    case CLEAR_TAGS:
      return {
        ...state,
        clearTags: action.payload,
      };
    case SET_COMMENT_BEING_EDITED:
      return {
        ...state,
        editCommentStore: {
          ...state.editCommentStore,
          comment: action.payload,
        },
      };
    case EDIT_COMMENT_LOADING:
      return {
        ...state,
        editCommentStore: {
          ...state.editCommentStore,
          loading: action.payload,
        },
      };
    case EDIT_COMMENT_SUCCESS:
      return {
        ...state,
        editCommentStore: {
          ...state.editCommentStore,
          comment: action.payload,
          loading: false,
          error: undefined,
        },
      };
    case EDIT_COMMENT_ERROR:
      return {
        ...state,
        editCommentStore: {
          ...state.editCommentStore,
          error: action.payload,
          loading: false,
        },
      };
    default:
      return state;
  }
};

export default commentsReducer;
