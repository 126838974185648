export const LOAD_TOKEN = 'LOAD_TOKEN';
export const LOAD_REFRESH_TOKEN = 'LOAD_REFRESH_TOKEN';
export const LOAD_IDENTITY_INFO = 'LOAD_IDENTITY_INFO';
export const LOGIN_LOADING = 'LOGIN_LOADING';

export const FETCH_USERS_LOADING = 'FETCH_USERS_LOADING';
export const LOAD_USERS_ERROR = 'LOAD_USERS_ERROR';
export const LOAD_USERS_TO_STORE = 'LOAD_USERS_TO_STORE';

export const SET_AUTH_REDIRECT_ROUTE = 'SET_AUTH_REDIRECT_ROUTE';
