import { useSelector } from 'react-redux';
import { RootState, StatusReason, Task, WaitingReason } from '../../redux/types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import ReasonDialog from '../ReasonDialog/ReasonDialog';
import { getIdsFromArray, statusDefaults } from '../../globalUtils/utils';
import { uniqBy } from 'lodash';
import { CommentTypes } from '../../globalUtils/constants';
import { DateField } from '../DatePickers/DateField';

export type updateTaskParams = {
  id: string;
  statusId: string;
  statusReasonIdsToAdd: string[];
  statusReasonIdsToRemove: string[];
  patientContactTag: boolean;
  waitingTag: boolean;
  referralFollowupTag: boolean;
  tagUserIds: string[];
  message: string;
  commentType?: string;
  nextFollowUpDate?: Date;
};

interface Props {
  waitingStatusId: string;
  isWaitingDialogOpen: boolean;
  id: string;
  title: string;
  shouldSubmitOnWaitingClick: boolean;
  loading?: boolean;
  shouldResetValues: boolean;
  isEditing?: boolean;
  errors?: {
    waitingReason?: string;
  };
  handleChange: (type: string, value: any) => void;
  setWaitingDialogOpen: (isOpen: boolean) => void;
  handleSubmitSetTaskToWaiting: (params: updateTaskParams, callback: () => void) => void;
  handleCancel?: () => void;
  task: Task;
}

const WaitingDialog: React.FC<Props> = (props) => {
  const {
    handleChange,
    id,
    title,
    setWaitingDialogOpen,
    isWaitingDialogOpen,
    shouldSubmitOnWaitingClick,
    handleSubmitSetTaskToWaiting,
    waitingStatusId,
    loading,
    errors: propsErrors,
    shouldResetValues,
    isEditing,
    handleCancel: onCancel,
    task,
  } = props;

  const [errors, setErrors] = useState<any>({
    waitingReason: propsErrors?.waitingReason,
  });
  const [selectedReasons, setSelectedReasons] = useState<any[]>([]);
  const [contactedChosen, setContactedChosen] = useState<boolean>(false);
  const [followedUpChosen, setFollowUpChosen] = useState<boolean>(false);
  const [publishToEMRChosen, setPublishToEMRChosen] = useState<boolean>(true);
  const [commentMessage, setCommentMessage] = useState('');
  const [mentions, setMentions] = useState<any[]>([]);
  const {
    auth: { identityInfo: loggedInUserInfo },
    statusReasons: { tasksStatusReasons },
  } = useSelector((state: RootState) => state);

  const currentStatusReasonIds: string[] = useMemo((): string[] => {
    return getIdsFromArray(task?.statusReasons);
  }, [task]);

  const selectedReasonIds: string[] = useMemo((): string[] => {
    return getIdsFromArray(selectedReasons);
  }, [selectedReasons]);

  const message: string = useMemo(() => {
    return selectedReasons?.length
      ? `${loggedInUserInfo?.name} transitioned ${title} to Waiting for the following reasons: ${selectedReasons
          ?.map((reason) => reason?.title)
          .join(', ')}`
      : '';
  }, [loggedInUserInfo?.name, selectedReasons, title]);

  const reasons = useMemo(() => {
    return tasksStatusReasons
      .filter(
        (reason: StatusReason) => reason.taskStatus?.name.toLowerCase() === statusDefaults.WAITING.toLocaleLowerCase(),
      )
      .map((reason: StatusReason) => {
        return {
          ...reason,
          label: reason.title,
          searchString: reason.title,
          value: reason,
        };
      })
      .sort((a, b) => ('' + a.title).localeCompare(b.title));
  }, [tasksStatusReasons]);

  const onChangeComments = (comments: string, mentions: any[]) => {
    setCommentMessage(comments);
    setMentions(mentions);
  };

  const handleReasonsChange = (statusReasons: StatusReason[]) => {
    setSelectedReasons(statusReasons);
    handleChange(
      'waitingReasons',
      statusReasons.map((reason: StatusReason) => reason.id),
    );
    setErrors({});
  };

  const [followUpDate, setFollowUpDate] = useState<Date | null>(null);
  const showFollowUpField = selectedReasons.some((x) => Boolean(x.followup));

  const handleSetTaskToWaiting = useCallback(() => {
    if (!selectedReasons?.length) {
      setErrors({
        waitingReason: 'Waiting reason is required',
      });

      return;
    }

    handleChange('messageAndTaggedUsers', {
      taggedUsersIds: mentions.map(({ id }) => id),
      message: commentMessage ? commentMessage : message,
      waitingTag: true,
      contactedChosen,
      followedUpChosen,
      commentType:
        commentMessage || message ? (publishToEMRChosen ? CommentTypes.EXTERNAL : CommentTypes.INTERNAL) : undefined,
    });

    if (shouldSubmitOnWaitingClick && handleSubmitSetTaskToWaiting) {
      handleSubmitSetTaskToWaiting(
        {
          id,
          statusId: waitingStatusId || '',
          statusReasonIdsToAdd: selectedReasonIds,
          statusReasonIdsToRemove: currentStatusReasonIds.filter(
            (currentReasonId: string) => !selectedReasonIds.includes(currentReasonId),
          ),
          patientContactTag: contactedChosen,
          waitingTag: true,
          referralFollowupTag: followedUpChosen,
          tagUserIds: mentions.map(({ id }) => id),
          message: commentMessage ? commentMessage : message,
          commentType:
            commentMessage || message
              ? publishToEMRChosen
                ? CommentTypes.EXTERNAL
                : CommentTypes.INTERNAL
              : undefined,
          nextFollowUpDate: followUpDate || undefined,
        },
        () => {
          setPublishToEMRChosen(true);
          setSelectedReasons([]);
          setFollowUpDate(null);
        },
      );
    } else {
      setWaitingDialogOpen(false);
    }
  }, [
    commentMessage,
    contactedChosen,
    currentStatusReasonIds,
    followedUpChosen,
    handleChange,
    handleSubmitSetTaskToWaiting,
    id,
    mentions,
    message,
    publishToEMRChosen,
    selectedReasonIds,
    selectedReasons?.length,
    setWaitingDialogOpen,
    shouldSubmitOnWaitingClick,
    waitingStatusId,
    followUpDate,
  ]);

  useEffect(() => {
    if (propsErrors?.waitingReason && !selectedReasons.length) {
      setErrors((previousErrors: any) => {
        return { ...previousErrors, waitingReason: propsErrors?.waitingReason };
      });
    }
  }, [propsErrors?.waitingReason, selectedReasons]);

  useEffect(() => {
    if (isEditing) {
      setSelectedReasons((previousSelectedReasons: any[]) => {
        return uniqBy(
          [...previousSelectedReasons, ...reasons.filter((reason) => currentStatusReasonIds.includes(reason.id))],
          'id',
        );
      });
    }
  }, [currentStatusReasonIds, reasons, isEditing]);

  const handleCancel = () => {
    setFollowUpDate(null);
    setWaitingDialogOpen(false);
    handleChange('waitingReasons', []);
    setSelectedReasons([]);
    onChangeComments('', []);
    setContactedChosen(false);
    setFollowUpChosen(false);
    setErrors({});
    setPublishToEMRChosen(true);
    onCancel && onCancel();
  };

  useEffect(() => {
    if (shouldResetValues) {
      handleCancel();
    }
    return () => {
      handleCancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldResetValues]);

  return (
    <ReasonDialog
      open={isWaitingDialogOpen}
      title={isEditing ? `Edit waiting reason` : `Set Task to Waiting`}
      bannerTitle="Select waiting reason"
      bannerText={
        isEditing
          ? 'Please select the reason to edit “Waiting” status.'
          : 'Please select the reasons to set status to “Waiting”'
      }
      commentLabel="Comment (Optional)"
      error={errors.waitingReason}
      mentionComponentProps={{
        loading: false,
        hideSend: true,
        showMentionTrigger: false,
        onSend: () => null,
        id,
        onChange: onChangeComments,
        placeholder: 'Type comment..',
        startValue: message,
        publishToEMRSelected: publishToEMRChosen,
        disablePublishToEMR: loading,
        isExpanded: true,
        onSelectPublishToEMR: () => {
          setPublishToEMRChosen(!publishToEMRChosen);
        },
      }}
      commentsActionProps={{
        contactedChosen,
        followedUpChosen,
        onContactedButtonClick: () => {
          setContactedChosen(!contactedChosen);
        },
        onFollowUpButtonClick: () => {
          setFollowUpChosen(!followedUpChosen);
        },
      }}
      actionButtonLabel={isEditing ? `Edit Task` : `Set Task to Waiting`}
      onCancel={handleCancel}
      onSubmit={handleSetTaskToWaiting}
      loading={loading}
      menuItemSearchProps={{
        initialValues: selectedReasons,
        onChange: handleReasonsChange,
        placeHolder: 'Search',
        style: { width: 358 },
        showAvatar: false,
        options: reasons,
        label: selectedReasons?.length
          ? selectedReasons.map((reason: WaitingReason) => reason.title).join(', ')
          : 'Select Waiting Reasons',
        isErrored: Boolean(errors.waitingReason),
      }}
    >
      {showFollowUpField && (
        <DateField label="Next follow-up date" value={followUpDate} onChange={setFollowUpDate} disablePast={true} />
      )}
    </ReasonDialog>
  );
};

export default WaitingDialog;
