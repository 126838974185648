import { Task } from '../../../../redux/types';
import { useDeclineReferralController } from '../index';
import { FaxStatus } from '../../../../components/Fax/FaxStatus';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import InputMask from 'react-input-mask';
import { useDispatch } from 'react-redux';
import { getOneReferral } from '../../../../redux/actions/referralsActions';

export function DeclineReferralTaskBody(task: Task) {
  const dispatch = useDispatch();
  const view = useDeclineReferralController(task, (referralId) => {
    dispatch(getOneReferral(referralId, false));
  });
  return (
    <>
      <FaxStatus fax={task.fax} />
      <Box display="flex" justifyContent="flex-end">
        <Button disabled={!view.sendButton.enabled} size="small" variant="contained" onClick={view.sendButton.click}>
          <Typography variant="button">{view.sendButton.label}</Typography>
          {view.sendButton.loading && <CircularProgress style={{ marginLeft: '8px' }} size={16} />}
        </Button>
      </Box>
      <Dialog open={view.dialog.open}>
        <DialogTitle>Decline Referral</DialogTitle>
        <DialogContent>
          <InputMask
            autoFocus
            maskPlaceholder=""
            mask="999-999-9999999999999"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => view.dialog.faxNumber.set(e.target.value)}
            value={view.dialog.faxNumber.value}
          >
            <TextField id="fax_number" label="Fax" variant="filled" />
          </InputMask>
        </DialogContent>

        <DialogActions>
          <Box style={{ width: '100%' }} display="flex" justifyContent="space-between">
            <Box>
              <Button onClick={view.dialog.cancel}>Cancel</Button>
            </Box>
            <Box>
              <Button
                disabled={!view.dialog.sendButton.enabled}
                variant="contained"
                onClick={view.dialog.sendButton.click}
              >
                Send
                {view.dialog.sendButton.loading && <CircularProgress style={{ marginLeft: '8px' }} size={16} />}
              </Button>
            </Box>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
}
