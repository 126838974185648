import {
  HIDE_WEINFUSE_CONNECTION_ALERT,
  SET_ATTACHMENT_BEING_UPLOADED_TO_WEINFUSE,
  SET_ATTACHMENT_BEING_UPLOADED_TO_WEINFUSE_LOADING,
  SET_WEINFUSE_ENABLED_STATUS,
} from '../constants/weInfuseTypes';
import { Attachment, BaseAction, WeInfuseStore } from '../types';

const initialState: WeInfuseStore = {
  hideWeInfuseConnectionAlert: false,
  enabled: true,
  attachmentSBeingUploadedToWeInfuse: [],
};

const weInfuseReducer = (state: WeInfuseStore = initialState, action: BaseAction): WeInfuseStore => {
  switch (action.type) {
    case HIDE_WEINFUSE_CONNECTION_ALERT:
      return {
        ...state,
        hideWeInfuseConnectionAlert: action.payload,
      };
    case SET_WEINFUSE_ENABLED_STATUS:
      return {
        ...state,
        enabled: action.payload,
      };
    case SET_ATTACHMENT_BEING_UPLOADED_TO_WEINFUSE:
      return {
        ...state,
        attachmentSBeingUploadedToWeInfuse: [...state.attachmentSBeingUploadedToWeInfuse, action.payload],
      };
    case SET_ATTACHMENT_BEING_UPLOADED_TO_WEINFUSE_LOADING:
      const attachmentBeingUpdated: Attachment =
        state.attachmentSBeingUploadedToWeInfuse.find(
          (attachment: Attachment) => attachment.id === action.payload.id,
        ) || action.payload;
      return {
        ...state,
        attachmentSBeingUploadedToWeInfuse: [
          ...state.attachmentSBeingUploadedToWeInfuse.filter(
            (attachment: Attachment) => attachment.id !== action.payload.id,
          ),
          { ...attachmentBeingUpdated, ...action.payload },
        ],
      };
    default:
      return state;
  }
};

export default weInfuseReducer;
