import { UPDATE_INBOX_ARCHIVED_TOTAL_COUNT, UPDATE_INBOX_RECEIVED_TOTAL_COUNT } from '../constants/inboxTypes';

export const updateInboxReceivedCount = (count: number) => ({
  type: UPDATE_INBOX_RECEIVED_TOTAL_COUNT,
  payload: count,
});

export const updateArchivedInboxCount = (count: number) => ({
  type: UPDATE_INBOX_ARCHIVED_TOTAL_COUNT,
  payload: count,
});
