import axios, { AxiosResponse } from 'axios';

const BASE_URL: string = `${process.env.REACT_APP_API_URL}`;

export const getCommentsApi = async (params: any): Promise<AxiosResponse<any, any>> => {
  return await axios.get(`${BASE_URL}/comments`, { params });
};

export interface AddCommentParams {
  message?: string;
  isExternal?: boolean;
  inboxId?: string;
  tagUserIds?: string[];
  referralFollowupTag?: boolean;
  patientContactTag?: boolean;
  waitingTag?: boolean;
  commentType?: string;
}

export interface AddReferralComment {
  message?: string;
  referralId?: string;
  isExternal?: boolean;
  tagUserIds?: string[];
  referralFollowupTag?: boolean;
  patientContactTag?: boolean;
  waitingTag?: boolean;
  commentType?: string;
}

export interface EditCommentPayload {
  message: string;
  tagUserIds?: string[];
  type: string;
  referralFollowupTag?: boolean;
  patientContactTag?: boolean;
  waitingTag?: boolean;
}

export const addCommentsApi = async (params: AddCommentParams): Promise<AxiosResponse<any, any>> => {
  return await axios.post(`${BASE_URL}/inboxes/comment`, params);
};

export const addReferralCommentsApi = async (payload: AddReferralComment): Promise<AxiosResponse<any, any>> => {
  return await axios.post(`${BASE_URL}/referrals/comment`, payload);
};

export const editCommentApi = async (
  commentId: string,
  payload: EditCommentPayload,
): Promise<AxiosResponse<any, any>> => {
  return await axios.patch(`${BASE_URL}/comments/${commentId}`, payload);
};
