import React from 'react';
import { TaskLine } from '../../../../components/TasksList/TaskLine';
import { Box, Button, Tooltip, TooltipProps, Typography } from '@mui/material';
import { OrderEntryTaskBodyViewModel } from '../presenter';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

interface Props extends TooltipProps {
  disabled: boolean;
}

const DisabledTooltipWrapper = (props: Props): JSX.Element => {
  const { disabled, children, ...restProps } = props;
  return disabled ? (
    <Tooltip {...restProps}>
      <span>{children}</span>
    </Tooltip>
  ) : (
    children
  );
};
export function OrderEntryTaskBody(
  props: OrderEntryTaskBodyViewModel & {
    onStartLink: () => void;
  },
) {
  return (
    <>
      <TaskLine labelText="Linked Order(s)">
        <Typography
          style={{ marginLeft: '6px' }}
          variant="body2"
          color={props.fadeText ? 'grey' : undefined}
          data-id="linkedOrders"
        >
          {props.loading ? 'Loading...' : props.linkedOrders}
        </Typography>
      </TaskLine>
      <Box display="flex" justifyContent="flex-end">
        <DisabledTooltipWrapper title={props.tooltipMessage || ''} disabled={props.disableButton}>
          <Button disabled={props.disableButton} size="small" variant="contained" onClick={props.onStartLink}>
            {props.disableButton && <ErrorOutlineOutlinedIcon />}
            <Typography variant="button">{props.buttonText}</Typography>
          </Button>
        </DisabledTooltipWrapper>
      </Box>
    </>
  );
}
