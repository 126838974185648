import React, { FC, useState } from 'react';
import SelectMenu, { SelectMenuProps } from '../SelectMenu/SelectMenu';
import binPrimaryColor from '../../images/binPrimaryColor.svg';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/types';
import { Box, Typography } from '@mui/material';

interface Props extends Partial<SelectMenuProps> {
  initialValue?: any;
  onChange: (item: any) => void;
  label?: any;
  status?: any;
  labelFor?: string;
}
const EditMedication: FC<Props> = (props) => {
  const { initialValue, onChange, status, labelFor, ...restProps } = props;

  const medications = useSelector((state: RootState) => state.medications);

  const [value, setValue] = useState(initialValue);

  const handleChange = (item: any) => {
    onChange(item);
    setValue(item);
  };

  const labelTransForm = (value: any) => {
    return (
      <Box style={{ textAlign: 'left' }}>
        {labelFor && (
          <Typography variant="caption" color="textSecondary">
            {labelFor}
          </Typography>
        )}
        <Typography style={{ textOverflow: 'ellipsis' }} variant="subtitle2" color="textPrimary">
          {value?.name}
        </Typography>
        {!labelFor && (
          <Typography style={{ textAlign: 'left' }} variant="body2" color="textSecondary">
            {value?.drugTier?.name}
          </Typography>
        )}
      </Box>
    );
  };

  const medicationData = medications?.data;

  const options = medicationData?.map((item: any) => {
    return {
      ...item,
      label: (
        <Box style={{ width: '100%' }} display="flex" justifyContent="space-between">
          <Typography style={{ flex: 1 }}>{item.name}</Typography>
          <Typography style={{ marginLeft: 16 }} color="textSecondary">
            {item?.drugTier?.name}
          </Typography>
        </Box>
      ),
      searchString: item.name,
    };
  });

  const nullMedicationValue = {
    name: 'No medication',
    id: null,
    drugTier: null,
    searchString: 'No medication',
    label: (
      <Box style={{ width: '100%' }} display="flex" justifyContent="space-between">
        <Typography variant="subtitle2" style={{ flex: 1 }}>
          No medication
        </Typography>
      </Box>
    ),
  };

  const isSelectedOption = (option: any, value: any): boolean => option?.id === value?.id;

  return (
    <SelectMenu
      label="Select"
      value={value}
      hideAddnew
      onChange={handleChange}
      onAddNew={console.log}
      handleSearch={console.log}
      showExpandMore
      searchLoading={false}
      valueIcon={<img alt="" src={binPrimaryColor} />}
      icon={<img alt="" src={binPrimaryColor} />}
      options={[...options, status?.title?.toLowerCase() === 'draft' && nullMedicationValue].map((option) => ({
        ...option,
        disabled: option?.disabled || isSelectedOption(option, value),
        showDoneIcon: isSelectedOption(option, value),
      }))}
      labelTransform={labelTransForm}
      groupBy={(item) => {
        return {
          label: (
            <Box style={{ padding: `8px 16px` }}>
              <Typography variant="button" color="textSecondary">
                {item?.drugTier?.name || 'OTHERS'}
              </Typography>
            </Box>
          ),
          key: item?.drugTierId || 'OTHERS',
        };
      }}
      {...restProps}
    />
  );
};

export default EditMedication;
