import {
  GET_STATUS_REASONS_ERROR,
  GET_STATUS_REASONS_SUCCESS,
  SET_STATUS_REASONS_LOADING,
} from '../constants/statusReasonsTypes';

export const setStatusReasonsLoading = (isLoading: boolean) => ({
  type: SET_STATUS_REASONS_LOADING,
  payload: isLoading,
});

export const getStatusReasonsError = (error: any) => ({
  type: GET_STATUS_REASONS_ERROR,
  payload: error,
});

export const getStatusReasonsSuccess = (payload: any) => ({
  type: GET_STATUS_REASONS_SUCCESS,
  payload,
});
