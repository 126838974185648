import { Dispatch } from 'redux';
import {
  createReferral,
  fetchReferralsApi,
  getReferralApi,
  addAttachment,
  editReferralApi,
  editAttachment,
  getReferralCategoriesApi,
  getReferralStatusesApi,
  updateReferralStatusesApi,
  deleteReferralApi,
  markAttachmentAsSeenOnReferralApi,
  addReferralProviderApi,
} from '../../api/referralsApi';
import { showErrorMessage } from '../../components/NotificationToast/NotificationToast';
import backendErrorHandler from '../../globalUtils/backendErrorHandler';
import {
  createReferralLoading,
  createReferralSuccess,
  createReferralError,
  addAttachmentLoading,
  addAttachmentSuccess,
  addAttachmentError,
  editAttachmentLoading,
  editAttachmentSuccess,
  editAttachmentError,
  createDraftReferralError,
  createDraftReferralLoading,
  createDraftReferralSuccess,
  updateOpenRow,
  getReferralCategories,
  getReferralCategoriesSuccess,
  getReferralCategoriesError,
  getReferralStatusesLoading,
  getReferralStatusesSuccess,
  getReferralStatusesError,
  editReferralLoading,
  editReferralSuccess,
  editReferralError,
  markAttachmentAsSeenOnReferralLoading,
  markAttachmentAsSeenOnReferralSuccess,
  markAttachmentAsSeenOnReferralError,
  deleteReferralLoading,
  deleteReferralError,
  deleteReferralSuccess,
  getReferralsCount,
  setAllReferralsGlobalLoading,
  setActiveReferralsGlobalLoading,
  setArchivedReferralsGlobalLoading,
} from '../actionCreators/referralActionCreators';
import { LOAD_NEW_COMMENT_FROM_SOCKET } from '../constants/commentsTypes';
import {
  FETCH_ACTIVE_REFERRAL_LOADING,
  FETCH_ACTIVE_REFERRAL_FAILURE,
  CLEAR_ALL_REFERRAL_LISTS,
  SET_ACTIVE_REFERRAL,
  CLEAR_ACTIVE_REFERRAL,
  REMOVE_REFERRAL_ITEM_FROM_LIST,
  GET_ACTIVE_REFERRALS_SUCCESS,
  GET_ARCHIVED_REFERRALS_SUCCESS,
  GET_ACTIVE_REFERRALS_LOADING,
  GET_ARCHIVED_REFERRALS_LOADING,
  GET_ACTIVE_REFERRALS_ERROR,
  GET_ARCHIVED_REFERRALS_ERROR,
  GET_ALL_REFERRALS_SUCCESS,
  GET_ALL_REFERRALS_LOADING,
  GET_ALL_REFERRALS_ERROR,
} from '../constants/referralTypes';
import { setActiveDrawer } from './activeDrawerActions';
import { loadCommentsToStoreActionCreator } from './commentActions';
import { updateInboxStatus } from './inboxActions';
import { bulkUpdateTasksAction } from './tasksActions';
import {
  addReferralProviderError,
  addReferralProviderLoading,
  addReferralProviderSuccess,
} from './referralProvidersAction';
import { addPatientApi } from '../../api/patientsApi';
import { apiErrorHandler } from '../../globalUtils/utils';
import { addPatientsError, addPatientsLoading, addPatientsSuccess } from './patientsActions';
import { getTasksFromReferralResponseData, ReferralEditFields } from '../../globalUtils/helpers';
import { ReferralProvider } from '../types';

export function getReferralCreationParams(viewModel: referralPayload) {
  const { locationToNavigateTo, activeTab, provider, patient, ...requiredParams } = viewModel;
  return {
    locationToNavigateTo,
    activeTab,
    provider,
    patient,
    requiredParams,
  };
}

export const createReferralAction =
  (
    params: referralPayload,
    onAfterCreate: (id?: string, errorMessage?: string, data?: any) => void,
    navigate?: any,
    reduxDispatch?: any,
  ) =>
  async (dispatch: Dispatch) => {
    let { provider, patient, requiredParams } = getReferralCreationParams(params);

    const markInboxAsComplete = (): void => {
      if (params?.inboxId) {
        reduxDispatch(updateInboxStatus({ status: 'COMPLETE', id: params.inboxId }));
      }
    };

    const closeDrawer = (): void => {
      dispatch(updateOpenRow({}));
      dispatch(setActiveDrawer('inbox'));
    };

    try {
      dispatch(createReferralLoading(true));

      // create temporary provider if available
      if (!params?.providerId && provider?.firstName) {
        try {
          const temporaryData = {
            isTemporary: true,
            firstName: provider?.firstName,
            lastName: provider?.lastName,
            npi: provider?.npi,
          };
          dispatch(addReferralProviderLoading());
          const response = await addReferralProviderApi(temporaryData);
          requiredParams = { ...requiredParams, providerId: response.data?.id };
          dispatch(addReferralProviderSuccess(response.data));
        } catch (e) {
          const errorMessage = backendErrorHandler(e);
          showErrorMessage(errorMessage);

          dispatch(addReferralProviderError(e));
        }
      }

      // create temporary patient if data is available available
      if (!params?.patientId && patient?.first_name) {
        try {
          const temporaryPatient = {
            isTemporary: true,
            first_name: patient?.first_name,
            last_name: patient?.last_name,
            date_of_birth: patient?.date_of_birth,
            sex: patient?.sex,
          };

          dispatch(addPatientsLoading(true));
          const response = await addPatientApi(temporaryPatient);
          requiredParams = { ...requiredParams, patientId: response.data?.id };
          dispatch(addPatientsSuccess(response.data));
        } catch (e: any) {
          const errorMessage = backendErrorHandler(e);
          showErrorMessage(errorMessage);

          apiErrorHandler(e);
          dispatch(addPatientsError(e));
        }
      }

      const { data } = await createReferral(requiredParams);
      dispatch(createReferralSuccess(data));
      markInboxAsComplete();
      closeDrawer();
      params?.locationToNavigateTo && navigate(params.locationToNavigateTo);
      onAfterCreate(data.id, '', data);
    } catch (e: any) {
      dispatch(createReferralError(e?.response?.data));
      dispatch(createReferralLoading(false));
      onAfterCreate(undefined, e?.response?.data?.message, e?.response?.data);
      const errorMessage = backendErrorHandler(e);
      showErrorMessage(errorMessage);
    }
  };

export const addAttachmentAction =
  (
    referralId: string,
    params: any,
    fileName: string,
    onSuccess: (data: string) => void,
    onFail: (data: string) => void,
    // To mark if it should be added to outgoing, useful for when sending fails, this way, it will be displayed in correct segment
    outgoing: boolean = false,
  ) =>
  async (dispatch: Dispatch) => {
    const dispatchLoading = (percentComplete?: number) => {
      dispatch(addAttachmentLoading({ loading: true, fileName, percentComplete, outgoing }));
    };

    try {
      dispatchLoading();
      const { data } = await addAttachment(params, dispatchLoading);
      dispatch(addAttachmentSuccess({ ...data, referralId }));

      if (onSuccess) {
        onSuccess(data.id);
      }
    } catch (e: any) {
      const errorMessage = backendErrorHandler(e);
      showErrorMessage(errorMessage);

      dispatch(
        addAttachmentError({
          ...e.response?.data,
          fileName,
          outgoing,
          status: e.response?.status,
          errorMessage: 'Error while uploading attachment',
        }),
      );

      if (onFail) {
        onFail(fileName);
      }
    }
  };

export const editAttachmentsAction =
  (params: editAttachmentProps, onSuccess?: () => void, onComplete?: () => void) => async (dispatch: Dispatch) => {
    try {
      dispatch(editAttachmentLoading(true));
      const { data } = await editAttachment(params);
      dispatch(editAttachmentSuccess(data));
      dispatch(editAttachmentLoading(false));

      if (onSuccess) {
        onSuccess();
      }
    } catch (e: any) {
      const errorMessage = backendErrorHandler(e);
      dispatch(editAttachmentLoading(false));
      showErrorMessage(errorMessage);
      dispatch(editAttachmentError(e.response.data));
    } finally {
      onComplete && onComplete();
    }
  };

export const createDraftReferralAction =
  (params: referralPayload, reduxDispatch: any, onAfterCreate: (id?: string, errorMessage?: string) => void) =>
  async (dispatch: Dispatch) => {
    const markInboxAsComplete = (): void => {
      if (params?.inboxId) {
        reduxDispatch(updateInboxStatus({ status: 'COMPLETE', id: params.inboxId }));
      }
    };

    const closeDrawer = (): void => {
      dispatch(updateOpenRow({}));
      dispatch(setActiveDrawer('inbox'));
    };

    try {
      dispatch(createDraftReferralLoading(true));
      let { activeTab, provider, patient, ...rest } = params;

      // create temporary provider if available
      if (!params?.providerId && provider?.firstName) {
        try {
          const temporaryData = {
            isTemporary: true,
            firstName: provider?.firstName,
            lastName: provider?.lastName,
            npi: provider?.npi,
          };
          dispatch(addReferralProviderLoading());
          const response = await addReferralProviderApi(temporaryData);
          rest = { ...rest, providerId: response.data?.id };
          dispatch(addReferralProviderSuccess(response.data));
        } catch (e) {
          const errorMessage = backendErrorHandler(e);
          showErrorMessage(errorMessage);

          dispatch(addReferralProviderError(e));
        }
      }

      // create temporary patient if data is available available
      if (!params?.patientId && patient?.first_name) {
        try {
          const temporaryPatient = {
            isTemporary: true,
            first_name: patient?.first_name,
            last_name: patient?.last_name,
            date_of_birth: patient?.date_of_birth,
            sex: patient?.sex,
          };

          dispatch(addPatientsLoading(true));
          const response = await addPatientApi(temporaryPatient);
          rest = { ...rest, patientId: response.data?.id };
          dispatch(addPatientsSuccess(response.data));
        } catch (e: any) {
          const errorMessage = backendErrorHandler(e);
          showErrorMessage(errorMessage);

          apiErrorHandler(e);
          dispatch(addPatientsError(e));
        }
      }

      const { data } = await createReferral(rest);
      dispatch(createDraftReferralSuccess(data));
      markInboxAsComplete();
      closeDrawer();
      onAfterCreate(data?.id);
    } catch (e: any) {
      const errorMessage = backendErrorHandler(e);
      showErrorMessage(errorMessage);
      dispatch(createDraftReferralError(e.response?.data));
      onAfterCreate(undefined, e?.response?.data?.message);
    } finally {
      dispatch(createDraftReferralLoading(false));
    }
  };

export const getReferralCategoriesAction = () => async (dispatch: Dispatch) => {
  try {
    dispatch(getReferralCategories());
    const response = await getReferralCategoriesApi();
    dispatch(getReferralCategoriesSuccess(response.data));
  } catch (e) {
    dispatch(getReferralCategoriesError(e));
  }
};

export const getReferralStatusesAction = () => async (dispatch: Dispatch) => {
  try {
    dispatch(getReferralStatusesLoading(true));
    const response = await getReferralStatusesApi();
    dispatch(getReferralStatusesSuccess(response.data));
  } catch (e) {
    dispatch(getReferralStatusesError(e));
  } finally {
    dispatch(getReferralStatusesLoading(false));
  }
};

export const setActiveReferral = (payload: any) => {
  return {
    type: SET_ACTIVE_REFERRAL,
    payload,
  };
};

export const clearActiveReferral = () => {
  return {
    type: CLEAR_ACTIVE_REFERRAL,
  };
};

const fetchActiveReferralLoading = (payload: boolean) => ({ type: FETCH_ACTIVE_REFERRAL_LOADING, payload });

const fetchReferralError = (payload: any) => ({ type: FETCH_ACTIVE_REFERRAL_FAILURE, payload });

export const getOneReferral =
  (id: string, disableLoading?: boolean, callBack?: () => void) => async (dispatch: Dispatch) => {
    try {
      !disableLoading && dispatch(fetchActiveReferralLoading(true));
      const { data } = await getReferralApi(id);
      dispatch(loadCommentsToStoreActionCreator(data.comments));
      dispatch(setActiveReferral(data));
      callBack && callBack();
    } catch (e: any) {
      dispatch(fetchReferralError(e.response?.data));
    } finally {
      dispatch(fetchActiveReferralLoading(false));
    }
  };

export const setActiveReferralsLoading = (isLoading: boolean) => ({
  type: GET_ACTIVE_REFERRALS_LOADING,
  payload: isLoading,
});

export const setArchivedReferralsLoading = (isLoading: boolean) => ({
  type: GET_ARCHIVED_REFERRALS_LOADING,
  payload: isLoading,
});

export const setAllReferralsLoading = (isLoading: boolean) => ({
  type: GET_ALL_REFERRALS_LOADING,
  payload: isLoading,
});

export const getActiveReferralsError = (payload: any) => ({
  type: GET_ACTIVE_REFERRALS_ERROR,
  payload,
});

export const getArchivedReferralsError = (payload: any) => ({
  type: GET_ARCHIVED_REFERRALS_ERROR,
  payload,
});

export const getAllReferralsError = (payload: any) => ({
  type: GET_ALL_REFERRALS_ERROR,
  payload,
});

export const loadActiveReferralsToStore = (payload: any) => ({
  type: GET_ACTIVE_REFERRALS_SUCCESS,
  payload,
});

export const loadArchivedReferralsToStore = (payload: any) => ({
  type: GET_ARCHIVED_REFERRALS_SUCCESS,
  payload,
});

export const loadAllReferralsToStore = (payload: any) => ({
  type: GET_ALL_REFERRALS_SUCCESS,
  payload,
});

export const clearAllReferralLists = (payload: any) => ({
  type: CLEAR_ALL_REFERRAL_LISTS,
  payload,
});

export const removeReferralFromList = (payload: any) => {
  return {
    type: REMOVE_REFERRAL_ITEM_FROM_LIST,
    payload,
  };
};

const hasActiveOrArchivedStatuses = (statuses: string[], activeOrArchivedStatuses: string[]): boolean => {
  return (
    statuses.length > 0 &&
    activeOrArchivedStatuses.length > 0 &&
    statuses.some((status: string) => activeOrArchivedStatuses.includes(status))
  );
};

const getReferrals = async (params?: any, activeStatuses?: string[], archivedStatuses?: string[]) => {
  const [activeReferralsResponse, archivedReferralsResponse, urgentReferralsResponse] = await Promise.all([
    fetchReferralsApi({
      ...params,
      statuses: activeStatuses,
    }),
    fetchReferralsApi({
      ...params,
      statuses: archivedStatuses,
    }),
    fetchReferralsApi({
      ...params,
      statuses: activeStatuses,
      priority: 'Urgent',
    }),
  ]);

  return { activeReferralsResponse, archivedReferralsResponse, urgentReferralsResponse };
};

export const fetchActiveAndArchivedReferralsAction =
  (
    isArchivedAction: boolean,
    params: any,
    activeStatuses: string[],
    archivedStatuses: string[],
    onSuccess?: () => void,
  ) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(isArchivedAction ? setArchivedReferralsLoading(true) : setActiveReferralsLoading(true));
      const hasActiveStatuses: boolean = hasActiveOrArchivedStatuses(params?.statuses || [], activeStatuses || []);
      const hasArchivedStatuses: boolean = hasActiveOrArchivedStatuses(params?.statuses || [], archivedStatuses || []);
      const hasStatusesInParams: boolean = params?.statuses?.length ? true : false;

      const activeStatusesInFilters: string[] =
        hasActiveStatuses && activeStatuses?.length
          ? (params?.statuses || []).filter((status: string) => activeStatuses.includes(status))
          : [];
      const archivedStatusesInFilters: string[] =
        hasArchivedStatuses && archivedStatuses?.length
          ? (params?.statuses || []).filter((status: string) => archivedStatuses.includes(status))
          : [];

      const pageSize = params?.pageSize || 26;

      if (isArchivedAction && !hasArchivedStatuses && archivedStatuses?.length && hasStatusesInParams) {
        dispatch(loadArchivedReferralsToStore({ hasMore: false, data: [] }));
      } else if (!isArchivedAction && !hasActiveStatuses && activeStatuses?.length && hasStatusesInParams) {
        dispatch(loadActiveReferralsToStore({ hasMore: false, data: [] }));
      } else {
        const updatedParams = params?.providers?.length
          ? { ...params, providers: params?.providers?.map((provider: ReferralProvider) => provider.id) }
          : params;
        const response = await fetchReferralsApi({
          ...updatedParams,
          statuses: isArchivedAction
            ? archivedStatusesInFilters.length
              ? archivedStatusesInFilters
              : archivedStatuses
            : activeStatusesInFilters.length
            ? activeStatusesInFilters
            : activeStatuses,
        });

        const { data } = response;
        const payload = {
          ...data,
          hasMore: data?.data?.length === pageSize,
        };
        dispatch(isArchivedAction ? loadArchivedReferralsToStore(payload) : loadActiveReferralsToStore(payload));
      }

      if (onSuccess) {
        onSuccess();
      }
    } catch (e: any) {
      const errorMessage = backendErrorHandler(e);

      showErrorMessage(errorMessage);
      dispatch(isArchivedAction ? getArchivedReferralsError(e) : getActiveReferralsError(e));
    } finally {
      dispatch(isArchivedAction ? setArchivedReferralsLoading(false) : setActiveReferralsLoading(false));
      dispatch(isArchivedAction ? setArchivedReferralsGlobalLoading(false) : setActiveReferralsGlobalLoading(false));
    }
  };

export const getReferralsCountsAction =
  (params?: any, activeStatuses?: string[], archivedStatuses?: string[]) => async (dispatch: Dispatch) => {
    try {
      const hasActiveStatuses: boolean = hasActiveOrArchivedStatuses(params?.statuses || [], activeStatuses || []);
      const hasArchivedStatuses: boolean = hasActiveOrArchivedStatuses(params?.statuses || [], archivedStatuses || []);

      const activeStatusesInParams: string[] =
        hasActiveStatuses && activeStatuses?.length
          ? (params?.statuses || []).filter((status: string) => activeStatuses.includes(status))
          : [];
      const archivedStatusesInParams: string[] =
        hasArchivedStatuses && archivedStatuses?.length
          ? (params?.statuses || []).filter((status: string) => archivedStatuses.includes(status))
          : [];

      const updatedParams = params?.providers?.length
        ? { ...params, providers: params?.providers?.map((provider: ReferralProvider) => provider.id) }
        : params;
      const { activeReferralsResponse, archivedReferralsResponse, urgentReferralsResponse } = await getReferrals(
        {
          ...updatedParams,
          pageSize: 1,
          page: 1,
          sortField: 'createdAt',
          sortDirection: 'ASC',
        },
        activeStatusesInParams.length ? activeStatusesInParams : activeStatuses,
        archivedStatusesInParams.length ? archivedStatusesInParams : archivedStatuses,
      );

      dispatch(
        getReferralsCount({
          activeTotalCount: hasArchivedStatuses && !hasActiveStatuses ? 0 : activeReferralsResponse?.data?.totalCount,
          archivedTotalCount:
            hasActiveStatuses && !hasArchivedStatuses ? 0 : archivedReferralsResponse?.data?.totalCount,
          urgentTotalCount: hasActiveStatuses && !hasArchivedStatuses ? 0 : urgentReferralsResponse?.data?.totalCount,
        }),
      );
    } catch (e: any) {
      const errorMessage = backendErrorHandler(e);
      showErrorMessage(errorMessage);
    }
  };

export const fetchAllReferralsAction =
  (params: any, callBack?: (result?: any) => void) => async (dispatch: Dispatch) => {
    try {
      dispatch(setAllReferralsLoading(true));

      const pageSize = params?.pageSize || 26;
      const updatedParams = params?.providers?.length
        ? { ...params, providers: params?.providers?.map((provider: ReferralProvider) => provider.id) }
        : params;

      const response = await fetchReferralsApi(updatedParams);

      const { data } = response;
      const payload = {
        ...data,
        hasMore: data?.data?.length === pageSize,
      };

      dispatch(loadAllReferralsToStore(payload));
      callBack && callBack(payload);
    } catch (error: any) {
      const errorMessage: string = backendErrorHandler(error);
      showErrorMessage(errorMessage);

      dispatch(getAllReferralsError(error));
    } finally {
      dispatch(setAllReferralsLoading(false));
      dispatch(setAllReferralsGlobalLoading(false));
    }
  };

const getReferralFieldUpdated = (payload: any): ReferralEditFields => {
  if (payload?.priority) {
    return ReferralEditFields.PRIORITY;
  }

  if (payload?.regionId) {
    return ReferralEditFields.REGION;
  }

  if (payload?.homeCity) {
    return ReferralEditFields.HOME_CITY;
  }

  if (payload?.clinicId || payload?.clinicId === null) {
    return ReferralEditFields.CLINIC;
  }

  return ReferralEditFields.ANY;
};

export const updateReferralAction =
  (params: any, onSuccess?: (responseData?: any) => void) => async (dispatch: Dispatch) => {
    try {
      dispatch(
        editReferralLoading({ isLoading: true, field: getReferralFieldUpdated(params), referralId: params?.id }),
      );
      const response = await editReferralApi(params);
      dispatch(setActiveReferral(response.data));
      dispatch(editReferralSuccess(response.data));
      dispatch(bulkUpdateTasksAction(getTasksFromReferralResponseData(response.data)));
      if (onSuccess) {
        onSuccess(response.data);
      }
    } catch (e: any) {
      const errorMessage = backendErrorHandler(e);
      showErrorMessage(errorMessage);
      dispatch(editReferralError(e?.response?.data));
    } finally {
      const field: ReferralEditFields = getReferralFieldUpdated(params);

      if (![ReferralEditFields.REGION].includes(field)) {
        dispatch(editReferralLoading({ isLoading: false, field, referralId: params?.id }));
      }
    }
  };

interface Props {
  attachmentId: string;
  referralId: string;
  seen: boolean;
}
export const markAttachmentAsSeenOnReferralAction =
  (params: Props, onSuccess?: () => void, onError?: () => void) => async (dispatch: Dispatch) => {
    try {
      dispatch(markAttachmentAsSeenOnReferralLoading());
      const response = await markAttachmentAsSeenOnReferralApi(params);
      dispatch(markAttachmentAsSeenOnReferralSuccess({ ...response.data, seen: params.seen }));

      if (onSuccess) {
        onSuccess();
      }
    } catch (e: any) {
      if (onError) {
        onError();
      }
      const errorMessage = backendErrorHandler(e);
      showErrorMessage(errorMessage);
      dispatch(markAttachmentAsSeenOnReferralError(e?.response?.data));
    }
  };

export const updateReferralStatusesAction =
  (referralId: string, payload: any, onComplete?: (responseData?: any) => void) => async (dispatch: any) => {
    try {
      dispatch(editReferralLoading({ isLoading: true, field: ReferralEditFields.STATUS, referralId }));
      const response = await updateReferralStatusesApi(referralId, payload);
      dispatch(setActiveReferral(response.data));
      dispatch(editReferralSuccess(response.data));
      dispatch(bulkUpdateTasksAction(response?.data?.tasks));
      dispatch(bulkUpdateTasksAction(getTasksFromReferralResponseData(response?.data)));

      onComplete && onComplete(response.data);
    } catch (e: any) {
      const errorMessage = backendErrorHandler(e);
      showErrorMessage(errorMessage);
      dispatch(editReferralError(e?.response?.data));

      onComplete && onComplete();
    } finally {
      dispatch(editReferralLoading({ isLoading: false, field: ReferralEditFields.STATUS, referralId }));
    }
  };

export const deleteReferralAction =
  (referralId: string, callBack?: (responseData?: any) => void) => async (dispatch: Dispatch) => {
    try {
      dispatch(deleteReferralLoading(true));
      const response = await deleteReferralApi(referralId);
      dispatch(
        deleteReferralSuccess({
          ...response.data,
          id: referralId,
        }),
      );
      callBack && callBack(response.data);
    } catch (e: any) {
      const errorMessage = backendErrorHandler(e);
      showErrorMessage(errorMessage);
      dispatch(deleteReferralError(e?.response?.data));
    } finally {
      dispatch(deleteReferralLoading(false));
    }
  };

export const loadNewCommentFromSocket = (payload: any) => {
  return {
    type: LOAD_NEW_COMMENT_FROM_SOCKET,
    payload,
  };
};

export type referralSubType = 'Normal Referral' | 'Drug Change' | 'Dose Change' | 'Normal Renewal';
type priority = 'Urgent' | 'Normal';

export interface referralPayload {
  firstName?: string;
  lastName?: string;
  dateOfBirth?: Date | string;
  clinicId?: string;
  inboxId?: string;
  patientId?: string;
  regionId: string;
  primaryInsuranceCarrier?: string;
  insuranceId?: string;
  diagnosis?: string;
  canceledReason?: string;
  notCompletedReason?: string;
  providerFax?: string;
  providerId?: string;
  drugTierId: string;
  taskTemplateId: string;
  medicationId: string;
  referralType?: 'Referral' | 'Renewal';
  referralSubType?: referralSubType;
  homeCity?: string;
  providerGroup?: string;
  lastPatientContactDate?: string;
  faxReceivedDate?: string;
  lastFollowupDate?: string;
  nextFollowupDate?: string;
  intakeCompletedDate?: string;
  scheduledDate?: string;
  appointmentDate?: string;
  agingCounter?: string;
  categoryId: string;
  assignedUserId?: string;
  statusId?: string;
  priority?: priority;
  completedByUserId?: string;
  //used in front-end only
  locationToNavigateTo?: string;
  attachmentIds?: string[];
  activeTab?: string;
  sex?: string;
  provider?: {
    npi?: string;
    firstName?: string;
    lastName?: string;
  };
  patient?: {
    first_name?: string;
    last_name?: string;
    date_of_birth?: string | Date;
    sex?: 'Male' | 'Female' | 'Unknown' | '';
  };
  acceptanceStatus?: string;
}

export interface editAttachmentProps {
  id: string;
  referralId?: string;
  categoryIdsToAdd?: string[];
  categoryIdsToRemove?: string[];
}
