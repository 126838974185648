import { Header } from '../../components/Header';
import {
  AppBar,
  Box,
  Button,
  ButtonGroup,
  DialogContent,
  Drawer,
  SwipeableDrawer,
  Tab,
  Typography,
} from '@mui/material';
import React, { useState, MouseEvent, useEffect, useMemo } from 'react';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useStyles } from './ReferralStyles';
import ReferralView from '../../components/ReferralView/ReferralView';
import { Referral } from '../../components/Referral';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { RootState } from '../../redux/types';
import { fetchRegionsAction } from '../../redux/actions/regionsActions';
import { fetchUsersAction } from '../../redux/actions/userActions';
import { clearActiveReferral, setActiveReferral } from '../../redux/actions/referralsActions';
import ReferralsTable from './ReferralsTable';
import { useGetFilteredReferralsList, useSearchOnChangeHandler } from '../../globalUtils/hooks';
import { clearAttachmentState, clearReferralFilters } from '../../redux/actionCreators/referralActionCreators';
import { Search } from '../../components/Search';
import { updateReferralFilters } from '../../redux/actions/referralProvidersAction';
import ReferralFilterIndicator from '../../components/ReferralFilterIndicator/ReferralFilterIndicator';
import { getTaskTypesTaskAction, getTaskStatusListAction } from '../../redux/actions/tasksActions';
import { getTaskTemplatesAction } from '../../redux/actions/taskTemplatesActions';
import { loadCommentsToStoreActionCreator } from '../../redux/actions/commentActions';
import { MAIN_PAGES } from '../../globalUtils/utils';
import ConfirmationModal from '../../components/ConfirmationModal/ConfirmationModal';
import { fetchStatusReasons } from '../../redux/actions/statusReasonsActions';
import { get } from 'lodash';
import { getUserPreferencesAction } from '../../redux/actions/userPreferencesAction';
import { getMedicalRecordsRequestOptions } from '../../redux/actions/outgoingFilesAction';
import DialogAlert from '../../components/Alerts/DialogAlert';
import { hideWeInfuseConnectionAlert as hideWeInfuseConnectionAlertAction } from '../../redux/actionCreators/weInfuseActionCreators';
import { ReferralType, ReferralTypeMenu } from '../../components/Referral/ReferralTypeMenu';

export const statuses = {
  ACTIVE: 'ACTIVE',
  ARCHIVED: 'ARCHIVED',
  URGENT: 'URGENT',
};

const ReferralsView = () => {
  const classes = useStyles();
  const [activeRow, setActiveRow] = useState<any>({});
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>();
  const [referralType, setReferralType] = useState<ReferralType>({
    referralType: 'Referral',
    referralSubType: 'Normal Referral',
  });
  const [swipeAbleDrawerIsOpen, setSwipeAbleDrawerIsOpen] = useState(false);
  let [searchParams, setSearchParams] = useSearchParams();
  const [tabValue, setTabValue] = useState(searchParams.get('tab')?.toUpperCase() || statuses.ACTIVE);
  const [isAlertOpen, setIsAlertOpen] = useState<boolean>(false);
  const [hasFetchedSavedFilters, setHasFetchedSavedFilters] = useState<boolean>(false);
  const createReferralSuccess = useSelector(
    (state: RootState) => state.referrals.success || state.referrals?.draftReferrals?.success,
  );
  const navigate = useNavigate();
  const {
    referrals: { activeTotalCount, archivedTotalCount, urgentTotalCount, activeReferralsList, archivedReferralsList },
    weInfuse: { hideWeInfuseConnectionAlert, enabled: weInfuseEnabled },
  } = useSelector((state: RootState) => state);
  const { globalLoading: activeGlobalLoading } = activeReferralsList;
  const { globalLoading: archivedGlobalLoading } = archivedReferralsList;
  const attachments = useSelector((state: RootState) => state.referrals.attachments);
  const filteredArchivedReferralsList: any[] = useGetFilteredReferralsList(true);
  const filteredActiveReferralsList: any[] = useGetFilteredReferralsList(false);
  const {
    onChange: handleSearchChange,
    searchValue,
    onClearSearch,
  } = useSearchOnChangeHandler((value: string) => dispatch(updateReferralFilters({ search: value })));
  const showWeInfuseAlert: boolean = useMemo(() => {
    return !weInfuseEnabled && !hideWeInfuseConnectionAlert;
  }, [hideWeInfuseConnectionAlert, weInfuseEnabled]);

  const onClickRow = (row: any) => {
    dispatch(setActiveReferral(row));
    setActiveRow(row);
    setSearchParams({ referralId: row.id, tab: tabValue });
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchRegionsAction());
    dispatch(fetchUsersAction());
    dispatch(getTaskTemplatesAction());
    dispatch(getTaskTypesTaskAction());
    dispatch(getTaskStatusListAction());
    dispatch(fetchStatusReasons());
    dispatch(getMedicalRecordsRequestOptions());
    dispatch(
      getUserPreferencesAction('referrals', (responseData: any) => {
        let savedFilters = {};

        get(responseData, 'filters', []).forEach((filter: any) => {
          savedFilters = {
            ...savedFilters,
            [filter.field]: filter.value,
          };
        });

        dispatch(updateReferralFilters(savedFilters));
        setHasFetchedSavedFilters(true);
      }),
    );
  }, [dispatch]);

  const referralId = searchParams.get('referralId');

  useEffect(() => {
    if (referralId) {
      setActiveRow({ id: referralId });
    }
  }, [referralId]);

  useEffect(() => {
    if (createReferralSuccess) {
      setSwipeAbleDrawerIsOpen(false);
    }
  }, [createReferralSuccess]);

  useEffect(() => {
    return () => {
      dispatch(clearReferralFilters());
    };
  }, [dispatch]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
    setSearchParams({ tab: newValue });
  };

  const isMenuOpen = Boolean(anchorEl);
  const openMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenDrawer = (referralType: ReferralType) => {
    setSwipeAbleDrawerIsOpen(true);
    setReferralType(referralType);
    handleClose();
  };

  const onCloseDrawer = () => {
    setIsAlertOpen(!isAlertOpen);
  };

  const handleOnCancel = () => {
    setIsAlertOpen(false);
  };

  const handleOnAccept = () => {
    handleOnCancel();
    setSwipeAbleDrawerIsOpen(!swipeAbleDrawerIsOpen);
    dispatch(clearAttachmentState());
    handleClose();
  };

  const [isEditting, setIsEditting] = useState<boolean>(false);
  const handleReferralViewClose = () => {
    if (!isEditting) {
      dispatch(loadCommentsToStoreActionCreator([]));
      setActiveRow({});
      dispatch(clearAttachmentState());
      dispatch(clearActiveReferral());
      navigate(`/referrals?tab=${tabValue}`);
    }
  };

  return (
    <>
      <Header activeTab="referrals" />
      <Drawer
        classes={{
          paper: classes.drawer,
        }}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 3 }}
        anchor={'right'}
        onClose={isEditting ? undefined : handleReferralViewClose}
        open={!!activeRow?.id}
      >
        {showWeInfuseAlert && <Box style={{ height: 48 }} />}
        <DialogContent style={{ padding: 0 }}>
          <ReferralView
            referralId={activeRow?.id}
            onClose={handleReferralViewClose}
            activeReferralsTab={tabValue}
            page={MAIN_PAGES.REFERRALS}
            isEditting={isEditting}
            setIsEditting={setIsEditting}
          />
        </DialogContent>
      </Drawer>
      <TabContext value={tabValue}>
        <AppBar
          position="sticky"
          sx={{ boxShadow: '0px 2px 16px rgba(0, 0, 0, 0.08)', top: 0 }}
          color="transparent"
          elevation={2}
        >
          <Box className={classes.header}>
            <Box className={classes.pageLabel} display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="h4">All Referrals</Typography>
              <Box>
                <ButtonGroup>
                  <Button
                    onClick={(event: MouseEvent<HTMLButtonElement>) => openMenu(event)}
                    size="small"
                    variant="contained"
                  >
                    <Typography style={{ padding: '16px' }} variant="button">
                      Create
                    </Typography>
                  </Button>
                  <Button
                    onClick={(event: MouseEvent<HTMLButtonElement>) => openMenu(event)}
                    classes={{ root: classes.darkPrimary }}
                    size="small"
                    variant="contained"
                  >
                    <ArrowDropDownIcon fontSize="large" />
                  </Button>
                  <ReferralTypeMenu
                    anchorEl={anchorEl}
                    onChange={handleOpenDrawer}
                    open={isMenuOpen}
                    onCancel={handleClose}
                  />
                </ButtonGroup>
              </Box>
            </Box>
            <Box display="flex" justifyContent="space-between">
              <Box>
                <TabList onChange={handleChange} aria-label="lab API tabs example" data-id="tabReferrals">
                  <Tab label={`Active (${activeTotalCount})`} value={statuses.ACTIVE} />
                  <Tab label={`Archived (${archivedTotalCount})`} value={statuses.ARCHIVED} />
                  <Tab label={`Urgent (${urgentTotalCount})`} value={statuses.URGENT} />
                </TabList>
              </Box>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <ReferralFilterIndicator />
                <Search
                  placeholder="Search Referrals"
                  value={searchValue}
                  onChange={handleSearchChange}
                  data-id="searchReferral"
                  showClearSearchIcon={searchValue?.length > 0}
                  onClearSearch={onClearSearch}
                />
              </Box>
            </Box>
          </Box>
        </AppBar>
        <TabPanel
          style={{
            boxSizing: 'border-box',
            padding: 0,
            width: '100vw',
            overflowX: 'hidden',
            overflowY: 'hidden',
            position: 'relative',
          }}
          value={statuses.ACTIVE}
        >
          <Box
            style={{
              height: `calc(100vh - ${showWeInfuseAlert ? 241 : 188}px)`,
              overflowY: 'hidden',
              overflowX: 'hidden',
            }}
            data-id="tblActiveReferrals"
          >
            <ReferralsTable
              status={statuses.ACTIVE}
              onSelectRow={onClickRow}
              data={filteredActiveReferralsList}
              globalLoading={activeGlobalLoading}
              hasFetchedSavedFilters={hasFetchedSavedFilters}
            />
          </Box>
        </TabPanel>
        <TabPanel
          style={{
            boxSizing: 'border-box',
            padding: 0,
            width: '100vw',
            overflowX: 'hidden',
            overflowY: 'hidden',
            position: 'relative',
          }}
          value={statuses.ARCHIVED}
        >
          <Box
            style={{
              height: `calc(100vh - ${showWeInfuseAlert ? 241 : 188}px)`,
              overflowY: 'hidden',
              overflowX: 'hidden',
            }}
            data-id="tblArchivedReferrals"
          >
            <ReferralsTable
              hideHighlights
              status={statuses.ARCHIVED}
              onSelectRow={onClickRow}
              data={filteredArchivedReferralsList}
              globalLoading={archivedGlobalLoading}
              hasFetchedSavedFilters={hasFetchedSavedFilters}
            />
          </Box>
        </TabPanel>
        {/* this tab contains active referrals with urgent priority */}
        <TabPanel
          style={{
            boxSizing: 'border-box',
            padding: 0,
            width: '100vw',
            overflowX: 'hidden',
            overflowY: 'hidden',
            position: 'relative',
          }}
          value={statuses.URGENT}
        >
          <Box
            style={{
              height: `calc(100vh - ${showWeInfuseAlert ? 241 : 188}px)`,
              overflowY: 'hidden',
              overflowX: 'hidden',
            }}
            data-id="tblUrgentReferrals"
          >
            {/* use active referral logic to maintain this, check if url is urgent, send api with priority urgent then */}
            <ReferralsTable
              status={statuses.URGENT}
              onSelectRow={onClickRow}
              data={filteredActiveReferralsList}
              globalLoading={activeGlobalLoading}
              hasFetchedSavedFilters={hasFetchedSavedFilters}
            />
          </Box>
        </TabPanel>
      </TabContext>
      <SwipeableDrawer
        anchor={'right'}
        open={swipeAbleDrawerIsOpen}
        classes={{
          paper: classes.drawer,
        }}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 3 }}
        onClose={onCloseDrawer}
        onOpen={console.log}
        SwipeAreaProps={{
          style: { pointerEvents: 'none' },
        }}
      >
        {showWeInfuseAlert && (
          <DialogAlert
            onCancel={() => {
              dispatch(hideWeInfuseConnectionAlertAction(true));
            }}
            style={{ height: 53 }}
          />
        )}
        <DialogContent style={{ padding: 0 }}>
          <ConfirmationModal
            actionButtonText="CONFIRM AND DELETE"
            titleText="Are you sure you want to close this unsaved draft?"
            contentText="All the entered information in this referral will be deleted"
            cancelButtonText="CANCEL"
            dialogProps={{
              open: isAlertOpen,
            }}
            onCancelButtonClick={handleOnCancel}
            onActionButtonClick={handleOnAccept}
          />
          <Referral
            attachments={attachments}
            referralType={referralType}
            onClickBack={onCloseDrawer}
            hideComments
            onReferralSwitchClick={(event: any) => openMenu(event)}
            activeTab={tabValue}
          />
        </DialogContent>
      </SwipeableDrawer>
    </>
  );
};

export default ReferralsView;
