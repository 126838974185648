export const GET_CLINICS_LOADING = 'GET_CLINICS_LOADING';
export const GET_CLINICS_SUCCESS = 'GET_CLINICS_SUCCESS';
export const GET_CLINICS_ERROR = 'GET_CLINICS_ERROR';

export const GET_ATTACHMENTS_CATEGORIES = 'GET_ATTACHMENTS_CATEGORIES';
export const GET_ATTACHMENTS_CATEGORIES_SUCCESS = 'GET_ATTACHMENTS_CATEGORIES_SUCCESS';
export const GET_ATTACHMENTS_CATEGORIES_ERROR = 'GET_ATTACHMENTS_CATEGORIES_ERROR';

export const REMOVE_ATTACHMENT = 'REMOVE_ATTACHMENT';
export const REMOVE_ATTACHMENT_SUCCESS = 'REMOVE_ATTACHMENT_SUCCESS';
export const REMOVE_ATTACHMENT_ERROR = 'REMOVE_ATTACHMENT_ERROR';

export const GET_PATIENTS = 'GET_PATIENTS';
export const GET_PATIENTS_SUCCESS = 'GET_PATIENTS_SUCCESS';
export const GET_PATIENTS_ERROR = 'GET_PATIENTS_ERROR';

export const SEARCH_PATIENTS = 'SEARCH_PATIENTS';
export const SEARCH_PATIENTS_SUCCESS = 'SEARCH_PATIENTS_SUCCESS';
export const SEARCH_PATIENTS_ERROR = 'SEARCH_PATIENTS_ERROR';
export const CLEAR_PATIENTS_FROM_SEARCH = 'CLEAR_PATIENTS_FROM_SEARCH';

export const GET_MEDICATIONS_LOADING = 'GET_MEDICATIONS_LOADING';
export const GET_MEDICATIONS_SUCCESS = 'GET_MEDICATIONS_SUCCESS';
export const GET_MEDICATIONS_ERROR = 'GET_MEDICATIONS_ERROR';

export const GET_DRUG_TIERS = 'GET_DRUG_TIERS';
export const GET_DRUG_TIERS_SUCCESS = 'GET_DRUG_TIERS_SUCCESS';
export const GET_DRUG_TIERS_ERROR = 'GET_DRUG_TIERS_ERROR';

export const GET_TASK_TEMPLATES = 'GET_TASK_TEMPLATES';
export const GET_TASK_TEMPLATES_SUCCESS = 'GET_TASK_TEMPLATES_SUCCESS';
export const GET_TASK_TEMPLATES_ERROR = 'GET_TASK_TEMPLATES_ERROR';

export const ADD_PATIENTS = 'ADD_PATIENTS';
export const ADD_PATIENTS_SUCCESS = 'ADD_PATIENTS_SUCCESS';
export const ADD_PATIENTS_ERROR = 'ADD_PATIENTS_ERROR';

export const EDIT_PATIENT = 'EDIT_PATIENT';
export const EDIT_PATIENT_SUCCESS = 'EDIT_PATIENT_SUCCESS';
export const EDIT_PATIENT_ERROR = 'EDIT_PATIENT_ERROR';

export const DELETE_PATIENT = 'DELETE_PATIENT';
export const DELETE_PATIENT_SUCCESS = 'DELETE_PATIENT_SUCCESS';
export const DELETE_PATIENT_ERROR = 'DELETE_PATIENT_ERROR';

export const CREATE_REFERRAL = 'CREATE_REFERRAL';
export const CREATE_REFERRAL_SUCCESS = 'CREATE_REFERRAL_SUCCESS';
export const CREATE_REFERRAL_ERROR = 'CREATE_REFERRAL_ERROR';

export const EDIT_REFERRAL_LOADING = 'EDIT_REFERRAL_LOADING';
export const EDIT_REFERRAL_SUCCESS = 'EDIT_REFERRAL_SUCCESS';
export const EDIT_REFERRAL_ERROR = 'EDIT_REFERRAL_ERROR';

export const MARK_ATTACHMENT_AS_SEEN_ON_REFERRAL = 'MARK_ATTACHMENT_AS_SEEN_ON_REFERRAL';
export const MARK_ATTACHMENT_AS_SEEN_ON_REFERRAL_SUCCESS = 'MARK_ATTACHMENT_AS_SEEN_ON_REFERRAL_SUCCESS';
export const MARK_ATTACHMENT_AS_SEEN_ON_REFERRAL_ERROR = 'MARK_ATTACHMENT_AS_SEEN_ON_REFERRAL_ERROR';

export const EDIT_ATTACHMENT_LOADING = 'EDIT_ATTACHMENT_LOADING';
export const EDIT_ATTACHMENT_SUCCESS = 'EDIT_ATTACHMENT_SUCCESS';
export const EDIT_ATTACHMENT_ERROR = 'EDIT_ATTACHMENT_ERROR';

export const ADD_ATTACHMENTS = 'ADD_ATTACHMENTS';
export const ADD_ATTACHMENTS_SUCCESS = 'ADD_ATTACHMENTS_SUCCESS';
export const CLEAR_ATTACHMENTS_STATE = 'CLEAR_ATTACHMENTS_STATE';
export const ADD_ATTACHMENTS_ERROR = 'ADD_ATTACHMENTS_ERROR';
export const UPDATE_ATTACHMENT_FAX = 'UPDATE_ATTACHMENT_FAX';

export const CREATE_DRAFT_REFERRAL = 'CREATE_DRAFT_REFERRAL';
export const CREATE_DRAFT_REFERRAL_SUCCESS = 'CREATE_DRAFT_REFERRAL_SUCCESS';
export const CREATE_DRAFT_REFERRAL_ERROR = 'CREATE_DRAFT_REFERRAL_ERROR';

export const SET_ACTIVE_REFERRAL = 'SET_ACTIVE_REFERRAL';
export const FETCH_ACTIVE_REFERRAL_LOADING = 'FETCH_ACTIVE_REFERRAL_LOADING';
export const FETCH_ACTIVE_REFERRAL_FAILURE = 'FETCH_ACTIVE_REFERRAL_FAILURE';
export const CLEAR_ACTIVE_REFERRAL = 'CLEAR_ACTIVE_REFERRAL';

export const CLEAR_ALL_REFERRAL_LISTS = 'CLEAR_ALL_REFERRAL_LISTS';
export const REMOVE_REFERRAL_ITEM_FROM_LIST = 'REMOVE_REFERRAL_ITEM_FROM_LIST';
export const GET_ARCHIVED_REFERRALS_LOADING = 'GET_ARCHIVED_REFERRALS_LOADING';
export const GET_ARCHIVED_REFERRALS_SUCCESS = 'GET_ARCHIVED_REFERRALS_SUCCESS';
export const GET_ARCHIVED_REFERRALS_ERROR = 'GET_ARCHIVED_REFERRALS_ERROR';
export const GET_ACTIVE_REFERRALS_LOADING = 'GET_ACTIVE_REFERRALS_LOADING';
export const GET_ACTIVE_REFERRALS_SUCCESS = 'GET_ACTIVE_REFERRALS_SUCCESS';
export const GET_ACTIVE_REFERRALS_ERROR = 'GET_ACTIVE_REFERRALS_ERROR';
export const GET_ALL_REFERRALS_LOADING = 'GET_ALL_REFERRALS_LOADING';
export const GET_ALL_REFERRALS_SUCCESS = 'GET_ALL_REFERRALS_SUCCESS';
export const GET_ALL_REFERRALS_ERROR = 'GET_ALL_REFERRALS_ERROR';

export const GET_REFERRAL_CATEGORIES = 'GET_REFERRAL_CATEGORIES';
export const GET_REFERRAL_CATEGORIES_SUCCESS = 'GET_REFERRAL_CATEGORIES_SUCCESS';
export const GET_REFERRAL_CATEGORIES_ERROR = 'GET_REFERRAL_CATEGORIES_ERROR';

export const GET_REFERRAL_STATUSES_LOADING = 'GET_REFERRAL_STATUSES_LOADING';
export const GET_REFERRAL_STATUSES_SUCCESS = 'GET_REFERRAL_STATUSES_SUCCESS';
export const GET_REFERRAL_STATUSES_ERROR = 'GET_REFERRAL_STATUSES_ERROR';

export const GET_REFERRAL_PROVIDERS_SUCCESS = 'GET_REFERRAL_PROVIDERS_SUCCESS';
export const GET_REFERRAL_PROVIDERS_ERROR = 'GET_REFERRAL_PROVIDERS_ERROR';
export const GET_ALL_REFERRALS_PROVIDERS_SUCCESS = 'GET_ALL_REFERRALS_PROVIDERS_SUCCESS';
export const GET_ALL_REFERRALS_PROVIDERS_ERROR = 'GET_ALL_REFERRALS_PROVIDERS_ERROR';
export const GET_REFERRAL_PROVIDERS_LOADING = 'GET_REFERRAL_PROVIDERS_LOADING';
export const CLEAR_PROVIDERS_FROM_SEARCH = 'CLEAR_PROVIDERS_FROM_SEARCH';

export const UPDATE_REFERRAL_PROVIDER_SUCCESS = 'UPDATE_REFERRAL_PROVIDER_SUCCESS';
export const UPDATE_REFERRAL_PROVIDER_ERROR = 'UPDATE_REFERRAL_PROVIDER_ERROR';
export const UPDATE_REFERRAL_PROVIDER_LOADING = 'UPDATE_REFERRAL_PROVIDER_LOADING';

export const DELETE_REFERRAL_PROVIDER_SUCCESS = 'DELETE_REFERRAL_PROVIDER_SUCCESS';
export const DELETE_REFERRAL_PROVIDER_ERROR = 'DELETE_REFERRAL_PROVIDER_ERROR';
export const DELETE_REFERRAL_PROVIDER_LOADING = 'DELETE_REFERRAL_PROVIDER_LOADING';

export const GET_ONE_REFERRAL_PROVIDER = 'GET_ONE_REFERRAL_PROVIDER';
export const GET_ONE_REFERRAL_PROVIDER_SUCCESS = 'GET_ONE_REFERRAL_PROVIDER_SUCCESS';
export const GET_ONE_REFERRAL_PROVIDER_ERROR = 'GET_ONE_REFERRAL_PROVIDER_ERROR';

export const ADD_REFERRAL_PROVIDER_SUCCESS = 'ADD_REFERRAL_PROVIDER_SUCCESS';
export const ADD_REFERRAL_PROVIDER_ERROR = 'ADD_REFERRAL_PROVIDER_ERROR';
export const ADD_REFERRAL_PROVIDER_LOADING = 'ADD_REFERRAL_PROVIDER_LOADING';

export const UPDATE_REFERRAL_FILTERS = 'UPDATE_REFERRAL_FILTERS';
export const CLEAR_REFERRAL_FILTERS = 'CLEAR_REFERRAL_FILTERS';

export const DELETE_REFERRAL_LOADING = 'DELETE_REFERRAL_LOADING';
export const DELETE_REFERRAL_ERROR = 'DELETE_REFERRAL_ERROR';
export const DELETE_REFERRAL_SUCCESS = 'DELETE_REFERRAL_SUCCESS';

export const GET_TASK_STATUS_LIST = 'GET_TASK_STATUS_LIST';
export const UPDATE_TASK_STATUS_LIST = 'UPDATE_TASK_STATUS_LIST';
export const UPDATE_ACTIVE_REFERRAL_TASKS = 'UPDATE_ACTIVE_REFERRAL_TASKS';

export const GET_REFERRALS_COUNT = 'GET_REFERRALS_COUNT';

export const SET_ARCHIVED_REFERRALS_GLOBAL_LOADING = 'SET_ARCHIVED_REFERRALS_GLOBAL_LOADING';
export const SET_ACTIVE_REFERRALS_GLOBAL_LOADING = 'SET_ACTIVE_REFERRALS_GLOBAL_LOADING';
export const SET_ALL_REFERRALS_GLOBAL_LOADING = 'SET_ALL_REFERRALS_GLOBAL_LOADING';

export const GET_INSURANCES = 'GET_INSURANCES';
export const GET_INSURANCES_ERROR = 'GET_INSURANCES_ERROR';
export const GET_INSURANCES_SUCCESS = 'GET_INSURANCES_SUCCESS';

export const GET_HOMECITY = 'GET_HOMECITY';
export const GET_HOMECITY_ERROR = 'GET_HOMECITY_ERROR';
export const GET_HOMECITY_SUCCESS = 'GET_HOMECITY_SUCCESS';
export const CLEAR_HOME_CITIES = 'CLEAR_HOME_CITIES';
