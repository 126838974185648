import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom';
import { RootState } from '../../redux/types';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { loadIdentityInfo, refreshJWT, setAuthRedirectRoute } from '../../redux/actions/authActions';
import { useIdleTimer, workerTimers } from 'react-idle-timer';
import { logoutAction } from '../../globalUtils/utils';

const AuthHOC = (Component: FC) => {
  const Authenticated: FC = () => {
    const dispatch = useDispatch();
    const { token, refreshToken } = useSelector(({ auth }: RootState) => auth);
    const navigate = useNavigate();
    const location = useLocation();

    const handleOnIdle = () => {
      console.log('logging out due to inactivity');
      dispatch(logoutAction());
    };

    useIdleTimer({
      timeout: 3300000, // 55 mins
      onIdle: handleOnIdle,
      crossTab: true,
      // stopOnIdle: true,
      timers: workerTimers,
    });

    useEffect(() => {
      if (location.pathname !== '/') {
        dispatch(setAuthRedirectRoute(`${location.pathname}${location.search}`));
      }
    }, [dispatch, location, location.pathname]);

    useEffect(
      () => loadSession({ token, refreshToken, dispatch, navigate, now: new Date() }),
      [token, dispatch, navigate, refreshToken],
    );

    useEffect(() => {
      const interceptor = axios.interceptors.response.use(undefined, (error) => {
        const response = error.response;
        if (String(response.config?.url).startsWith(String(process.env.REACT_APP_API_URL))) {
          if (response.status === 401) {
            console.log('Session invalid, logging out');
            dispatch(logoutAction());
          }
        }
        throw error;
      });

      return () => axios.interceptors.response.eject(interceptor);
    }, [dispatch]);

    // add token to all requests
    useEffect(() => {
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    }, [navigate, token]);
    return token ? <Component /> : <h1>...</h1>;
  };
  return <Authenticated />;
};
export default AuthHOC;

export function getMsUntilRefresh(jwtExpiry: number, now: Date) {
  const expiryTime = jwtExpiry * 1000;
  return expiryTime - now.getTime() - 10 * 60 * 1000;
}

export function loadSession({
  token,
  refreshToken,
  dispatch,
  navigate,
  now,
}: {
  token: any;
  refreshToken: any;
  dispatch: any;
  navigate: NavigateFunction;
  now: Date;
}) {
  if (token) {
    try {
      const decoded = jwtDecode(token) as any;
      const msUntilRefresh = getMsUntilRefresh(decoded.exp, now);
      if (msUntilRefresh > 0) {
        dispatch(loadIdentityInfo(decoded));
        if (refreshToken) {
          const refreshHandler = setTimeout(() => {
            dispatch(refreshJWT(refreshToken));
          }, msUntilRefresh);

          return () => clearTimeout(refreshHandler);
        }
      } else {
        dispatch(logoutAction());
      }
    } catch (e) {
      console.error(e);
      dispatch(logoutAction());
    }
  } else {
    navigate('/');
  }
}
