import { MentionComponentProps } from './MentionTypes';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { MentionsInput, Mention } from 'react-mentions';
import { mentionStyles, styles, useStyles } from './MentionStyles';
import { Box, CircularProgress, IconButton, Typography, Button, Checkbox } from '@mui/material';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import sendIcon from '../../images/send.svg';
import sendIconPrimary from '../../images/sendIconprimary.svg';
import { useTheme } from '@mui/styles';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/types';
import CustomAvatar from '../Avatar/Avatar';
import { regexEscape } from '../../globalUtils/utils';
import { isEmpty } from 'lodash';

let container: any;

const MentionComponent: FC<MentionComponentProps> = (props) => {
  const [mentions, setMentions] = useState<any[]>([]);
  const [focussed, setFocussed] = useState<boolean>(false);
  const {
    placeholder,
    data,
    id,
    onSend,
    loading,
    isExpanded,
    secondRowItems,
    hideSend,
    children,
    disabled,
    showMentionTrigger,
    useMentionSendIcon,
    onSelectPublishToEMR,
    publishToEMRSelected,
    defaultValue,
    hidePublishToEMR,
    disablePublishToEMR,
    enableCancelButton,
    onCancelButtonClick,
    submitButtonLabel,
  } = props;
  const [value, setValue] = useState<string>(defaultValue?.toString() || '');
  const typedValueIsEmpty: boolean = useMemo(() => {
    return isEmpty(value?.trim()) && isEmpty(defaultValue);
  }, [defaultValue, value]);

  const onChange = (event: any, newValue: string, newPlainTextValue: string, mentions: any) => {
    const regex = new RegExp(regexEscape(props?.startValue) || '', 'g');
    if (regex.test(event.target.value)) {
      const val = event.target.value?.replace(regex, '');
      setValue(val);
      setMentions(mentions);
      if (props.onChange) {
        props.onChange(event.target.value, mentions);
      }
    }
  };

  const currentUserInfo = useSelector((state: RootState) => state.auth.identityInfo);
  const users = useSelector((state: RootState) => state.users.data).filter(
    ({ email, active }) => currentUserInfo?.preferred_username !== email && active,
  );

  const mentionData =
    data ||
    users.map((user) => ({
      id: user.id,
      display: `${user.firstName} ${user.lastName}`,
      group: `ALL`,
    }));

  const theme: any = useTheme();
  const classes = useStyles();
  const style = styles(theme, isExpanded);
  const mentionStyle = mentionStyles(theme);
  const ref = useRef<HTMLInputElement | null>(null);

  const onClick = () => {
    if (!disabled) {
      setValue((v) => v + `@`);
      document.getElementById(id)?.focus();
    }
  };

  const onClickSend = useCallback(() => {
    if (!typedValueIsEmpty && !disabled) {
      onSend(value, mentions);
    }
  }, [disabled, mentions, onSend, typedValueIsEmpty, value]);

  useEffect(() => {
    if (!loading) {
      setValue('');
      setMentions([]);
    }
  }, [loading]);

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue?.toString());
    }
  }, [defaultValue]);

  return (
    <Box
      ref={(el) => {
        container = el;
      }}
      className={classes.root}
      style={{
        borderBottom: focussed ? '2px solid #1976D2' : '2px solid transparent',
        borderRadius: '4px 4px 0px 0px',
      }}
      onMouseEnter={() => setFocussed(true)}
      onMouseLeave={() => setFocussed(false)}
    >
      <Box style={{ paddingRight: isExpanded ? 112 : 68 }}>
        <MentionsInput
          id="xx"
          inputRef={ref}
          allowSuggestionsAboveCursor
          allowSpaceInQuery
          placeholder={placeholder}
          style={style}
          value={(props.startValue || '') + (value || defaultValue || '')}
          onChange={onChange}
          disabled={disabled}
          suggestionsPortalHost={container}
          data-id="txtComment"
        >
          <Mention
            trigger="@"
            data={mentionData}
            appendSpaceOnAdd
            style={mentionStyle}
            className="classes"
            displayTransform={(id, display) => {
              const toView = mentionData.find((m) => m.id === id)?.display;
              return `@${toView}`;
            }}
            renderSuggestion={(entry, search, highlightedDisplay, index, focused) => {
              return (
                <Box display="flex" alignItems="center" style={{ width: 358 }}>
                  <CustomAvatar name={entry.display || ''} />
                  <Typography style={{ paddingLeft: 8 }}>{highlightedDisplay}</Typography>
                </Box>
              );
            }}
          />
        </MentionsInput>
      </Box>
      <Box display="flex" alignItems="center" className={isExpanded ? classes.iconsExpandedView : classes.icons}>
        {showMentionTrigger && (
          <IconButton onClick={onClick} disableRipple>
            <AlternateEmailIcon
              style={{
                color: !disabled ? theme.palette.text.secondary : '#00000042',
              }}
              fontSize="large"
            />
          </IconButton>
        )}
        {!hideSend &&
          useMentionSendIcon &&
          (loading ? (
            <IconButton>
              <CircularProgress size={14} />
            </IconButton>
          ) : (
            <IconButton onClick={onClickSend} disableRipple data-id="iconSendComment">
              <img alt="" src={!typedValueIsEmpty ? sendIconPrimary : sendIcon} />
            </IconButton>
          ))}
      </Box>
      <Box>
        {(!(hideSend || useMentionSendIcon) || !hidePublishToEMR) && (
          <Box
            display="flex"
            alignItems="center"
            flexDirection="row-reverse"
            className={classes.followUpWrapper}
            style={{ width: '100%', marginRight: 0, marginLeft: -32 }}
          >
            <Button
              data-id="btnSendComment"
              variant="contained"
              disableElevation
              style={{ height: 30, width: 55, display: hideSend || useMentionSendIcon ? 'none' : '' }}
              onClick={onClickSend}
              disabled={isEmpty(value) || loading}
            >
              {loading ? (
                <CircularProgress size={14} color="inherit" />
              ) : (
                <Typography>{submitButtonLabel ? submitButtonLabel : 'SEND'}</Typography>
              )}
            </Button>
            {enableCancelButton && (
              <Button
                style={{ marginRight: 4 }}
                onClick={() => {
                  onCancelButtonClick && onCancelButtonClick();
                }}
              >
                Cancel
              </Button>
            )}
            <Button
              disableElevation
              disableRipple
              disabled={disablePublishToEMR}
              startIcon={
                <Checkbox
                  color="primary"
                  sx={{ width: 16.67, height: 16.67 }}
                  checked={publishToEMRSelected}
                  value={undefined}
                />
              }
              style={{
                height: 30,
                textTransform: 'none',
                marginRight: 16,
                visibility: hidePublishToEMR ? 'hidden' : 'visible',
              }}
              onClick={onSelectPublishToEMR}
            >
              <Typography style={{ paddingLeft: 9.67 }} color="#000000DE" marginLeft={-1}>
                Publish to EMR
              </Typography>
            </Button>
          </Box>
        )}
      </Box>
      <Box>{secondRowItems ? secondRowItems : children}</Box>
    </Box>
  );
};

export default MentionComponent;
