import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      // height: 72,
      justifyContent: 'flex-end',
      // position: 'relative',
      zIndex: theme.zIndex.drawer + 1,
    },
    logo: {},
    iconBox: {
      // color: theme.palette.text.secondary,
      width: 40,
    },
    box: {
      backgroundColor: theme.palette.background.paper,
      padding: '20px 40px 0',
      paddingRight: 40,
      paddingTop: 0,
      paddingBottom: 0,
    },

    '@keyframes growEffect': {
      '0%': {
        transform: 'scale(1)',
      },
      '50%': {
        transform: 'scale(1.8)',
      },
      '100%': {
        transform: 'scale(1)',
      },
    },

    grow: {
      animation: `$growEffect 2s infinite`,
    },
  };
});
