import React from 'react';
import { Menu, MenuProps } from '@mui/material';
import { useStyles } from './MenuStyles';

const NonScrollableMenu: React.FC<MenuProps> = (props) => {
  const classes = useStyles();
  return (
    <Menu {...props} className={classes.menu}>
      {props.children}
    </Menu>
  );
};

export default NonScrollableMenu;
