import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => {
  return {
    menu: {
      '& .MuiPaper-root': {
        overflowY: 'hidden',
      },
    },
  };
});
