const MS_IN_SECOND = 1000;
const SECONDS_IN_MINUTE = 60;
const MINUTES_IN_HOUR = 60;
const HOURS_IN_DAYS = 24;

const getParts = (t: number, divisor: number) => {
  const [big, _small] = t.toFixed(2).split('.');
  const small = Math.round((Number(_small) / 100) * divisor);

  return [Number(big), small];
};

export default function prettyMs(t: number) {
  if (t < MS_IN_SECOND) {
    return `${t}ms`;
  }

  t /= MS_IN_SECOND;

  {
    const s = Math.round(t);

    if (s < SECONDS_IN_MINUTE) {
      return `${s}s`;
    }
  }

  t /= SECONDS_IN_MINUTE;

  {
    const [m, s] = getParts(t, SECONDS_IN_MINUTE);

    if (m < MINUTES_IN_HOUR) {
      return `${m}m ${s}s`;
    }
  }

  t /= MINUTES_IN_HOUR;

  const [h, m] = getParts(t, MINUTES_IN_HOUR);

  t /= HOURS_IN_DAYS;

  {
    const [d, h] = getParts(t, HOURS_IN_DAYS);

    if (d >= 5) {
      return `${d}d`;
    }
    if (d > 0) {
      return `${d}d ${h}h`;
    }
  }

  return `${h}h ${m}m`;
}
