import axios, { AxiosResponse } from 'axios';
const BASE_URL: string = `${process.env.REACT_APP_API_URL}`;

export interface GetNotificationsParams {
  isMentioned?: boolean;
  isReviewed?: boolean;
  page?: number;
  pageSize?: number;
  sortDirection?: 'DESC' | 'DESC';
  sortField?: string;
}
export const getAllNotificationsApi = async (params?: GetNotificationsParams): Promise<AxiosResponse<any, any>> => {
  return axios.post(`${BASE_URL}/app-notifications`, params);
};

export const markNotificationAsReadApi = async (notificationId: string): Promise<AxiosResponse<any, any>> => {
  return axios.patch(`${BASE_URL}/app-notifications/read/${notificationId}`);
};

export const markNotificationAsUnreadApi = async (notificationId: string): Promise<AxiosResponse<any, any>> => {
  return axios.patch(`${BASE_URL}/app-notifications/unread/${notificationId}`);
};

export const markAllNotificationsAsReadApi = async (): Promise<AxiosResponse<any, any>> => {
  return axios.patch(`${BASE_URL}/app-notifications/read/all`);
};

export const getUnreviewedNotificationsCountApi = async (): Promise<AxiosResponse<any, any>> => {
  return axios.get(`${BASE_URL}/app-notifications/count/unreviewed`);
};
