import { PatientSource } from '../patient-search-controller';
import { Patient } from '../../../../redux/types';
import { searchPatients } from '../../../../api/patientsApi';

export function ServerPatientSource(): PatientSource {
  return {
    async find(searchText: string, phoneTerm?: string): Promise<Patient[]> {
      return (
        await searchPatients({
          search: searchText,
          phoneTerm: phoneTerm,
        })
      ).data;
    },
  };
}
