import { Dispatch } from 'redux';
import {
  addPatientApi,
  getPatients,
  searchPatients,
  editPatientApi,
  deletePatientApi,
  EditPatientParams,
} from '../../api/patientsApi';
import {
  GET_PATIENTS,
  GET_PATIENTS_SUCCESS,
  GET_PATIENTS_ERROR,
  SEARCH_PATIENTS,
  SEARCH_PATIENTS_SUCCESS,
  SEARCH_PATIENTS_ERROR,
  ADD_PATIENTS,
  ADD_PATIENTS_SUCCESS,
  ADD_PATIENTS_ERROR,
  EDIT_PATIENT,
  EDIT_PATIENT_SUCCESS,
  EDIT_PATIENT_ERROR,
  DELETE_PATIENT,
  DELETE_PATIENT_SUCCESS,
  DELETE_PATIENT_ERROR,
  CLEAR_PATIENTS_FROM_SEARCH,
} from '../constants/referralTypes';
import { apiErrorHandler } from '../../globalUtils/utils';
import backendErrorHandler from '../../globalUtils/backendErrorHandler';
import { showErrorMessage } from '../../components/NotificationToast/NotificationToast';

const getPatientsLoading = (payload: boolean) => ({
  type: GET_PATIENTS,
  payload,
});

const getPatientsSuccess = (payload: any) => ({
  type: GET_PATIENTS_SUCCESS,
  payload,
});

const getPatientsError = (payload: any) => ({
  type: GET_PATIENTS_ERROR,
  payload,
});

export const addPatientsLoading = (payload: boolean) => ({
  type: ADD_PATIENTS,
  payload,
});

export const addPatientsSuccess = (payload: any) => ({
  type: ADD_PATIENTS_SUCCESS,
  payload,
});

export const addPatientsError = (payload: any) => ({
  type: ADD_PATIENTS_ERROR,
  payload,
});

export const searchPatientsLoading = (payload: boolean) => ({
  type: SEARCH_PATIENTS,
  payload,
});

export const searchPatientsSuccess = (payload: any) => ({
  type: SEARCH_PATIENTS_SUCCESS,
  payload,
});

const searchPatientsError = (payload: any) => ({
  type: SEARCH_PATIENTS_ERROR,
  payload,
});

export const clearPatientsFromSearch = () => ({
  type: CLEAR_PATIENTS_FROM_SEARCH,
});

const editPatientLoading = () => ({
  type: EDIT_PATIENT,
});

const editPatientSuccess = (payload: any) => ({
  type: EDIT_PATIENT_SUCCESS,
  payload,
});

const editPatientError = (payload: any) => ({
  type: EDIT_PATIENT_ERROR,
  payload,
});

const deletePatientLoading = () => ({
  type: DELETE_PATIENT,
});

const deletePatientSuccess = (payload: any) => ({
  type: DELETE_PATIENT_SUCCESS,
  payload,
});

const deletePatientError = (payload: any) => ({
  type: DELETE_PATIENT_ERROR,
  payload,
});

/**
 * @deprecated this utility is not being used as this data is now appended in the original api
 */
export const getPatientsAction = () => async (dispatch: Dispatch) => {
  try {
    dispatch(getPatientsLoading(true));
    const response = await getPatients();
    dispatch(getPatientsSuccess(response.data));
    dispatch(getPatientsLoading(false));
  } catch (e) {
    dispatch(getPatientsError(e));
    dispatch(getPatientsLoading(false));
  }
};

export const addPatientAction =
  (data: any, onSuccess?: (res: any) => void, onError?: () => void) => async (dispatch: Dispatch) => {
    try {
      dispatch(addPatientsLoading(true));
      const response = await addPatientApi(data);
      dispatch(addPatientsSuccess(response.data));
      if (onSuccess) {
        onSuccess(response.data);
      }
    } catch (e: any) {
      const errorMessage = backendErrorHandler(e);
      showErrorMessage(errorMessage);

      apiErrorHandler(e);
      dispatch(addPatientsError(e));
      if (onError) {
        onError();
      }
    }
  };

export const editPatientAction =
  (data: EditPatientParams, onSuccess?: (res: any) => void, onError?: () => void) => async (dispatch: Dispatch) => {
    try {
      dispatch(editPatientLoading());
      const response = await editPatientApi(data);
      dispatch(editPatientSuccess(response.data));
      if (onSuccess) {
        onSuccess(response.data);
      }
    } catch (e: any) {
      const errorMessage = backendErrorHandler(e);
      showErrorMessage(errorMessage);

      apiErrorHandler(e);
      dispatch(editPatientError(e));
      if (onError) {
        onError();
      }
    }
  };

export const deletePatientAction =
  (id: string, onSuccess?: (res: any) => void, onError?: () => void) => async (dispatch: Dispatch) => {
    try {
      dispatch(deletePatientLoading());
      const response = await deletePatientApi(id);
      dispatch(deletePatientSuccess(response.data));
      if (onSuccess) {
        onSuccess(response.data);
      }
    } catch (e: any) {
      const errorMessage = backendErrorHandler(e);
      showErrorMessage(errorMessage);

      apiErrorHandler(e);
      dispatch(deletePatientError(e));
      if (onError) {
        onError();
      }
    }
  };

export const searchPatientsAction = (data: any) => async (dispatch: Dispatch) => {
  try {
    dispatch(searchPatientsLoading(true));
    const response = await searchPatients(data);
    dispatch(searchPatientsSuccess(response.data));
    dispatch(searchPatientsLoading(false));
  } catch (e) {
    const errorMessage = backendErrorHandler(e);
    showErrorMessage(errorMessage);

    dispatch(searchPatientsError(e));
    dispatch(searchPatientsLoading(false));
  }
};
