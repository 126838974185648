import {
  GET_NOTIFICATIONS_ERROR,
  GET_NOTIFICATIONS_LOADING,
  GET_NOTIFICATIONS_SUCCESS,
  GET_UNREVIEWED_NOTIFICATIONS_COUNT_SUCCESS,
  MARK_ALL_NOTIFICATIONS_AS_READ_ERROR,
  MARK_ALL_NOTIFICATIONS_AS_READ_LOADING,
  MARK_ALL_NOTIFICATIONS_AS_READ_SUCCESS,
  MARK_NOTIFICATION_AS_READ_ERROR,
  MARK_NOTIFICATION_AS_READ_LOADING,
  MARK_NOTIFICATION_AS_READ_SUCCESS,
  SET_INITIAL_NOTIFICATION_LOADING_DONE,
  SET_NOTIFICATIONS_FILTERS,
  SET_NOTIFICATIONS_GLOBAL_LOADING,
} from '../constants/notificationsTypes';
import { BaseAction, Notification, NotificationsStore } from '../types';
import _ from 'lodash';

const initialState: NotificationsStore = {
  loading: false,
  globalLoading: false,
  notifications: [],
  error: undefined,
  totalCount: 0,
  unreviewedNotificationsCount: 0,
  markNotificationAsRead: {
    error: undefined,
  },
  filters: {
    page: 1,
    pageSize: 50,
  },
  markAllNotificationsAsReadLoading: false,
  initialLoadingDone: false,
};

const notificationsReducer = (state: NotificationsStore = initialState, action: BaseAction): NotificationsStore => {
  switch (action.type) {
    case GET_NOTIFICATIONS_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        totalCount: action.payload.totalCount,
        notifications:
          action.payload?.page === 1
            ? action.payload.data
            : _.uniqBy([...state.notifications, ...action.payload?.data], 'id'),
        error: undefined,
      };
    case GET_NOTIFICATIONS_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case MARK_NOTIFICATION_AS_READ_LOADING:
      return {
        ...state,
        markNotificationAsRead: {
          ...state.markNotificationAsRead,
        },
        notifications: state.notifications.map((notification: Notification): Notification => {
          if (notification.id === action.payload.id) {
            return {
              ...notification,
              loading: action.payload?.loading,
            };
          }

          return notification;
        }),
      };
    case MARK_NOTIFICATION_AS_READ_SUCCESS:
      return {
        ...state,
        notifications: state.notifications.map((notification: Notification): Notification => {
          if (notification.id === action.payload.id) {
            return {
              ...notification,
              reviewed: action?.payload?.reviewed,
            };
          }

          return notification;
        }),
        unreviewedNotificationsCount: action?.payload?.reviewed
          ? state.unreviewedNotificationsCount - 1
          : state.unreviewedNotificationsCount + 1,
      };
    case MARK_NOTIFICATION_AS_READ_ERROR:
      return {
        ...state,
        markNotificationAsRead: {
          ...state.markNotificationAsRead,
          error: action.payload,
        },
      };
    case SET_NOTIFICATIONS_FILTERS:
      return {
        ...state,
        ...state.filters,
        filters: {
          ...state.filters,
          ...action.payload,
        },
      };
    case MARK_ALL_NOTIFICATIONS_AS_READ_LOADING:
      return {
        ...state,
        markAllNotificationsAsReadLoading: action.payload,
      };
    case MARK_ALL_NOTIFICATIONS_AS_READ_SUCCESS:
      return {
        ...state,
        notifications: state.notifications.map((notification: Notification) => ({
          ...notification,
          reviewed: true,
        })),
        unreviewedNotificationsCount: 0,
      };
    case MARK_ALL_NOTIFICATIONS_AS_READ_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case GET_UNREVIEWED_NOTIFICATIONS_COUNT_SUCCESS:
      return {
        ...state,
        unreviewedNotificationsCount: action.payload.count,
      };
    case SET_NOTIFICATIONS_GLOBAL_LOADING:
      return {
        ...state,
        globalLoading: action.payload,
      };
    case SET_INITIAL_NOTIFICATION_LOADING_DONE:
      return {
        ...state,
        initialLoadingDone: action.payload,
      };
    default:
      return state;
  }
};

export default notificationsReducer;
