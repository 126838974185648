import { Dispatch } from 'redux';
import { getDrugTiers } from '../../api/medicationsApi';
import { GET_DRUG_TIERS, GET_DRUG_TIERS_SUCCESS, GET_DRUG_TIERS_ERROR } from '../constants/referralTypes';

const getDrugTiersLoading = (payload: boolean) => ({
  type: GET_DRUG_TIERS,
  payload,
});

const getDrugTiersSuccess = (payload: any) => ({
  type: GET_DRUG_TIERS_SUCCESS,
  payload,
});

const getDrugTiersError = (payload: any) => ({
  type: GET_DRUG_TIERS_ERROR,
  payload,
});

export const getDrugTiersAction = () => async (dispatch: Dispatch) => {
  try {
    dispatch(getDrugTiersLoading(true));
    const response = await getDrugTiers();
    dispatch(getDrugTiersSuccess(response.data));
    dispatch(getDrugTiersLoading(false));
  } catch (e) {
    dispatch(getDrugTiersError(e));
    dispatch(getDrugTiersLoading(false));
  }
};
