import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => {
  return {
    header: {
      fontSize: '20px !important',
      fontWeight: '500 !important',
      width: '80%',
    },
  };
});
