import {
  GET_NOTIFICATIONS_ERROR,
  GET_NOTIFICATIONS_LOADING,
  GET_NOTIFICATIONS_SUCCESS,
  GET_UNREVIEWED_NOTIFICATIONS_COUNT_SUCCESS,
  LOAD_NEW_NOTIFICATION_FROM_SOCKET,
  MARK_ALL_NOTIFICATIONS_AS_READ_ERROR,
  MARK_ALL_NOTIFICATIONS_AS_READ_LOADING,
  MARK_ALL_NOTIFICATIONS_AS_READ_SUCCESS,
  MARK_NOTIFICATION_AS_READ_ERROR,
  MARK_NOTIFICATION_AS_READ_LOADING,
  MARK_NOTIFICATION_AS_READ_SUCCESS,
  SET_INITIAL_NOTIFICATION_LOADING_DONE,
  SET_NOTIFICATIONS_FILTERS,
  SET_NOTIFICATIONS_GLOBAL_LOADING,
} from '../constants/notificationsTypes';
import { Notification } from '../types';

export const getNotificationsLoading = (loading: boolean) => ({
  type: GET_NOTIFICATIONS_LOADING,
  payload: loading,
});

export const getNotificationsSuccess = (payload: any) => ({
  type: GET_NOTIFICATIONS_SUCCESS,
  payload,
});

export const getNotificationsError = (payload: any) => ({
  type: GET_NOTIFICATIONS_ERROR,
  payload,
});

export const markNotificationAsReadSuccess = (payload: any) => ({
  type: MARK_NOTIFICATION_AS_READ_SUCCESS,
  payload,
});

export const markNotificationAsReadError = (payload: any) => ({
  type: MARK_NOTIFICATION_AS_READ_ERROR,
  payload,
});

export const markNotificationAsReadLoading = (isLoading: boolean, notification: Notification) => ({
  type: MARK_NOTIFICATION_AS_READ_LOADING,
  payload: {
    ...notification,
    loading: isLoading,
  },
});

export const setNotificationsFilters = (filters: any) => ({
  type: SET_NOTIFICATIONS_FILTERS,
  payload: filters,
});

export const markAllNotificationsAsReadLoading = (isLoading: boolean) => ({
  type: MARK_ALL_NOTIFICATIONS_AS_READ_LOADING,
  payload: isLoading,
});

export const markAllNotificationsAsReadError = (error: any) => ({
  type: MARK_ALL_NOTIFICATIONS_AS_READ_ERROR,
  payload: error,
});

export const markAllNotificationsAsReadSuccess = (payload: any) => ({
  type: MARK_ALL_NOTIFICATIONS_AS_READ_SUCCESS,
  payload,
});

export const getUnreviewedNotificationsCountuccess = (payload: any) => ({
  type: GET_UNREVIEWED_NOTIFICATIONS_COUNT_SUCCESS,
  payload,
});

export const setNotificationsGlobalLoading = (isLoading: boolean) => ({
  type: SET_NOTIFICATIONS_GLOBAL_LOADING,
  payload: isLoading,
});

export const setNotificationsInitialLoadingDone = (initialLoadingDone: boolean) => ({
  type: SET_INITIAL_NOTIFICATION_LOADING_DONE,
  payload: initialLoadingDone,
});

export const loadNewNotificationFromSocket = (payload: any) => ({
  type: LOAD_NEW_NOTIFICATION_FROM_SOCKET,
  payload,
});
