import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  Tab,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Notification, RootState } from '../../redux/types';
import NotificationItem from './NotificationItem';
import { useStyles } from './notificationsStyles';
import { ViewportList } from 'react-viewport-list';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { setNotificationsFilters } from '../../redux/actionCreators/notificationsActionCreators';
import { getNotificationsAction, markAllNotificationsAsReadAction } from '../../redux/actions/notificationsActions';
import { NotificationsSkeleton } from './NotificationsSkeleton';
import { EmptyNotifications } from './EmptyNotifications';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { ArrowDropDown } from '@mui/icons-material';
import { MsTeamsNotificationsSwitch } from '../../features/Notification/MsTeamsNotificationsSwitch';

interface Props {
  title: string;
  notifications: Notification[];
  onClose: () => void;
}

const Notifications: React.FC<Props> = (props) => {
  const { title, notifications, onClose } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const ref = useRef(null);
  const {
    notifications: {
      totalCount,
      filters,
      loading,
      unreviewedNotificationsCount,
      markAllNotificationsAsReadLoading,
      globalLoading,
    },
    referrals: { referralStatuses },
  } = useSelector((state: RootState) => state);
  const [markAllNotificationsAsReadEnabled, enableMarkAllNotificationsAsRead] = useState<boolean>(false);

  useEffect(() => {
    enableMarkAllNotificationsAsRead(
      unreviewedNotificationsCount >= 1 && !markAllNotificationsAsReadLoading && !globalLoading,
    );
  }, [unreviewedNotificationsCount, markAllNotificationsAsReadLoading, globalLoading]);

  const onClickOnMarkAllNotificationsAsReadButton = (): void => {
    dispatch(markAllNotificationsAsReadAction());
  };

  const onScrollToNextPage = useCallback(() => {
    dispatch(setNotificationsFilters({ page: filters.page + 1 }));
    dispatch(getNotificationsAction({ ...filters, page: filters.page + 1 }));
  }, [dispatch, filters]);

  const categorizedNotifications = useMemo(
    () =>
      notifications.reduce(
        (cats, notification) => ({
          ...cats,
          [notification.category]: [...(cats[notification.category] || []), notification],
        }),
        {} as Record<Notification['category'], Notification[]>,
      ),
    [notifications],
  );

  const [tab, setTab] = useState('date');

  return (
    <Box
      className={classes.notificationsContainer}
      display="flex"
      flexDirection="column"
      height={!globalLoading && !notifications?.length ? 480 : 'calc(100vh - 130px)'}
    >
      <Box className={classes.notificationTitleContainer} display="flex" justifyContent="space-between">
        <Box>
          <Typography className={classes.notificationTitleText}>{title}</Typography>
        </Box>
        <Box display="flex" flexDirection="column" alignItems="end">
          <Button
            variant="text"
            disableRipple
            onClick={onClickOnMarkAllNotificationsAsReadButton}
            disabled={!markAllNotificationsAsReadEnabled}
            style={{
              background: 'transparent',
              fontSize: 13,
            }}
          >
            <Box display="flex">
              <Box>
                <Typography>Mark ALl AS READ</Typography>
              </Box>
              {markAllNotificationsAsReadLoading && (
                <Box display="flex" alignItems="center" className={classes.markAllNotificationsAsReadSpinnerContainer}>
                  <CircularProgress size={18} />
                </Box>
              )}
            </Box>
          </Button>
          <MsTeamsNotificationsSwitch />
        </Box>
      </Box>
      <TabContext value={tab}>
        <TabList onChange={(event, newValue) => setTab(newValue)}>
          <Tab label="By Date" value="date" />
          <Tab label="By Type" value="type" />
        </TabList>
        <Box className={classes.notificationsListContainer} ref={ref} id="notifications-content-box">
          {globalLoading ? (
            <NotificationsSkeleton numberOfRows={50} />
          ) : !notifications?.length ? (
            <EmptyNotifications />
          ) : (
            <>
              <TabPanel value="date" style={{ padding: 0 }}>
                <InfiniteScroll
                  style={{ overflowX: 'hidden' }}
                  next={onScrollToNextPage}
                  hasMore={notifications.length < totalCount}
                  dataLength={notifications.length}
                  loader={
                    loading && (
                      <Box alignContent="center" display="flex" justifyContent="center">
                        <CircularProgress size={24} />
                      </Box>
                    )
                  }
                  scrollableTarget="notifications-content-box"
                >
                  <ViewportList viewportRef={ref} items={notifications} itemMinSize={70}>
                    {(item: Notification) =>
                      item && (
                        <NotificationItem
                          notification={item}
                          key={item?.id}
                          onClose={onClose}
                          referralStatuses={referralStatuses.data}
                        />
                      )
                    }
                  </ViewportList>
                </InfiniteScroll>
              </TabPanel>
              <TabPanel value="type" style={{ padding: 0 }}>
                {Object.entries(categorizedNotifications).map(([label, notifications]) => (
                  <Accordion key={label}>
                    <AccordionSummary expandIcon={<ArrowDropDown />}>{label}</AccordionSummary>
                    <AccordionDetails style={{ padding: 0 }}>
                      {notifications.map((item) => (
                        <NotificationItem
                          notification={item}
                          key={item?.id}
                          onClose={onClose}
                          referralStatuses={referralStatuses.data}
                        />
                      ))}
                    </AccordionDetails>
                  </Accordion>
                ))}
              </TabPanel>
            </>
          )}
        </Box>
      </TabContext>
    </Box>
  );
};

export default Notifications;
