import { Alert, AlertProps } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

export interface Props extends AlertProps {
  onCancel: () => void;
}

const DialogAlert: React.FC<Props> = (props) => {
  const { onCancel, ...restProps } = props;

  return (
    <Alert
      style={{
        alignItems: 'center',
        background: '#FCEEEE',
        color: '#212121',
        fontSize: 16,
        height: 43,
        opacity: '100%',
      }}
      icon={<Box />}
      severity="error"
      color="error"
      {...restProps}
    />
  );
};

export default DialogAlert;
