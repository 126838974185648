import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => {
  return {
    '@keyframes loadingEffect': {
      to: {
        clipPath: 'inset(0 -1ch 0 0)',
      },
    },
    loading: {
      display: 'inline-block',
      clipPath: 'inset(0 1ch 0 0)',
      animation: '$loadingEffect 1s steps(4) infinite',
    },
  };
});
