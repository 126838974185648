import { Dialog, DialogTitle, Typography, DialogContent, Box, DialogActions, Button, Tooltip } from '@mui/material';
import React, { FC } from 'react';
import { ListView } from '../ReferralView/PatientDetails';
import { useStyles } from './ReadyToScheduleStyled';
import orangeProvider from '../../images/orangeProvider.svg';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { getTaskColors, ignoreTimezoneDate } from '../../globalUtils/utils';
import { format } from 'date-fns';
import { Task } from '../../redux/types';
import ChangeTaskStatus from '../ChangeTaskStatus/ChangeTaskStatus';
import CustomChip from '../CustomChip/CustomChip';
import { useGetTasksOptions } from '../../globalUtils/hooks';
import CustomAvatar from '../Avatar/Avatar';
import { getCustomLogic } from '../../features/taskTypes/taskTypes';
import alertIcon from '../../images/hazard.svg';

interface Props {
  provider: any;
  patient: any;
  tasks: Task[];
  isTemporaryPatient: boolean;
  isTemporaryProvider: boolean;
  open: boolean;
  onClose: () => void;
}

interface TaskItemProps extends Task {}
const TaskItem: FC<TaskItemProps> = (props) => {
  const statusOptions = useGetTasksOptions('ALL');
  const defaultStatus = statusOptions.find((option: any) => option.id === props.status?.id);
  const { assignedUser, active } = props;
  const customLogic = getCustomLogic(props);

  const disabled = !active;
  const inactiveTaskMessage = 'Inactive tasks cannot be edited. To edit this task, change the referral status.';

  return (
    <Tooltip title={disabled ? inactiveTaskMessage : ''}>
      <Box display="flex" alignItems="center" justifyContent="space-between" style={{ width: '100%' }} flex={1}>
        <Box display="flex" alignItems="center">
          {disabled && <img style={{ marginRight: 8 }} src={alertIcon} height={20} width={20} alt="" />}
          <Typography style={{ flex: 1, color: disabled ? '#6b778c' : 'rgba(0,0,0,0.87)' }}>{props.title}</Typography>
        </Box>
        <Box display="flex">
          <ChangeTaskStatus
            task={props}
            disabled={disabled}
            statusChangeHook={() => customLogic.statusChangeHook}
            labelTransform={(value) => {
              return (
                <CustomChip
                  data-id="status"
                  size="small"
                  style={{ color: getTaskColors(defaultStatus?.name).textColor, textTransform: 'capitalize' }}
                  icon={<img alt="" src={getTaskColors(defaultStatus?.name).iconImage} />}
                  {...getTaskColors(defaultStatus?.name)}
                  label={defaultStatus?.name?.toLowerCase()}
                />
              );
            }}
          />
          <CustomAvatar
            style={{ width: 30, height: 30, marginLeft: 24, fontSize: 15 }}
            name={`${assignedUser?.firstName} ${assignedUser?.lastName}`}
          />
        </Box>
      </Box>
    </Tooltip>
  );
};

export const ReadyToSchedule: FC<Props> = (props) => {
  const classes = useStyles();
  const { provider, patient, isTemporaryPatient, isTemporaryProvider, open, onClose } = props;
  return (
    <Dialog maxWidth="lg" open={open}>
      <DialogTitle>
        <Typography variant="h6">Before setting to Ready to Schedule</Typography>
      </DialogTitle>
      <DialogContent style={{ maxHeight: 400 }}>
        {(isTemporaryPatient || isTemporaryProvider) && (
          <Typography style={{ fontWeight: 500, marginBottom: 20 }} variant="body2">
            Complete Patient Details
          </Typography>
        )}
        <Box style={{ marginTop: 12, minWidth: 576 }}>
          {isTemporaryPatient && (
            <Box className={classes.row} alignItems="center" justifyContent="space-between" display="flex">
              <Typography style={{ flex: 1 }}>Patient</Typography>
              <Box style={{ color: 'rgba(0,0,0,0.87)' }} flex={1}>
                <ListView
                  secondaryText={
                    patient?.dateOfBirth ? format(ignoreTimezoneDate(patient?.dateOfBirth), 'MM/dd/yyyy') : ''
                  }
                  primaryText={`${patient?.firstName || ''} ${patient?.lastName || ''}`}
                  style={{ maxWidth: 328, overflow: 'hidden', textOverflow: 'ellipsis' }}
                  icon={<AccountCircleOutlinedIcon fontSize={'large'} style={{ fill: '#ED6C0280' }} />}
                />
              </Box>
            </Box>
          )}
          {isTemporaryProvider && (
            <Box className={classes.row} alignItems="center" justifyContent="space-between" display="flex">
              <Typography style={{ flex: 1 }}>Provider</Typography>
              <Box style={{ color: 'rgba(0,0,0,0.87)' }} flex={1}>
                <ListView
                  secondaryText={provider.providerNPI}
                  secondaryTextProps={{ color: 'primary' }}
                  style={{ maxWidth: 328, overflow: 'hidden', textOverflow: 'ellipsis' }}
                  primaryText={`${provider?.providerFirstName || ''} ${provider?.providerLastName || ''}${
                    provider?.credentials ? `, ${provider?.credentials}` : ''
                  }`}
                  icon={<img alt="" src={orangeProvider} height={20} width={20} />}
                />
              </Box>
            </Box>
          )}
        </Box>
        <Box style={{ marginTop: 16 }}>
          <Typography style={{ fontWeight: 500, marginBottom: 20 }} variant="body2">
            Complete the following tasks:
          </Typography>
          {props.tasks.map((item: Task) => {
            return (
              <Box
                key={item.id}
                className={classes.row}
                alignItems="center"
                justifyContent="space-between"
                display="flex"
              >
                <TaskItem {...item} />
              </Box>
            );
          })}
        </Box>
      </DialogContent>
      <DialogActions>
        {/* <Button>Cancel</Button> */}
        <Button onClick={onClose} variant="contained">
          I understand
        </Button>
      </DialogActions>
    </Dialog>
  );
};
