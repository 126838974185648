import { FC, useState } from 'react';
import { Input } from '@mui/material';
import { Box, Typography } from '@mui/material';
import { Props } from './CustomizedLocationFieldTypes';
import { useStyles } from './locationFieldStyles';

const CustomizedTextField: FC<Props> = ({
  startAdornment,
  disableUnderline,
  label,
  inputId,
  handleInputChange,
  value,
  ...restProps
}) => {
  const [focused, setFocused] = useState<boolean>(false);
  const [mouseOver, setMouseOver] = useState<boolean>(false);
  const classes = useStyles({ open: focused || mouseOver });
  const adornment = startAdornment ? (
    <Box className={classes.adornment} display="flex" alignItems="center" justifyContent="center">
      {startAdornment}
    </Box>
  ) : undefined;

  return (
    <div style={{ cursor: restProps.disabled ? 'no-drop !important' : '' }} className={classes.parent}>
      {label && !value && !focused && (
        <div className={classes.label}>
          <Typography color="primary" variant="subtitle2" style={{ fontSize: 16, fontWeight: 500 }}>
            {label}
          </Typography>
        </div>
      )}
      <Input
        id={inputId}
        onMouseOver={() => setMouseOver(true)}
        onMouseLeave={() => setMouseOver(false)}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        onChange={handleInputChange}
        size="medium"
        className={classes.root}
        disableUnderline={true}
        placeholder={focused ? restProps.customplaceholder : ''}
        startAdornment={adornment}
        inputProps={{ maxLength: restProps.maximumlength ?? 255 }}
        style={{ fontSize: 16, fontWeight: 500 }}
        {...restProps}
      />
    </div>
  );
};

export default CustomizedTextField;
