import React, { FC, useEffect, useMemo, useState } from 'react';
import SelectMenu, { SelectMenuProps } from '../SelectMenu/SelectMenu';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';
// import region from '../../images/region.svg';
import { RootState } from '../../redux/types';
import CustomAvatar from '../Avatar/Avatar';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
// import { format } from 'date-fns';

interface Props extends Partial<SelectMenuProps> {
  initialValue?: any;
  onChange: (item: any) => void;
  style?: React.CSSProperties;
  icon?: React.ReactNode;
  valueIcon?: React.ReactNode;
  isErrored?: boolean;
  label?: string | React.ReactNode;
  avatarStyles?: React.CSSProperties;
  disabled?: boolean;
  showClearIcon?: boolean;
  filterOutInactiveAssignees: boolean;
  assignedUserId?: string;
  iconColor?: string;
}

const EditAssignee: FC<Props> = (props) => {
  const {
    initialValue,
    onChange,
    isErrored,
    avatarStyles,
    filterOutInactiveAssignees,
    assignedUserId,
    iconColor,
    ...restProps
  } = props;

  const { data: usersData } = useSelector((state: RootState) => state.users);

  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    if (initialValue) {
      setValue(initialValue);
    }
  }, [initialValue]);

  const handleChange = (item: any) => {
    onChange(item ? item : { id: null });
    setValue(item);
  };

  const allOptions = useMemo(() => {
    return usersData?.map((item: any) => {
      return {
        ...item,
        label: (
          <Box style={{ color: 'rgba(0,0,0,0.67)' }} display="flex" alignItems="center">
            <CustomAvatar
              name={`${item?.name || ''} ${item?.name || ''}`}
              style={{
                width: 30,
                height: 30,
                fontSize: 15,
                marginRight: 8,
                ...avatarStyles,
              }}
            />
            <Box style={{ color: 'rgba(0,0,0,0.67)' }}>
              <Typography
                style={
                  !item?.active
                    ? { width: 120, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }
                    : {}
                }
                variant="body1"
              >
                {item?.name}
              </Typography>
            </Box>
            {!item?.active && (
              <Typography variant="body1">
                <i>(Inactive)</i>
              </Typography>
            )}
          </Box>
        ),
        preferredValueLabel: (
          <Box display="flex">
            <Typography color="textPrimary" variant="subtitle2">
              {item?.name}
            </Typography>{' '}
          </Box>
        ),
        searchString: item.name,
        secondaryLabel: `${item?.referralsCount ? item?.referralsCount : 0} referrals`,
        disabled: item?.id === value?.id || !item?.active,
        showDoneIcon: item?.id === value?.id,
      };
    });
  }, [avatarStyles, usersData, value?.id]);

  const options = useMemo(() => {
    return !filterOutInactiveAssignees ? allOptions : allOptions.filter((option) => Boolean(option?.active));
  }, [allOptions, filterOutInactiveAssignees]);

  useEffect(() => {
    if (options.length && initialValue) {
      const initialOption = allOptions.find((item) => item?.id === initialValue?.id);
      setValue(initialOption);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue]);

  // const filteredOptions = options.filter((option) => option.active);
  return (
    <SelectMenu
      value={value}
      onChange={handleChange}
      onAddNew={console.log}
      handleSearch={console.log}
      hideAddnew
      // showExpandMore
      // groupBy={groupBy}
      searchLoading={false}
      showClearIcon
      valueIcon={
        !value?.label ? (
          <AccountCircleOutlinedIcon
            style={{ color: isErrored ? 'rgba(255, 0, 0, 1)' : iconColor || '#1976d2' }}
            fontSize="large"
          />
        ) : (
          <CustomAvatar
            name={`${value?.name || ''} ${value?.name || ''}`}
            style={{
              width: 30,
              height: 30,
              fontSize: 15,
              ...avatarStyles,
            }}
          />
        )
      }
      icon={
        !value?.label ? (
          <AccountCircleOutlinedIcon
            style={{ color: isErrored ? 'rgba(255, 0, 0, 1)' : iconColor || '#1976d2' }}
            fontSize="large"
          />
        ) : (
          <CustomAvatar
            name={`${value?.name || ''} ${value?.name || ''}`}
            style={{
              height: 30,
              width: 30,
              background: isErrored ? 'red' : '',
              fontSize: 15,
              ...avatarStyles,
            }}
          />
        )
      }
      options={options}
      isErrored={isErrored}
      {...restProps}
    />
  );
};

export default EditAssignee;
