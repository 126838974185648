import { Menu, MenuItem, MenuProps } from '@mui/material';
import React, { useMemo } from 'react';
import { Comment } from '../../redux/types';
import pencil from '../../images/blackPencilUnderlined.svg';
import { useDispatch } from 'react-redux';
import { setCommentBeingEdited } from '../../redux/actions/commentActions';
import { differenceInMinutes } from 'date-fns';

export interface Props extends MenuProps {
  onEditButtonClick?: () => void;
  selectedComment?: Comment;
  hideCommentEditing: boolean;
}

const CommentActionMenu: React.FC<Props> = (props) => {
  const { onEditButtonClick, selectedComment, hideCommentEditing, ...menuProps } = props;
  const dispatch = useDispatch();

  const editCommentButtonDisabled: boolean = useMemo(() => {
    return (
      hideCommentEditing ||
      (selectedComment ? differenceInMinutes(new Date(), new Date(selectedComment.createdAt)) >= 10 : false)
    );
  }, [hideCommentEditing, selectedComment]);

  return (
    <Menu {...menuProps}>
      <MenuItem
        onClick={() => {
          onEditButtonClick && onEditButtonClick();
          selectedComment && dispatch(setCommentBeingEdited(selectedComment));
        }}
        disabled={editCommentButtonDisabled}
        style={{ minWidth: 176 }}
      >
        <img src={pencil} alt="" style={{ marginRight: 12 }} />
        Edit
      </MenuItem>
    </Menu>
  );
};

export default CommentActionMenu;
