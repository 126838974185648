import { FC } from 'react';
import { Box, Button } from '@mui/material';

interface Props {
  selectedOptions: any[];
  filteredOptions: any[];
  selectAll: () => void;
  removeAll: () => void;
}

const ActionButtons: FC<Props> = (props) => {
  const { selectedOptions, filteredOptions, selectAll, removeAll } = props;

  return (
    <Box style={{ padding: '0 8px' }} display="flex" justifyContent="space-between">
      <Button disabled={Array.from(new Set(selectedOptions)).length === filteredOptions.length} onClick={selectAll}>
        Select All
      </Button>
      <Button disabled={Array.from(new Set(selectedOptions)).length < 1} onClick={removeAll}>
        Remove All
      </Button>
    </Box>
  );
};

export default ActionButtons;
