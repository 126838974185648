import { useEffect, useState } from 'react';
import { Badge, Box, Button, CircularProgress, Divider, Menu, Typography } from '@mui/material';
import { useStyles } from './FilterStyles';
import filter from '../../images/Filter.svg';
import filterPrimary from '../../images/filterPrimary.svg';
import FlashOnPrimary from '../../images/FlashOnPrimary.svg';
import AnimatedLoading from '../AnimatedLoading/AnimatedLoading';
import { FilterIndicatorGroup } from './FilterIndicatorGroup';

export type Filter = {
  key: string;
  label: string;
  values: { label: string; value: string }[];
};

interface Props {
  onFilterUpdate: (updatedFilters: Record<string, string[]>) => void;
  filters: Filter[];
  hasSavedFilters?: boolean;
  onRestoreSavedFilters(): void;
  onSaveFilters(): void;
  onDeleteSavedFilters(): void;
}

/**
 * Extracted from TasksFilterIndicator
 * @see TasksFilterIndicator.tsx
 */
export function FilterIndicator({
  onFilterUpdate,
  filters,
  hasSavedFilters,
  onRestoreSavedFilters,
  onSaveFilters,
  onDeleteSavedFilters,
}: Props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const filtersTotalCount = filters.reduce((count, filter) => count + Number(filter.values.length > 0), 0);
  const [filtersChanged, setFiltersChanged] = useState(true);
  const savedFiltersLoading = typeof hasSavedFilters === 'undefined';
  const serializedFilters = JSON.stringify(filters);

  useEffect(() => {
    setFiltersChanged(true);
  }, [setFiltersChanged, serializedFilters]);

  const clearAll = () => {
    setFiltersChanged(true);
    onFilterUpdate(getEmptyFilters());
    setAnchorEl(null);

    function getEmptyFilters() {
      return filters.reduce(
        (emptyFilters, filter) => ({
          ...emptyFilters,
          [filter.key]: [],
        }),
        {},
      );
    }
  };

  function handleRestoreFilters() {
    setFiltersChanged(false);
    onRestoreSavedFilters();
  }

  function handleRemoveFilterValue(key: string, value: string) {
    setFiltersChanged(true);
    onFilterUpdate(
      filters.reduce(
        (selections, filter) => ({
          ...selections,
          [filter.key]: filter.values.filter((x) => filter.key !== key || x.value !== value).map((x) => x.value),
        }),
        {},
      ),
    );
  }

  function handleSaveOrDeleteFilters() {
    setFiltersChanged(false);
    filtersChanged ? onSaveFilters() : onDeleteSavedFilters();
  }

  return (
    <Box className={classes.root}>
      <Button
        style={{ background: open ? `rgba(0,0,0,0.04)` : '', paddingRight: 16 }}
        onClick={(e: any) => setAnchorEl(e.target)}
      >
        <img style={{ marginRight: 16 }} alt="funnel" src={open ? filterPrimary : filter} />
        <Typography variant="button" style={{}} color="textSecondary">
          Filters
        </Typography>
        <Badge style={{ marginLeft: 16, marginRight: 0 }} color="primary" badgeContent={filtersTotalCount} />
        {hasSavedFilters && <img style={{ marginLeft: 16 }} src={FlashOnPrimary} alt="" />}
      </Button>
      <Menu
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorEl={anchorEl}
        open={!!(anchorEl && open)}
      >
        <Box className={classes.menu}>
          <Box display="flex" justifyContent="space-between" style={{ paddingLeft: 8 }}>
            <Button
              disableRipple
              disableElevation
              disabled={!hasSavedFilters || !filtersChanged}
              onClick={handleRestoreFilters}
            >
              RESTORE SAVED FILTERS
            </Button>
            <Button disableElevation disableRipple disabled={!filtersTotalCount} onClick={clearAll}>
              REMOVE ALL
            </Button>
          </Box>
          <Divider />
          <Box style={{ padding: 16, boxSizing: 'border-box' }}>
            {!filtersTotalCount && (
              <Box display="flex" alignItems="center" justifyContent="center">
                <Typography color="#00000061">
                  {!hasSavedFilters ? 'There are no saved or applied filters.' : 'There are no applied filters.'}
                </Typography>
              </Box>
            )}
            {filters.map((filter) =>
              filter.values.length ? (
                <FilterIndicatorGroup
                  name={filter.label}
                  filters={filter.values.map((option) => ({
                    ...option,
                    onRemove: () => handleRemoveFilterValue(filter.key, option.value),
                  }))}
                />
              ) : null,
            )}
          </Box>
        </Box>
        {Boolean(filtersTotalCount) && [
          <Divider />,
          <Box display="flex" alignItems="center" paddingTop={0} paddingBottom={0} height={48} paddingLeft={1}>
            <Button
              variant={hasSavedFilters && !filtersChanged ? 'text' : 'contained'}
              disableRipple
              disableElevation
              disabled={savedFiltersLoading}
              onClick={handleSaveOrDeleteFilters}
              style={{ marginRight: 14 }}
            >
              {hasSavedFilters && !filtersChanged ? 'DELETE SAVED FILTERS' : 'SAVE FILTERS'}
            </Button>
            {savedFiltersLoading && (
              <Box display="flex" alignItems="center">
                <CircularProgress size={20} style={{ marginRight: 8 }} />
                <AnimatedLoading text="Saving ..." color="#00000099" />
              </Box>
            )}
          </Box>,
        ]}
      </Menu>
    </Box>
  );
}
