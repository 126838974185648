import { Dispatch } from 'redux';
import { addCommentsApi, addReferralCommentsApi, editCommentApi, EditCommentPayload } from '../../api/commentsApi';
import { showErrorMessage } from '../../components/NotificationToast/NotificationToast';
import backendErrorHandler from '../../globalUtils/backendErrorHandler';
import {
  CREATE_COMMENTS_LOADING,
  FETCH_COMMENTS_LOADING,
  LOAD_COMMENT_CREATION_RESPONSE_TO_STORE,
  LOAD_COMMENTS_TO_STORE,
  CLEAR_TAGS,
  EDIT_COMMENT_LOADING,
  EDIT_COMMENT_ERROR,
  EDIT_COMMENT_SUCCESS,
  SET_COMMENT_BEING_EDITED,
} from '../constants/commentsTypes';
import { SET_INBOX_ACTIVE_ROW, UPDATE_LIST_ITEMS } from '../constants/inboxTypes';
import { setActiveReferral } from './referralsActions';
import { Comment } from '../types';

const commentsLoadingActionCreator = (payload: boolean) => ({
  type: FETCH_COMMENTS_LOADING,
  payload,
});

export const loadCommentsToStoreActionCreator = (payload: any) => ({
  type: LOAD_COMMENTS_TO_STORE,
  payload,
});

export const actionCreator = (type: string, payload: any) => ({
  type,
  payload,
});

export const clearTagsActionCreator = (clearTags: boolean) => ({
  type: CLEAR_TAGS,
  payload: clearTags,
});

export const updateInboxList = (payload: any) => {
  return {
    type: UPDATE_LIST_ITEMS,
    payload,
  };
};

export const setEditCommentLoading = (isLoading: boolean) => ({
  type: EDIT_COMMENT_LOADING,
  payload: isLoading,
});

export const setEditCommentSuccess = (payload: any) => ({
  type: EDIT_COMMENT_SUCCESS,
  payload,
});

export const setEditCommentError = (payload: any) => ({
  type: EDIT_COMMENT_ERROR,
  payload,
});

export const setCommentBeingEdited = (payload?: Comment) => ({
  type: SET_COMMENT_BEING_EDITED,
  payload,
});

export const addInboxComment = (params: any) => async (dispatch: Dispatch) => {
  try {
    dispatch(actionCreator(CREATE_COMMENTS_LOADING, true));
    const { data } = await addCommentsApi(params);
    dispatch(actionCreator(LOAD_COMMENT_CREATION_RESPONSE_TO_STORE, data));
    dispatch(loadCommentsToStoreActionCreator(data.comments));
    dispatch({
      type: SET_INBOX_ACTIVE_ROW,
      payload: data,
    });
    dispatch(updateInboxList(data));
  } catch (error: any) {
    const errorMessage = backendErrorHandler(error);
    showErrorMessage(errorMessage);
    dispatch(commentsLoadingActionCreator(false));
  } finally {
    dispatch(actionCreator(CREATE_COMMENTS_LOADING, false));
  }
};

export const addReferralsComment = (payload: any, onCompletion?: () => void) => async (dispatch: any) => {
  try {
    dispatch(actionCreator(CREATE_COMMENTS_LOADING, true));
    const { data } = await addReferralCommentsApi(payload);
    dispatch(setActiveReferral(data));
    dispatch(actionCreator(LOAD_COMMENT_CREATION_RESPONSE_TO_STORE, data));
    dispatch(loadCommentsToStoreActionCreator(data.comments));
  } catch (error: any) {
    const errorMessage = backendErrorHandler(error);
    showErrorMessage(errorMessage);
    dispatch(commentsLoadingActionCreator(false));
  } finally {
    onCompletion && onCompletion();
    dispatch(actionCreator(CREATE_COMMENTS_LOADING, false));
    dispatch(clearTagsActionCreator(true));
  }
};

export const editCommentAction =
  (
    commentId: string,
    payload: EditCommentPayload,
    callBack?: (responseData?: any) => void,
    onError?: (error: any) => void,
  ) =>
  async (dispatch: any) => {
    try {
      dispatch(setEditCommentLoading(true));
      const response = await editCommentApi(commentId, payload);
      dispatch(setEditCommentSuccess(response.data));

      callBack && callBack(response.data);
    } catch (error: any) {
      dispatch(setEditCommentError(error));

      const errorMessage: string = backendErrorHandler(error);
      showErrorMessage(errorMessage);
      onError && onError(error);
    } finally {
      dispatch(clearTagsActionCreator(true));
      dispatch(setEditCommentLoading(false));
    }
  };
