export const FETCH_COMMENTS_LOADING = 'FETCH_COMMENTS_LOADING';
export const LOAD_COMMENTS_TO_STORE = 'LOAD_COMMENTS_TO_STORE';
export const LOAD_COMMENTS_ERROR = 'LOAD_COMMENTS_ERROR';

export const CREATE_COMMENTS_LOADING = 'CREATE_COMMENTS_LOADING';
export const LOAD_COMMENT_CREATION_RESPONSE_TO_STORE = 'LOAD_COMMENT_CREATION_RESPONSE_TO_STORE';
export const LOAD_COMMENT_CREATION_ERROR = 'LOAD_COMMENT_CREATION_ERROR';
export const LOAD_NEW_COMMENT_FROM_SOCKET = 'LOAD_COMMENT_FROM_SOCKET';

export const CLEAR_TAGS = 'CLEAR_TAGS';

export const EDIT_COMMENT_LOADING = 'EDIT_COMMENT_LOADING';
export const EDIT_COMMENT_SUCCESS = 'EDIT_COMMENT_SUCCESS';
export const EDIT_COMMENT_ERROR = 'EDIT_COMMENT_ERROR';
export const SET_COMMENT_BEING_EDITED = 'SET_COMMENT_BEING_EDITED';
