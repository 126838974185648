import { useState } from 'react';
import { Task } from '../../../redux/types';
import { resendFaxApi } from '../../../api/faxApi';

export function DeclineReferral(server: DeclineReferralServer) {
  return function useDeclineReferral(
    task: DeclineReferralTask,
    onDecline: (referralId: DeclineReferralTask['referral']['id']) => void = () => {},
  ) {
    const defaultFaxNumber = task.referral.provider?.officeFaxNumber;
    const [state, setState] = useState({
      dialogOpen: false,
      faxNumber: defaultFaxNumber,
      sending: false,
    });
    const faxStatus = task.fax?.status;
    const faxFailed = faxStatus?.id === '3841514E-F4E8-4418-8526-4C3C4DF31155';
    return {
      sendButton: {
        enabled: !state.sending && (!faxStatus || faxFailed),
        loading: state.sending,
        label: faxFailed ? 'Retry' : 'Send Fax',
        click() {
          if (faxFailed && task.fax?.id) {
            setState((state) => ({ ...state, sending: true }));
            resendFaxApi(task.fax.id)
              .then(() => onDecline(task.referral.id))
              .finally(() => setState((state) => ({ ...state, sending: false })));
          } else {
            setState((state) => ({ ...state, dialogOpen: true }));
          }
        },
      },
      dialog: {
        open: state.dialogOpen,
        faxNumber: {
          value: state.faxNumber,
          set(val: string) {
            setState((state) => ({ ...state, faxNumber: val }));
          },
        },
        cancel() {
          setState((state) => ({ ...state, dialogOpen: false, faxNumber: defaultFaxNumber }));
        },
        sendButton: {
          enabled: !!state.faxNumber && !state.sending,
          loading: state.sending,
          click() {
            if (!state.faxNumber) {
              throw new Error('Fax number is required');
            }
            setState((state) => ({ ...state, sending: true }));
            server
              .decline({
                taskId: task.id,
                faxNumber: state.faxNumber.replace(/\D+/g, ''),
              })
              .then(() => onDecline(task.referral.id))
              .then(() => setState((state) => ({ ...state, dialogOpen: false })))
              .catch(console.error)
              .then(() => setState((state) => ({ ...state, sending: false })));
          },
        },
      },
    };
  };
}

export type DeclineReferralTask = Pick<Task, 'referral' | 'fax' | 'id'>;

export interface DeclineReferralServer {
  decline(request: { taskId: DeclineReferralTask['id']; faxNumber: string }): Promise<DeclineReferralTask>;
}
