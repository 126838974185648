import { makeStyles } from '@mui/styles';
import { alpha, Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      width: '100%',
    },
    addNew: {
      marginTop: 8,
      marginBottom: 8,
    },
    menu: {
      // '&.MuiPopover-paper': {
      minWidth: `360px !important`,
    },
    noScroll: {
      maxHeight: 350,
      // overflowY: 'scroll',
      boxSizing: 'border-box',
      paddingBottom: 0,
      scrollbarWidth: 'none',
      '-ms-overflow-style': 'none',
      msOverflowStyle: 'none',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    secondaryLabel: {
      paddingLeft: 8,
      paddingRight: 12,
    },
    selectHeader: {
      padding: '0 12px',
      boxSizing: 'border-box',
      width: '100%',
      background: 'white',
      position: 'sticky',
      top: 0,
      zIndex: 1,
      // paddingTop: 8,
    },
  };
});

export const searchStyles = (({ theme }: any) => {
  return {
    position: 'relative',
    borderRadius: 8,
    backgroundColor: alpha(theme.palette.common.black, 0.04),
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    height: 40,
  };
}) as any;

export const searchIconWrapperStyles = ({ theme }: any) =>
  ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  } as any);

export const inputBaseStyles = ({ theme }: any) =>
  ({
    color: 'inherit',
    width: '100%',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: 16,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
  } as any);
