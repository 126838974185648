import { Box, Typography, IconButton } from '@mui/material';
import { AccountCircleOutlined } from '@mui/icons-material';
import SelectMenu from '../SelectMenu/SelectMenu';
import { useStyles } from './PatientComponentStyles';
import { format } from 'date-fns';
import { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Patient, Region, RootState } from '../../redux/types';
import AddPatient from '../AddPatient/AddPatient';
import { clearPatientsFromSearch, searchPatientsAction } from '../../redux/actions/patientsActions';
import pencil from '../../images/bluePencil.svg';
import orangeProvider from '../../images/orangeProvider.svg';
import { ignoreTimezoneDate } from '../../globalUtils/utils';
import { debounce, uniqBy } from 'lodash';

interface Props {
  handleChange: (fieldName: string, value: any) => void;
  selectedPatientValue: any;
  onAfterAdd: (data: any) => void;
  addedPatient: any;
  isTemporaryPatient?: boolean;
  hideAddNew: boolean;
  forceClose?: boolean;
  formErrors?: any;
  referralRegion?: Region;
}
const PatientComponent: FC<Props> = (props) => {
  const {
    handleChange,
    selectedPatientValue,
    onAfterAdd,
    addedPatient,
    isTemporaryPatient,
    formErrors,
    hideAddNew,
    forceClose,
    referralRegion,
  } = props;
  const classes = useStyles();

  const dispatch = useDispatch();
  const label = hideAddNew ? 'Select' : 'Select or Add New';

  const [openPatientAdd, setOpenPatientAdd] = useState<boolean>(false);
  const [editTempPatient, setEditTempPatient] = useState<boolean>(false);
  const [hideNoResults, setHideNoResults] = useState<boolean>(false);
  const [searchString, setSearchString] = useState('');

  const { patientsFromSearch } = useSelector((state: RootState) => state.patients);
  const { patients: patientsState } = useSelector((state: RootState) => state);

  useEffect(() => {
    return () => {
      dispatch(clearPatientsFromSearch());
    };
  }, [dispatch]);

  const patients = patientsFromSearch.map((patient: any) => ({
    ...patient,
    label: (
      <Box display="flex" alignItems="center">
        <Typography style={{ fontSize: 16 }} variant="subtitle2">
          {patient.first_name + ' ' + patient.last_name}
        </Typography>
        <Typography style={{ marginLeft: 8 }} color="textSecondary">
          {(patient.date_of_birth && format(ignoreTimezoneDate(patient.date_of_birth), 'MM/dd/yyyy')) || ''}
        </Typography>
      </Box>
    ),
    inputId: 'patient',
  }));

  const formattedAddedPatient = {
    ...addedPatient,
    searchString: addedPatient?.first_name + ' ' + addedPatient?.last_name,
    label: (
      <Box display="flex" alignItems="center">
        <Typography style={{ fontSize: 16 }} variant="subtitle2">
          {addedPatient.first_name + ' ' + addedPatient.last_name}
        </Typography>
        <Typography style={{ marginLeft: 8 }} color="textSecondary">
          {(addedPatient.date_of_birth && format(ignoreTimezoneDate(addedPatient.date_of_birth), 'MM/dd/yyyy')) || ''}
        </Typography>
      </Box>
    ),
    secondaryLabel: addedPatient?.region?.abbreviation ? addedPatient?.region?.abbreviation : '-',
  };

  const patientOptions = patients.map((patient: Patient, i: number) => {
    return {
      searchString: patient.first_name + ' ' + patient.last_name,
      label: (
        <Box display="flex" alignItems="center">
          <Typography style={{ fontSize: 16 }} variant="subtitle2">
            {patient.first_name + ' ' + patient.last_name}
          </Typography>
          <Typography style={{ marginLeft: 8 }} color="textSecondary">
            {(patient.date_of_birth && format(ignoreTimezoneDate(patient.date_of_birth), 'MM/dd/yyyy')) || ''}
          </Typography>
        </Box>
      ),
      secondaryLabel: patient?.region?.abbreviation ? patient?.region?.abbreviation : '_',
      ...patient,
    };
  });

  useEffect(() => {
    return () => {
      dispatch(clearPatientsFromSearch());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referralRegion]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const delayedQuery = useCallback(
    debounce((searchText: string) => {
      dispatch(
        searchPatientsAction({
          search: searchText,
          isTemporary: false,
          ...(referralRegion?.weinfuseGroupId && { weinfuseGroupId: referralRegion?.weinfuseGroupId?.toString() }),
        }),
      );
      setHideNoResults(false);
    }, 500),
    [referralRegion],
  );

  const handleSearch = (searchText: string) => {
    setHideNoResults(true);
    if (searchText.length > 1) {
      delayedQuery(searchText);
    }
    setSearchString(searchText);
  };

  const patientInitialValues = {
    first_name: addedPatient?.first_name || '',
    last_name: addedPatient?.last_name || '',
    sex: addedPatient?.sex || '',
    date_of_birth: ignoreTimezoneDate(addedPatient?.date_of_birth),
    ...addedPatient,
  };

  const handleEdit = () => {
    setOpenPatientAdd(true);
    setEditTempPatient(true);
  };

  const handleClose = (value: boolean = false) => {
    setOpenPatientAdd(value);
    setEditTempPatient(false);
  };

  const isSelectedOption = (option: any, value: any): boolean => option?.id === value?.id;

  return (
    <Box style={{ marginRight: 16, width: 'calc(60% - 16px)' }} className={classes.labelInput} data-id="patient">
      <AddPatient
        onAfterAdd={onAfterAdd}
        open={openPatientAdd}
        onOpenChange={handleClose}
        initialValues={editTempPatient ? patientInitialValues : undefined}
        isEditingTempPatient={editTempPatient}
      />

      <SelectMenu
        disabled={!referralRegion?.weinfuseGroupId}
        value={selectedPatientValue}
        onChange={(value) => handleChange('patient', { ...value, inputId: 'patient' })}
        onClose={() => setSearchString('')}
        onAddNew={() => setOpenPatientAdd(true)}
        handleSearch={handleSearch}
        hideAddnew={hideAddNew}
        forceClose={forceClose}
        showExpandMore
        searchLoading={patientsState.loading}
        valueIcon={<AccountCircleOutlined fontSize="large" style={{ width: 26, height: 26 }} />}
        hideNoResults={hideNoResults}
        label={
          patientInitialValues?.first_name ? (
            <Box className={classes.viewTransform}>
              <Typography
                variant="subtitle2"
                style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
                color="textPrimary"
              >
                {`${patientInitialValues.first_name} ${patientInitialValues.last_name}`}
              </Typography>
              {patientInitialValues.first_name && (
                <Typography variant="body2" color="textSecondary">
                  {patientInitialValues.date_of_birth &&
                    format(ignoreTimezoneDate(patientInitialValues.date_of_birth), 'MM/dd/yyyy')}
                </Typography>
              )}
            </Box>
          ) : (
            label
          )
        }
        labelStyles={{
          color: formErrors?.patient ? 'red' : '',
        }}
        icon={
          <AccountCircleOutlined
            fontSize="large"
            style={{ width: 26, height: 26, fill: isTemporaryPatient ? '#ED6C0280' : formErrors?.patient ? 'red' : '' }}
          />
        }
        options={uniqBy(
          (formattedAddedPatient?.id && !searchString
            ? [formattedAddedPatient, ...patientOptions]
            : patientOptions
          ).map((option: any) => ({
            ...option,
            disabled: option?.disabled || isSelectedOption(option, selectedPatientValue),
            showDoneIcon: isSelectedOption(option, selectedPatientValue),
          })),
          'id',
        )}
        labelTransform={(value) => {
          return (
            <Box className={classes.viewTransform}>
              <Typography style={{ textOverflow: 'ellipsis' }} color="textPrimary">
                {value?.label}
              </Typography>
            </Box>
          );
        }}
        headerComponent={
          isTemporaryPatient ? (
            <Box display="flex" justifyContent="space-between" style={{ paddingTop: 4, paddingBottom: 4 }}>
              <Box display="flex" justifyContent="flex-start">
                <img alt="" src={orangeProvider} height={20} width={20} style={{ paddingRight: 12 }} />
                <Typography variant="body1">{`${addedPatient?.first_name || patientInitialValues?.first_name} ${
                  addedPatient?.last_name || patientInitialValues?.last_name
                }`}</Typography>
              </Box>

              <Box>
                <IconButton style={{ marginRight: 10 }} onClick={handleEdit}>
                  <img alt="" src={pencil} height={20} width={20} />
                </IconButton>
              </Box>
            </Box>
          ) : undefined
        }
      />
    </Box>
  );
};

export default PatientComponent;
