import {
  HIDE_WEINFUSE_CONNECTION_ALERT,
  SET_ATTACHMENT_BEING_UPLOADED_TO_WEINFUSE,
  SET_ATTACHMENT_BEING_UPLOADED_TO_WEINFUSE_LOADING,
  SET_WEINFUSE_ENABLED_STATUS,
} from '../constants/weInfuseTypes';

export const hideWeInfuseConnectionAlert = (hideAlert: boolean) => ({
  type: HIDE_WEINFUSE_CONNECTION_ALERT,
  payload: hideAlert,
});

export const setWeInfuseEnabledStatus = (enabled: boolean) => ({
  type: SET_WEINFUSE_ENABLED_STATUS,
  payload: enabled,
});

export const setAttachmentBeingUploadedToWeInfuse = (attachment: any) => ({
  type: SET_ATTACHMENT_BEING_UPLOADED_TO_WEINFUSE,
  payload: attachment,
});

export const setAttachmentBeingUploadedToWeInfuseLoading = (attachmentId: string, isLoading: boolean) => ({
  type: SET_ATTACHMENT_BEING_UPLOADED_TO_WEINFUSE_LOADING,
  payload: {
    id: attachmentId,
    loading: isLoading,
  },
});
