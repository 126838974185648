import { Dispatch } from 'redux';
import { getTaskTemplates } from '../../api/medicationsApi';
import { GET_TASK_TEMPLATES, GET_TASK_TEMPLATES_SUCCESS, GET_TASK_TEMPLATES_ERROR } from '../constants/referralTypes';

const getTaskTemplatesLoading = (payload: boolean) => ({
  type: GET_TASK_TEMPLATES,
  payload,
});

const getTaskTemplatesSuccess = (payload: any) => ({
  type: GET_TASK_TEMPLATES_SUCCESS,
  payload,
});

const getTaskTemplatesError = (payload: any) => ({
  type: GET_TASK_TEMPLATES_ERROR,
  payload,
});

export const getTaskTemplatesAction = () => async (dispatch: Dispatch) => {
  try {
    dispatch(getTaskTemplatesLoading(true));
    const response = await getTaskTemplates();
    dispatch(getTaskTemplatesSuccess(response.data));
  } catch (e) {
    dispatch(getTaskTemplatesError(e));
  } finally {
    dispatch(getTaskTemplatesLoading(false));
  }
};
