import { FETCH_USERS_LOADING, LOAD_USERS_ERROR, LOAD_USERS_TO_STORE } from '../constants/authTypes';
import { Region } from '../types';
import { Dispatch } from 'redux';
import { fetchUsersApi } from '../../api/usersApi';

const usersLoading = (payload: boolean) => ({
  type: FETCH_USERS_LOADING,
  payload,
});

const loadUsersToStore = (payload: Region) => ({
  type: LOAD_USERS_TO_STORE,
  payload,
});

const loadUSERSErrorToStore = (payload: any) => ({
  type: LOAD_USERS_ERROR,
  payload,
});

export const fetchUsersAction = () => async (dispatch: Dispatch) => {
  try {
    dispatch(usersLoading(true));
    const response = await fetchUsersApi();
    dispatch(loadUsersToStore(response.data));
    dispatch(usersLoading(false));
  } catch (e) {
    dispatch(loadUSERSErrorToStore(e));
    dispatch(usersLoading(false));
  }
};
