import axios, { AxiosResponse } from 'axios';

export interface FetchInboxesParams {
  search?: string;
  regions?: string[];
  users?: string[];
  statuses?: string[];
  fromFaxReceivedDate?: string;
  toFaxReceivedDate?: string;
  fromCreatedAt?: string;
  toCreatedAt?: string;
  sortDirection?: 'ASC' | 'DESC';
  sortField?: string;
  page?: number;
  pageSize?: number;
}
export const fetchInboxApi = async (params: FetchInboxesParams): Promise<AxiosResponse<any, any>> => {
  const baseUrl = `${process.env.REACT_APP_API_URL}`;
  const data = {
    sortDirection: 'ASC',
    sortField: 'faxReceivedDate',
    ...params,
  };
  return await axios.post(`${baseUrl}/inboxes/find`, data);
};

export interface UpdateInboxParams {
  title?: string;
  id?: string;
}
export const updateInboxApi = async (params: UpdateInboxParams): Promise<AxiosResponse<any, any>> => {
  const { id, title } = params;
  const baseUrl = `${process.env.REACT_APP_API_URL}`;
  return await axios.patch(`${baseUrl}/inboxes/${id}`, { title });
};

export interface AddToExistingReferralParams {
  inboxId?: string;
  referralId?: string;
}
export const addToExistingReferralApi = async (
  params: AddToExistingReferralParams,
): Promise<AxiosResponse<any, any>> => {
  const baseUrl = `${process.env.REACT_APP_API_URL}`;
  return await axios.post(`${baseUrl}/inboxes/add-to-existing-referral/`, params);
};

export const forwardInboxToAsanaApi = async (id: string): Promise<AxiosResponse<any, any>> => {
  const baseUrl = `${process.env.REACT_APP_API_URL}`;
  return await axios.post(`${baseUrl}/inboxes/forward/asana/${id}`);
};

export interface UpdateInboxParams {
  regionId?: string;
  id?: string;
}
export const updateInboxRegionApi = async (params: UpdateInboxParams): Promise<AxiosResponse<any, any>> => {
  const { id, regionId } = params;
  const baseUrl = `${process.env.REACT_APP_API_URL}`;
  return await axios.post(`${baseUrl}/inboxes/region/set/${id}`, { regionId });
};

export interface AssignInboxParams {
  userId?: string;
  id?: string;
}
export const assignInboxApi = async (params: AssignInboxParams): Promise<AxiosResponse<any, any>> => {
  const { id, userId } = params;
  const baseUrl = `${process.env.REACT_APP_API_URL}`;
  return await axios.patch(`${baseUrl}/inboxes/user/assign/${id}`, { userId });
};

export interface UnAssignInboxParams {
  id?: string;
}
export const unAssignInboxApi = async (params: UnAssignInboxParams): Promise<AxiosResponse<any, any>> => {
  const { id } = params;
  const baseUrl = `${process.env.REACT_APP_API_URL}`;
  return await axios.patch(`${baseUrl}/inboxes/user/unassign/${id}`, {});
};

export interface UpdateStatusParams {
  status?: string;
  id?: string;
}
export const updateStatusApi = async (params: UpdateStatusParams): Promise<AxiosResponse<any, any>> => {
  const { id, status } = params;
  const baseUrl = `${process.env.REACT_APP_API_URL}`;
  return await axios.patch(`${baseUrl}/inboxes/status/${id}`, { status });
};

export const getInboxItemApi = async (id: string): Promise<AxiosResponse<any, any>> => {
  const baseUrl = `${process.env.REACT_APP_API_URL}`;
  return await axios.get(`${baseUrl}/inboxes/${id}`);
};
//
export const getCommentApi = async (id: string): Promise<AxiosResponse<any, any>> => {
  const baseUrl = `${process.env.REACT_APP_API_URL}`;
  return await axios.get(`${baseUrl}/comments/${id}`);
};

export const getAttachmentApi = async (id: string): Promise<AxiosResponse<any, any>> => {
  const baseUrl = `${process.env.REACT_APP_API_URL}`;
  return await axios.get(`${baseUrl}/attachments/file/${id}`, {
    responseType: 'arraybuffer',
  });
};

export interface attachmentsProps {
  referralId?: string;
  attachmentId?: string;
}

export const removeAttachmentApi = async (params: attachmentsProps): Promise<AxiosResponse<any, any>> => {
  const baseUrl = `${process.env.REACT_APP_API_URL}`;
  return await axios.patch(`${baseUrl}/attachments/remove/referral/`, params);
};
