import { sortByNameKeyCompareFunction } from '../../globalUtils/helpers';
import {
  GET_REFERRAL_PROVIDERS_ERROR,
  GET_REFERRAL_PROVIDERS_LOADING,
  GET_REFERRAL_PROVIDERS_SUCCESS,
  UPDATE_REFERRAL_PROVIDER_ERROR,
  UPDATE_REFERRAL_PROVIDER_LOADING,
  UPDATE_REFERRAL_PROVIDER_SUCCESS,
  ADD_REFERRAL_PROVIDER_ERROR,
  ADD_REFERRAL_PROVIDER_LOADING,
  ADD_REFERRAL_PROVIDER_SUCCESS,
  CLEAR_PROVIDERS_FROM_SEARCH,
  GET_ALL_REFERRALS_PROVIDERS_SUCCESS,
  GET_ALL_REFERRALS_PROVIDERS_ERROR,
  GET_ONE_REFERRAL_PROVIDER,
  GET_ONE_REFERRAL_PROVIDER_SUCCESS,
  GET_ONE_REFERRAL_PROVIDER_ERROR,
} from '../constants/referralTypes';
import { BaseAction, ReferralProvider } from '../types';

export interface AllProvidersState {
  allProviders: ReferralProvider[];
  totalCount: number;
  page: number;
  pageSize: number;
  offset: number;
  isLastPage: boolean;
  loading: boolean;
  error: any;
}
export interface ReferralProviderStore {
  loading: boolean;
  providers: ReferralProvider[];
  allProvidersState: AllProvidersState;
  editProvider: {
    loading: boolean;
    success: boolean;
    error: any;
    data: any;
  };
  newProvider: {
    loading: boolean;
    success: boolean;
    error: any;
    data: any;
  };
  oneReferralProvider: {
    loading: boolean;
    data: any;
    error: any;
    success: boolean;
  };
}

const initialState: ReferralProviderStore = {
  loading: false,
  providers: [],
  allProvidersState: {
    allProviders: [],
    totalCount: 0,
    page: 0,
    pageSize: 0,
    offset: 0,
    isLastPage: false,
    loading: false,
    error: null,
  },
  editProvider: {
    loading: false,
    success: false,
    error: null,
    data: {},
  },
  newProvider: {
    loading: false,
    success: false,
    error: null,
    data: {},
  },
  oneReferralProvider: {
    loading: false,
    error: undefined,
    data: null,
    success: false,
  },
};

const reducer = (state: ReferralProviderStore = initialState, action: BaseAction): ReferralProviderStore => {
  switch (action.type) {
    case GET_REFERRAL_PROVIDERS_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case GET_REFERRAL_PROVIDERS_ERROR:
      return {
        ...state,
        loading: false,
      };
    case GET_ALL_REFERRALS_PROVIDERS_ERROR:
      return {
        ...state,
        allProvidersState: {
          ...state.allProvidersState,
          error: action.payload,
          isLastPage: true,
          loading: false,
        },
      };
    case GET_REFERRAL_PROVIDERS_SUCCESS:
      const newProviders =
        action.payload.data.length > 0
          ? action.payload.data.map((provider: any) => ({
              ...provider,
              name: `${provider.firstName} ${provider.lastName}`,
            }))
          : [];

      return {
        ...state,
        ...action.payload,
        loading: Boolean(action?.payload?.loading),
        providers: [...state.providers, ...newProviders.sort(sortByNameKeyCompareFunction)],
      };
    case GET_ALL_REFERRALS_PROVIDERS_SUCCESS:
      return {
        ...state,
        allProvidersState: {
          ...state.allProvidersState,
          ...action.payload,
          loading: false,
          allProviders: [...state.allProvidersState?.allProviders, ...action.payload.data],
        },
      };
    case CLEAR_PROVIDERS_FROM_SEARCH:
      return {
        ...state,
        loading: false,
        providers: [],
      };
    case UPDATE_REFERRAL_PROVIDER_LOADING:
      return {
        ...state,
        editProvider: {
          ...state.editProvider,
          loading: true,
          success: false,
          error: null,
        },
      };
    case UPDATE_REFERRAL_PROVIDER_SUCCESS:
      const updatedProviders = state.providers?.map((provider) =>
        provider.id === action.payload.id ? { ...provider, ...action.payload } : provider,
      );

      return {
        ...state,
        editProvider: {
          ...state.editProvider,
          loading: false,
          success: true,
          data: action?.payload,
        },
        providers: updatedProviders,
      };
    case UPDATE_REFERRAL_PROVIDER_ERROR:
      return {
        ...state,
        editProvider: {
          ...state.editProvider,
          loading: false,
          success: false,
          error: action?.payload,
        },
      };
    case ADD_REFERRAL_PROVIDER_LOADING:
      return {
        ...state,
        newProvider: {
          ...state.newProvider,
          loading: true,
          success: false,
          error: null,
        },
      };
    case ADD_REFERRAL_PROVIDER_SUCCESS:
      const withNewProvider = [...state.providers, action.payload];
      return {
        ...state,
        newProvider: {
          ...state.newProvider,
          loading: false,
          success: true,
          data: action?.payload,
        },
        providers: withNewProvider,
      };
    case ADD_REFERRAL_PROVIDER_ERROR:
      return {
        ...state,
        newProvider: {
          ...state.newProvider,
          loading: false,
          success: false,
          error: action?.payload,
        },
      };
    case GET_ONE_REFERRAL_PROVIDER:
      return {
        ...state,
        oneReferralProvider: {
          ...state.oneReferralProvider,
          loading: true,
        },
      };
    case GET_ONE_REFERRAL_PROVIDER_SUCCESS:
      return {
        ...state,
        oneReferralProvider: {
          ...state.oneReferralProvider,
          loading: false,
          error: null,
          data: action.payload,
        },
      };
    case GET_ONE_REFERRAL_PROVIDER_ERROR:
      return {
        ...state,
        oneReferralProvider: {
          ...state.oneReferralProvider,
          loading: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
};

export default reducer;
