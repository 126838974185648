import { Tooltip, TooltipProps } from '@mui/material';
import { useStyles } from './tooltipStyles';

interface Props extends TooltipProps {
  wrap?: boolean;
}
export const TooltipWrapper = (props: Props) => {
  const { wrap, children, title, placement, ...restProps } = props;
  const classes = useStyles();

  return wrap ? (
    <Tooltip classes={{ tooltip: classes.tooltip }} placement={placement || 'top'} title={title} {...restProps}>
      {children}
    </Tooltip>
  ) : (
    children
  );
};
