import { makeStyles, styled } from '@mui/styles';
import { alpha, Box, Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => {
  return {
    sticky: {
      position: 'sticky',
      top: 0,
      background: theme.palette.background.paper,
      zIndex: theme.zIndex.appBar,
    },
    dialogTitle: {
      fontWeight: 500,
      fontSize: 20,
    },
    commentBox: {
      marginTop: 10,
    },
    banner: {
      padding: 10,
      borderRadius: 4,
      border: `1px solid #0288D1`,
      color: '#0288D1',
    },
    root: {
      // padding: `10px 0`,
      height: '100vh',
      overflow: 'auto',
      overflowY: 'scroll',
      boxSizing: 'border-box',
      paddingBottom: 0,
      scrollbarWidth: 'none',
      '-ms-overflow-style': 'none',
      msOverflowStyle: 'none',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    chips: {
      marginTop: 32,
    },
    chip: {
      marginLeft: 16,
    },
    chipRoot: {
      background: `#E3F2FD !important`,
    },
    icons: {
      color: theme.palette.text.secondary,
    },
    referralDetails: {
      padding: 24,
      border: `1px solid ${theme.palette.grey['300']}`,
      borderRadius: 6,
      marginTop: 16,
      display: 'flex',
      flexWrap: 'wrap',
    },
    label: {
      display: 'flex',
      alignItems: 'center',
      width: '40%',
      height: 56,
    },
    labelInput: {
      display: 'flex',
      width: '60%',
      alignItems: 'center',
      height: 48,
      boxSizing: 'border-box',
    },
    renderInput: {},
    patientDetails: {
      marginTop: 16,
    },
    popper: {
      background: 'transparent',
      marginTop: '-6px !important',
    },
    '.SnackbarItem-variantDefault': {
      backgroundColor: '#ff3d00 !important',
    },
    icon: { background: '#BDBDBD', height: 24, width: 24, borderRadius: '50%', color: '#FFFFFF' },
    tooltip: {
      background: '#616161 !important',
      opacity: '0.9 !important',
      color: '#FFFFFF !important',
    },
    paper: {
      minWidth: '572px',
      minHeight: '196px',
    },
    deletedDraftViewContainer: {
      height: '100%',
      padding: '0 24px',
    },
    deletedViewDetailsContainer: {
      height: '100%',
    },
    deletedViewCloseIconContainer: {
      marginLeft: -8,
    },
    deletedViewCloseIcon: {
      fontSize: 20,
      color: '#000000',
    },
    deletedViewTitle: {
      paddingBottom: 32,
      paddingTop: 16,
    },
    deletedViewTextContainer: {
      height: '84%',
      borderRadius: 6,
      boxShadow: '0px 0px 0px 1px #E0E0E0',
      border: '1px solid #E0E0E0',
    },
    addAttachmentTooltip: {
      fontSize: '14px !important',
    },
    addIconButton: {
      '&:hover': {
        background: '#0000000F !important',
      },
    },
  };
});

export const buttonStyles = {
  borderRadius: 32,
  height: 32,
};

export const StyledSearch: any = ({ focus, ...restProps }: any) => {
  const inputStyles = (({ theme, ...restProps }: any) => {
    return {
      position: 'relative',
      borderRadius: 0,
      backgroundColor: focus && alpha(theme.palette.common.black, 0.04),
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: '100%',
      height: 40,
      'li:has(> input:active)': {
        backgroundColor: alpha(theme.palette.common.black, 0.04),
      },
    };
  }) as any;
  const Component = styled(Box)(inputStyles);
  return <Component {...restProps} />;
};

export const inputBaseStyles = ({ theme }: any) =>
  ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      // paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
  } as any);
