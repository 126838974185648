import toast from 'toastr';

toast.options = {
  progressBar: false,
  closeButton: true,
  preventDuplicates: true,
  positionClass: 'toast-bottom-center',
  timeOut: 4000,
  extendedTimeOut: 500,
  toastClass: 'custom-class',
  closeHtml: `
    <button style="right: 8px; top:0px; margin-left: 10px" class="custom-close-btn">
      <span style="font-size:14px; font-weight: 500; font-family: 'Poppins'; margin-right: 8px ">OKAY</span> <img alt="close btn"/>
    </button>
  `,
};

export const showSuccessMessage = (message: string) => toast.success(message);
export const showErrorMessage = (message: string, options?: ToastrOptions) => toast.error(message, undefined, options);
