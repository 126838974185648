import React, { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Chip,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Tooltip,
  CircularProgress,
  Grid,
  FormControl,
  Input,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  FormControlLabel,
} from '@mui/material';
import pencil from '../../images/pencilBlack.svg';
import deleteBlack from '../../images/deleteBlack.svg';
import mailWithArrow from '../../images/mailWithArrow.svg';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { styles, useStyles } from './AttachmentItemStyles';
import { useTheme } from '@mui/styles';
import { Props } from './AttachmentItemTypes';
import { getFileExtension, openInNewTabSupportedFiles, userFriendlyTimestamp } from '../../globalUtils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { getAttachmentAction } from '../../redux/actions/inboxActions';
import fileDownload from 'js-file-download';
import deleteIcon from '../../images/deleteIcon.svg';
import uploadError from '../../images/uploadError.svg';
import xIcon from '../../images/X.svg';
import checkMark from '../../images/checkMark.svg';
import {
  editAttachmentsAction,
  getOneReferral,
  markAttachmentAsSeenOnReferralAction,
} from '../../redux/actions/referralsActions';
import { Attachment, RootState } from '../../redux/types';
import { removeFileAction } from '../../redux/actions/attachmentActions';
import { useGetAttachmentOperations, useOpenFileInNewTab } from '../../globalUtils/hooks';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { Link } from 'react-router-dom';
import { archivedInboxStatuses } from '../../globalUtils/helpers';
import AnimatedLoading from '../AnimatedLoading/AnimatedLoading';
import { validate } from 'validate.js';
import PublishToCloud from '../../images/publishToCloud.svg';
import CloudOffline from '../../images/cloudOffline.svg';
import PublishToCloudPrimary from '../../images/publishToCloudPrimary.svg';
import cloudSuccess from '../../images/cloudSuccess.svg';
import cloudError from '../../images/cloudError.svg';
import cloudSuccessDark from '../../images/cloudSuccessDark.svg';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import MenuItemSearchComponent from '../MenuItemSearchComponent/MenuItemSearchComponent';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { UPLOAD_STATUSES, FAX_STATUSES } from '../../globalUtils/constants';
import { editAttachmentLoading } from '../../redux/actionCreators/referralActionCreators';
import faxSuccess from '../../images/faxSuccess.svg';
import faxFailed from '../../images/caution.svg';
import faxUploading from '../../images/faxUploading.svg';
import NonScrollableMenu from '../Menu/NonScrollableMenu';

const getFaxDeliveryStatus = (faxes: any): { title: string; icon: string } => {
  switch (faxes?.[0]?.status?.name) {
    case FAX_STATUSES.IN_PROGRESS:
      return {
        title: FAX_STATUSES.IN_PROGRESS,
        icon: faxUploading,
      };
    case FAX_STATUSES.SUCCESS:
      return { title: FAX_STATUSES.SUCCESS, icon: faxSuccess };
    case FAX_STATUSES.MANUAL_DELIVERY:
      return { title: FAX_STATUSES.MANUAL_DELIVERY, icon: faxSuccess };
    case FAX_STATUSES.FAILED:
      return { title: FAX_STATUSES.FAILED, icon: faxFailed };
    default:
      return { title: faxes?.[0]?.status?.name, icon: faxFailed };
  }
};
const AttachmentItem: FC<Props> = (props) => {
  const classes = useStyles();
  const {
    tags,
    id,
    originalFileName,
    fileUploadError,
    fileUploadLoading,
    errorToolTip,
    percentComplete,
    handleRemoveFile,
    hideDeleteButton,
    selectValues,
    isEditable,
    referralId,
    afterEditCallback,
    hasMarkAsRead,
    attachment,
    hasWeInfusePatient,
    style: customStyles,
    hasRetrySendingFaxInActionMenu,
    retrySendingFax,
    emr,
    hidePublishToWeInfuse,
    hideManuallyPublishToEMR,
    faxes = [],
    deleteButtonDisabled,
    manualPublishDisabled,
  } = props;
  const outgoing = props.outgoing || props.alwaysShowFaxStatus;
  const theme: any = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [checked, setChecked] = useState(attachment?.seen);
  const existingCategories = tags?.map((tag: any) => tag.id) || [];
  const [selectedValues, setselectedValues] = useState<string[]>(existingCategories);
  const filename = originalFileName?.substring(0, originalFileName?.lastIndexOf('.')) || '';
  const [newFileName, setNewFileName] = useState<string>(filename);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [loadingMarkAsSeen, setLoadingMarkAsSeen] = useState(false);
  const {
    referrals: { removeAttachment },
    attachments,
    weInfuse: { attachmentSBeingUploadedToWeInfuse },
  } = useSelector((state: RootState) => state);
  const { publishAttachmentToWeInfuse } = useGetAttachmentOperations();
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const [newTabFileId, setNewtabFileId] = useState<string>();
  const [fileExtension, setFileExtension] = useState<string>('');
  const [attachmentFormValidationErrors, setAttachmentFormValidationErrors] = useState<any>({});
  const attachmentItemValidationSchema = {
    originalFileName: {
      presence: {
        allowEmpty: false,
        message: 'cannot be blank',
      },
    },
  };
  const attachmentPublishingToWeInfuseLoading: boolean = useMemo(() => {
    return attachmentSBeingUploadedToWeInfuse.find((attachment: Attachment) => attachment?.id === id)?.loading || false;
  }, [attachmentSBeingUploadedToWeInfuse, id]);

  const faxDeliveryStatus = useMemo(() => {
    return getFaxDeliveryStatus(faxes);
  }, [faxes]);

  const validateAttachmentsForm = (data: any) => {
    const validationErrors = validate(data, attachmentItemValidationSchema);
    setAttachmentFormValidationErrors(validationErrors);
    return validationErrors;
  };

  useOpenFileInNewTab(newTabFileId, fileExtension, () => {
    setNewtabFileId('');
  });

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const download = () => {
    const onSuccess = (d: any) => {
      fileDownload(d, `${originalFileName}`);
    };

    dispatch(getAttachmentAction(id, onSuccess));
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
    handleClose();
  };

  const handleRemoveAttachment = () => {
    const params = {
      attachmentId: id,
      referralId,
    };
    dispatch(removeFileAction(params, handleCloseDeleteDialog));
  };

  const deleteAttachment = () => {
    setIsDeleteDialogOpen(!isDeleteDialogOpen);
  };

  const handleAttachmentEdit = () => {
    setIsEditing(!isEditing);
    setselectedValues(existingCategories);
    if (afterEditCallback) {
      afterEditCallback(!isEditing);
    }
  };

  const handleCancelAttachmentEdit = (updatedFileName?: string) => {
    handleClose();
    setNewFileName(updatedFileName || filename);
    setselectedValues([]);
    setAttachmentFormValidationErrors({});
    setIsEditing(false);
    if (afterEditCallback) {
      afterEditCallback(false);
    }
  };

  const handleCheckBoxChange = () => {
    setLoadingMarkAsSeen(true);
    const seen = !checked;
    const onSuccess = () => {
      setChecked(seen);
      setLoadingMarkAsSeen(false);

      if (anchorEl) {
        handleClose();
      }
    };
    const onError = () => {
      setChecked(!seen);
      setLoadingMarkAsSeen(false);
    };
    if (referralId && id) {
      dispatch(markAttachmentAsSeenOnReferralAction({ referralId, seen, attachmentId: id }, onSuccess, onError));
    }
  };

  const handlePublishToWeinfuse = () => {
    publishAttachmentToWeInfuse(referralId, id, undefined, handleClose);
  };

  const handleChange = (event: any, isNameInput: boolean) => {
    const {
      target: { value: eventValue },
    } = event;
    !isNameInput &&
      setselectedValues(
        // On autofill we get a stringified value.
        typeof eventValue === 'string' ? eventValue.split(',') : eventValue,
      );

    isNameInput && setNewFileName(eventValue);
  };

  const handleEditAttachment = (params?: { emrUploadStatus?: string }) => {
    const categoryIdsToRemove = existingCategories.filter((category: string) => !selectedValues.includes(category));

    const dataToSubmit = {
      id,
      categoryIdsToAdd: selectedValues,
      ...(categoryIdsToRemove.length && { categoryIdsToRemove }),
      ...(newFileName && { originalFileName: `${newFileName}.${fileExtension}` }),
      ...(params?.emrUploadStatus && { emrUploadStatus: params?.emrUploadStatus }),
    };

    const errors = validateAttachmentsForm(dataToSubmit);

    if (!errors) {
      const onCompleteEditingAttachment = () => {
        referralId &&
          dispatch(
            getOneReferral(referralId, true, () => {
              dispatch(editAttachmentLoading(false));
            }),
          );
      };

      dispatch(
        editAttachmentsAction(
          dataToSubmit,
          () => {
            handleCancelAttachmentEdit(newFileName);
          },
          onCompleteEditingAttachment,
        ),
      );
    }
  };

  const getWeInfuseMetadata = useCallback((): { icon: string; tooltipText: string } => {
    if (!emr?.publishExternal) {
      return { icon: CloudOffline, tooltipText: 'Not published to EMR' };
    }

    switch (emr?.emrUploadStatus) {
      case UPLOAD_STATUSES.WAITING:
      case UPLOAD_STATUSES.IN_PROGRESS:
        return {
          icon: PublishToCloudPrimary,
          tooltipText: 'Publishing to EMR in progress',
        };
      case UPLOAD_STATUSES.FAILED:
        return {
          icon: cloudError,
          tooltipText: emr?.emrUploadStatusMessage || 'Failed publishing to EMR',
        };
      case UPLOAD_STATUSES.SUCCESS:
        return {
          icon: cloudSuccess,
          tooltipText: 'Published to EMR',
        };
      case UPLOAD_STATUSES.MANUALLY_ADDED:
        return {
          icon: cloudSuccessDark,
          tooltipText: 'Published to EMR (Manually)',
        };
      default:
        return {
          icon: CloudOffline,
          tooltipText: 'Not published to EMR',
        };
    }
  }, [emr]);

  useEffect(() => {
    if (originalFileName) {
      setFileExtension(getFileExtension(originalFileName) || '');
    }
  }, [originalFileName]);

  useEffect(() => {
    setChecked(attachment?.seen);
  }, [attachment?.seen]);

  const categoryOptions = (selectValues || [])
    .map((item) => {
      const isSelected = selectedValues.includes(item.id);
      return {
        ...item,
        searchString: item.name,
        label: item.name,
        value: item.id,
        groupName: isSelected ? ' SELECTED' : 'ALL',
      };
    })
    .sort(({ groupName }) => (groupName === 'SELECTED' ? 1 : -1));
  const [itemAnchorEl, setItemAnchorEl] = React.useState<null | HTMLElement>(null);
  const disableDelete =
    (emr?.publishExternal && emr?.emrUploadStatus === UPLOAD_STATUSES.WAITING) || deleteButtonDisabled;
  const onSelectCategory = (value: any) => {
    setselectedValues(value);
  };

  return !isEditing ? (
    <Box
      display="flex"
      className={classes.attachmentItem}
      key={props.id}
      data-id="attachmentItem"
      style={{
        background:
          emr?.emrUploadStatus === UPLOAD_STATUSES.FAILED ||
          (outgoing && faxDeliveryStatus?.title?.toLowerCase() === 'failed delivery')
            ? '#FEEBEE'
            : '#FAFAFA',
        ...customStyles,
      }}
    >
      <Dialog
        open={isDeleteDialogOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ paper: classes.paper }}
      >
        <DialogTitle style={{ fontWeight: 400 }} id="alert-dialog-title">
          {`Are you sure you want to delete "${props.originalFileName}" ?`}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog}>Cancel</Button>
          <Button variant="contained" disabled={removeAttachment?.loading} onClick={handleRemoveAttachment} autoFocus>
            {removeAttachment?.loading ? (
              <>
                Delete <CircularProgress style={{ color: 'rgba(0, 0, 0, 0.26)' }} size={18} />
              </>
            ) : (
              'Delete'
            )}
          </Button>
        </DialogActions>
      </Dialog>
      <Box flex={1}>
        <Typography
          color={'textSecondary'}
          variant="body2"
          data-id="attachmentFileName"
          style={{ wordBreak: 'break-word' }}
        >
          {props.originalFileName}
        </Typography>
        {tags?.map((tag: any, index: number) => (
          <Fragment key={index}>
            <Chip
              disabled={fileUploadLoading}
              size="small"
              style={styles.chip}
              label={tag?.name}
              key={tag}
              data-id="tagItems"
            />
            <span> </span>
          </Fragment>
        ))}

        <Typography color="textSecondary" variant="body2" data-id="attachmentTimeStamp">
          {userFriendlyTimestamp(props.createdAt || new Date())}
        </Typography>

        {hasMarkAsRead && !checked && attachment?.seen === false && (
          <FormControlLabel
            style={{ marginLeft: -1 }}
            value={checked}
            control={
              loadingMarkAsSeen ? (
                <CircularProgress size={16} style={{ paddingRight: 8 }} />
              ) : (
                <Checkbox onChange={() => handleCheckBoxChange()} checked={checked} />
              )
            }
            label={
              <Typography className={classes.markAsLabel}> {`Mark as ${checked ? 'Unseen' : 'Seen'}`} </Typography>
            }
            labelPlacement="start"
          />
        )}
      </Box>

      <Box display="flex" alignItems="center" justifyContent="center">
        {outgoing && faxes?.length > 0 && (
          <IconButton sx={{ mr: 2 }}>
            <Tooltip title={faxDeliveryStatus.title || ''}>
              <img alt="" src={faxDeliveryStatus.icon} width={20} height={20} />
            </Tooltip>
          </IconButton>
        )}
        {fileUploadError && (
          <IconButton>
            <Tooltip title={errorToolTip || ''}>
              <img alt="" src={uploadError} width={20} height={20} />
            </Tooltip>
          </IconButton>
        )}

        {fileUploadLoading && (
          <IconButton>
            <CircularProgress variant="determinate" value={percentComplete} size={40} />
            <Box
              sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography variant="caption" component="div" color="text.secondary">{`${Math.round(
                percentComplete || 0,
              )}%`}</Typography>
            </Box>
          </IconButton>
        )}
        {fileUploadError && (
          <IconButton onClick={() => handleRemoveFile(props.filename)}>
            <img role="button" height={20} width={18} alt="" src={deleteIcon} />
          </IconButton>
        )}
        {!fileUploadError && !fileUploadLoading && (
          <Tooltip title={getWeInfuseMetadata().tooltipText} placement="left">
            <img src={getWeInfuseMetadata().icon} alt="" />
          </Tooltip>
        )}
        {!fileUploadError && !fileUploadLoading && (
          <IconButton onClick={handleClick}>
            <MoreVertIcon style={{ color: theme.palette.text.secondary }} fontSize="large" />
          </IconButton>
        )}
        <Menu className={classes.menu} onClose={handleClose} anchorEl={anchorEl} open={open}>
          <MenuItem onClick={download}>
            <Typography fontSize={16}>Download</Typography>
          </MenuItem>
          <MenuItem
            onClick={() =>
              fileExtension?.toLowerCase() === 'html' || fileExtension?.toLowerCase() === 'htm'
                ? window.open(`/view-html-attachment/${id}`, '_blank', 'rel=noopener noreferrer')
                : setNewtabFileId(id)
            }
            disabled={!openInNewTabSupportedFiles.includes(fileExtension.toLowerCase())}
          >
            {newTabFileId ? (
              <AnimatedLoading text="Opening ..." fontSize={16} />
            ) : (
              <Typography fontSize={16}>Open in New Tab</Typography>
            )}
          </MenuItem>
          {hasMarkAsRead && checked && attachment?.seen === true && (
            <MenuItem>
              <FormControlLabel
                value={checked}
                control={
                  loadingMarkAsSeen ? (
                    <CircularProgress size={16} style={{ paddingLeft: 8 }} />
                  ) : (
                    <Checkbox checkedIcon={<></>} onChange={() => handleCheckBoxChange()} checked={checked} />
                  )
                }
                style={{ margin: 0 }}
                label={
                  <Typography className={classes.menuMarkAsLabel} style={{ fontSize: 16 }}>
                    {' '}
                    {`Mark as ${checked ? 'Unseen' : 'Seen'}`}{' '}
                  </Typography>
                }
                labelPlacement="start"
              />
            </MenuItem>
          )}
          {<Divider />}
          {!hidePublishToWeInfuse && (
            <Box display="flex" alignItems="center" paddingTop={0} paddingBottom={0}>
              <MenuItem
                onClick={handlePublishToWeinfuse}
                style={{ width: hasWeInfusePatient ? '100%' : '' }}
                disabled={
                  [
                    UPLOAD_STATUSES.FAILED,
                    UPLOAD_STATUSES.IN_PROGRESS,
                    UPLOAD_STATUSES.SUCCESS,
                    UPLOAD_STATUSES.MANUALLY_ADDED,
                  ].includes(emr?.emrUploadStatus) ||
                  !hasWeInfusePatient ||
                  attachmentPublishingToWeInfuseLoading ||
                  (emr?.publishExternal && emr.emrUploadStatus === UPLOAD_STATUSES.WAITING)
                }
              >
                <img src={PublishToCloud} style={{ marginRight: 30 }} alt="" />
                {attachmentPublishingToWeInfuseLoading ? (
                  <AnimatedLoading text="Publishing ..." fontSize={16} />
                ) : (
                  <Typography fontSize={16}>Publish to EMR</Typography>
                )}
              </MenuItem>
              {!hasWeInfusePatient && (
                <Tooltip title="Please select a Weinfuse patient to publish attachment to EMR" placement="top">
                  <ErrorOutlineOutlinedIcon
                    fontSize="large"
                    style={{ marginLeft: 0, color: '#00000042', width: 20, height: 20 }}
                  />
                </Tooltip>
              )}
            </Box>
          )}
          {!hideManuallyPublishToEMR && (
            <MenuItem
              onClick={() => handleEditAttachment({ emrUploadStatus: UPLOAD_STATUSES.MANUALLY_ADDED })}
              disabled={manualPublishDisabled}
            >
              <img style={{ marginRight: 30 }} alt={''} src={cloudSuccessDark} />
              <Typography fontSize={16}> Mark as "Manually published to EMR"</Typography>
            </MenuItem>
          )}
          {isEditable && (
            <MenuItem onClick={handleAttachmentEdit} style={{ paddingBottom: 8 }}>
              <img style={{ marginRight: 30 }} alt={''} src={pencil} />
              <Typography fontSize={16}>Edit</Typography>
            </MenuItem>
          )}

          {hasRetrySendingFaxInActionMenu && (
            <MenuItem
              onClick={() => {
                retrySendingFax?.();
                handleClose();
              }}
            >
              <img style={{ marginRight: 30 }} alt={''} src={mailWithArrow} />{' '}
              <Typography fontSize={16}>Retry sending Fax</Typography>
            </MenuItem>
          )}

          {attachment?.inboxes?.length ? (
            <Link
              to={`/inbox?inboxId=${attachment.inboxes[0].id}&tab=${
                !archivedInboxStatuses.includes(attachment.inboxes[0].status) ? 'received' : 'archived'
              }`}
              className={classes.viewInboxItemContainer}
              target="_blank"
            >
              <MenuItem className={classes.viewInboxItemMenuItem}>
                <MailOutlineIcon className={classes.openInNewTabIcon} />
                <Typography fontSize={16}>View Inbox Item</Typography>
              </MenuItem>
            </Link>
          ) : null}
          {!hideDeleteButton && <Divider />}
          {!hideDeleteButton && (
            <MenuItem onClick={deleteAttachment} disabled={disableDelete}>
              <img style={{ marginRight: 32 }} alt={''} src={deleteBlack} />{' '}
              <Typography fontSize={16}>Delete</Typography>
            </MenuItem>
          )}
        </Menu>
      </Box>
    </Box>
  ) : (
    <Grid container spacing={1} key={props.id}>
      <Grid item xs={7}>
        <FormControl
          className={classes.listedAttachment}
          fullWidth
          style={{ border: attachmentFormValidationErrors?.originalFileName ? '1px solid #f44336' : 'none' }}
        >
          <Input
            defaultValue={newFileName || 'no name'}
            onChange={(event) => {
              handleChange(event, true);
              setAttachmentFormValidationErrors((prevState: any) => ({
                ...prevState,
                originalFileName: undefined,
              }));
            }}
            disableUnderline
            endAdornment={<Typography variant="subtitle1">.{fileExtension}</Typography>}
            style={{ paddingLeft: 8, paddingRight: 8 }}
            data-id="editAttachmentFileName"
            inputProps={{ maxlength: props.filenameMaxLength || 245 }}
          />
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <Box>
          <Button
            disableRipple
            style={{ width: '100%', padding: 0, display: 'flex' }}
            onClick={(event: any) => setItemAnchorEl(event.target)}
          >
            <Box
              flex={1}
              width="100%"
              display="flex"
              alignItems="center"
              style={{ background: 'rgba(0,0,0,0.05)', height: 32, padding: 8, boxSizing: 'border-box' }}
              justifyContent="space-between"
              data-id="editTagAttachment"
            >
              <Typography
                color="textPrimary"
                style={{ whiteSpace: 'nowrap', textTransform: 'none', textOverflow: 'ellipsis' }}
              >
                {selectedValues.length} Categories
              </Typography>
              <ArrowDropDownIcon style={{ color: 'rgba(0,0,0,0.54)', marginRight: 16 }} fontSize={'large'} />
            </Box>
          </Button>
          <NonScrollableMenu
            style={{ height: 400, top: 8 }}
            anchorEl={itemAnchorEl}
            onClose={() => setItemAnchorEl(null)}
            open={Boolean(itemAnchorEl)}
          >
            <MenuItemSearchComponent
              showByGroup
              options={categoryOptions}
              showAvatar={false}
              onChange={(value) => onSelectCategory(value)}
              initialValues={selectedValues}
              sortByName
              menuItemsContentStyles={{
                maxHeight: 250,
                overflowY: undefined,
              }}
            />
          </NonScrollableMenu>
        </Box>
      </Grid>
      {attachments?.loading ? (
        <Grid item xs={2} display="flex" justifyContent="center" alignItems="center">
          <CircularProgress size={16} />
        </Grid>
      ) : (
        [
          <Grid item xs={1} display="flex" justifyContent="center" alignItems="center">
            <IconButton onClick={() => handleCancelAttachmentEdit()} data-id="closeEditAttachment">
              <img src={xIcon} alt="close" />
            </IconButton>
          </Grid>,
          <Grid item xs={1} display="flex" justifyContent="center" alignItems="center">
            <IconButton onClick={() => handleEditAttachment()} data-id="submitEditAttachment">
              <img src={checkMark} alt="submit" />
            </IconButton>
          </Grid>,
        ]
      )}
      {attachmentFormValidationErrors?.originalFileName && (
        <span style={{ paddingLeft: 8 }}>
          <Typography variant="caption" color="#f44336">
            Attachment name cannot be blank.
          </Typography>
        </span>
      )}
    </Grid>
  );
};

export default AttachmentItem;
