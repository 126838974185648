import { StatefulOrderEntryTask } from './OrderEntry/react-component/StatefulOrderEntryTask';
import { Task } from '../../redux/types';
import { PriorAuthTaskType } from './PriorAuth/prior-auth-task-type';
import { usePriorAuthAppealController, usePriorAuthDecisionController } from './PriorAuth';
import { DeclineReferralTaskType } from './DeclineReferral/view/decline-referral-task-type';
import { SchedulingTaskType } from './Scheduling/scheduling-task-type';

const TASK_TYPES = {
  ORDER_ENTRY: '8CDF7FA1-361B-4761-9F7C-EA44F7BCAC4D',
  PRIOR_AUTH_DECISION: '485C5A78-9920-488F-BA5E-968FB1438F0F',
  PRIOR_AUTH_APPEAL: 'eff2ccd6-75d9-4a7e-9beb-5d87f74b93da'.toUpperCase(),
  DECLINE_REFERRAL: 'DC33ECBA-6B1E-4184-98A9-CFA25E6EC3AC',
  SCHEDULING: 'F2A880AD-D410-4F8E-8DC7-5AC374BC6052',
};

const taskTypes: { [id: string]: TaskLogicFactory } = {
  [TASK_TYPES.ORDER_ENTRY]: StatefulOrderEntryTask,
  [TASK_TYPES.PRIOR_AUTH_DECISION]: (task) => PriorAuthTaskType(task, usePriorAuthDecisionController),
  [TASK_TYPES.PRIOR_AUTH_APPEAL]: (task) => PriorAuthTaskType(task, usePriorAuthAppealController),
  [TASK_TYPES.DECLINE_REFERRAL]: DeclineReferralTaskType,
  [TASK_TYPES.SCHEDULING]: SchedulingTaskType,
};

const NO_LOGIC = {
  taskBody: null,
  statusChangeHook: {
    component: null,
    allowChange: () => true,
    getStatusOptionDisplayState: () => ({ disabled: false }),
  },
} as TaskLogic;

export type TaskLogic = {
  taskBody: JSX.Element | null;
  statusChangeHook: StatusChangeHook;
  taskTitleIcon?: JSX.Element | null;
};
type TaskLogicFactory = (task: Task) => TaskLogic;
export type StatusChangeHook = {
  component: JSX.Element | null;
  allowChange: (statusName: string) => boolean;
  getStatusOptionDisplayState: (statusName: string) => { disabled: boolean; tooltip?: string; loading?: boolean };
};

export function getCustomLogic(task: Task): TaskLogic {
  return ((task.taskTypeId && taskTypes[task.taskTypeId]) || (() => NO_LOGIC))(task);
}
