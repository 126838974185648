import { Dispatch } from 'redux';
import { getUserPreferencesApi, updateFiltersPreferencesApi, UserPreferenceParams } from '../../api/userPreferencesApi';
import { showErrorMessage } from '../../components/NotificationToast/NotificationToast';
import backendErrorHandler from '../../globalUtils/backendErrorHandler';
import {
  getUserPreferencesError,
  getUserPreferencesLoading,
  getUserPreferencesSuccess,
  updateUserPreferencesError,
  updateUserPreferencesLoading,
  updateUserPreferencesSuccess,
} from '../actionCreators/userPreferencesActionCreators';
import { UserPreferencePageName } from '../types';

export const getUserPreferencesAction =
  (pageName: UserPreferencePageName, callBack?: (data: any) => void) => async (dispatch: Dispatch) => {
    dispatch(getUserPreferencesLoading(pageName, true));

    try {
      const response = await getUserPreferencesApi({ gridName: pageName });
      dispatch(getUserPreferencesSuccess(pageName, response.data));
      callBack && callBack(response.data);
    } catch (error: any) {
      const errorMessage = backendErrorHandler(error);
      showErrorMessage(errorMessage);

      dispatch(getUserPreferencesError(pageName, error));
    } finally {
      dispatch(getUserPreferencesLoading(pageName, false));
    }
  };

export const updateUserPreferencesAction =
  (pageName: UserPreferencePageName, payload: UserPreferenceParams) => async (dispatch: Dispatch) => {
    dispatch(updateUserPreferencesLoading(pageName, true));

    try {
      const response = await updateFiltersPreferencesApi({ ...payload, gridName: pageName });
      dispatch(updateUserPreferencesSuccess(pageName, response.data));
    } catch (error: any) {
      const errorMessage: string = backendErrorHandler(error);
      showErrorMessage(errorMessage);

      dispatch(updateUserPreferencesError(pageName, error));
    } finally {
      dispatch(updateUserPreferencesLoading(pageName, false));
    }
  };
