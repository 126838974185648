import React from 'react';
import { Skeleton, TableRow, TableCell, TableBody } from '@mui/material';
import { tableColumnStyles } from '../../globalUtils/utils';

interface Props {
  numberOfRows: number;
  numberOfColumns: number;
}

export const ReferralsRowsSkeleton: React.FC<Props> = (props) => {
  const { numberOfRows, numberOfColumns } = props;

  return (
    <>
      {Array(numberOfRows)
        .fill(3)
        .map((row, i) => {
          return (
            <TableRow key={`row-${i}`} hover>
              {Array(numberOfColumns)
                .fill(4)
                .map((col, index) => {
                  return (
                    <TableCell key={`$col-${index}`} style={{ ...tableColumnStyles }}>
                      <Skeleton />
                    </TableCell>
                  );
                })}
            </TableRow>
          );
        })}
    </>
  );
};

const ReferralsSkeletonLoader: React.FC<Props> = (props) => {
  return (
    <TableBody style={{ width: '100%' }}>
      <ReferralsRowsSkeleton {...props} />
    </TableBody>
  );
};

export default ReferralsSkeletonLoader;
