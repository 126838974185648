import React, { ChangeEvent, FC, ReactNode, useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  InputBase,
  Menu,
  MenuItem,
  Typography,
  CircularProgress,
  Divider,
  IconButton,
  ButtonProps,
  Tooltip,
} from '@mui/material';
import { useStyles, inputBaseStyles, searchStyles } from './selectmenuStyles';
import { styled, useTheme } from '@mui/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import { alpha } from '@mui/material/styles';
import DoneIcon from '@mui/icons-material/Done';
import { InfoOutlined } from '@mui/icons-material';
import { useSearchOnChangeHandler } from '@/globalUtils/hooks';

interface Group {
  label: ReactNode;
  key: string | number;
}
interface Option {
  searchString: string;
  label: string;
  secondaryLabel?: string;
  tooltip?: string;
  disabled?: boolean;
  listItemView?: ReactNode;
  showDoneIcon?: boolean;
  loading?: boolean;
}
export interface SelectMenuProps {
  options: Option[] | any[];
  hideAddnew?: boolean;
  renderAddNewButton?: (searchText: string) => ReactNode;
  hideSearch?: boolean;
  disabled?: boolean;
  showExpandMore?: boolean;
  icon?: ReactNode;
  valueIcon?: ReactNode;
  groupBy?: (item: any) => Group;
  onAddNew?: () => void;
  value: any;
  onChange: (value: any) => void;
  handleSearch?: (value: any) => void;
  labelTransform?: (value: any) => ReactNode;
  label?: string | ReactNode;
  floatSecondaryLabelRight?: boolean;
  searchLoading?: boolean;
  style?: React.CSSProperties;
  headerComponent?: ReactNode;
  labelStyles?: React.CSSProperties;
  loading?: boolean;
  forceClose?: boolean;
  showClearIcon?: boolean;
  labelButtonStyles?: React.CSSProperties;
  expandMoreIcon?: ReactNode;
  hideDefaultStyles?: boolean;
  onClicking?: () => void;
  isErrored?: boolean;
  onClose?: () => void;
  menuButtonProps?: ButtonProps;
  hideNoResults?: boolean;
  noResultsMessage?: string;
  spacing?: number | string;
  initialSearchTerm?: string;
  filterOptionsFunc?: (options: Option[]) => Option[];
}

export const StyledSearch = styled(Box)(searchStyles);

export const StyledInputBase = styled(InputBase)(inputBaseStyles);
const SelectMenu: FC<SelectMenuProps> = (props) => {
  const {
    options,
    icon,
    groupBy,
    onAddNew,
    value,
    onChange,
    labelTransform,
    valueIcon,
    label,
    floatSecondaryLabelRight,
    handleSearch,
    searchLoading,
    showExpandMore,
    disabled,
    hideAddnew,
    renderAddNewButton,
    hideSearch,
    style,
    headerComponent,
    labelStyles,
    loading,
    forceClose,
    showClearIcon,
    labelButtonStyles,
    expandMoreIcon,
    hideDefaultStyles,
    onClicking,
    isErrored,
    onClose,
    menuButtonProps,
    hideNoResults,
    noResultsMessage,
    spacing,
    initialSearchTerm,
    filterOptionsFunc,
  } = props;
  const valueToDisplay = value?.preferredValueLabel || value?.label || '';
  const classes = useStyles();
  const [menuSearchText, setMenuSearchText] = useState<string>(initialSearchTerm || '');
  const [closeMenu, setCloseMenu] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [autoFocusSearchInput, setAutoFocusSearchInput] = useState(true);
  const filteredOptions = filterOptionsFunc
    ? filterOptionsFunc(options)
    : options.filter(({ searchString }) => searchString?.toLowerCase().includes(menuSearchText?.toLowerCase()));
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { onChange: handleSearchInputChange } = useSearchOnChangeHandler((searchedValue: string) => {
    setMenuSearchText(searchedValue);
    handleSearch && handleSearch(searchedValue);
  }, initialSearchTerm);
  const openBtnRef = useRef(null);

  useEffect(() => {
    if (initialSearchTerm) {
      // give time to openBtnRef to be set
      setTimeout(() => {
        setMenuSearchText(initialSearchTerm);
        handleSearch && handleSearch(initialSearchTerm);
        setAnchorEl(openBtnRef.current);
      }, 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialSearchTerm]);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setAutoFocusSearchInput(true);
    onClicking && onClicking();
  };

  const handleClose = () => {
    setAnchorEl(null);
    onClose && onClose();
    // reset search
    setMenuSearchText('');
  };

  const handleMenuItemSelect = (value: any) => {
    onChange(value);
    handleClose();
  };

  const handleMouseOver = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleClear = () => {
    onChange(undefined);
    setCloseMenu(!closeMenu);
  };

  useEffect(() => {
    handleClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closeMenu]);

  const grouped: any = {};
  if (groupBy) {
    filteredOptions.forEach((item) => {
      const { key, label } = groupBy(item);
      const val = grouped[key] || { data: [] };
      grouped[key] = { ...val, label, data: [...val.data, item] };
    });
  }

  const transformedLabel = labelTransform ? (
    labelTransform(value)
  ) : typeof valueToDisplay === 'string' ? (
    <Typography
      variant="subtitle2"
      color={disabled ? 'textSecondary' : 'textPrimary'}
      style={{ textTransform: 'none', fontSize: 16, ...labelButtonStyles }}
    >
      {valueToDisplay}
    </Typography>
  ) : (
    valueToDisplay
  );
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    // close on any change whether true or false
    setAnchorEl(null);
  }, [forceClose]);

  const handleAddNew = () => {
    onAddNew && onAddNew();
    setAutoFocusSearchInput(false);
    setAnchorEl(null);
  };

  const theme: any = useTheme();
  const labelDisplay =
    typeof label === 'string' ? (
      <Typography
        style={{
          textTransform: 'none',
          fontSize: 16,
          ...labelStyles,
          flex: 1,
          color: isErrored ? '#FF0000' : labelStyles?.color,
        }}
        variant="subtitle2"
      >
        {label}
      </Typography>
    ) : (
      label || 'Select or Add New'
    );

  return (
    <Box style={{ width: !hideDefaultStyles ? '100%' : '' }}>
      <Button
        ref={openBtnRef}
        style={{
          width: '100%',
          position: 'inherit',
          textTransform: 'none',
          background: isErrored ? '#FEEBEE' : open ? alpha(theme.palette.common.black, 0.04) : '',
          padding: 0,
          ...menuButtonProps?.style,
        }}
        disableRipple
        onClick={handleClick}
        onMouseOver={handleMouseOver}
        onMouseLeave={handleMouseLeave}
        disabled={disabled}
      >
        <Box
          ref={ref}
          display="flex"
          alignItems="center"
          className={classes.root}
          style={{ height: !hideDefaultStyles ? 48 : '', paddingLeft: 8, ...style }}
        >
          {icon && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              style={{ width: 32, height: 32, marginRight: spacing || 16 }}
            >
              {value?.label && !open ? valueIcon : icon}
            </Box>
          )}
          <Box
            justifyContent="flex-start"
            style={{ textAlign: 'left', fontSize: 16, fontWeight: 500, overflow: 'hidden', textOverflow: 'ellipsis' }}
            flex={1}
          >
            {valueToDisplay ? (
              transformedLabel
            ) : (
              <Box display="flex" style={{ fontSize: 16 }}>
                {labelDisplay}
              </Box>
            )}
          </Box>
          {loading && <CircularProgress size={20} />}
          {!disabled && showClearIcon && value && isHovered && (
            <IconButton onClick={handleClear}>
              <CloseIcon style={{ color: '#6b778c', fontSize: 16 }} />
            </IconButton>
          )}
          {showExpandMore && !disabled && (
            <Box display="flex" alignItems="center" justifyContent="center">
              {expandMoreIcon || <ExpandMoreIcon style={{ color: '#6b778c', fontSize: 26, paddingRight: 10 }} />}
            </Box>
          )}
        </Box>
      </Button>
      {!disabled && (
        <Menu
          classes={{
            paper: classes.menu,
          }}
          className={classes.noScroll}
          onClose={handleClose}
          anchorEl={anchorEl}
          open={disabled ? false : open}
        >
          <Box className={classes.selectHeader}>
            {headerComponent && headerComponent}
            {headerComponent && <Divider style={{ marginBottom: 12 }} />}
            {!hideSearch && (
              <StyledSearch>
                <StyledInputBase
                  defaultValue={initialSearchTerm}
                  placeholder="Search"
                  onChange={(event: ChangeEvent<HTMLInputElement>) => handleSearchInputChange(event)}
                  inputProps={{ 'aria-label': 'search', style: { width: '100%' } }}
                  inputRef={(input) => autoFocusSearchInput && input?.focus()}
                  onKeyDown={(e) => e.stopPropagation()}
                  endAdornment={
                    searchLoading ? (
                      <Box>
                        <CircularProgress size={16} style={{ marginRight: 16 }} />
                      </Box>
                    ) : null
                  }
                />
              </StyledSearch>
            )}
            {menuSearchText && !filteredOptions.length && !searchLoading && !hideNoResults && (
              <Typography style={{ paddingLeft: 12, paddingTop: 13, paddingBottom: 13, color: '#00000099' }}>
                {noResultsMessage || 'No Results Found'}
              </Typography>
            )}
            {!hideAddnew &&
              ((renderAddNewButton && renderAddNewButton(menuSearchText)) || (
                <Box className={classes.addNew}>
                  <Button onClick={handleAddNew}>Add New</Button>
                </Box>
              ))}
          </Box>
          {groupBy &&
            Object.keys(grouped).map((e, i) => {
              return (
                <div key={i}>
                  <Box>{grouped[e].label}</Box>
                  {grouped[e].data.map((option: any, index: number) => {
                    return (
                      <MenuItem
                        onClick={() => handleMenuItemSelect(option)}
                        style={{ minWidth: 300, width: '100%', fontSize: 16, height: 40, marginTop: 8 }}
                        key={index}
                        disabled={option?.disabled}
                      >
                        <Box
                          display="flex"
                          justifyContent={floatSecondaryLabelRight ? 'space-between' : 'flex-start'}
                          alignItems="center"
                          width="100%"
                        >
                          <Typography style={{ flex: 1, fontSize: 16 }} variant="body1">
                            {option.label}
                          </Typography>
                          <Typography
                            variant="body1"
                            style={{ fontSize: 16 }}
                            className={classes.secondaryLabel}
                            color="textSecondary"
                          >
                            {option?.secondaryLabel}
                          </Typography>
                          {option?.showDoneIcon && <DoneIcon />}
                        </Box>
                      </MenuItem>
                    );
                  })}
                </div>
              );
            })}
          {!groupBy &&
            filteredOptions.map((filteredOption, i) => {
              return (
                <Tooltip key={i} title={filteredOption.tooltip || ''} placement="right">
                  <Box>
                    <MenuItem
                      onClick={() => handleMenuItemSelect(filteredOption)}
                      style={{ minWidth: 300, width: '100%', fontSize: 16, height: 40, marginTop: i ? 8 : 0 }}
                      key={i}
                      disabled={filteredOption.disabled}
                    >
                      <Box
                        display="flex"
                        justifyContent={floatSecondaryLabelRight ? 'space-between' : 'flex-start'}
                        alignItems="center"
                        width="100%"
                        flex={1}
                      >
                        {filteredOption.listItemView ? (
                          <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                            <Box display="flex" alignItems="center" style={{ fontSize: 16 }}>
                              {filteredOption.listItemView}
                              {filteredOption.tooltip && (
                                <Box marginLeft="6px" marginTop="6px">
                                  <InfoOutlined fontSize="medium" style={{ transform: 'rotate(180deg)' }} />
                                </Box>
                              )}
                            </Box>
                            <Box display="flex" alignItems="center">
                              {filteredOption?.showDoneIcon && <DoneIcon />}
                              {filteredOption?.loading && <CircularProgress size={16} />}
                            </Box>
                          </Box>
                        ) : (
                          <Box display="flex" justifyContent="space-between" style={{ width: '100%' }}>
                            <Box flex={1}>
                              <Typography
                                style={{
                                  color: filteredOption.disabled ? 'rgba(0,0,0,0.6)' : '',
                                  fontSize: 16,
                                }}
                              >
                                {filteredOption.label}
                              </Typography>
                            </Box>
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                              <Typography
                                style={{ fontSize: 16 }}
                                className={classes.secondaryLabel}
                                color="#00000061"
                                variant="caption"
                              >
                                {filteredOption?.secondaryLabel}
                              </Typography>
                              {filteredOption?.showDoneIcon && <DoneIcon />}
                              {filteredOption?.loading && <CircularProgress size={16} />}
                            </Box>
                          </Box>
                        )}
                      </Box>
                    </MenuItem>
                  </Box>
                </Tooltip>
              );
            })}
        </Menu>
      )}
    </Box>
  );
};

export default SelectMenu;
