import { Box, Typography, IconButton } from '@mui/material';
import { AccountCircleOutlined } from '@mui/icons-material';
import SelectMenu from '../SelectMenu/SelectMenu';
import { useStyles } from './ProviderComponentStyles';
import { FC, useState } from 'react';

import pencil from '../../images/bluePencil.svg';
import orangeProvider from '../../images/orangeProvider.svg';
import providerPlusIconPrimary from '../../images/providerPlusIconPrimary.svg';
import AddProvider from '../AddProvider/AddProvider';
import { useProviderHooks } from '../../globalUtils/hooks';
import { clearProvidersFromSearch } from '../../redux/actions/referralProvidersAction';
import { useDispatch } from 'react-redux';
import { uniqBy } from 'lodash';

interface Props {
  handleChange: (fieldName: string, value: any) => void;
  selectedProviderValue: any;
  onAfterChangeProvider: (data: any) => void;
  changedProvider: any;
  isTemporaryProvider?: boolean;
  forceClose?: boolean;
  existingWeInfuseProvider?: any;
  clinicId?: string;
}
const ProviderComponent: FC<Props> = (props) => {
  const {
    handleChange,
    selectedProviderValue,
    onAfterChangeProvider,
    changedProvider: newProvider,
    isTemporaryProvider,
    forceClose,
    existingWeInfuseProvider,
  } = props;
  const classes = useStyles();

  const changedProvider = {
    ...newProvider,
    // label: newProvider?.firstName + ' ' + newProvider?.lastName,
    label: `${newProvider?.firstName} ${newProvider?.lastName}${
      newProvider?.credentials ? ', ' + newProvider?.credentials : ''
    }`,
    secondaryLabel: `# ${newProvider?.npi || ''} ${
      newProvider?.region?.abbreviation ? ' - ' + newProvider?.region?.abbreviation : ''
    }`,

    searchString: newProvider?.firstName,
    firstName: newProvider?.firstName,
    lastName: newProvider?.lastName,
    npi: newProvider?.npi,
  };

  const { handleSearch, loading, providerOptions, hideNoResults, onClose, searchString } = useProviderHooks(
    props.clinicId,
    false,
  );
  const [openProviderAdd, setOpenProviderAdd] = useState<boolean>(false);
  const [editTempProvider, setEditTempProvider] = useState<boolean>(false);

  const providerInitialValues = {
    firstName: changedProvider?.firstName || '',
    lastName: changedProvider?.lastName || '',
    npi: changedProvider?.npi || '',
    id: changedProvider?.id || '',
  };

  const handleEdit = () => {
    setOpenProviderAdd(true);
    setEditTempProvider(true);
  };

  const handleAddNew = () => {
    setOpenProviderAdd(true);
    setEditTempProvider(false);
  };

  const handleClose = (value: boolean = false) => {
    setEditTempProvider(value);
    setOpenProviderAdd(false);
  };

  const dispatch = useDispatch();
  const handleClearExistingReferrals = () => {
    dispatch(clearProvidersFromSearch());
  };

  const providerNPIToDisplay = existingWeInfuseProvider?.npi || providerInitialValues.npi;

  const isSelectedOption = (option: any, value: any): boolean => option?.id === value?.id;

  return (
    <Box style={{ marginRight: 16, width: 'calc(60% - 16px)' }} className={classes.labelInput} data-id="provider">
      <AddProvider
        onAfterChangeProvider={onAfterChangeProvider}
        open={openProviderAdd}
        onOpenChange={handleClose}
        initialValues={editTempProvider ? providerInitialValues : undefined}
        isEditingTempProvider={editTempProvider}
      />

      <SelectMenu
        value={selectedProviderValue}
        onChange={(value) => handleChange('provider', { ...value, inputId: 'provider' })}
        onClicking={handleClearExistingReferrals}
        onAddNew={handleAddNew}
        handleSearch={handleSearch}
        onClose={onClose}
        hideAddnew={!!existingWeInfuseProvider?.npi}
        showExpandMore
        floatSecondaryLabelRight
        searchLoading={loading}
        forceClose={forceClose}
        valueIcon={<AccountCircleOutlined fontSize="large" style={{ width: 26, height: 26 }} />}
        hideNoResults={hideNoResults}
        disabled={!props.clinicId}
        label={
          providerInitialValues?.firstName ? (
            <Box className={classes.viewTransform}>
              <Typography
                variant="body2"
                style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                color="textPrimary"
              >
                {`${existingWeInfuseProvider?.firstName || providerInitialValues?.firstName} ${
                  existingWeInfuseProvider?.lastName || providerInitialValues?.lastName || ''
                }${existingWeInfuseProvider?.credentials ? `, ${existingWeInfuseProvider?.credentials}` : ''}`}
              </Typography>

              {providerNPIToDisplay && (
                <Typography variant="caption" color="textSecondary">
                  #{providerNPIToDisplay}
                </Typography>
              )}
            </Box>
          ) : undefined
        }
        icon={
          <img alt="" src={isTemporaryProvider ? orangeProvider : providerPlusIconPrimary} height={20} width={20} />
        }
        options={uniqBy(
          changedProvider?.firstName && !searchString ? [changedProvider, ...providerOptions] : providerOptions,
          'id',
        ).map((option) => ({
          ...option,
          disabled: option?.disabled || isSelectedOption(option, selectedProviderValue),
          showDoneIcon: isSelectedOption(option, selectedProviderValue),
        }))}
        labelTransform={(value) => {
          return (
            <Box className={classes.viewTransform}>
              <Typography
                variant="body2"
                style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                color="textPrimary"
              >
                {value?.labelWithoutAvatar || value?.label}
              </Typography>
              <Typography variant="caption" color="textSecondary">
                #{value?.npi}
              </Typography>
            </Box>
          );
        }}
        headerComponent={
          isTemporaryProvider && (
            <Box display="flex" justifyContent="space-between" style={{ paddingTop: 4, paddingBottom: 4 }}>
              <Box display="flex" justifyContent="flex-start">
                <img alt="" src={orangeProvider} height={20} width={20} style={{ paddingRight: 12 }} />
                <Typography variant="body1">{`${changedProvider.firstName} ${changedProvider.lastName}`}</Typography>
              </Box>

              <Box>
                <IconButton style={{ marginRight: 10 }} onClick={handleEdit}>
                  <img alt="" src={pencil} height={20} width={20} />
                </IconButton>
              </Box>
            </Box>
          )
        }
      />
    </Box>
  );
};

export default ProviderComponent;
