import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => {
  return {
    filtersSection: {
      // width: 80,
      flex: 1,
    },
    filtersContainer: {
      // width: 50,
      height: 17,
    },
    filterImage: {},
    counter: {
      paddingRight: 9,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis !important',
    },
    sortContainer: {
      width: 30,
      height: 30,
      flex: 1,
    },
  };
});
