import { Box, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import React, { ReactChild, ReactElement } from 'react';
import CustomAvatar from '../Avatar/Avatar';

export function GenericCommentComponent({
  username,
  avatar,
  children,
  timestamp,
  actionButtons,
  headings,
  ...props
}: {
  username: string;
  avatar?: ReactElement;
  children: ReactChild;
  timestamp: string;
  actionButtons?: ReactElement[];
  headings?: ReactElement[];
}) {
  return (
    <ListItem {...props} alignItems="flex-start" style={{ justifyContent: 'flex-end' }}>
      <ListItemAvatar>{avatar || <CustomAvatar name={username} />}</ListItemAvatar>
      <ListItemText
        style={{ flex: '1 1 auto' }}
        primary={
          <Box display="flex" alignItems="center" style={{ marginTop: 8 }} justifyContent="space-between">
            <Box display="flex" alignItems="center">
              <Typography variant="subtitle2">{username}</Typography>
              <Typography style={{ marginLeft: 16 }} variant="caption" color="textSecondary">
                {timestamp}
              </Typography>
            </Box>
            <Box display="flex" style={{ height: 16 }} flexDirection="row-reverse" alignItems="center">
              {actionButtons || []}
            </Box>
          </Box>
        }
        secondary={
          <>
            <Box display="flex" flexDirection="column">
              {headings || []}
              <Box color="black">
                <Typography variant="body2" style={{ width: '100%', overflowWrap: 'break-word' }}>
                  {children}
                </Typography>
              </Box>
            </Box>
          </>
        }
      />
    </ListItem>
  );
}
