import { AcceptanceStatus, ReferralAcceptanceApi } from '@features/ReferralAcceptance/referral-acceptance-controller';
import axios from 'axios';
import { referralPayload } from '@/redux/actions/referralsActions';

export class HttpReferralAcceptanceApi implements ReferralAcceptanceApi {
  constructor(private readonly baseUrl: string) {}

  async calculate(referralData: referralPayload): Promise<AcceptanceStatus> {
    const result = await axios.post(`${this.baseUrl}/referrals/acceptance`, referralData);
    return (result.data as AcceptanceStatus) || null;
  }
}
