import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => {
  return {
    paper: {
      minWidth: '738px',
      minHeight: '284px',
    },
    commentsTitle: {
      marginTop: 16,
    },
    dialogTitle: {
      height: 64,
    },
  };
});
