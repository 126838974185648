import { Box } from '@mui/system';
import { Divider, TextField } from '@mui/material';
import React from 'react';
import InputMask from 'react-input-mask';
import { useStyles } from '../../../components/TasksList/FaxAcknowledgement.styles';

type TextFieldModel = {
  value: string;
  set: (value: string) => void;
};

export function FaxForm(view: { providerName: TextFieldModel; providerFax: TextFieldModel; notes: TextFieldModel }) {
  const classes = useStyles({});
  return (
    <Box>
      <Box className={classes.boxWrap} display="flex" justifyContent="space-between" alignItems="center">
        <TextField
          autoFocus={!view.providerName.value}
          className={classes.halfWidth}
          id="provider_name"
          label="To"
          variant="filled"
          style={{ marginRight: 24 }}
          value={view.providerName.value}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => view.providerName.set(e.target.value)}
        />
        <InputMask
          autoFocus={!!view.providerName.value && !view.providerFax.value}
          maskPlaceholder=""
          mask="999-999-9999999999999"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => view.providerFax.set(e.target.value)}
          value={view.providerFax.value}
        >
          <TextField className={classes.halfWidth} id="fax_number" label="Fax" variant="filled" />
        </InputMask>
      </Box>
      <Box className={classes.boxWrap}>
        <Divider />
      </Box>
      <TextField
        autoFocus={!!view.providerName.value && !!view.providerFax.value && !view.notes.value}
        label="Notes"
        variant="filled"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => view.notes.set(e.target.value)}
        className={classes.fullWidth}
        multiline
        rows={4}
        value={view.notes.value}
      />
    </Box>
  );
}
