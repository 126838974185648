import { Dispatch } from 'redux';
import { getHomeCity } from '../../api/homeCityApi';
import { GET_HOMECITY, GET_HOMECITY_SUCCESS, GET_HOMECITY_ERROR, CLEAR_HOME_CITIES } from '../constants/referralTypes';

const getHomeCityLoading = (payload: boolean) => ({
  type: GET_HOMECITY,
  payload,
});

const getHomeCitySuccess = (payload: any) => ({
  type: GET_HOMECITY_SUCCESS,
  payload,
});

export const clearHomeCities = () => ({
  type: CLEAR_HOME_CITIES,
});

const getHomeCityError = (payload: any) => ({
  type: GET_HOMECITY_ERROR,
  payload,
});

export const getHomeCityAction = (search: string) => async (dispatch: Dispatch) => {
  try {
    dispatch(getHomeCityLoading(true));
    const response = await getHomeCity({ search });
    dispatch(getHomeCitySuccess(response.data));
    dispatch(getHomeCityLoading(false));
  } catch (e) {
    dispatch(getHomeCityError(e));
    dispatch(getHomeCityLoading(false));
  }
};
