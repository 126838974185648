import { IconButton, IconButtonProps, Tooltip, Typography } from '@mui/material';
import { FC } from 'react';
import { CalendarMonthOutlined } from '@mui/icons-material';
import { useStyles } from './TasklistStyles';
import { getFormattedDaysAgoText, statusDefaults, userFriendlyTimestamp } from '../../globalUtils/utils';

interface Props extends IconButtonProps {
  statusUpdatedAt?: string | Date;
  statusName: string;
  isDisabled?: boolean;
  tooltipTitle?: string;
}

const DaysInStatus: FC<Props> = (props) => {
  const { statusUpdatedAt, statusName, tooltipTitle, isDisabled, ...restProps } = props;

  const statusToShowUserFriendlyTime = [statusDefaults.COMPLETED, statusDefaults.NOTAPPLICABLE];
  const shouldShowUserFriendlyDate = statusToShowUserFriendlyTime?.includes(statusName?.toUpperCase()) || isDisabled;

  const classes = useStyles({});
  return (
    <Tooltip title={tooltipTitle || ''}>
      <IconButton
        className={classes.disableRipple}
        disableRipple
        style={{ position: 'inherit', width: 'max-content' }}
        {...restProps}
      >
        <CalendarMonthOutlined style={{ color: '#9E9E9E', height: 20, width: 20, paddingRight: 4 }} />
        <Typography className={classes.waitText}>
          {shouldShowUserFriendlyDate
            ? userFriendlyTimestamp(statusUpdatedAt, {
                roundOffLessThan2: true,
                hideTime: true,
                persistTimezone: false,
              })
            : getFormattedDaysAgoText(statusUpdatedAt || '', false)}
        </Typography>
      </IconButton>
    </Tooltip>
  );
};

export default DaysInStatus;
