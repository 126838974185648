import { AcceptanceStatusDropdown } from '@features/ReferralAcceptance/view/AcceptanceStatusDropdown';
import { FieldBody, FieldLabel } from '@components/ReferralView/FieldLabel';
import { ListView } from '@components/ReferralView/PatientDetails';
import { ThumbsUpDownOutlined } from '@mui/icons-material';

const isEditable = false;

export function AcceptanceStatus({
  value,
  onChange = () => {},
}: {
  value: string | null;
  onChange?: (value: string) => void;
}) {
  return (
    <>
      <FieldLabel>Acceptance Status</FieldLabel>
      <FieldBody>
        {isEditable ? (
          <AcceptanceStatusDropdown value={value} onChange={onChange} />
        ) : (
          <ListView icon={<ThumbsUpDownOutlined />} primaryText={value || ''} secondaryText={''} />
        )}
      </FieldBody>
    </>
  );
}
