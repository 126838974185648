import { ReferralEditFields } from '../../globalUtils/helpers';
import { SET_INBOX_ACTIVE_ROW } from '../constants/inboxTypes';
import {
  CREATE_REFERRAL,
  CREATE_REFERRAL_SUCCESS,
  CREATE_REFERRAL_ERROR,
  EDIT_REFERRAL_LOADING,
  EDIT_REFERRAL_SUCCESS,
  EDIT_REFERRAL_ERROR,
  MARK_ATTACHMENT_AS_SEEN_ON_REFERRAL,
  MARK_ATTACHMENT_AS_SEEN_ON_REFERRAL_SUCCESS,
  MARK_ATTACHMENT_AS_SEEN_ON_REFERRAL_ERROR,
  ADD_ATTACHMENTS,
  ADD_ATTACHMENTS_SUCCESS,
  ADD_ATTACHMENTS_ERROR,
  EDIT_ATTACHMENT_LOADING,
  EDIT_ATTACHMENT_SUCCESS,
  EDIT_ATTACHMENT_ERROR,
  CREATE_DRAFT_REFERRAL,
  CREATE_DRAFT_REFERRAL_ERROR,
  CREATE_DRAFT_REFERRAL_SUCCESS,
  GET_REFERRAL_CATEGORIES,
  GET_REFERRAL_CATEGORIES_ERROR,
  GET_REFERRAL_CATEGORIES_SUCCESS,
  GET_REFERRAL_STATUSES_LOADING,
  GET_REFERRAL_STATUSES_ERROR,
  GET_REFERRAL_STATUSES_SUCCESS,
  CLEAR_ATTACHMENTS_STATE,
  DELETE_REFERRAL_SUCCESS,
  DELETE_REFERRAL_LOADING,
  DELETE_REFERRAL_ERROR,
  GET_REFERRALS_COUNT,
  SET_ACTIVE_REFERRALS_GLOBAL_LOADING,
  SET_ARCHIVED_REFERRALS_GLOBAL_LOADING,
  CLEAR_REFERRAL_FILTERS,
  SET_ALL_REFERRALS_GLOBAL_LOADING,
  UPDATE_ATTACHMENT_FAX,
  GET_INSURANCES,
} from '../constants/referralTypes';

export const createReferralLoading = (payload: boolean) => ({
  type: CREATE_REFERRAL,
  payload,
});

export const createReferralSuccess = (payload: any) => ({
  type: CREATE_REFERRAL_SUCCESS,
  payload,
});

export const createReferralError = (payload: any) => ({
  type: CREATE_REFERRAL_ERROR,
  payload,
});

export const editReferralLoading = (payload: {
  isLoading: boolean;
  field: ReferralEditFields;
  referralId: string;
}) => ({
  type: EDIT_REFERRAL_LOADING,
  payload,
});

export const editReferralSuccess = (payload: any) => ({
  type: EDIT_REFERRAL_SUCCESS,
  payload,
});

export const editReferralError = (payload: any) => ({
  type: EDIT_REFERRAL_ERROR,
  payload,
});

export const markAttachmentAsSeenOnReferralLoading = () => ({
  type: MARK_ATTACHMENT_AS_SEEN_ON_REFERRAL,
});

export const markAttachmentAsSeenOnReferralSuccess = (payload: any) => ({
  type: MARK_ATTACHMENT_AS_SEEN_ON_REFERRAL_SUCCESS,
  payload,
});

export const markAttachmentAsSeenOnReferralError = (payload: any) => ({
  type: MARK_ATTACHMENT_AS_SEEN_ON_REFERRAL_ERROR,
  payload,
});

export const addAttachmentLoading = (payload: {
  loading: boolean;
  fileName: string;
  percentComplete?: number;
  outgoing?: boolean;
}) => ({
  type: ADD_ATTACHMENTS,
  payload,
});

export const addAttachmentSuccess = (payload: any) => ({
  type: ADD_ATTACHMENTS_SUCCESS,
  payload,
});

export const updateAttachmentFax = (payload: any) => ({
  type: UPDATE_ATTACHMENT_FAX,
  payload,
});

export const clearAttachmentState = () => ({
  type: CLEAR_ATTACHMENTS_STATE,
});

export const addAttachmentError = (payload: any) => ({
  type: ADD_ATTACHMENTS_ERROR,
  payload,
});

export const editAttachmentLoading = (isLoading: boolean) => ({
  type: EDIT_ATTACHMENT_LOADING,
  payload: isLoading,
});

export const editAttachmentSuccess = (payload: any) => ({
  type: EDIT_ATTACHMENT_SUCCESS,
  payload,
});

export const editAttachmentError = (payload: any) => ({
  type: EDIT_ATTACHMENT_ERROR,
  payload,
});

export const createDraftReferralLoading = (payload: boolean) => ({
  type: CREATE_DRAFT_REFERRAL,
  payload,
});

export const createDraftReferralSuccess = (payload: any) => ({
  type: CREATE_DRAFT_REFERRAL_SUCCESS,
  payload,
});

export const createDraftReferralError = (payload: any) => ({
  type: CREATE_DRAFT_REFERRAL_ERROR,
  payload,
});

export const getReferralCategories = () => ({
  type: GET_REFERRAL_CATEGORIES,
});

export const getInsurances = () => ({
  type: GET_INSURANCES,
});

export const getReferralCategoriesSuccess = (payload: any) => ({
  type: GET_REFERRAL_CATEGORIES_SUCCESS,
  payload,
});

export const getReferralCategoriesError = (payload: any) => ({
  type: GET_REFERRAL_CATEGORIES_ERROR,
  payload,
});

export const getReferralStatusesLoading = (isLoading: boolean) => ({
  type: GET_REFERRAL_STATUSES_LOADING,
  payload: isLoading,
});

export const getReferralStatusesSuccess = (payload: any) => ({
  type: GET_REFERRAL_STATUSES_SUCCESS,
  payload,
});

export const getReferralStatusesError = (payload: any) => ({
  type: GET_REFERRAL_STATUSES_ERROR,
  payload,
});

export const updateOpenRow = (payload: any) => ({ type: SET_INBOX_ACTIVE_ROW, payload });

export const deleteReferralSuccess = (payload: any) => ({
  type: DELETE_REFERRAL_SUCCESS,
  payload,
});

export const deleteReferralLoading = (payload: boolean) => ({
  type: DELETE_REFERRAL_LOADING,
  payload,
});

export const deleteReferralError = (payload: any) => ({
  type: DELETE_REFERRAL_ERROR,
  payload,
});

export const getReferralsCount = (payload: {
  activeTotalCount: number;
  archivedTotalCount: number;
  urgentTotalCount: number;
}) => ({
  type: GET_REFERRALS_COUNT,
  payload,
});

export const setActiveReferralsGlobalLoading = (isLoading: boolean) => ({
  type: SET_ACTIVE_REFERRALS_GLOBAL_LOADING,
  payload: isLoading,
});

export const setArchivedReferralsGlobalLoading = (isLoading: boolean) => ({
  type: SET_ARCHIVED_REFERRALS_GLOBAL_LOADING,
  payload: isLoading,
});

export const setAllReferralsGlobalLoading = (isLoading: boolean) => ({
  type: SET_ALL_REFERRALS_GLOBAL_LOADING,
  payload: isLoading,
});

export const clearReferralFilters = () => ({
  type: CLEAR_REFERRAL_FILTERS,
});
