import { Dispatch } from 'redux';
import { sendFaxAckApi, getFaxAckPreviewApi, getFaxTypesApi, reSendFaxAckApi } from '../../api/faxApi';
import { showErrorMessage } from '../../components/NotificationToast/NotificationToast';
import backendErrorHandler from '../../globalUtils/backendErrorHandler';
import {
  SEND_FAX_ACK,
  SEND_FAX_ACK_SUCCESS,
  SEND_FAX_ACK_ERROR,
  RESEND_FAX_ACKNOWLEDGEMENT_LOADING,
  RESEND_FAX_ACK_SUCCESS,
  RESEND_FAX_ACK_ERROR,
  GET_FAX_ACK_PREVIEW,
  GET_FAX_ACK_PREVIEW_SUCCESS,
  GET_FAX_ACK_PREVIEW_ERROR,
  FAX_ACK_SUCCESS,
  FETCH_FAX_TYPES,
  FETCH_FAX_TYPES_SUCCESS,
  FETCH_FAX_TYPES_ERROR,
} from '../constants/faxTypes';

const sendFaxAckLoading = () => ({
  type: SEND_FAX_ACK,
});

export const sendFaxAckSuccess = (payload: any) => ({
  type: SEND_FAX_ACK_SUCCESS,
  payload,
});

const sendFaxAckError = (payload: any) => ({
  type: SEND_FAX_ACK_ERROR,
  payload,
});

export const reSendFaxAckLoading = () => ({
  type: RESEND_FAX_ACKNOWLEDGEMENT_LOADING,
});

export const reSendFaxAckSuccess = (payload: any) => ({
  type: RESEND_FAX_ACK_SUCCESS,
  payload,
});

export const faxAckSuccess = (payload: any) => ({
  type: FAX_ACK_SUCCESS,
  payload,
});

export const reSendFaxAckError = (payload: any) => ({
  type: RESEND_FAX_ACK_ERROR,
  payload,
});

const getFaxAckPreviewLoading = () => ({
  type: GET_FAX_ACK_PREVIEW,
});

const getFaxAckPreviewSuccess = (payload: any) => ({
  type: GET_FAX_ACK_PREVIEW_SUCCESS,
  payload,
});

const getFaxAckPreviewError = (payload: any) => ({
  type: GET_FAX_ACK_PREVIEW_ERROR,
  payload,
});

const fetchFaxTypes = () => ({
  type: FETCH_FAX_TYPES,
});

const fetchFaxTypesSuccess = (payload: any) => ({
  payload,
  type: FETCH_FAX_TYPES_SUCCESS,
});

const fetchFaxTypesFailure = (payload: any) => ({
  payload,
  type: FETCH_FAX_TYPES_ERROR,
});

export interface sendFaxApiParams {
  referralId?: string;
  provider_name?: string;
  fax_number?: string;
  patient_name?: string;
  date_received?: string;
  patient_dob?: string;
  requested_docs?: string;
  intake_spec_name?: string;
  phone_number?: string;
  medication?: string;
}

export interface reSendFaxApiParams {
  referralId?: string;
  provider_name?: string;
  fax_number?: string;
  patient_name?: string;
  current_date?: string;
  requested_docs?: string;
  faxId?: string;
}

export const getFaxAckPreviewAction =
  (data: sendFaxApiParams, onSuccess?: (res: any) => void, onError?: () => void) => async (dispatch: Dispatch) => {
    try {
      dispatch(getFaxAckPreviewLoading());
      const response = await getFaxAckPreviewApi(data);
      if (onSuccess) {
        onSuccess(response.data);
      }
      dispatch(getFaxAckPreviewSuccess(response.data));
    } catch (e) {
      const errorMessage: string = backendErrorHandler(e);
      if (onError) {
        onError();
      }
      showErrorMessage(errorMessage);
      dispatch(getFaxAckPreviewError(e));
    }
  };

export const sendFaxAckAction =
  (data: sendFaxApiParams, onSuccess?: (res: any) => void, onError?: () => void) => async (dispatch: Dispatch) => {
    try {
      dispatch(sendFaxAckLoading());
      const response = await sendFaxAckApi(data);
      dispatch(sendFaxAckSuccess(response.data));

      if (onSuccess) {
        onSuccess(response.data);
      }
    } catch (e) {
      if (onError) {
        onError();
      }
      const errorMessage: string = backendErrorHandler(e);
      showErrorMessage(errorMessage);
      dispatch(sendFaxAckError(e));
    }
  };

export const reSendFaxAckAction =
  (data: reSendFaxApiParams, onSuccess?: (res: any) => void, onError?: () => void) => async (dispatch: Dispatch) => {
    try {
      dispatch(reSendFaxAckLoading());
      const response = await reSendFaxAckApi(data);

      dispatch(reSendFaxAckSuccess(response.data));

      if (onSuccess) {
        onSuccess(response.data);
      }
    } catch (e) {
      if (onError) {
        onError();
      }
      const errorMessage: string = backendErrorHandler(e);
      showErrorMessage(errorMessage);
      dispatch(reSendFaxAckError(e));
    }
  };

export const fetchFaxTypesAction = () => async (dispatch: Dispatch) => {
  try {
    dispatch(fetchFaxTypes());
    const response = await getFaxTypesApi();
    dispatch(fetchFaxTypesSuccess(response.data));
  } catch (error) {
    dispatch(fetchFaxTypesFailure(error));
  }
};
