import axios, { AxiosResponse } from 'axios';
import { reSendFaxApiParams, sendFaxApiParams } from '../redux/actions/faxActions';

const baseUrl = `${process.env.REACT_APP_API_URL}`;

export const sendFaxAckApi = async (data: sendFaxApiParams): Promise<AxiosResponse<any, any>> => {
  return await axios.post(`${baseUrl}/faxes/acknowledgement/send`, data);
};

export const reSendFaxAckApi = async (data: reSendFaxApiParams): Promise<AxiosResponse<any, any>> => {
  return await axios.post(`${baseUrl}/faxes/acknowledgement/resend`, data);
};

export const resendFaxApi = async (faxId: string): Promise<AxiosResponse<any, any>> => {
  return await axios.post(`${baseUrl}/faxes/resend/${faxId}`);
};

export const getFaxAckPreviewApi = async (data: sendFaxApiParams): Promise<AxiosResponse<any, any>> => {
  return axios({
    url: `${baseUrl}/faxes/acknowledgement/create`,
    method: 'POST',
    responseType: 'blob',
    data,
  });
};

export const getFaxTypesApi = async (): Promise<AxiosResponse<any, any>> => {
  return await axios.get(`${baseUrl}/fax-types`);
};
