import { combineReducers } from 'redux';
import { loginReducer } from './authReducer';
import inboxReducer from './inboxReducer';
import regionsReducer from './regionsReducer';
import usersReducer from './usersReducer';
import commentsReducer from './commentsReducer';
import clinicsReducer from './clinicReducer';
import patientsReducer from './patientReducer';
import referralsReducer from './referralsReducer';
import medicationsReducer from './medicationsReducer';
import drugTiersReducer from './drugTiersReducer';
import taskTemplates from './taskTemplatesReducer';
import activeDrawer from './activeDrawerReducer';
import attachments from './attachmentsReducer';
import referralProviders from './referralProvidersReducer';
import tasksReducer from './tasksReducer';
import notifications from './notificationsReducer';
import faxStore from './faxReducer';
import statusReasons from './statusReasonsReducer';
import userPreferences from './userPreferencesReducer';
import weInfuse from './weInfuseReducer';
import insurances from './insurancesReducer';
import homeCities from './homeCityReducer';

export default combineReducers({
  auth: loginReducer,
  inbox: inboxReducer,
  regions: regionsReducer,
  users: usersReducer,
  comments: commentsReducer,
  clinics: clinicsReducer,
  patients: patientsReducer,
  referrals: referralsReducer,
  medications: medicationsReducer,
  drugTiers: drugTiersReducer,
  taskTemplates,
  activeDrawer,
  attachments,
  referralProviders,
  tasks: tasksReducer,
  notifications,
  faxStore,
  statusReasons,
  userPreferences,
  weInfuse,
  insurances,
  homeCities,
});
