import { Box, CircularProgress, Divider, Menu, MenuItem, MenuProps, PopoverProps, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import DoDisturbSharpIcon from '@mui/icons-material/DoDisturbSharp';
import RestoreIcon from '@mui/icons-material/Restore';
import { useStyles } from './MoreMenuItemsStyles';
import greenCheck from '../../images/greenCheck.svg';

interface Props extends Partial<MenuProps> {
  anchorEl: PopoverProps['anchorEl'];
  open: boolean;
  referral?: any;
  onClose?: () => void;
  discardable?: boolean;
  restorable?: boolean;
  restoreLoading?: boolean;
  onDiscard?: () => void;
  onRestore?: () => void;
}

const MoreMenuItems: React.FC<Props> = (props) => {
  const { referral, onClose, discardable, onDiscard, restorable, onRestore, restoreLoading, ...restProps } = props;
  const classes = useStyles();

  const [showSuccess, setShowSuccess] = useState(false);

  const onCopyLink = () => {
    navigator.clipboard
      .writeText(window.location.href)
      .then(() => {
        setShowSuccess(true);
        setTimeout(() => {
          setShowSuccess(false);
          onClose && onClose();
        }, 2000);
      })
      .catch(() => {
        console.log('Error occurred copying link');
      });
  };

  return (
    <Menu onClose={onClose} {...restProps}>
      <MenuItem onClick={onCopyLink} style={{ minWidth: 280 }}>
        <ShareOutlinedIcon style={{ fontSize: 20 }} className={classes.icon} />
        <Tooltip
          open={showSuccess}
          arrow
          placement="top"
          title={
            <span className={classes.successTooltip}>
              <img alt="" src={greenCheck} style={{ paddingRight: 6 }} />
              Link copied to clipboard!
            </span>
          }
          classes={{ popper: classes.popper, tooltipPlacementBottom: classes.popper }}
        >
          <span>Copy Link</span>
        </Tooltip>
      </MenuItem>
      {discardable && (
        <Box>
          <Divider />
          <MenuItem onClick={onDiscard}>
            <DoDisturbSharpIcon style={{ fontSize: 20 }} className={classes.icon} /> Discard
          </MenuItem>
        </Box>
      )}
      {restorable && (
        <Box>
          <Divider />
          <MenuItem disabled={restoreLoading} onClick={onRestore}>
            <RestoreIcon style={{ fontSize: 20 }} className={classes.icon} /> Restore Referral
            {restoreLoading && <CircularProgress size={20} />}
          </MenuItem>
        </Box>
      )}
    </Menu>
  );
};

export default MoreMenuItems;
