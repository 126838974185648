import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => {
  return {
    colorPrimary: {
      '&.MuiChip-root': {
        backgroundColor: '#E3F2FD',
        color: theme.palette.primary.main,
      },
    },
    colorSecondary: {
      '&.MuiChip-root': {
        backgroundColor: '#F1F8E9',
        color: '#33691E',
      },
    },
    chip: {
      paddingLeft: '15px !important',
      paddingRight: '15px !important',
      minHeight: '32px !important',
    },
  };
});
