import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import alertIcon from '../../images/infoIcon.svg';
import clinicPrimary from '../../images/clinicPrimary.svg';
import { ClinicType, Medication, Referral, RootState } from '../../redux/types';
import { CommentActions, CommentsActionProps } from '../CommentsViewComponent/CommentsViewComponent';
import EditClinic from '../EditClinic/EditClinic';
import EditMedication from '../EditMedicationsComponent/EditMedicationsComponent';
import MentionComponent from '../MentionComponent/MentionComponent';
import { useStyles } from './setToScheduledStyles';
import { updateReferralStatusesAction } from '../../redux/actions/referralsActions';
import { CommentTypes } from '../../globalUtils/constants';

interface Props {
  isScheduledTaskModalOpen: boolean;
  setIsScheduledTaskModalOpen: (isOpen: boolean) => void;
  referral: Referral;
  loading?: boolean;
  commentsActionProps?: CommentsActionProps;
  scheduledStatusId?: string;
}

interface valuesToEdit {
  clinic?: ClinicType;
  medication?: Medication;
}
const SetToScheduled = (props: Props) => {
  const {
    isScheduledTaskModalOpen,
    setIsScheduledTaskModalOpen,
    commentsActionProps,
    referral,
    scheduledStatusId,
    loading,
  } = props;

  const classes = useStyles({});
  const dispatch = useDispatch();

  const [medicationInputValue, setMedicationInputValue] = useState<any>('');
  const [commentMessage, setCommentMessage] = useState('');
  const [mentions, setMentions] = useState<any[]>([]);
  const [contactedChosen, setContactedChosen] = useState<boolean>(false);
  const [followedUpChosen, setFollowUpChosen] = useState<boolean>(false);
  const [publishCommentToEMRChosen, setPublishCommentToEMRChosen] = useState<boolean>(true);

  const [valuesToEdit, setValuesToEdit] = useState<valuesToEdit>({
    medication: { id: referral?.medication?.id },
  });
  const medications = useSelector((state: RootState) => state.medications.data) as any;

  const medicationTier: any = medications.find(({ id }: any) => id === referral?.medication?.id)?.drugTier;
  const initialMedication = !isEmpty(referral?.medication)
    ? {
        ...referral?.medication,
        name: referral?.medication?.name,
        drugTier: medicationTier,
        label: referral?.medication?.name,
      }
    : undefined;

  useEffect(() => {
    setMedicationInputValue({
      name: referral?.medication?.name || '',
      drugTier: medicationTier || null,
      label: referral?.medication?.name || '',
    });
  }, [medicationTier, referral?.medication]);

  useEffect(() => {
    if (referral?.medication) {
      setValuesToEdit((valuesToEdit) => ({
        ...valuesToEdit,
        clinic: undefined,
        medication: referral?.medication,
      }));
    }
  }, [referral?.medication]);

  const handleChange = (fieldName: string, value: any) => {
    setValuesToEdit((values: any) => ({ ...values, [fieldName]: value }));
  };

  const onChangeComments = (comments: string, mentions: any[]) => {
    setCommentMessage(comments);
    setMentions(mentions);
  };

  const handleCancel = () => {
    setIsScheduledTaskModalOpen(false);
    onChangeComments('', []);
    setContactedChosen(false);
    setFollowUpChosen(false);
    setPublishCommentToEMRChosen(true);
    setMentions([]);
  };

  const handleSetToScheduled = () => {
    const onSuccess = () => {
      setIsScheduledTaskModalOpen(false);
      handleCancel();
    };

    const params = {
      statusId: scheduledStatusId,
      patientContactTag: contactedChosen,
      referralFollowupTag: followedUpChosen,
      tagUserIds: mentions.map(({ id }) => id),
      message: commentMessage ? commentMessage : '',
      clinicId: valuesToEdit?.clinic?.id,
      medicationId: valuesToEdit?.medication?.id,
      commentType: commentMessage
        ? publishCommentToEMRChosen
          ? CommentTypes.EXTERNAL
          : CommentTypes.INTERNAL
        : undefined,
    };

    dispatch(updateReferralStatusesAction(referral.id, params, onSuccess));
  };

  return (
    <Dialog open={isScheduledTaskModalOpen}>
      <DialogTitle>Set to Scheduled</DialogTitle>
      <DialogContent>
        <Alert className={classes.alert} icon={<img src={alertIcon} alt="alert" height={20} width={20} />}>
          <AlertTitle>Confirm the following information</AlertTitle>
          Please select clinic and confirm medication for the patient before updating status and add comment if
          necessary.
        </Alert>
        <Box className={classes.selectMenu}>
          <EditClinic
            homeCityToFilterBy={referral.homeCity}
            regionToFilterBy={referral?.region}
            onChange={(value) => handleChange('clinic', value)}
            icon={<img alt="" src={clinicPrimary} />}
            valueIcon={<img alt="" src={clinicPrimary} />}
            labelFor={'Clinic*'}
            showExpandMore
            showClearIcon={false}
            style={{ height: 55 }}
          />
        </Box>
        <Box className={classes.selectMenu}>
          <EditMedication
            initialValue={medicationInputValue || initialMedication}
            onChange={(value) => handleChange('medication', value)}
            status={referral?.status}
            labelFor={'Medication*'}
            style={{ height: 55 }}
          />
        </Box>

        <>
          <Box style={{ marginTop: 32 }}>
            <Typography variant="subtitle2">Comment (Optional)</Typography>
          </Box>
          <Box className={classes.commentBox}>
            <CommentActions
              contactedChosen={contactedChosen}
              followedUpChosen={followedUpChosen}
              onContactedButtonClick={() => {
                setContactedChosen(!contactedChosen);
              }}
              onFollowUpButtonClick={() => {
                setFollowUpChosen(!followedUpChosen);
              }}
              isTopLevel
              {...commentsActionProps}
            />
            <MentionComponent
              loading={false}
              hideSend={true}
              onSend={() => null}
              id={referral?.id}
              onChange={onChangeComments}
              placeholder={'Type comment..'}
              startValue={``}
              isExpanded
              disablePublishToEMR={loading}
              publishToEMRSelected={publishCommentToEMRChosen}
              onSelectPublishToEMR={() => {
                setPublishCommentToEMRChosen(!publishCommentToEMRChosen);
              }}
            />
          </Box>
        </>
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={handleCancel}>
          Cancel
        </Button>
        <Button onClick={handleSetToScheduled} disabled={!canSubmitForm()} variant="contained">
          Set To scheduled
          {loading && <CircularProgress size={20} />}
        </Button>
      </DialogActions>
    </Dialog>
  );

  function canSubmitForm() {
    return Boolean(!loading && valuesToEdit.clinic?.id);
  }
};

export default SetToScheduled;
