import { useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { TextMessage, TextMessageDeliveryStatusCategory } from '../text-message';
import prettyMs from '../../../globalUtils/prettyMs';
import { events } from '../index';
import { DashboardPatientInfo } from '@features/Omnichannel/dashboard-patient-info';
import _ from 'lodash';
import { useDebounce } from 'react-use';
import { MessageServer } from '@features/Omnichannel/message-server/message-server';
import { PhoneNumber } from 'libphonenumber-js';
import { E164Number } from 'libphonenumber-js/types';

const moment = require('moment').default || require('moment');

export function ChatController(messageServer: MessageServer) {
  return function useChatController(
    phoneNumber: PhoneNumber,
    patient?: DashboardPatientInfo,
    onChatUpdated?: () => void,
  ) {
    const tempMessages = useRef<TextMessage[]>([]);
    const [state, setState] = useState({
      messages: [] as TextMessage[],
      messagesLoading: true,
    });

    const [, forceUpdate] = useReducer((x) => x + 1, 0);

    const addTempMessages = useCallback(
      (
        phoneNumber: E164Number,
        actualMessages: Readonly<TextMessage[]>,
        tempMessages: Readonly<TextMessage[]>,
      ): TextMessage[] => {
        const addTempMessage = (
          messages: Readonly<TextMessage[]>,
          tempMessage: Readonly<TextMessage>,
        ): TextMessage[] => {
          const existingActualMessage = messages.find((m) => m.id === tempMessage.id);
          return existingActualMessage ? [...messages] : [...messages, tempMessage];
        };

        let result = actualMessages;

        tempMessages
          .filter((m) => (m.direction === 'outbound' ? m.to === phoneNumber : m.from === phoneNumber))
          .forEach((tempMessage) => {
            result = addTempMessage(result, tempMessage);
          });

        return _(result)
          .sortBy((m) => m.dateSent)
          .value();
      },
      [],
    );

    const fetchMessages = useCallback(
      async (showLoading?: boolean) => {
        let isMounted = true;

        setState((state) => ({
          ...state,
          messagesLoading: showLoading ?? true,
        }));

        messageServer
          .getHistoryByPhone(phoneNumber.number)
          .then((messages) => messages.sort((a, b) => a.dateSent.getTime() - b.dateSent.getTime()))
          .then((actualMessages) => {
            const messages = addTempMessages(phoneNumber.number, actualMessages, tempMessages.current);
            if (isMounted) {
              setState((state) => ({
                ...state,
                messages: messages,
                messagesLoading: false,
              }));
              onChatUpdated && onChatUpdated();
            }
          });

        return () => {
          isMounted = false;
        };
      },
      [phoneNumber.number, addTempMessages, onChatUpdated],
    );

    useEffect(() => {
      (async () => {
        await fetchMessages();
      })();
    }, [fetchMessages]);

    events.useNewMessageSubmittedFromChatListener((e) => {
      addTempMessage(e.message);
      fetchMessages(false).then(() => {
        forceUpdate();
      });
    });

    // Purge temp messages every 5 minutes
    const purgeFrequency = 5 * 60 * 1000;
    const [,] = useDebounce(
      () => {
        tempMessages.current = tempMessages.current.filter(
          (m) => m.dateSent > new Date(new Date().getTime() - purgeFrequency),
        );
      },
      purgeFrequency,
      [],
    );

    // refresh chat box messages every x milliseconds
    const refreshFrequencyMs = undefined; // disabled
    const throttledFetchMessages = useMemo(
      () =>
        _.throttle(() => fetchMessages(false), refreshFrequencyMs, {
          leading: true,
          trailing: false,
        }),
      [fetchMessages, refreshFrequencyMs],
    );

    useEffect(() => {
      if (refreshFrequencyMs) {
        const intervalId = setInterval(() => {
          throttledFetchMessages();
        }, refreshFrequencyMs);

        return () => {
          clearInterval(intervalId);
          throttledFetchMessages.cancel();
        };
      }
    }, [throttledFetchMessages, refreshFrequencyMs]);

    const now = Date.now();

    return {
      usable: !!phoneNumber && !state.messagesLoading,
      warningMessage:
        patient && !patientHasPhone()
          ? `No phone number registered for ${patient.first_name} ${patient.last_name}`
          : '',
      phoneNumber,
      patientId: patient?.id,
      messageView: {
        loading: state.messagesLoading,
        messages: state.messages.map((message, i) => {
          const nextMessage = i < state.messages.length - 1 ? state.messages[i + 1] : null;
          return {
            ...message,
            author:
              message.direction === 'inbound'
                ? patient
                  ? message.author
                  : 'Unknown'
                : message.authorId
                ? message.author
                : 'FlexCare',
            body: message.body || '',
            dateSentFormatted: moment(message.dateSent).format('M/D h:mm A'),
            age: prettyMs(now - message.dateSent.getTime()),
            showAvatar: !nextMessage || message.author !== nextMessage?.author,
            status: {
              category: message.status.category,
              text: getStatusText(message.status.category),
              color: getStatusColor(message.status.category),
              tooltip: message.status.message,
            },
          };
        }),
      },
    };

    function addTempMessage(message: TextMessage) {
      const tempMessage = {
        ...message,
        authorId: 'something', // necessary to indicate author is a user
        status: {
          category: TextMessageDeliveryStatusCategory.INFO,
          message: 'Queued',
        },
      };
      setState((state) => ({
        ...state,
        messages: [...state.messages, tempMessage],
      }));

      tempMessages.current.push(tempMessage);
    }

    function getStatusColor(status: TextMessageDeliveryStatusCategory) {
      return {
        [TextMessageDeliveryStatusCategory.INFO]: 'default',
        [TextMessageDeliveryStatusCategory.ERROR]: 'error',
        [TextMessageDeliveryStatusCategory.SUCCESS]: 'success',
      }[status];
    }

    function getStatusText(status: TextMessageDeliveryStatusCategory) {
      return {
        [TextMessageDeliveryStatusCategory.INFO]: 'Queued',
        [TextMessageDeliveryStatusCategory.ERROR]: 'Failed',
        [TextMessageDeliveryStatusCategory.SUCCESS]: 'Sent',
      }[status];
    }

    function patientHasPhone() {
      return Boolean(patient?.mobile_phone || patient?.home_phone);
    }
  };
}
