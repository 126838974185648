import { Box, Typography, Button } from '@mui/material';
import { ReactElement } from 'react';

interface Props {
  infoText: ReactElement;
  onActionButtonClick?: () => void;
}
const MoreInfoTooltip = (props: Props) => {
  const { infoText, onActionButtonClick } = props;
  return (
    <Box style={{ background: 'black' }}>
      <Typography>{infoText}</Typography>
      {onActionButtonClick && <Button onClick={onActionButtonClick}>Learn More</Button>}
    </Box>
  );
};

export default MoreInfoTooltip;
