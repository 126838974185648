export class ProviderCommunicationPresenter {
  constructor(
    private readonly activeReferral: ProviderCommunicationReferral,
    private readonly view: ProviderCommunicationView,
    private readonly storage: ProviderCommunicationStorage,
    private readonly api: ProviderCommunicationApi,
  ) {}

  async showSendDialog() {
    const state: ProviderCommunicationState = {
      form: {
        ...this.formatProviderData(),
        notes: '',
      },
      previewLoading: false,
      sending: false,
    };
    await this.update(() => state);
  }

  private async update(func: (state: ProviderCommunicationState) => ProviderCommunicationState) {
    const state = func(await handleEmptyState(() => this.storage.get()));
    await this.storage.set(state);
    const formComplete = Boolean(state.form.providerFaxNumber && state.form.providerName && state.form.notes);
    this.view.update({
      ...state.form,
      providerFaxNumber: state.form.providerFaxNumber,
      previewButtonEnabled: formComplete && !state.previewLoading && !state.sending,
      sendButtonEnabled: formComplete && !state.previewLoading && !state.sending,
      previewActive: Boolean(state.preview),
      previewFile: state.preview,
      previewLoading: state.previewLoading,
      sendInProgress: state.sending,
    });

    async function handleEmptyState(getState: () => Promise<ProviderCommunicationState>) {
      try {
        return await getState();
      } catch (e) {
        return {
          form: {
            providerName: '',
            providerFaxNumber: '',
            notes: '',
          },
          previewLoading: false,
          sending: false,
        };
      }
    }
  }

  private formatProviderData(): ProviderCommunicationProvider {
    const provider = this.activeReferral.provider;
    if (!provider) {
      return {
        providerName: '',
        providerFaxNumber: '',
      };
    }
    return {
      providerName: `${provider.firstName} ${provider.lastName}${
        provider.credentials ? `, ${provider.credentials}` : ''
      }`,
      providerFaxNumber: provider.officeFaxNumber || '',
    };
  }

  async setProviderName(name: string) {
    await this.update((state) => ({
      ...state,
      form: {
        ...state.form,
        providerName: name,
      },
    }));
  }

  async setNotes(notes: string) {
    await this.update((state) => ({
      ...state,
      form: {
        ...state.form,
        notes: notes.substring(0, 1500),
      },
    }));
  }

  async setProviderFaxNumber(number: string) {
    await this.update((state) => ({
      ...state,
      form: {
        ...state.form,
        providerFaxNumber: number.replace(/\D+/g, ''),
      },
    }));
  }

  async previewPdf() {
    const state = await this.storage.get();
    await this.update((state) => ({
      ...state,
      previewLoading: true,
    }));

    try {
      const preview = await this.api.preview(this.createFaxRequest(state));

      await this.update((state) => ({
        ...state,
        preview,
        previewLoading: false,
      }));
    } catch (e) {
      console.error(e);
      await this.update((state) => ({
        ...state,
        previewLoading: false,
      }));
    }
  }

  async closePreview() {
    await this.update((state) => ({
      ...state,
      preview: undefined,
    }));
  }

  async sendFax() {
    const state = await this.storage.get();
    await this.update((state) => ({
      ...state,
      sending: true,
    }));

    try {
      await this.view.addAttachmentToReferral(await this.api.send(this.createFaxRequest(state)));
      await this.view.close();
    } catch (e) {
      console.error(e);
      await this.update((state) => ({
        ...state,
        sending: false,
      }));
    }
  }

  private createFaxRequest(state: ProviderCommunicationState) {
    return {
      currentDate: new Date(),
      providerName: state.form.providerName,
      recipientFaxNumber: state.form.providerFaxNumber,
      referralId: this.activeReferral.id,
      text: state.form.notes,
    };
  }

  async cancel() {
    await this.view.close();
  }
}

type ProviderCommunicationReferral = {
  id: string;
  provider?: {
    firstName?: string;
    lastName?: string;
    credentials?: string;
    officeFaxNumber?: string;
  };
};
type ProviderCommunicationProvider = {
  providerName: string;
  providerFaxNumber: string;
};
export type ProviderCommunicationDialog = {
  providerName: string;
  providerFaxNumber: string;
  notes: string;
  previewButtonEnabled: boolean;
  sendButtonEnabled: boolean;
  previewActive: boolean;
  previewFile?: Blob;
  previewLoading: boolean;
  sendInProgress: boolean;
};

export type ProviderCommunicationState = {
  sending: boolean;
  form: ProviderCommunicationFormState;
  previewLoading: boolean;
  preview?: Blob;
};

export type ProviderCommunicationFormState = {
  providerName: string;
  providerFaxNumber: string;
  notes: string;
};

export interface ProviderCommunicationView {
  update(viewModel: ProviderCommunicationDialog): void;
  addAttachmentToReferral(attachment: any): void;
  close(): void;
}

export interface ProviderCommunicationStorage {
  get(): Promise<ProviderCommunicationState>;
  set(state: ProviderCommunicationState): Promise<void>;
}

export interface ProviderCommunicationApi {
  preview(request: ProviderCommRequest): Promise<Blob>;
  send(request: ProviderCommRequest): Promise<void>;
}

export type ProviderCommRequest = {
  currentDate: Date;
  text: string;
  referralId: string;
  recipientFaxNumber: string;
  providerName: string;
};
