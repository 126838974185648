import {
  GET_USER_PREFERENCES_ERROR,
  GET_USER_PREFERENCES_LOADING,
  GET_USER_PREFERENCES_SUCCESS,
  UPDATE_USER_PREFERENCES_ERROR,
  UPDATE_USER_PREFERENCES_LOADING,
  UPDATE_USER_PREFERENCES_SUCCESS,
} from '../constants/userPreferencesTypes';
import { UserPreferencePageName, UserPreferencesStore } from '../types';

interface Action {
  type: string;
  payload: {
    pageName: UserPreferencePageName;
    [x: string]: any;
  };
}

const initialState: UserPreferencesStore = {
  referrals: {
    loading: false,
    error: undefined,
    filters: [],
  },
  tasks: {
    loading: false,
    error: undefined,
    filters: [],
  },
  notifications: {
    loading: false,
    error: undefined,
    filters: [],
  },
  inbox: {
    loading: false,
    error: undefined,
    filters: [],
  },
};

const usePreferencesReducer = (state: UserPreferencesStore = initialState, action: Action): UserPreferencesStore => {
  switch (action.type) {
    case GET_USER_PREFERENCES_LOADING:
      return {
        ...state,
        [action.payload?.pageName]: {
          ...state[action.payload.pageName],
          loading: action.payload.isLoading,
        },
      };
    case GET_USER_PREFERENCES_SUCCESS:
      return {
        ...state,
        [action.payload?.pageName]: {
          ...state[action.payload?.pageName],
          ...action.payload,
          error: undefined,
        },
      };
    case GET_USER_PREFERENCES_ERROR:
      return {
        ...state,
        [action.payload?.pageName]: {
          ...state[action.payload?.pageName],
          error: action.payload,
        },
      };
    case UPDATE_USER_PREFERENCES_LOADING:
      return {
        ...state,
        [action.payload?.pageName]: {
          ...state[action.payload?.pageName],
          loading: action.payload?.isLoading,
        },
      };
    case UPDATE_USER_PREFERENCES_SUCCESS:
      return {
        ...state,
        [action.payload?.pageName]: {
          ...state[action.payload?.pageName],
          ...action.payload[action.payload?.pageName],
          ...action.payload,
          error: undefined,
        },
      };
    case UPDATE_USER_PREFERENCES_ERROR:
      return {
        ...state,
        [action.payload?.pageName]: {
          ...state[action.payload?.pageName],
          error: action.payload,
        },
      };
    default:
      return state;
  }
};

export default usePreferencesReducer;
