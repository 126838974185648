import axios, { AxiosResponse } from 'axios';

export interface ClinicsParams {
  homeCity?: string;
  regions?: string[];
  name?: string;
  state?: string;
  sortDirection?: 'ASC' | 'DESC';
  sortField?: string;
  page?: number;
  pageSize?: number;
}

export const getClinics = async (data: ClinicsParams): Promise<AxiosResponse<any, any>> => {
  const baseUrl = `${process.env.REACT_APP_API_URL}`;
  return await axios.post(`${baseUrl}/clinics/find`, data);
};
