import { BaseAction } from '../types';
import { GET_HOMECITY, GET_HOMECITY_SUCCESS, GET_HOMECITY_ERROR, CLEAR_HOME_CITIES } from '../constants/referralTypes';

const initialState: any = {
  loading: false,
  data: [],
  error: null,
};

const reducer = (state: any = initialState, action: BaseAction) => {
  switch (action.type) {
    case GET_HOMECITY:
      return {
        ...state,
        loading: action.payload,
      };
    case GET_HOMECITY_SUCCESS:
      return {
        ...state,
        data: action.payload,
      };
    case CLEAR_HOME_CITIES:
      return initialState;
    case GET_HOMECITY_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
