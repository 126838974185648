import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
  Typography,
} from '@mui/material';
import { useState, MouseEvent } from 'react';
import MenuItemSearchComponent from '../../../../components/MenuItemSearchComponent/MenuItemSearchComponent';
import { OrderOptionViewModel, OrderSelectViewModel } from '../order-select-presenter';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import NonScrollableMenu from '../../../../components/Menu/NonScrollableMenu';

const COLORS = {
  'Light/Other/Filled Input Background': 'rgba(0,0,0,0.06)',
  'Light/Primary/Shades/12p': '#1976D21F',
};

export function LinkOrdersDialog(
  props: OrderSelectViewModel & {
    onOrderToggle: (orderId: string) => void;
    onSave: () => void;
    onCancel: () => void;
  },
) {
  const options = props.orders.map((order) => ({
    label: (
      <Box style={{ width: '100%' }} display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex" alignItems="center" flex={1} sx={{ minWidth: 0 }}>
          <Typography>{order.idLabel}</Typography>
          <Tooltip title={getTooltipTitle(order)}>
            <Typography
              sx={{
                opacity: order.fade ? 0.6 : 1,
                flex: 1,
                marginLeft: 2,
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              {order.dateLabel}
            </Typography>
          </Tooltip>
        </Box>
      </Box>
    ),
    secondaryLabel: (
      <Typography style={{ color: 'rgba(0, 0, 0, 0.38)' }} color="textSecondary">
        {order.medicationLabel}
      </Typography>
    ),
    searchString: order.idLabel + order.medicationLabel,
    value: order.id,
  }));

  const selectedOrders = props.orders.filter((x) => x.isSelected);

  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [menuKey, setMenuKey] = useState(0);

  const onMenuSelectionChange = async (value: string[]) => {
    let changed = false;
    // Handle any order that was selected or deselected
    props.orders.forEach((order) => {
      if (order.isSelected !== value.includes(order.id)) {
        changed = true;
        return props.onOrderToggle(order.id);
      }
    });
    // Refresh the Menu component. This is a non-invasive way to
    // manage the state of a stateful child component.
    // If a stateless version of the component is ever added, use that and remove the key logic.
    if (changed) {
      setMenuKey((menuKey) => menuKey + 1);
    }
  };

  const onOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <Dialog open={props.open} maxWidth="xl" data-id="linkOrdersDialog">
      <DialogTitle>Link Orders</DialogTitle>
      <DialogContent>
        <Button
          data-id="cbxOrders"
          disableRipple
          style={{ minWidth: 460, background: COLORS['Light/Other/Filled Input Background'] }}
          onClick={onOpen}
          endIcon={<ArrowDropDownIcon fontSize="large" style={{ marginRight: 12, color: '#6b778c' }} />}
        >
          <Box style={{ width: '100%' }} display="flex">
            <Typography style={{ flex: 1, textAlign: 'left', textTransform: 'none' }} color="textSecondary">
              {selectedOrders.map((x) => x.idLabel).join(', ') || props.placeholderText}
            </Typography>
          </Box>
        </Button>
        <NonScrollableMenu anchorEl={anchorEl} onClose={() => setAnchorEl(null)} open={Boolean(anchorEl)}>
          {props.isLoadingOrders ? (
            <Box style={{ minWidth: 460 }} textAlign="center">
              <CircularProgress size={16} />
              <Typography color="textSecondary" align="center">
                Fetching orders from WeInfuse
              </Typography>
            </Box>
          ) : (
            [
              <MenuItemSearchComponent
                key={menuKey}
                multiple
                options={options}
                showAvatar={false}
                onChange={onMenuSelectionChange}
                initialValues={selectedOrders.map((x) => x.id)}
                menuItemsContentStyles={{ maxHeight: 358 }}
                hideActionButtons
                hideSearch
              />,
              props.showEmptyDropdownMessage && (
                <Typography key="none" padding={1}>
                  No orders found in WeInfuse
                </Typography>
              ),
            ]
          )}
        </NonScrollableMenu>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onCancel}>Cancel</Button>
        <Button disabled={props.saveButtonDisabled} onClick={props.onSave} variant="contained" color="primary">
          {props.saveButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function getTooltipTitle(order: OrderOptionViewModel) {
  return isTooLong(order.dateLabel) ? order.dateLabel : '';

  function isTooLong(dateLabel: string) {
    return dateLabel.length > 20; // arbitrary, room for improvement here
  }
}
