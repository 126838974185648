import {
  ProviderCommunicationDialog,
  ProviderCommunicationPresenter,
  ProviderCommunicationView,
} from '../provider-communication-presenter';
import PDFPreviewModal from '../../../components/PDFPreview/PDFPreviewModal';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { AxiosProviderCommunicationApi } from '../api/axios-provider-communication-api';
import { useDispatch } from 'react-redux';
import { addAttachmentSuccess } from '../../../redux/actionCreators/referralActionCreators';
import { InMemoryProviderCommunicationStorage } from '../storage/in-memory-provider-communication.storage';
import { FaxForm } from '../../Fax/view/FaxForm';

export function ProviderCommunicationModal(props: any) {
  const [viewModel, setViewModel] = useState<ProviderCommunicationDialog>();
  const dispatch = useDispatch();
  const [presenter] = useState(
    () =>
      new ProviderCommunicationPresenter(
        props.activeReferral,
        new ReactReduxProviderCommunicationView(setViewModel, dispatch, props.activeReferral.id, props.onClose),
        new InMemoryProviderCommunicationStorage(),
        new AxiosProviderCommunicationApi(),
      ),
  );

  useEffect(() => {
    presenter.showSendDialog().catch(console.error);
  }, [presenter]);

  if (!viewModel) {
    return null;
  }

  return (
    <>
      <PDFPreviewModal
        open={viewModel.previewActive}
        handleClosePreview={() => presenter.closePreview()}
        pdfToPreview={viewModel.previewFile}
        title="Provider Communication Preview"
      />
      <Dialog open={true}>
        <DialogTitle>Send Provider Communication</DialogTitle>
        <DialogContent>
          <FaxForm
            providerName={{ value: viewModel.providerName, set: (value) => presenter.setProviderName(value) }}
            providerFax={{ value: viewModel.providerFaxNumber, set: (value) => presenter.setProviderFaxNumber(value) }}
            notes={{ value: viewModel.notes, set: (value) => presenter.setNotes(value) }}
          />
        </DialogContent>

        <DialogActions>
          <Box style={{ width: '100%' }} display="flex" justifyContent="space-between">
            <Box>
              <Button onClick={() => presenter.cancel()}>Cancel</Button>
            </Box>
            <Box>
              <Button disabled={!viewModel.previewButtonEnabled} onClick={() => presenter.previewPdf()}>
                Preview Content {viewModel.previewLoading && <CircularProgress size={16} />}
              </Button>
              <Button disabled={!viewModel.sendButtonEnabled} variant="contained" onClick={() => presenter.sendFax()}>
                Send
                {viewModel.sendInProgress && <CircularProgress size={16} />}
              </Button>
            </Box>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
}

class ReactReduxProviderCommunicationView implements ProviderCommunicationView {
  constructor(
    private readonly setState: (state: ProviderCommunicationDialog) => void,
    private readonly dispatch: any,
    private readonly referralId: string,
    private readonly onClose: () => void,
  ) {}

  update(viewModel: ProviderCommunicationDialog): void {
    this.setState(viewModel);
  }

  addAttachmentToReferral(attachment: any): void {
    this.dispatch(addAttachmentSuccess({ ...attachment, referralId: this.referralId }));
  }

  close() {
    this.onClose();
  }
}
