import { FC, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { Box, InputBase, Typography } from '@mui/material';
import { Props } from './CustomizedAutocompleteTypes';
import { useStyles } from './autoCompleteStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DoneIcon from '@mui/icons-material/Done';

const CustomizedAutocomplete: FC<Props> = ({
  startAdornment,
  label,
  options,
  hidePopup,
  inputId,
  loading,
  handleInputChange,
  tooltipText,
  labelStyle,
  classes: restClasses,
  ...restProps
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const classes = useStyles({ open, disabled: restProps.disabled });
  const adornment = startAdornment ? (
    <Box
      style={{ marginTop: 8 }}
      className={classes.adornment}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      {startAdornment}
    </Box>
  ) : undefined;

  return (
    <div title={restProps.disabled ? tooltipText || 'Disabled' : ''} className={classes.parent}>
      {label && !open && (
        <div className={classes.label}>
          <Typography color={restProps.disabled ? 'textSecondary' : 'primary'} variant="subtitle2" style={labelStyle}>
            {label}
          </Typography>
        </div>
      )}
      <Autocomplete
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        options={(Array.isArray(options) && options) || []}
        loading={loading}
        loadingText={<Box display="flex">Loading...</Box>}
        id={inputId}
        size="medium"
        popupIcon={
          hidePopup ? (
            <></>
          ) : (
            <ExpandMoreIcon fontSize="large" style={{ color: '#0000008A', fontSize: 26, paddingRight: 10 }} />
          )
        }
        className={classes.root}
        classes={{
          ...restClasses,
          endAdornment: classes.endAdornment,
        }}
        renderOption={(props, option, { selected }) => {
          return option.icon ? (
            // eslint-disable-next-line jsx-a11y/role-supports-aria-props
            <li
              {...props}
              key={option.key || option.id || option.label}
              style={{ ...props?.style, background: '#ffffff' }}
              aria-selected="false"
            >
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box display="flex" alignItems="center">
                  <Box className={classes.boxIcon} display="flex" alignItems="center" justifyContent="center">
                    {option.icon}
                  </Box>
                  <Box>
                    <Box>
                      <Typography>{!option?.hideLabel && option?.label}</Typography>
                    </Box>
                  </Box>
                </Box>
                <Box display="flex" alignItems="center">
                  <Typography variant="caption" style={{ color: '#00000061', paddingRight: 12 }}>
                    {option?.additionalText}
                  </Typography>
                  {option?.showDoneIcon && <DoneIcon />}
                </Box>
              </Box>
            </li>
          ) : (
            <li
              {...props}
              key={option.key || option.id || option.label || option}
              style={{ ...props?.style, background: '#ffffff' }}
            >
              <Box display="flex">{option?.reactNodeIcon && option.reactNodeIcon()}</Box>
              <Box display="flex" justifyContent="space-between" style={{ width: '100%' }}>
                <Box display="flex" alignItems="center">
                  <Typography>{!option?.hideLabel && (option.label || option)}</Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <Typography variant="caption" style={{ color: '#00000061', paddingRight: 12 }}>
                    {option?.additionalText}
                  </Typography>
                  {option?.showDoneIcon && <DoneIcon />}
                </Box>
              </Box>
            </li>
          );
        }}
        noOptionsText="No Results Found"
        renderInput={(params) => {
          const { InputLabelProps, InputProps, ...rest } = params;
          return (
            <InputBase
              style={{
                height: 48,
                display: 'flex',
                alignItems: 'center',
                lineHeight: '48px',
                fontSize: 14,
                fontWeight: 500,
                ...restProps.inputstyle,
              }}
              onChange={handleInputChange}
              {...restProps.InputProps}
              {...params.InputProps}
              {...rest}
              startAdornment={adornment}
              placeholder={open ? restProps.placeholder : ''}
              classes={{
                input: classes.input,
              }}
            />
          );
        }}
        {...restProps}
      />
    </div>
  );
};

export default CustomizedAutocomplete;
