import { makeStyles } from '@mui/styles';
import { alpha, Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => {
  return {
    dialogTitle: {
      fontWeight: 500,
      fontSize: 20,
    },
    alert: {
      backgroundColor: '#FFFFFF !important',
      border: '1px solid #0288D1',
      borderRadius: '4px',
    },
    commentBox: {
      marginTop: 10,
    },
    selectMenu: {
      marginTop: 15,
      background: ({ errors }: any) =>
        errors?.waitingReason ? 'rgb(254, 235, 238)' : alpha(theme.palette.common.black, 0.06),
      borderRadius: 4,
    },
  };
});
