import {
  OrderSelectState,
  OrderSelectViewModel,
  presentOrderSelect,
  WeInfuseOrderOption,
} from './order-select-presenter';
import { isNotMarkedComplete, COMPLETE_STATUS_NAME } from './block-completion';

export type TaskState = {
  linkingAllowed: boolean;
  maxLinkedOrders: number;
  linkedOrders: TaskLinkedOrder[];
};

export type TaskLinkedOrder = {
  id: string;
  isArchived: boolean;
};

export type OrderEntryTaskViewModel = {
  body: OrderEntryTaskBodyViewModel;
  dialog: OrderSelectViewModel;
};

export type OrderEntryTaskBodyViewModel = {
  loading: boolean;
  linkedOrders: string;
  fadeText: boolean;
  buttonText: string;
  disableButton: boolean;
  maxLinkedOrders: number;
  tooltipMessage?: string;
};

export type OrderEntryTaskView = (update: (view: OrderEntryTaskViewModel) => OrderEntryTaskViewModel) => void;

export type OrderEntryTaskState = {
  task: TaskState;
  orderSelect: OrderSelectState;
  loading: boolean;
  errorMessage: string;
};

function getInitialDialogState() {
  return {
    open: false,
    options: null,
    selectedOrders: {},
    isSaving: false,
  };
}

export type OrderEntryTaskApi = {
  getTaskState(taskId: string): Promise<TaskState>;
  getOrderLinkOptions(taskId: string): Promise<WeInfuseOrderOption[]>;
  saveLinkedOrders(taskId: string, orderIds: string[]): Promise<TaskState>;
};

export class OrderEntryTaskPresenter {
  static INITIAL_VIEW_MODEL: OrderEntryTaskViewModel = {
    body: {
      linkedOrders: '-',
      fadeText: false,
      buttonText: 'Link Orders',
      disableButton: true,
      loading: true,
      maxLinkedOrders: 2,
      tooltipMessage: 'To Link orders, please select a WeInfuse Patient.',
    },
    dialog: presentOrderSelect(2, getInitialDialogState()),
  };
  static COMPLETE_STATUS_NAME = COMPLETE_STATUS_NAME;

  private readonly state: OrderEntryTaskState = {
    task: {
      maxLinkedOrders: 2,
      linkedOrders: [],
      linkingAllowed: false,
    },
    orderSelect: getInitialDialogState(),
    loading: true,
    errorMessage: '',
  };

  constructor(
    private readonly api: OrderEntryTaskApi,
    private readonly taskId: string,
    private readonly setViewModel: (viewModel: OrderEntryTaskViewModel) => void,
    private readonly refreshTaskStatus: () => void,
    private readonly console: any,
  ) {}

  async display() {
    try {
      this.state.task = await this.api.getTaskState(this.taskId);
    } catch (e) {
      if (e instanceof Error) {
        this.state.errorMessage = e.message;
      }
      this.console.error(e);
    }
    this.state.loading = false;
    this.updateView();
  }

  openDialog() {
    this.state.orderSelect.selectedOrders = this.state.task.linkedOrders.reduce(
      (selectedOrders, linkedOrder) => ({
        ...selectedOrders,
        [linkedOrder.id]: true,
      }),
      {},
    );
    this.state.orderSelect.open = true;
    this.updateView();
    return this.api.getOrderLinkOptions(this.taskId).then((orderOptions) => {
      this.state.orderSelect.options = orderOptions;
      this.updateView();
    });
  }

  cancelDialog() {
    this.state.orderSelect = getInitialDialogState();
    this.updateView();
  }

  save() {
    this.state.orderSelect.isSaving = true;
    this.updateView();
    return this.api
      .saveLinkedOrders(this.taskId, OrderEntryTaskPresenter.getSelectedOrderIds(this.state.orderSelect))
      .then((bodyViewModel) => {
        this.state.task = bodyViewModel;
        this.cancelDialog();
        this.refreshTaskStatus();
      })
      .catch(this.console.error);
  }

  private static getSelectedOrderIds(state: OrderSelectState) {
    return Object.entries(state.selectedOrders)
      .filter(([, isSelected]) => isSelected)
      .map(([id]) => id);
  }

  toggleOrderSelected(orderId: string) {
    const oldState = this.state;
    const wasSelected = Boolean(oldState.orderSelect.selectedOrders[orderId]);
    if (
      !wasSelected &&
      OrderEntryTaskPresenter.getSelectedOrderIds(oldState.orderSelect).length >= oldState.task.maxLinkedOrders
    ) {
      return;
    }
    this.state.orderSelect.selectedOrders[orderId] = !oldState.orderSelect.selectedOrders[orderId];
    this.updateView();
  }

  private updateView() {
    this.setViewModel({
      body: {
        ...presentOrderEntryTask(this.state.task),
        tooltipMessage: this.state.errorMessage || 'To link orders, please select a WeInfuse Patient.',
      },
      dialog: presentOrderSelect(this.state.task.maxLinkedOrders, this.state.orderSelect),
    });
  }

  validateStatusChange(newStatusName: string) {
    if (isNotMarkedComplete(newStatusName) || this.state.task.linkedOrders.length > 0) {
      return true;
    }

    if (this.state.task.linkingAllowed) {
      this.openDialog();
    }
    return false;
  }

  getStatusOptionDisplayState(statusName: string) {
    if (statusName === 'COMPLETED') {
      if (this.state.loading) {
        return {
          disabled: true,
          loading: true,
        };
      }
      if (!this.state.task.linkingAllowed) {
        return {
          disabled: true,
          tooltip: this.state.errorMessage || `To change task status to "Completed", please select a WeInfuse Patient.`,
        };
      }
    }
    return {
      disabled: false,
    };
  }
}

export function presentOrderEntryTask(state: TaskState): OrderEntryTaskBodyViewModel {
  return {
    loading: false,
    buttonText: state.linkedOrders.length > 0 ? 'Change Linked Orders' : 'Link Orders',
    fadeText: !state.linkingAllowed,
    disableButton: !state.linkingAllowed,
    linkedOrders: state.linkedOrders.length === 0 ? '-' : state.linkedOrders.map(formatLinkedOrder).join(', '),
    maxLinkedOrders: state.maxLinkedOrders,
  };

  function formatLinkedOrder(linkedOrder: TaskLinkedOrder) {
    return `ID ${linkedOrder.id}${linkedOrder.isArchived ? ' (Archived)' : ''}`;
  }
}
