import SelectMenu from '../../../components/SelectMenu/SelectMenu';
import React from 'react';
import { SendSmsController } from '../send-sms-controller';

export function TemplateSelect({
  templates,
}: {
  templates: ReturnType<ReturnType<typeof SendSmsController>>['templates'];
}) {
  function getSelected() {
    return templates.find((x) => x.selected);
  }

  function onChange(option: Parameters<typeof SelectMenu>[0]['options'][0]) {
    (
      templates.find((x) => x.label === option?.label) || {
        click: () => console.error('Selected option not found:', option),
      }
    ).click();
  }

  return (
    <SelectMenu
      value={getSelected()?.label}
      onChange={onChange}
      onAddNew={console.log}
      handleSearch={console.log}
      showClearIcon={false}
      hideAddnew
      showExpandMore
      searchLoading={false}
      floatSecondaryLabelRight={true}
      options={templates.map((option) => ({
        ...option,
        disabled: option.selected,
        showDoneIcon: option.selected,
        label: option.label,
        searchString: option.label,
      }))}
      label={getSelected()?.label || 'Select Template'}
      style={{ paddingLeft: 8, backgroundColor: '#E8E8E8' }}
    />
  );
}
