import axios, { AxiosResponse } from 'axios';

export interface TaskUpdate {
  id?: string;
  active?: boolean;
  statusId?: string;
  assignedUserId?: string;
  statusReasonIdsToAdd?: string[];
  statusReasonIdsToRemove?: string[];
  message?: string;
  tagUserIds?: string[];
  patientContactTag?: boolean;
  referralFollowupTag?: boolean;
  waitingTag?: boolean;
}

export const editStatusAttachmentApi = async (
  id?: string,
  formData?: FormData | any,
): Promise<AxiosResponse<any, any>> => {
  const baseUrl = `${process.env.REACT_APP_API_URL}`;
  return await axios.patch(`${baseUrl}/tasks/status/${id}`, formData);
};

export const editTaskStatusApi = async (params: TaskUpdate): Promise<AxiosResponse<any, any>> => {
  const baseUrl = `${process.env.REACT_APP_API_URL}`;
  const { id, ...restParams } = params;
  return await axios.patch(`${baseUrl}/tasks/status/${id}`, restParams);
};

export const editTaskApi = async (params: TaskUpdate): Promise<AxiosResponse<any, any>> => {
  const baseUrl = `${process.env.REACT_APP_API_URL}`;
  const { id, ...restParams } = params;
  return await axios.patch(`${baseUrl}/tasks/${id}`, restParams);
};

export const getTaskStatuses = async () => {
  const baseUrl = `${process.env.REACT_APP_API_URL}`;
  return await axios.get(`${baseUrl}/task-statuses`);
};

export const getTask = async (taskId: string) => {
  const baseUrl = `${process.env.REACT_APP_API_URL}`;
  return await axios.get(`${baseUrl}/tasks/${taskId}`);
};
