import { AxiosError } from 'axios';
import { Box, Button, IconButton, Typography } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  addHours,
  addMilliseconds,
  addMinutes,
  addSeconds,
  differenceInBusinessDays,
  differenceInCalendarDays,
  differenceInHours,
  differenceInMinutes,
  eachWeekendOfInterval,
  format,
  getDay,
  isAfter,
  isSameDay,
  isSaturday,
  isWeekend,
  nextMonday,
  nextSaturday,
  previousFriday,
  startOfDay,
  startOfTomorrow,
  subDays,
} from 'date-fns';
import { Dispatch } from 'redux';
import { loadIdentityInfo, loadRefreshToStore, loadTokenToStore } from '../redux/actions/authActions';
import clockIcon from '../images/clockIconRed.svg';
import { CSSProperties, FC } from 'react';
import { difference, get, isNull } from 'lodash';
import referralStatusCancelled from '../images/referralStatusCancelled.svg';
import referralStatusDraft from '../images/referralStatusDraft.svg';
import referralStatusNotCompleted from '../images/referralStatusNotCompleted.svg';
import referralStatusOnHold from '../images/referralStatusOnHold.svg';
import referralStatusOpen from '../images/referralStatusOpen.svg';
import referralStatusReadyToSchedule from '../images/referralStatusReadyToSchedule.svg';
import referralStatusScheduled from '../images/referralStatusScheduled.svg';
import taskStatusToDo from '../images/tastStatusToDo.svg';
import taskStatusReady from '../images/taskStatusReady.svg';
import taskStatusInProgress from '../images/taskStatusInProgress.svg';
import taskStatusWaiting from '../images/taskStatusWaiting.svg';
import taskStatusCompleted from '../images/taskStatusCompleted.svg';
import notReadyIcon from '../images/notReadyIcon.svg';
import notApplicableIcon from '../images/notApplicableIcon.svg';
// import redDot from '../images/redDot.svg';
import { PatientName } from '../components/ReferralView/ReferralViewTypes';
import CustomAvatar from '../components/Avatar/Avatar';
import arrowUp from '../images/arrowUp.svg';
import forwardIcon from '../images/forwardIcon.svg';
import {
  InboxFilters,
  InboxItem,
  medicationsState,
  Notification,
  ReferralFilters,
  ReferralProvider,
  ReferralStatus,
  referralStatuses,
  Task,
  TasksFilters,
  UserPreferencePageName,
} from '../redux/types';
import {
  inboxStatusConstants,
  referralStatusesOptions as referralFilterStatusesOptions,
  TASK_TYPE_ID,
  taskListTabs,
} from './constants';
import { markNotificationAsReadAction } from '../redux/actions/notificationsActions';
import { NavigateFunction } from 'react-router-dom';
import { statuses } from '../views/Referrals/Referrals';
import { updateUserPreferencesAction } from '../redux/actions/userPreferencesAction';
import { UserPreferenceFilterParam } from '../api/userPreferencesApi';

declare type Error = {
  message: string;
  code: number;
};

export const logoutAction = () => async (dispatch: Dispatch) => {
  try {
    dispatch(loadIdentityInfo({}));
    dispatch(loadRefreshToStore(''));
    dispatch(loadTokenToStore(''));
    // window.location.href = `https://login.microsoftonline.com/common/oauth2/v2.0/logout?post_logout_redirect_uri=${
    //   process.env.REACT_APP_REDIRECT_URI || ''
    // }`;
  } catch (error: any) {}
};
export const apiErrorHandler = (error: AxiosError): Error => {
  //  Use this to handle all possible error messages from the backend and return a descriptive error message
  if (error?.response?.status === 401) {
    // logoutAction()(store.dispatch);
  }
  return {
    message: '',
    code: error?.response?.status || 500,
  };
};

export const maskFax = (fax: string = '') => {
  return fax
    ? `+1 (${fax?.toString()?.slice(0, 3)}) ${fax?.toString()?.slice(3, 6)}-${fax?.toString()?.slice(6, 20)}`
    : '';
};

export const hourDifferenceExcludingWeekends = (currentDate: Date, date: Date): number => {
  let testDate: Date = currentDate || new Date();
  let weekendDaysToRemove: number = 0;
  let dateToCheckAgainst: Date = date;

  if (isWeekend(testDate)) {
    weekendDaysToRemove = isSaturday(testDate) ? 1 : 2;
    testDate = addHours(startOfDay(previousFriday(testDate)), 24);
  }

  if (isWeekend(date)) {
    date.setHours(0, 0, 0);
    dateToCheckAgainst = nextMonday(date);
  }

  if (dateToCheckAgainst > testDate) {
    return 0;
  }

  const hoursDifference: number = differenceInHours(testDate, dateToCheckAgainst);

  const numberOfWeekendIntervals: number = eachWeekendOfInterval({
    start: dateToCheckAgainst,
    end: testDate,
  }).length;

  const weekendHours: number = (numberOfWeekendIntervals - weekendDaysToRemove) * 24;

  return hoursDifference - weekendHours;
};

export const Timer: FC<any> = (props) => {
  const { date, hours, hideResult } = props;
  const todayDate = format(new Date(), 'yyyy-MM-dd');
  const result = hourDifferenceExcludingWeekends(ignoreTimezoneDate(todayDate), ignoreTimezoneDate(date));
  return result >= hours ? (
    <Box display="flex">
      <img style={{ marginLeft: 8 }} alt="" src={clockIcon} />
      {!hideResult && (
        <Typography variant="body2" style={{ color: '#C62828', marginLeft: 8 }}>
          {result}h
        </Typography>
      )}
    </Box>
  ) : (
    <></>
  );
};

export const inboxItemsFormatter = (e: InboxItem) => {
  const result = hourDifferenceExcludingWeekends(new Date(), new Date(e.faxReceivedDate));
  const assignee = !e?.assignedUser ? 'Unassigned' : e?.assignedUser?.firstName + ' ' + e?.assignedUser?.lastName;
  const assignedUser = (
    <Box display="flex" alignItems="center">
      <CustomAvatar
        name={`${assignee || ''}`}
        style={{ width: 30, height: 30, marginRight: 8, fontSize: 14, fontWeight: 500 }}
        hideIconWrapper
      />
      <span style={{ marginLeft: 8 }}>{assignee}</span>
    </Box>
  );
  return {
    ...e,
    from: e?.fromFaxNumber ? maskFax(e?.fromFaxNumber) : e?.fromEmail,
    to: maskFax(e?.toFaxNumber),
    fax: (
      <Box display="flex" alignItems="center">
        <span>{e.title || `New Fax from ${maskFax(e.fromFaxNumber)}`}</span>
        {result >= 24 && e.status === 'OPEN' && (
          <>
            <img style={{ marginLeft: 8 }} alt="" src={clockIcon} />
            <Typography variant="body2" style={{ color: '#C62828', marginLeft: 8 }}>
              {result}h
            </Typography>
          </>
        )}
      </Box>
    ),
    region: e.region?.name,
    action: (
      <IconButton onClick={() => {}}>
        <MoreVertIcon style={{ color: '' }} />
      </IconButton>
    ),
    assignee: assignedUser,
    time: userFriendlyTimestamp(e.faxReceivedDate),
    faxReceivedDateTime: new Date(e.faxReceivedDate),
  };
};

export const formatComment = (comment: string = ''): string => {
  const regex = /@\[(.*?)]\((.*?)\)/g;
  const matchingText = comment.match(regex) || [];
  const mappings: any = {};
  matchingText.forEach((text: string) => {
    const splittedText = text.split(/@\[|]/);
    mappings[text] = splittedText[1];
  });
  let output = comment;
  Object.keys(mappings).forEach((key) => {
    const newText = key.replace('[', '\\[').replace('(', '\\(').replace(']', '\\]').replace(')', '\\)');
    const regex = new RegExp(newText, 'g');
    output = output.replace(regex, `<span style="color: #1976D2;">@${mappings[key]}</span>`);
  });

  // if there's new line character, render in new line
  output = output.replace(/(?:\r\n|\r|\n)/g, '<br />');

  return output;
};

export const getValidDate = (date: Date | string): Date => (typeof date === 'string' ? new Date(date) : date);

export const userFriendlyTimestamp = (
  input?: Date | string,
  otherFormats: {
    roundOffLessThan2?: boolean;
    hideTime?: boolean;
    persistTimezone?: boolean;
  } = { roundOffLessThan2: false, hideTime: false, persistTimezone: true },
): string => {
  const roundOffLessThan2 = otherFormats && otherFormats?.roundOffLessThan2;
  const hideTime = otherFormats && otherFormats?.hideTime;
  if (!input) {
    return '';
  }

  const dateInput = typeof input === 'string' ? new Date(input) : input;
  const date: string | Date = !otherFormats.persistTimezone ? ignoreTimezoneDate(dateInput) : dateInput;
  const minDifference = differenceInMinutes(new Date(), date, { roundingMethod: 'floor' });
  if (minDifference < 1) {
    return roundOffLessThan2 ? 'Today' : 'Just now';
  }
  if (minDifference <= 5) {
    return roundOffLessThan2 ? 'Today' : 'A few minutes ago';
  }
  if (minDifference < 60) {
    return `${minDifference} minutes ago`;
  }
  const isToday = isSameDay(date, new Date());
  if (isToday) {
    const formatted = format(date, 'h:mm a');
    return hideTime ? `Today` : `Today, ${formatted}`;
  }
  const dateYesterday = subDays(new Date(), 1);
  const isYesterday = isSameDay(date, dateYesterday);
  if (isYesterday) {
    const formatted = format(date, 'h:mm a');
    return hideTime ? `Yesterday` : `Yesterday, ${formatted}`;
  }
  // const dateBeforeYesterday = subDays(new Date(), 2);
  // const isDateBeforeYesterday = isSameDay(date, dateBeforeYesterday);
  // if(isDateBeforeYesterday) {
  //   return '2 days ago'
  // }
  return hideTime ? format(date, 'LLL d, yyyy') : format(date, 'LLL d, yyyy h:mm a');
};

export const convertDayToStringRepresentation = (day: number): string => {
  switch (day) {
    case 0:
      return 'sunday';
    case 1:
      return 'monday';
    case 2:
      return 'tuesday';
    case 3:
      return 'wednesday';
    case 4:
      return 'thursday';
    case 5:
      return 'friday';
    case 6:
      return 'saturday';
    default:
      throw new Error('Invalid date the dates range from 0 - 6');
  }
};

export const getDayName = (inputDate: Date | string): string => {
  const date: Date = getValidDate(inputDate);
  const day: number = getDay(date);

  return convertDayToStringRepresentation(day);
};

export const userFriendlyTimeStampForInboxGroupedDates = (
  inputDate: Date | string,
  persistTimezone: boolean = true,
): {
  title: string;
  caption: string;
} => {
  const date = !persistTimezone ? ignoreTimezoneDate(getValidDate(inputDate)) : getValidDate(inputDate);
  const dateYesterday: Date = subDays(new Date(), 1);
  const isYesterday: boolean = isSameDay(date, dateYesterday);
  const isToday: boolean = isSameDay(date, new Date());
  const dayName: string = getDayName(date);

  if (isToday) {
    return {
      title: `Today`,
      caption: `${format(date, 'LLL d yyyy')}`,
    };
  }

  if (isYesterday) {
    return {
      title: `Yesterday`,
      caption: `${format(date, 'LLL d yyyy')}`,
    };
  }

  return {
    title: `${dayName}, ${format(date, 'LLL d yyyy')}`,
    caption: '',
  };
};

export const sortByDate = (dateA: string, dateB: string) => {
  const dateAObject = new Date(dateA).getTime();
  const dateBObject = new Date(dateB).getTime();
  if (dateAObject > dateBObject) {
    return -1;
  }
  return 1;
};

export const statusDefaults = {
  WAITING: 'WAITING',
  COMPLETED: 'COMPLETED',
  NOTREADY: 'NOT READY',
  READY: 'READY',
  TODO: 'TODO',
  INPROGRESS: 'IN PROGRESS',
  NOTAPPLICABLE: 'NOT APPLICABLE',
};

export const taskFaxStatuses = {
  DELIVERY_IN_PROGRESS: 'delivery in progress',
  FAILED_DELIVERY: 'failed delivery',
  SUCCESSFUL_DELIVERY: 'successful delivery',
  MANUAL_DELIVERY: 'manual delivery',
};

/**
 * @description  gets tasks status text color, background color and icon image
 * @param {string} status
 * @returns {object}
 */
export const getTaskColors = (status: string = '') => {
  const transformed = status?.toUpperCase();
  const { WAITING, COMPLETED, NOTREADY, READY, INPROGRESS, NOTAPPLICABLE, TODO } = statusDefaults;

  switch (transformed) {
    case TODO:
      return {
        label: 'To Do',
        dotColor: '#FF9800',
        textColor: '#000000',
        background: '#FFF5E5',
        iconImage: taskStatusToDo,
      };
    case NOTREADY:
      return {
        label: 'Not Ready',
        dotColor: '#FFFFFF',
        background: '#FFFFFF',
        textColor: '#000000',
        border: '1px solid #BDBDBD',
        borderRadius: '32px',
        iconImage: notReadyIcon,
      };
    case WAITING:
      return {
        label: 'Waiting',
        dotColor: '#FB8C00',
        background: '#FFF3E0',
        textColor: '#000000',
        iconImage: taskStatusWaiting,
      };
    case READY:
      return {
        label: 'Ready',
        dotColor: '#8BC34A',
        background: '#F1F8E9',
        textColor: '#000000',
        iconImage: taskStatusReady,
      };
    case INPROGRESS:
      return {
        label: 'In Progress',
        dotColor: '#1976D2',
        background: '#E8F1FB',
        textColor: '#000000',
        iconImage: taskStatusInProgress,
      };
    case COMPLETED:
      return {
        label: 'Completed',
        dotColor: '#009688',
        background: '#E0F2F1',
        textColor: '#000000 !important',
        iconImage: taskStatusCompleted,
      };
    case NOTAPPLICABLE:
      return {
        label: 'Not Applicable',
        dotColor: '#BDBDBD',
        background: '#F4F4F4',
        textColor: '#000000',
        iconImage: notApplicableIcon,
      };
    default:
      return {
        label: transformed,
        dotColor: '',
        background: '#E5E5E5',
        textColor: '#000000',
      };
  }
};

/**
 * @description  returns fax acknowledgement icons
 * @param {string} status
 * @returns {object}
 */
export const faxAcknowledgementStatusIcons = (status: string) => {
  const transformed = status?.toUpperCase();
  const { COMPLETED, INPROGRESS } = statusDefaults;

  switch (transformed) {
    case COMPLETED:
      return {
        icon: taskStatusToDo,
      };
    case INPROGRESS:
      return {
        icon: taskStatusInProgress,
      };
    case 'ERROR':
      return {
        icon: taskStatusInProgress,
      };
    default:
      return {
        icon: taskStatusToDo,
      };
  }
};

export const referralStatusesDefaults = {
  DRAFT: 'DRAFT',
  OPEN: 'OPEN',
  ONHOLD: 'ON HOLD',
  READY_TO_SCHEDULE: 'READY TO SCHEDULE',
  SCHEDULED: 'SCHEDULED',
  WAITING: 'WAITING',
  NOTCOMPLETED: 'NOT COMPLETED',
  DISCARDED: 'DISCARDED',
  CANCELLED: 'CANCELLED',
};

/**
 * @description gets referrals status text color, background color, sort value and icon image
 * @param {string} status
 * @returns {object}
 */
export const getReferralsStatusColors = (status: string = '') => {
  const transformed = status?.toUpperCase();
  const { DRAFT, OPEN, ONHOLD, READY_TO_SCHEDULE, SCHEDULED, NOTCOMPLETED, DISCARDED, CANCELLED } =
    referralStatusesDefaults;
  switch (transformed) {
    case DRAFT:
      return {
        background: '#E5E5E5',
        textColor: '#000000',
        sortValue: 1,
        iconImage: referralStatusDraft,
      };
    case OPEN:
      return {
        background: '#E8F1FB',
        textColor: '#000000',
        sortValue: 2,
        iconImage: referralStatusOpen,
      };
    case ONHOLD:
      return {
        background: '#FFF5E5',
        textColor: '#000000',
        sortValue: 3,
        iconImage: referralStatusOnHold,
      };
    case READY_TO_SCHEDULE:
      return {
        background: '#F1F8E9',
        textColor: '#000000',
        sortValue: 4,
        iconImage: referralStatusReadyToSchedule,
      };
    case SCHEDULED:
      return {
        background: '#E0F2F1',
        textColor: '#000000',
        sortValue: 5,
        iconImage: referralStatusScheduled,
      };
    case NOTCOMPLETED:
      return {
        background: '#E5E5E5',
        textColor: '#000000',
        sortValue: 6,
        iconImage: referralStatusNotCompleted,
      };
    case DISCARDED:
      return {
        background: '#FEECEB',
        textColor: '#000000',
        sortValue: 7,
        iconImage: referralStatusCancelled,
      };
    case CANCELLED:
      return {
        background: '#FEECEB',
        textColor: '#000000',
        sortValue: 7,
        iconImage: referralStatusCancelled,
      };
    default:
      return {
        background: '#E3F2FD',
        textColor: '#000000',
        sortValue: 8,
        icon: referralStatusDraft,
      };
  }
};

export const taskStatusTypes = {
  TODO: 'to do',
  WAITING: 'waiting',
  IN_PROGRESS: 'in progress',
  NOT_APPLICABLE: 'not applicable',
  READY: 'ready',
  COMPLETED: 'completed',
};

/**
 * @description Gets Referral type or subtypes to render.
 * @param {object} referral
 * @returns {object}
 */
export const getReferralTypeToRender = (referral: any): { label: string; color: 'primary' | 'default' } => {
  switch (referral?.referralType?.toLowerCase()) {
    case 'referral':
      return {
        label: getReferralTypeLabel(referral?.referralSubType) || '',
        color: 'primary',
      };
    case 'renewal':
      return {
        label: getReferralTypeLabel(referral?.referralSubType) || '',
        color: 'default',
      };
    default:
      return {
        label: '',
        color: 'primary',
      };
  }
};

export const getReferralAttachmentSeenStatus = (referral: any): number => {
  return referral?.attachments?.filter((item: any) => {
    return !item.seen;
  }).length;
};

export interface Duration {
  hours?: number;
  minutes?: number;
  seconds?: number;
  milliseconds?: number;
}

export const getTimeToNextAction = (previousActionPerformedTime: Date, duration: Duration): Date => {
  const minutes: number = get(duration, 'minutes', 0);
  const seconds: number = get(duration, 'seconds', 0);
  const hours: number = get(duration, 'hours', 0);
  const milliseconds: number = get(duration, 'milliseconds', 0);

  return addMilliseconds(
    addSeconds(addMinutes(addHours(previousActionPerformedTime, hours), minutes), seconds),
    milliseconds,
  );
};

export const getNextFollowUpDate = (currentFollowUpDate: Date): Date => {
  const hoursToSaturday: number = differenceInHours(nextSaturday(currentFollowUpDate), currentFollowUpDate);
  const nextMondayDate: Date = nextMonday(currentFollowUpDate);
  const duration: Duration = {
    hours: 48,
  };

  if (isWeekend(currentFollowUpDate)) {
    return getTimeToNextAction(nextMondayDate, duration);
  } else if (hoursToSaturday < 48) {
    return getTimeToNextAction(nextMondayDate, {
      hours: 48 - hoursToSaturday,
    });
  }

  return getTimeToNextAction(currentFollowUpDate, duration);
};

export const taskStatuses = {
  TODO: 'TO DO',
  IN_PROGRESS: 'IN PROGRESS',
  READY: 'READY',
  COMPLETED: 'COMPLETED',
  WAITING: 'WAITING',
  NOT_APPLICABLE: 'NOT APPLICABLE',
};

export const getReferralPatientNameToRender = (currentReferral: any): PatientName => {
  return {
    firstName: currentReferral?.patient?.first_name || currentReferral?.firstName || '',
    lastName: currentReferral?.patient?.last_name || currentReferral?.lastName || '',
  };
};

export const getReferralTitle = (currentReferral: any): string => {
  const patientName: PatientName = getReferralPatientNameToRender(currentReferral);

  return `${patientName?.firstName}  ${patientName?.lastName}  ${
    currentReferral?.medication?.name ? 'for ' + currentReferral?.medication?.name : ''
  }`;
};

export const statusesToShowAlert = ['not completed', 'discarded', 'on hold', 'scheduled'];

export const ignoreTimezoneDate = (date: string | any): Date => {
  const invalidDate = date ? new Date(date) : new Date();
  const userTimezoneOffset = invalidDate.getTimezoneOffset() * 60000;
  return new Date(invalidDate.getTime() + userTimezoneOffset);
};

export const statusSort = (item: any) => {
  const sortOrder: any = {
    'to do': 1,
    ready: 2,
    'in progress': 3,
    waiting: 4,
    completed: 5,
    'not applicable': 6,
  };

  return { ...item, sortOrder: sortOrder[item?.name?.toLowerCase()] };
};

export const prioritiesOptions = [
  {
    label: 'Normal',
    value: 'Normal',
    inputId: 'priority',
    hideLabel: true,
    reactNodeIcon: () => (
      <Button
        disableElevation
        style={{
          color: 'white',
          borderRadius: 16,
          height: 32,
          background: '#BDBDBD',
          width: 82,
          fontSize: 13,
          paddingLeft: 13,
          paddingRight: 10,
        }}
        variant="contained"
        startIcon={<img alt="" src={forwardIcon} />}
      >
        <Typography
          style={{
            color: 'white',
            textTransform: 'none',
            fontSize: 13,
            fontWeight: 400,
          }}
        >
          Normal
        </Typography>
      </Button>
    ),
  },
  {
    label: 'Urgent',
    value: 'Urgent',
    inputId: 'priority',
    hideLabel: true,
    reactNodeIcon: () => (
      <Button
        disableElevation
        color={'error'}
        style={{
          color: '#BDBDBD',
          borderRadius: 16,
          height: 32,
          width: 78,
          fontSize: 13,
          paddingLeft: 14,
          paddingRight: 10,
        }}
        variant="contained"
        startIcon={<img alt="" src={arrowUp} />}
      >
        <Typography
          style={{
            color: 'white',
            textTransform: 'none',
            fontSize: 13,
            fontWeight: 400,
          }}
        >
          Urgent
        </Typography>
      </Button>
    ),
  },
];

/**
 * @description Checks whether an item is empty or undefined.
 * @param {any} item
 * @returns {boolean}
 */
export const isNullEmptyOrUndefined = (item: any): boolean => {
  return !item || isNull(item);
};

export const getIdsFromArray = (items?: any[]): string[] => (items?.length ? items.map((item: any) => item?.id) : []);

export const getFormattedDaysAgoText = (earlierDate: string | Date, persistTimezone: boolean = true) => {
  const earlierDateWithoutTimezone = !persistTimezone
    ? ignoreTimezoneDate(new Date(earlierDate))
    : new Date(earlierDate);
  const todayDate = !persistTimezone ? ignoreTimezoneDate(new Date()) : new Date();
  const daysAgo = differenceInCalendarDays(todayDate, new Date(earlierDateWithoutTimezone));
  const workDaysAgo = differenceInBusinessDays(todayDate, new Date(earlierDateWithoutTimezone));

  let formattedDaysAgo = `${workDaysAgo} workdays ago`;
  if (daysAgo > 0 && workDaysAgo === 0) {
    return (formattedDaysAgo = `1 workday ago`);
  }
  if (workDaysAgo === 0) {
    return (formattedDaysAgo = 'Today');
  } else if (workDaysAgo === 1) {
    return (formattedDaysAgo = `1 workday ago`);
  } else if (workDaysAgo > 1) {
    return (formattedDaysAgo = `${workDaysAgo} workdays ago`);
  } else {
    return formattedDaysAgo;
  }
};

export const shouldDisplayInboxRow = (inboxItem: InboxItem, filters: InboxFilters): boolean => {
  if (filters.users.length) {
    if (inboxItem.assignedUser || !filters.statuses.includes(inboxStatusConstants.UNASSIGNED)) {
      if (!inboxItem.assignedUserId || !filters.users.includes(inboxItem.assignedUserId)) {
        return false;
      }
    }
  }

  if (filters.regions.length) {
    if (!inboxItem.regionId && !filters.regions.includes(null)) {
      return false;
    }

    if (!filters.regions.includes(inboxItem.regionId)) {
      return false;
    }
  }

  if (filters.statuses.length) {
    const status: string | undefined = inboxItem?.statusValue || inboxItem.status;

    if (!status || !filters.statuses.includes(status)) {
      return false;
    }
  }

  return true;
};

export const shouldDisplayReferralRow = (
  referralFilters: ReferralFilters,
  referral: any,
  activeStatuses: ReferralStatus[],
  archivedStatuses: ReferralStatus[],
  status?: string,
): boolean => {
  const referralStatuses: string[] = referralFilters?.statuses?.length
    ? referralFilters?.statuses
    : status?.toUpperCase() === statuses.ACTIVE
    ? getIdsFromArray(activeStatuses)
    : getIdsFromArray(archivedStatuses);

  if (
    referralFilters?.assignedUsers?.length &&
    !referralFilters.assignedUsers.includes(referral?.assignedUser?.id || null)
  ) {
    return false;
  }

  if (
    referralFilters?.fromNextFollowupDate &&
    new Date(referral?.nextFollowupDate) < new Date(referralFilters?.fromNextFollowupDate)
  ) {
    return false;
  }

  if (
    referralFilters?.toNextFollowupDate &&
    new Date(referral?.nextFollowupDate) > new Date(referralFilters?.toNextFollowupDate)
  ) {
    return false;
  }

  if (referralFilters?.medications?.length && !referralFilters?.medications.includes(referral?.medicationId)) {
    return false;
  }

  if (referralStatuses.length && !referralStatuses.includes(referral?.status?.id)) {
    return false;
  }

  if (referralFilters?.clinics?.length && !referralFilters?.clinics.includes(referral?.clinic?.id)) {
    return false;
  }

  if (referralFilters?.regions?.length && !referralFilters.regions.includes(referral?.region?.id)) {
    return false;
  }

  const referralProvidersIds = referralFilters?.providers?.map((provider) => provider.id);

  if (referralFilters?.providers?.length && !referralProvidersIds?.includes(referral?.provider?.id)) {
    return false;
  }

  return true;
};

export const getFilteredReferrals = (
  referrals: any[],
  filters: ReferralFilters,
  activeStatuses: ReferralStatus[],
  archivedStatuses: ReferralStatus[],
  status?: string,
): any[] => {
  return referrals.filter((referral: any) =>
    shouldDisplayReferralRow(filters, referral, activeStatuses, archivedStatuses, status),
  );
};

export const getResultsToDisplayInInboxList = (inboxResult: InboxItem[], filters: InboxFilters): InboxItem[] => {
  return inboxResult.filter((inboxItem: InboxItem) => shouldDisplayInboxRow(inboxItem, filters));
};

export enum MAIN_PAGES {
  REFERRALS,
  TASKS,
  INBOX_ITEMS,
}

/**
 * Gets file name from extension name
 * @param {string} fileName
 * @returns {string}
 */
export const getFileExtension = (fileName?: string): string | undefined => fileName?.split('.').pop();

export const openInNewTabSupportedFiles: string[] = ['pdf', 'jpg', 'jpeg', 'mp3', 'wav', 'gif', 'png', 'htm', 'html'];

export const imageFiles: string[] = ['png', 'gif', 'jpeg', 'jpg', 'tiff'];
export const applicationFiles: string[] = ['pdf'];
export const audioFiles: string[] = ['mp3', 'wav'];

export const getFileType = (fileExtension?: string): 'audio' | 'image' | 'application' | undefined => {
  if (fileExtension) {
    if (imageFiles.includes(fileExtension)) {
      return 'image';
    }

    if (audioFiles.includes(fileExtension)) {
      return 'audio';
    }

    if (applicationFiles.includes(fileExtension)) {
      return 'application';
    }
  }
};

// escapes special chars in regex
export const regexEscape = (text: string = '') => text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');

export const onClickNotificationItem = (
  notification: Notification,
  dispatch: Dispatch<any>,
  navigate: NavigateFunction,
  callBack?: () => void,
) => {
  if (!notification.reviewed) {
    dispatch(markNotificationAsReadAction(notification));
  }

  navigate(notification.link);
  callBack && callBack();
};

export const referralTypeOptions = [
  {
    searchString: 'Referral',
    groupName: 'REFERRAL',
    label: 'Referral',
    value: 'Normal Referral',
    isActive: true,
  },
  {
    searchString: 'Drug Change',
    groupName: 'REFERRAL',
    label: 'Drug Change',
    value: 'Drug Change',
    isActive: true,
  },
  {
    searchString: 'Renewal',
    groupName: 'RENEWAL',
    label: 'Renewal',
    value: 'Normal Renewal',
    isActive: true,
  },
  {
    searchString: 'Dose Change',
    groupName: 'RENEWAL',
    label: 'Dose Change',
    value: 'Dose Change',
    isActive: true,
  },
];

export function getReferralTypeLabel(referralType: string) {
  return referralTypeOptions.find((x) => x.value === referralType)?.label;
}

export const getInboxItemFromInboxResults = (
  isArchivedPage: boolean,
  archivedInboxResults: InboxItem[],
  receivedInboxResults: InboxItem[],
  inboxId: string,
): InboxItem | undefined => {
  let inboxItem: InboxItem | undefined;
  const selectedCollection: InboxItem[] = isArchivedPage ? archivedInboxResults : receivedInboxResults;
  const unselectedCollection: InboxItem[] = isArchivedPage ? receivedInboxResults : archivedInboxResults;

  const getInboxItem = (inboxResult: InboxItem[], inboxId: string): InboxItem | undefined => {
    return inboxResult.find((inboxItem: InboxItem) => inboxItem.id === inboxId);
  };

  inboxItem = getInboxItem(selectedCollection, inboxId);

  return inboxItem ? inboxItem : getInboxItem(unselectedCollection, inboxId);
};

export const shouldDisplayTasksRow = (
  task: Task,
  currentStatus: 'ARCHIVE' | 'OPEN',
  openTaskStatuses?: any[],
  archiveTaskStatuses?: any[],
  tasksFilters?: TasksFilters,
): boolean => {
  if (!task?.active) {
    return false;
  }

  if (tasksFilters?.assignedUserIds?.length && !tasksFilters?.assignedUserIds.includes(task?.assignedUserId || '')) {
    return false;
  }

  if (tasksFilters?.taskTypeIds?.length && !tasksFilters.taskTypeIds.includes(task.taskTypeId || '')) {
    return false;
  }

  switch (currentStatus) {
    case taskListTabs.ARCHIVE:
      const archivedStatusIds: string[] = tasksFilters?.taskStatuses?.length
        ? tasksFilters.taskStatuses
        : getIdsFromArray(archiveTaskStatuses);
      return archivedStatusIds.length
        ? task?.statusId && archivedStatusIds.includes(task.statusId)
          ? true
          : false
        : true;
    case taskListTabs.OPEN:
      const openStatusIds: string[] = tasksFilters?.taskStatuses?.length
        ? tasksFilters?.taskStatuses
        : getIdsFromArray(openTaskStatuses);
      return openStatusIds.length ? (task?.statusId && openStatusIds.includes(task.statusId) ? true : false) : true;
    default:
      return true;
  }
};

export const tableColumnStyles: CSSProperties = {
  paddingTop: '12px !important',
  paddingBottom: '12px !important',
  flex: 1,
};

export const hasArrayDifference = (a: any[], b: any[]): boolean => {
  return difference(a, b).length !== 0 || difference(b, a).length !== 0;
};

// TODO: This currently works for only array diffs, implement other keys
export const shouldDispatchFilterAction = (filtersKeys: string[], previousFilters: any, newFilters: any): boolean => {
  let hasDifference: boolean = false;

  filtersKeys.forEach((key: string) => {
    if (hasArrayDifference(get(previousFilters, key, []), get(newFilters, key, []))) {
      hasDifference = true;
    }
  });

  return hasDifference;
};

export const getReferralStatusFromStringRepresentation = (
  representation: string,
  referralStatuses: referralStatuses,
): ReferralStatus | undefined =>
  referralStatuses?.data?.find((status: any) => {
    return status?.title?.toUpperCase() === representation?.toUpperCase();
  });

/**
 * @deprecated this utility is not being used as this data is now appended in the original api
 */
export const getAggregatedTaskListWithMoreDetails = (
  tasks: Task[],
  medications: medicationsState,
  patients: any,
): any[] => {
  const getMedicationForTask = (task: any, medications: any[]): any =>
    medications.find((medication: any) => medication?.id === task?.referral?.medicationId);
  const getPatientForTask = (task: any, patients: any[]): any =>
    patients.find((patient: any) => patient?.id === task?.referral?.patientId);

  const getUpdatedTasks = (tasks: Task[], medications: medicationsState, patients: any) => {
    return tasks.map((task: any) => ({
      ...task,
      referral: {
        ...task.referral,
        medication: getMedicationForTask(task, medications.data),
        patient: getPatientForTask(task, patients.data),
      },
    }));
  };

  return getUpdatedTasks(tasks, medications, patients);
};

export const getReferralStatusReasonTitle = (currentReferral: any): string => {
  switch (currentReferral?.status?.title?.toLowerCase()) {
    case referralStatusesDefaults.NOTCOMPLETED?.toLowerCase():
      return currentReferral?.statusReason?.title || currentReferral?.notCompletedReason;
    case referralStatusesDefaults.DISCARDED.toLowerCase():
      return currentReferral?.statusReason?.title || currentReferral?.canceledReason;
    case referralStatusesDefaults.ONHOLD?.toLowerCase():
      return currentReferral?.statusReason?.title || currentReferral?.onHoldReason;
    default:
      return '';
  }
};

export const saveUserPreferences = (
  pageName: UserPreferencePageName,
  fieldNames: string[],
  filters: any,
  dispatch: Dispatch<any>,
  clearFilters: boolean,
) => {
  let payload: any = {
    filters: [],
    sort: {},
  };

  if (clearFilters) {
    dispatch(updateUserPreferencesAction(pageName, payload));
    return;
  }

  fieldNames.forEach((filterName: string) => {
    const value =
      filterName !== 'providers'
        ? get(filters, filterName)
        : get(filters, filterName).map((provider: ReferralProvider) => {
            return Object.fromEntries(Object.entries(provider).filter(([key]) => !key.includes('label')));
          });

    if (value?.length) {
      payload = {
        ...payload,
        filters: [
          ...payload.filters,
          {
            field: filterName,
            value,
          },
        ],
      };
    }
  });

  dispatch(updateUserPreferencesAction(pageName, payload));
};

export const getFiltersFromSavedPreferences = (savedPreferences?: UserPreferenceFilterParam[]): any => {
  let filters = {};

  savedPreferences?.forEach((preference: UserPreferenceFilterParam) => {
    filters = { ...filters, [preference.field]: preference.value };
  });

  return filters;
};

export const canTransitionToReadyToSchedule = (task: Task): boolean => {
  const exemptTaskIds = [
    TASK_TYPE_ID.Scheduling,
    TASK_TYPE_ID['Upload Documents'],
    TASK_TYPE_ID['Specialty Pharmacy'],
    TASK_TYPE_ID['Weinfuse Entry - Allergies & Meds'],
    TASK_TYPE_ID['Specialty Pharmacy SQ'],
  ];
  const validTask = task.status?.name === 'COMPLETED' || task.status?.name === 'NOT APPLICABLE';
  return validTask || exemptTaskIds.includes(String(task.taskTypeId));
};

/**
 * @description  returns comma separated string semantically formatted from array of strings
 * @param {string[]} status
 * @returns {string}
 */
export const stringArrayToValidCommaSentence = (strings: string[]) => {
  const stringArr = strings.filter(Boolean);

  if (!stringArr.length) return '';
  if (stringArr.length === 1) return stringArr[0];
  const firsts = stringArr.slice(0, stringArr.length - 1);
  const last = stringArr[stringArr.length - 1];
  return firsts.join(', ') + ' and ' + last;
};

/**
 * @description  returns true if date provided is in the past
 * @param {string[]} date
 * @returns {boolean}
 */
export const isAfterDate = (date?: string): boolean => {
  const tomorrow = startOfTomorrow();
  const isInSameDay = isSameDay(new Date(), date ? ignoreTimezoneDate(date) : new Date());
  return isAfter(tomorrow, date ? ignoreTimezoneDate(date) : new Date()) && !isInSameDay;
};

export const getReferralOptions = (status: string, statusOptions: any[]) => {
  let referralStatusOptions: any[] = statusOptions;
  if (status === statuses.ACTIVE) {
    const archivedStatuses = [
      referralFilterStatusesOptions.notCompleted.toLowerCase(),
      referralFilterStatusesOptions.discarded.toLowerCase(),
      referralFilterStatusesOptions.scheduled.toLowerCase(),
    ];
    const activeReferralStatusOptions = statusOptions.map((option) => {
      return archivedStatuses?.includes(option?.label?.toLowerCase()) ? { ...option, disabled: true } : option;
    });
    referralStatusOptions = activeReferralStatusOptions;
  } else if (status === statuses.ARCHIVED) {
    const activeStatuses = [
      referralFilterStatusesOptions.draft.toLowerCase(),
      referralFilterStatusesOptions.onHold.toLowerCase(),
      referralFilterStatusesOptions.open.toLowerCase(),
      referralFilterStatusesOptions.readyToSchedule.toLowerCase(),
    ];
    referralStatusOptions = statusOptions.map((option) => {
      return activeStatuses?.includes(option?.label?.toLowerCase()) ? { ...option, disabled: true } : option;
    });
  }
  return referralStatusOptions;
};

/**
 * @description returns true if shouldShowNATasks is set to true and false if it's not
 * @returns {boolean}
 */
export const shouldDisplayNATasks = () => {
  const value = localStorage.getItem('shouldShowNATasks');
  return value?.toLowerCase() === 'true';
};
