import { makeStyles, withStyles } from '@mui/styles';
import { Chip, Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => {
  return {
    ellipsis: {
      borderRadius: '50%',
      minWidth: 24,
      minHeight: 24,
      background: 'red',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginLeft: 8,
    },
    box: {
      width: 24,
      height: 24,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },

    patientNameRightElements: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },

    colorPrimary: {
      '&.MuiChip-root': {
        backgroundColor: '#E3F2FD',
        color: theme.palette.primary.main,
      },
    },
    colorSecondary: {
      '&.MuiChip-root': {
        backgroundColor: '#F1F8E9',
        color: '#33691E',
      },
    },
    header: {
      padding: '24px 24px 0',
      position: 'relative',
      background: theme.palette.background.paper,
      paddingTop: 4,
    },
    table: {
      // background: '#F6F6F6'
    },
    headerName: {
      borderLeft: '2px solid #E0E0E0',
      paddingLeft: 8,
    },
    rowRoot: {
      // background: `${theme.palette.background.paper} !important`,
      borderBottom: 'none !important',
      minWidth: 100,
      width: 'fit-content',
    },
    stickyLeft: {
      borderBottom: 'none !important',
      background: `${theme.palette.background.paper}`,
      position: 'sticky',
      left: 0,
      minWidth: 278,
      padding: '0px !important',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      // display: 'table-cell',
      flexDirection: 'column',
      height: 1,
      // height: 56,
      transition: 'background-color 0.5s ease',
    },
    shadowLeft: {
      boxShadow: '2px 0 3px -1px rgba(0,0,0,0.36);',
      padding: 16,
      boxSizing: 'border-box',
      flex: 1,
      height: '100%',
      borderBottom: '1px solid #e0e0e0 !important',
      position: 'absolute',
      top: 0,
      bottom: 0,
      width: '100%',
    },
    shadowLeftHead: {
      boxShadow: '2px 0 3px -1px rgba(0,0,0,0.36);',
      padding: 16,
      background: `${theme.palette.background.paper}`,
      boxSizing: 'border-box',
      flex: 1,
      height: '100%',
      position: 'absolute',
      top: 0,
      bottom: 0,
      width: '100%',
      // borderBottom: '1px solid #e0e0e0 !important',
    },
    shadowRight: {
      boxShadow: '-2px 0 5px -2px rgba(0,0,0,0.36)',
      padding: 16,
      boxSizing: 'border-box',
      height: '100%',
      borderBottom: '1px solid #e0e0e0 !important',
      position: 'absolute',
      top: 0,
      bottom: 0,
      width: '100%',
    },
    stickyRight: {
      borderBottom: 'none !important',
      // boxShadow: '3px 10px 16px -2px rgba(0,0,0,0.36);',
      background: `${theme.palette.background.paper}`,
      position: 'sticky',
      right: 0,
      height: 1,
      padding: '0px !important',
      minWidth: 182,
      transition: 'background-color 0.5s ease',
    },
    stickyRightHead: {
      // height: '100%',
      borderBottom: '1px solid transparent !important',
      // boxShadow: '3px 10px 16px -2px rgba(0,0,0,0.36);',
      background: `${theme.palette.background.paper} !important`,
      position: 'sticky',
      right: 0,
      // height: 1,
      padding: '0px !important',
      minWidth: 182,
    },
    shadowRightHead: {
      boxShadow: '-2px 0 5px -2px rgba(0,0,0,0.36)',
      padding: 16,
      boxSizing: 'border-box',
      height: '100%',
      position: 'absolute',
      top: 0,
      bottom: 0,
      width: '100%',
      justifyContent: 'space-between',
    },
    drawer: {
      width: '50%',
      // paddingTop: 16
    },
    darkPrimary: {
      backgroundColor: `${theme.palette.primary.dark} !important`,
    },
    pageLabel: {
      marginBottom: 0,
    },
    noScroll: {
      overflowY: 'scroll',
      boxSizing: 'border-box',
      paddingBottom: 0,
      scrollbarWidth: 'none',
      '-ms-overflow-style': 'none',
      msOverflowStyle: 'none',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    menuLabel: {
      padding: '6px 16px',
    },
    popper: {
      background: 'transparent',
      marginBottom: '2px !important',
    },
    tooltip: {
      background: '#616161 !important',
      opacity: '0.9 !important',
      color: '#FFFFFF !important',
      fontSize: '14px !important',
    },
    row: {
      transition: 'background-color 0.5s ease',
      '&:hover': {
        background: '#0000000A',
        '& #stickyRight': {
          background: 'gainsboro',
        },
        '& #stickyLeft': {
          background: 'gainsboro',
        },
      },
    },
    chipLabel: {
      fontSize: '12px !important',
    },
    column: {
      paddingTop: '8px !important',
      paddingBottom: '8px !important',
      flex: 1,
    },
  };
});

export const PrimaryChip = withStyles((theme) => {
  return {};
})(Chip);
