import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => {
  return {
    dialogTitle: {
      fontWeight: 500,
      fontSize: 20,
    },
  };
});
