import { PriorAuthDenial, PriorAuthTaskServer, PriorAuthTaskState } from '../prior-auth-task-controller';
import { Task } from '../../../../redux/types';
import axios from 'axios';

export class HttpPriorAuthTaskServer implements PriorAuthTaskServer {
  constructor(private readonly baseUrl: string) {}

  async approve(taskId: Task['id']): Promise<PriorAuthTaskState> {
    const response = await axios.put(`${this.baseUrl}/tasks/${encodeURIComponent(taskId)}/prior-auth/approve`);
    return response.data;
  }

  async deny(taskId: Task['id']): Promise<PriorAuthDenial> {
    const response = await axios.put(`${this.baseUrl}/tasks/${encodeURIComponent(taskId)}/prior-auth/deny`);
    return response.data;
  }

  async getStatus(taskId: Task['id']): Promise<PriorAuthTaskState> {
    const response = await axios.get(`${this.baseUrl}/tasks/${encodeURIComponent(taskId)}/prior-auth`);
    return response.data;
  }

  async undo(taskId: Task['id']): Promise<PriorAuthTaskState> {
    const response = await axios.put(`${this.baseUrl}/tasks/${encodeURIComponent(taskId)}/prior-auth/undo`);
    return response.data;
  }
}
