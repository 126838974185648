import { useEffect, useState } from 'react';
import { Patient } from '@/redux/types';
import { MessageServer } from './message-server/message-server';
import { MessageTemplate, SendSmsController } from './send-sms-controller';
import { PatientSearchController } from './patient-search/patient-search-controller';
import { PhoneNumber } from 'libphonenumber-js';

export function FreeformSmsController(
  messageServer: MessageServer,
  usePatientSearch: ReturnType<typeof PatientSearchController>,
) {
  return function useFreeformSmsController() {
    const [state, setState] = useState({
      status: '',
      patient: undefined as Patient | undefined,
      templates: [] as MessageTemplate[],
    });
    const patientSearch = usePatientSearch(populateSelection);
    const sendSms = SendSmsController(messageServer)({
      onSendSuccess: () => {
        sendSms.phoneNumber.set('');
        patientSearch.reset();
      },
      templates: state.templates,
    });

    useEffect(() => loadTemplates(), []);

    return {
      warning: state.status,
      ...sendSms,
      patientSearch,
    };

    function populateSelection(patient?: Patient, phoneNumber?: PhoneNumber) {
      if (patient) {
        const patientPhoneNumber = patient.mobile_phone || patient.home_phone || '';
        setState((state) => ({
          ...state,
          patient,
        }));

        if (patientPhoneNumber) {
          sendSms.phoneNumber.set(patientPhoneNumber);
        } else {
          setState((state) => ({
            ...state,
            status: `No phone number registered for ${patient.full_name}`,
          }));
        }

        loadTemplates(patient.id);
      } else {
        setState((state) => ({
          ...state,
          patient: undefined as Patient | undefined,
          status: '',
        }));
        if (phoneNumber) {
          const formattedPhoneNumber = phoneNumber?.format('E.164');
          if (formattedPhoneNumber) {
            sendSms.phoneNumber.set(formattedPhoneNumber);
          }
        }
        loadTemplates();
      }
    }

    function loadTemplates(patientId?: Patient['id']) {
      messageServer.getTemplates({ patientId }).then((templates) => setState((state) => ({ ...state, templates })));
    }
  };
}
