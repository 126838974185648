import { Avatar, AvatarProps, Box, CircularProgress, IconButton, Tooltip } from '@mui/material';
import unassignedIconNewgrey from '../../images/unassignedIconNewgrey.svg';
import { FC } from 'react';

const colors = {
  pink: '#F48FB1',
  red: '#EF9A9A',
  deepOrange: '#FFAB91',
  amber: '#FFCA28',
  lightGreen: '#C5E1A5',
  green: '#A5D6A7',
  teal: '#80CBC4',
  cyan: '#80DEEA',
  lightBlue: '#81D4FA',
  blue: '#90CAF9',
  indigo: '#9FA8DA',
  deepPurple: '#B39DDB',
  purple: ' #CE93D8',
  blueGray: '#B0BEC5',
};

const stringToColor = (name: string) => {
  const alphabet: any = {
    A: colors.pink,
    B: colors.red,
    C: colors.deepOrange,
    D: colors.amber,
    E: colors.lightGreen,
    F: colors.green,
    G: colors.teal,
    H: colors.cyan,
    I: colors.lightBlue,
    J: colors.blue,
    K: colors.indigo,
    L: colors.deepPurple,
    M: colors.purple,
    N: colors.blueGray,
    O: colors.pink,
    P: colors.red,
    Q: colors.deepOrange,
    R: colors.amber,
    S: colors.lightGreen,
    T: colors.green,
    U: colors.teal,
    V: colors.cyan,
    W: colors.lightBlue,
    X: colors.blue,
    Y: colors.indigo,
    Z: colors.deepPurple,
  };

  let firstNameCharacter: string = name?.split(' ')[0][0];

  firstNameCharacter = firstNameCharacter ? firstNameCharacter : 'X';

  return alphabet[firstNameCharacter];
};

const stringAvatar = (name: string) => {
  const hasTwoNames = name?.split(' ').length > 1;
  if ((!hasTwoNames && name?.toLowerCase() === 'unassigned') || name === '- -' || !name) {
    return {
      src: unassignedIconNewgrey,
    };
  }

  return {
    sx: {
      bgcolor: stringToColor(name),
      backgroundColor: stringToColor(name),
      width: 32,
      height: 32,
    },
    children: hasTwoNames ? `${name?.split(' ')[0][0]}${name?.split(' ')[1][0]}` : `${name?.split(' ')[0][0]}`,
  };
};

interface Props extends AvatarProps {
  name: string;
  loading?: boolean;
  style?: any;
  hideIconWrapper?: boolean;
  tooltipTitle?: string;
}

const IconWrapper: FC<any> = ({ wrap, children, style, hideIconWrapper }) => {
  if (hideIconWrapper) {
    return <>{children}</>;
  }

  if (wrap) {
    return <IconButton style={style}>{children}</IconButton>;
  }
  return <Box style={{ ...style, padding: 8 }}>{children}</Box>;
};

const OptionalTooltip: FC<any> = ({ children, title }) => {
  return title ? <Tooltip title={title}>{children}</Tooltip> : <>{children}</>;
};

const CustomAvatar = (props: Props) => {
  const { name, loading, style = {}, hideIconWrapper, tooltipTitle, ...restProps } = props;
  const { margin, marginLeft, marginRight, ...styles } = style;
  return (
    <IconWrapper
      wrap={stringAvatar(name).src}
      style={{ margin, marginLeft, marginRight }}
      hideIconWrapper={hideIconWrapper}
    >
      {loading ? (
        <Avatar
          style={{
            fontSize: 14,
            width: 32,
            height: 32,
            textAlign: 'center',
            fontWeight: 500,
            ...styles,
          }}
        >
          <CircularProgress size={12} />
        </Avatar>
      ) : (
        <OptionalTooltip title={tooltipTitle}>
          <Avatar
            alt={name}
            {...stringAvatar(name)}
            {...restProps}
            style={{
              fontSize: 14,
              width: stringAvatar(name).src ? (name?.toLowerCase() === 'unassigned' ? 32 : 20) : 32,
              height: stringAvatar(name).src ? (name?.toLowerCase() === 'unassigned' ? 32 : 20) : 32,
              textAlign: 'center',
              fontWeight: 500,
              ...styles,
            }}
          />
        </OptionalTooltip>
      )}
    </IconWrapper>
  );
};

export default CustomAvatar;
