import { BaseAction, TaskTemplatesState } from '../types';
import {
  GET_TASK_TEMPLATES,
  GET_TASK_TEMPLATES_SUCCESS,
  GET_TASK_TEMPLATES_ERROR,
  UPDATE_TASK_STATUS_LIST,
} from '../constants/referralTypes';
import { sortByNameKeyCompareFunction } from '../../globalUtils/helpers';

const initialState: TaskTemplatesState = {
  loading: false,
  data: [],
  error: null,
  taskStatuses: [],
  archivedTabTaskStatuses: [],
  openTabTaskStatuses: [],
};

const reducer = (state: any = initialState, action: BaseAction) => {
  switch (action.type) {
    case GET_TASK_TEMPLATES:
      return {
        ...state,
        loading: action.payload,
      };
    case UPDATE_TASK_STATUS_LIST:
      return {
        ...state,
        taskStatuses: action.payload,
        archivedTabTaskStatuses: action.payload.filter(
          (taskStatus: any) =>
            taskStatus.name.toLowerCase() === 'completed' || taskStatus.name.toLowerCase() === 'not applicable',
        ),
        openTabTaskStatuses: action.payload.filter(
          (taskStatus: any) =>
            taskStatus.name.toLowerCase() !== 'completed' && taskStatus.name.toLowerCase() !== 'not applicable',
        ),
      };
    case GET_TASK_TEMPLATES_SUCCESS:
      return {
        ...state,
        data: action.payload.sort(sortByNameKeyCompareFunction),
      };
    case GET_TASK_TEMPLATES_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
