import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => {
  return {
    emptyStateContainer: {
      height: 376,
      background: '#FFFFFF',
      width: '100%',
    },
    iconContainer: {
      width: '100%',
    },
    informationContainer: {
      paddingTop: 40,
      width: '100%',
    },
    informationText: {
      fontWeight: 500,
      fontSize: 20,
      color: '#00000061',
    },
  };
});

export { useStyles };
