import { TextField as MuiTextField, TextFieldProps } from '@mui/material';
import { FC, useState, useRef, useEffect, FocusEvent } from 'react';

const TextField: FC<TextFieldProps> = ({ onFocus, onBlur, sx, ...props }) => {
  const [focus, setFocus] = useState(false);
  const inputRef = useRef<any>(null);

  const handleFocus = (e: FocusEvent<any, any>) => {
    setFocus(true);
    onFocus && onFocus(e);
  };

  const handleBlur = (e: FocusEvent<any, any>) => {
    setFocus(false);
    onBlur && onBlur(e);
  };

  useEffect(() => {
    if (focus) {
      inputRef.current.focus();
    }
  }, [focus]);

  return (
    <MuiTextField
      multiline={focus}
      {...props}
      sx={{
        '& .MuiInputBase-input': {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
        ...sx,
      }}
      inputRef={inputRef}
      onFocus={handleFocus}
      onBlur={handleBlur}
    />
  );
};

export default TextField;
