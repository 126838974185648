import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => {
  return {
    notificationsContainer: {
      paddingTop: 16,
      height: 'calc(100vh - 130px)',
      overflow: 'hidden',
      width: 560,
    },
    notificationTitleContainer: {
      paddingBottom: 16,
      paddingLeft: 16,
      paddingRight: 16,
    },
    notificationTitleText: {
      fontWeight: '400 !important',
      fontSize: '24px !important',
      color: '#000000DE',
    },
    avatarContainer: {
      paddingRight: 16,
    },
    notificationItemContainer: {
      minHeight: 70,
      paddingLeft: 16,
      paddingRight: 16,
      paddingTop: 16,
      paddingBottom: 0,
      borderBottom: '1px solid #00000014',
      width: '100%',
      cursor: 'pointer',
      transition: 'background-color 0.5s ease',
      '&:hover': {
        background: '#f7f7f7',
      },
    },
    itemTitleText: {
      color: '#000000 !important',
      fontWeight: '500 !important',
      fontSize: '14px !important',
    },
    itemTitle: {
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    timeStampText: {
      color: '#848484',
      fontWeight: '400 !important',
      fontSize: '12px !important',
    },
    primaryNotificationIcon: {
      width: 16,
      height: 16,
      background: '#5D5FEF',
      borderRadius: 4,
      color: '#5D5FEF',
      marginTop: 4,
    },
    ghostNotificationIcon: {
      width: 16,
      height: 16,
      background: '#FFFFFF',
      borderRadius: 4,
      color: '#FFFFFF',
      marginTop: 2,
      border: '1px solid #5D5FEF',
    },
    defaultNotificationIcon: {
      width: 16,
      height: 16,
      background: '#C4C4C4',
      borderRadius: 4,
      color: '#C4C4C4',
      marginTop: 4,
    },
    unreadIConContainer: {
      width: 24,
      height: 24,
      background: '#FAFAFA',
      cursor: 'pointer',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '50%',
      transition: 'background-color 0.5s ease',
      '&:hover': {
        background: '#BBDEFB',
      },
    },
    readIconContainer: {
      width: 24,
      height: 24,
      cursor: 'pointer',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '50%',
      transition: 'background-color 0.5s ease',
      border: '1px solid #BDBDBD',
    },
    unreadNotificationIcon: {
      background: '#5D5FEF',
      color: '#5D5FEF',
      width: 8,
      height: 8,
      borderRadius: '50%',
    },
    unreadNotificationIconContainer: {
      paddingTop: 20,
    },
    notificationContentContainer: {
      width: '100%',
      paddingRight: 31,
    },
    notificationItemTitleContainer: {
      width: 445,
    },
    notificationMessageContainer: {
      width: 400,
    },
    notificationMessage: {
      fontWeight: '400 !important',
      fontSize: '14px !important',
      color: '#00000099',
      width: 400,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis !important',
      paddingBottom: 12,
    },
    notificationsListContainer: {
      overflowX: 'hidden',
      height: '100%',
    },
    notificationIconsContainer: {
      width: 40,
      height: 40,
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '50%',
    },
    innerIconContainer: {
      width: 32,
      height: 32,
      borderRadius: '50%',
      alignItems: 'center',
      justifyContent: 'center',
    },
    innerIcon: {
      width: 20,
      height: 20,
    },
    markAllNotificationsAsReadSpinnerContainer: {
      paddingLeft: 8,
    },
    loaderIconContaienr: {
      width: 24,
      height: 24,
    },
    emptyNotificationsContainer: {
      widh: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      flexDirection: 'column',
      color: '#00000061',
    },
  };
});
