import { Box, Divider, FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import InputMask from 'react-input-mask';
import { MedicalRequestFormValues } from '../ReferralView/ReferralViewTypes';
import { useStyles } from './MedicalRecordsRequest';
interface Props {
  onChange: (value: Partial<MedicalRequestFormValues>) => void;
  values: Partial<MedicalRequestFormValues>;
}

const radioSx = {
  '& .MuiSvgIcon-root': {
    fontSize: 28,
  },
};

const MedicalRecordsRequestForm = (props: Props): JSX.Element => {
  const classes = useStyles();
  const { onChange, values } = props;

  const [isFaxFieldValid, setIsFaxFieldValid] = useState(true);

  const onBlurFaxField = () => {
    setIsFaxFieldValid(values.faxNumber?.replace(/-/g, '')?.length === 10);
  };

  const handleChange = (e: any) => {
    onChange({ [e.target.name]: e.target.value });
  };

  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid item xs={6}>
        <TextField
          fullWidth
          name="providerName"
          value={values.providerName}
          onChange={handleChange}
          size="small"
          id="standard-basic"
          label={'To*'}
          variant="filled"
          inputProps={{ maxLength: 255 }}
        />
      </Grid>
      <Grid item xs={6}>
        <InputMask
          maskPlaceholder=""
          mask="999-999-9999999999999"
          onChange={handleChange}
          onBlur={onBlurFaxField}
          value={values.faxNumber}
          disabled={false}
        >
          <TextField
            fullWidth
            error={!isFaxFieldValid}
            size="small"
            name="faxNumber"
            value={values.faxNumber}
            id="standard-basic"
            label={'Fax*'}
            variant="filled"
            helperText={!isFaxFieldValid && 'Fax format is incorrect please try again'}
          />
        </InputMask>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <TextField
          multiline
          fullWidth
          value={values.coverPageComments}
          onChange={handleChange}
          size="small"
          id="standard-basic"
          name="coverPageComments"
          label={'Cover Page Comments'}
          variant="filled"
          inputProps={{
            maxLength: 750,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" alignItems="center">
          <Typography>Request Attempts*</Typography>
          <RadioGroup
            row
            onChange={handleChange}
            aria-labelledby="demo-radio-buttons-group-label"
            value={values.requestAttempt}
            name="requestAttempt"
            style={{ marginLeft: 16 }}
          >
            <FormControlLabel value="1st Request" control={<Radio sx={radioSx} />} label="1st" />
            <FormControlLabel value="2nd Request" control={<Radio sx={radioSx} />} label="2nd" />
            <FormControlLabel value="3rd Request" control={<Radio sx={radioSx} />} label="3rd" />
            <FormControlLabel value="Other:" control={<Radio sx={radioSx} />} label="Other" />
          </RadioGroup>
        </Box>
      </Grid>
      {values.requestAttempt === 'Other:' && (
        <Grid item xs={12}>
          <TextField
            multiline
            fullWidth
            value={values.attemptsComments}
            name="attemptsComments"
            onChange={handleChange}
            size="small"
            id="standard-basic"
            label={'Enter comments regarding request attempts'}
            variant="filled"
            inputProps={{
              maxLength: 80,
            }}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default MedicalRecordsRequestForm;
