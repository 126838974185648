import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => {
  return {
    editCommentsContainer: {
      paddingLeft: 12,
      paddingRight: 24,
      border: '1px solid #00000061',
      borderRadius: 4,
      paddingTop: 12,
      paddingBottom: 20,
    },
    avatarContainer: {
      paddingBottom: 16,
    },
    commentActionsContainer: {
      paddingLeft: 12,
    },
    commentInputContainer: {
      paddingLeft: 12,
    },
  };
});
