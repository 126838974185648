import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EditCommentPayload } from '../../api/commentsApi';
import { CommentTypes } from '../../globalUtils/constants';
import { userFriendlyTimestamp } from '../../globalUtils/utils';
import { editCommentAction, setCommentBeingEdited } from '../../redux/actions/commentActions';
import { getOneReferral } from '../../redux/actions/referralsActions';
import { Comment, RootState } from '../../redux/types';
import CustomAvatar from '../Avatar/Avatar';
import { CommentActions } from '../CommentsViewComponent/CommentsViewComponent';
import MentionComponent from '../MentionComponent/MentionComponent';
import { useStyles } from './EditCommentStyles';

interface Props {
  comment: Comment;
}

const EditComment: React.FC<Props> = (props) => {
  const { comment } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [clearDefaultValue, setClearDefaultValue] = useState<boolean>(false);
  const [publishToEMRChosen, setPublishToEMRChosen] = useState<boolean>(false);
  const [contactedChosen, setContactedChosen] = useState<boolean>(false);
  const [followedUpChosen, setFollowUpChosen] = useState<boolean>(false);
  const {
    comments: {
      editCommentStore: { comment: commentBeingEdited, loading: editCommentLoading },
    },
  } = useSelector((state: RootState) => state);

  const onContactedButtonClick = () => {
    if (!commentBeingEdited?.patientContactTag && !editCommentLoading) {
      setContactedChosen(!contactedChosen);
    }
  };

  const onFollowUpButtonClick = () => {
    if (!commentBeingEdited?.referralFollowupTag && !editCommentLoading) {
      setFollowUpChosen(!followedUpChosen);
    }
  };

  const onEditCommentSuccess = useCallback(
    (responseData?: any) => {
      comment?.referralId && dispatch(getOneReferral(comment?.referralId, true));
      setPublishToEMRChosen(false);
    },
    [comment?.referralId, dispatch],
  );

  const onChange = (value: string, mentions: string[]) => {
    setClearDefaultValue(true);
  };

  const getUpdatedCommentToBeEdited = useCallback(
    (value: string, mentions: any[]): EditCommentPayload | undefined => {
      if (commentBeingEdited) {
        let updatedMessageProperties: EditCommentPayload = {
          message: value,
          type: publishToEMRChosen ? CommentTypes.EXTERNAL : commentBeingEdited.type || CommentTypes.INTERNAL,
        };

        if (mentions.length || commentBeingEdited?.tagUsers?.length) {
          updatedMessageProperties = {
            ...updatedMessageProperties,
            tagUserIds: [...mentions, ...commentBeingEdited.tagUsers].map((mentionedUser: any) => mentionedUser?.id),
          };
        }

        if (contactedChosen || commentBeingEdited?.patientContactTag) {
          updatedMessageProperties = {
            ...updatedMessageProperties,
            patientContactTag: true,
          };
        }

        if (followedUpChosen || commentBeingEdited?.referralFollowupTag) {
          updatedMessageProperties = {
            ...updatedMessageProperties,
            referralFollowupTag: true,
          };
        }

        return updatedMessageProperties;
      }

      return undefined;
    },
    [commentBeingEdited, contactedChosen, followedUpChosen, publishToEMRChosen],
  );

  const onSubmitEditChange = useCallback(
    (value: string, mentions: any[]) => {
      const onEditCommentRequestCompleted = (data?: any): void => {
        dispatch(setCommentBeingEdited(undefined));
        setClearDefaultValue(false);
        setPublishToEMRChosen(false);
        setContactedChosen(false);
        setFollowUpChosen(false);
      };

      const updatedCommentProperties: EditCommentPayload | undefined = getUpdatedCommentToBeEdited(value, mentions);

      if (commentBeingEdited && updatedCommentProperties) {
        dispatch(
          editCommentAction(
            commentBeingEdited?.id,
            updatedCommentProperties,
            (data: any) => {
              onEditCommentRequestCompleted(data);
              onEditCommentSuccess(data);
            },
            (error: any) => {
              onEditCommentRequestCompleted(error);
            },
          ),
        );
      }
    },
    [commentBeingEdited, dispatch, getUpdatedCommentToBeEdited, onEditCommentSuccess],
  );

  const onCancel = () => {
    setClearDefaultValue(false);
    setPublishToEMRChosen(false);
    setContactedChosen(false);
    setFollowUpChosen(false);
    dispatch(setCommentBeingEdited(undefined));
  };

  useEffect(() => {
    if (commentBeingEdited?.type === CommentTypes.EXTERNAL) {
      setPublishToEMRChosen(true);
    }

    if (commentBeingEdited?.patientContactTag) {
      setContactedChosen(true);
    }

    if (commentBeingEdited?.referralFollowupTag) {
      setFollowUpChosen(true);
    }
  }, [commentBeingEdited]);

  return (
    <Box className={classes.editCommentsContainer}>
      <Box display="flex" className={classes.avatarContainer}>
        <CustomAvatar name={`${comment?.user?.firstName || ''} ${comment?.user?.lastName || ''}`} />
        <Box display="flex" alignItems="center">
          <Typography variant="subtitle2">{`${comment.user.firstName} ${comment.user.lastName}`}</Typography>
          <Typography style={{ marginLeft: 16 }} variant="caption" color="textSecondary">
            {userFriendlyTimestamp(comment.updatedAt, { persistTimezone: true })}
          </Typography>
        </Box>
      </Box>
      <Box className={classes.commentActionsContainer}>
        <CommentActions
          isTopLevel
          contactedChosen={contactedChosen}
          followedUpChosen={followedUpChosen}
          onContactedButtonClick={onContactedButtonClick}
          onFollowUpButtonClick={onFollowUpButtonClick}
        />
      </Box>
      <Box className={classes.commentInputContainer}>
        <MentionComponent
          onSend={onSubmitEditChange}
          placeholder={'Type Comment...'}
          id={comment?.id}
          loading={editCommentLoading}
          isExpanded
          publishToEMRSelected={publishToEMRChosen}
          onSelectPublishToEMR={() => {
            setPublishToEMRChosen(!publishToEMRChosen);
          }}
          defaultValue={!clearDefaultValue ? commentBeingEdited?.message || '' : ''}
          onChange={onChange}
          disablePublishToEMR={editCommentLoading || commentBeingEdited?.type === CommentTypes.EXTERNAL}
          enableCancelButton
          onCancelButtonClick={onCancel}
          submitButtonLabel={'SAVE'}
        />
      </Box>
    </Box>
  );
};

export default EditComment;
