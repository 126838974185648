import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import routes, { RoutesInterface } from './routes';
import { AuthHOC } from '../components';

const AppRoutes: FC = () => {
  return (
    <Routes>
      {routes.map(({ path, Component, isAuthenticated }: RoutesInterface, index: number) => (
        <Route key={index} path={path} element={isAuthenticated ? AuthHOC(Component) : <Component />} />
      ))}
    </Routes>
  );
};

export default AppRoutes;
