import { FreeformSmsController } from './freeform-sms-controller';
import { HttpMessageServer } from './message-server/http-message-server';
import { PatientSearchController } from './patient-search/patient-search-controller';
import { ServerPatientSource } from './patient-search/source/server-patient-source';
import { SmsHistoryController } from './sms-history-controller';
import { SendSmsController } from './send-sms-controller';
import { PatientMessagesController } from './patient-messages/patient-messages-controller';
import { ChatController } from './dashboard/chat-controller';
import { DashboardController } from './dashboard/dashboard-controller';
import { createEvent } from 'react-event-hook';
import { Patient } from '@/redux/types';
import { TextMessage } from '@features/Omnichannel/text-message';
import { PhoneNumber } from 'libphonenumber-js';
import { E164Number } from 'libphonenumber-js/types';

export const messageServer = HttpMessageServer(process.env.REACT_APP_API_URL || '');
const patientSource = ServerPatientSource();

export const usePatientSearchController = PatientSearchController(patientSource);
export const useFreeformSmsController = FreeformSmsController(messageServer, usePatientSearchController);
export const useSmsHistoryController = SmsHistoryController(messageServer);
export const useSendSmsController = SendSmsController(messageServer);
export const usePatientMessagesController = PatientMessagesController(messageServer, useSendSmsController);
export const useChatController = ChatController(messageServer);
export const useMessagingDashboardController = DashboardController();

export const isOmniChannelEnabled = true;

export interface MessageSubmittedEvent {
  phoneNumber: E164Number;
  patient?: Patient;
  message: TextMessage;
}

export interface ParticipantSelectedFromComposeDialogEvent {
  phoneNumber: PhoneNumber;
  patient?: Patient;
}

const { emitNewMessageSubmittedFromChat, useNewMessageSubmittedFromChatListener } =
  createEvent('NewMessageSubmittedFromChat')<MessageSubmittedEvent>();

const { emitParticipantSelectedFromComposeDialogEvent, useParticipantSelectedFromComposeDialogEventListener } =
  createEvent('ParticipantSelectedFromComposeDialogEvent')<ParticipantSelectedFromComposeDialogEvent>();

export const events = {
  emitNewMessageSubmittedFromChat,
  useNewMessageSubmittedFromChatListener,
  emitParticipantSelectedFromComposeDialogEvent,
  useParticipantSelectedFromComposeDialogEventListener,
};
