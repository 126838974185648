import { FC } from 'react';
import { Chip, ChipProps } from '@mui/material';
import { useStyles } from './CustomChipStyles';

interface CustomChipProps extends ChipProps {
  background?: string;
  textColor?: string;
  icon?: any;
}

const CustomChip: FC<CustomChipProps> = (props: CustomChipProps) => {
  const { classes, background, textColor, sx, ...restProps } = props;
  const classNames = useStyles();
  return (
    <Chip
      size="small"
      className={classNames.chip}
      classes={{
        colorPrimary: classNames.colorPrimary,
        colorSecondary: classNames.colorSecondary,
        ...classes,
      }}
      sx={{
        background,
        color: textColor,
        ...sx,
      }}
      {...restProps}
    />
  );
};

export default CustomChip;
