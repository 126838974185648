import { Dispatch } from 'redux';
import {
  getItemReasonsApi,
  getMedicalRequestPreviewApi,
  sendMedicalRecordsFaxApi,
  resendMedicalRecordsFaxWithoutEditsApi,
  resendMedicalRecordsFaxWithEditsApi,
} from '../../api/outgoingFilesApi';
import { showErrorMessage } from '../../components/NotificationToast/NotificationToast';
import { MedicalRequestFormValues } from '../../components/ReferralView/ReferralViewTypes';
import backendErrorHandler from '../../globalUtils/backendErrorHandler';
import { addAttachmentSuccess } from '../actionCreators/referralActionCreators';
import format from 'date-fns/format';
import {
  CREATE_MEDICAL_REQUEST,
  CREATE_MEDICAL_REQUEST_ERROR,
  CREATE_MEDICAL_REQUEST_SUCCESS,
  MEDICAL_REQUEST_OPTIONS,
  MEDICAL_REQUEST_OPTIONS_SUCCESS,
  MEDICAL_REQUEST_OPTIONS_ERROR,
  SEND_MEDICAL_REQUEST_FAX_SUCCESS,
  SEND_MEDICAL_REQUEST_FAX_ERROR,
  SEND_MEDICAL_REQUEST_FAX,
} from '../constants/faxTypes';

import { isEqual } from 'lodash';

const createMedicalRecordLoading = () => ({ type: CREATE_MEDICAL_REQUEST });
const createMedicalRecordSuccess = (payload: any) => ({ type: CREATE_MEDICAL_REQUEST_SUCCESS, payload });
const createMedicalRecordError = (payload: any) => ({ type: CREATE_MEDICAL_REQUEST_ERROR, payload });

const getMedicalRecordReasonsLoading = () => ({ type: MEDICAL_REQUEST_OPTIONS });
const getMedicalRecordReasonsSuccess = (payload: any) => ({ type: MEDICAL_REQUEST_OPTIONS_SUCCESS, payload });
const getMedicalRecordReasonsError = (payload: any) => ({ type: MEDICAL_REQUEST_OPTIONS_ERROR, payload });

const sendMedicalRecordFaxLoading = () => ({ type: SEND_MEDICAL_REQUEST_FAX });
const sendMedicalRecordFaxSuccess = (payload: any) => ({ type: SEND_MEDICAL_REQUEST_FAX_SUCCESS, payload });
const sendMedicalRecordFaxError = (payload: any) => ({ type: SEND_MEDICAL_REQUEST_FAX_ERROR, payload });

const processData = (values: MedicalRequestFormValues): any => {
  const data = {
    referralId: values.referralId,
    provider_name: values.providerName,
    fax_number: values.faxNumber?.replace(/-/g, ''),
    current_date: values.currentDate,
    // requested_docs:
    faxId: values.faxId,
    cover_page_comments: values.coverPageComments,
    request_attempt: values.requestAttempt,
    attempt_comment: values.requestAttempt === 'Other:' ? values.attemptsComments?.trim() : '',
    requested_items: values.requestedItems?.map((item) => ({
      items_list: item.itemRequested,
      comments: item.comments,
      reasons_list: item.reasons,
    })),
  };
  return data;
};

export const serializeFormData = (formData: any) => {
  const data: MedicalRequestFormValues = {
    coverPageComments: formData.cover_page_comments,
    faxNumber: formData.fax_number,
    attemptsComments: formData.attempt_comment || '',
    providerName: formData.provider_name,
    requestAttempt: formData.request_attempt,
    referralId: formData.referralId,
    faxId: formData.faxId,
    requestedItems: [
      {
        itemRequested: formData.requested_1_items,
        comments: formData.requested_1_comments || '',
        reasons: formData.requested_1_reasons?.split(/\s+\|\s+/) || [],
      },
      {
        itemRequested: formData.requested_2_items,
        comments: formData.requested_2_comments || '',
        reasons: formData.requested_2_reasons?.split(/\s+\|\s+/) || [],
      },
      {
        itemRequested: formData.requested_3_items,
        comments: formData.requested_3_comments || '',
        reasons: formData.requested_3_reasons?.split(/\s+\|\s+/) || [],
      },
    ].filter(({ itemRequested }) => Boolean(itemRequested)),
  };
  return data;
};
export const getMedicalRecordPreviewAction =
  (values: MedicalRequestFormValues, onSuccess?: (res: any) => void, onError?: (res?: any) => void) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(createMedicalRecordLoading());
      const { faxId, ...data } = processData(values);
      const response = await getMedicalRequestPreviewApi({ ...data, current_date: format(new Date(), 'yyyy-MM-dd') });
      if (onSuccess) {
        onSuccess(response.data);
      }
      dispatch(createMedicalRecordSuccess(response.data));
    } catch (e) {
      const errorMessage: string = backendErrorHandler(e);
      if (onError) {
        onError();
      }
      showErrorMessage(errorMessage);
      dispatch(createMedicalRecordError(e));
    }
  };

export const getMedicalRecordsRequestOptions = () => async (dispatch: Dispatch) => {
  try {
    dispatch(getMedicalRecordReasonsLoading());
    const { data } = await getItemReasonsApi();
    dispatch(getMedicalRecordReasonsSuccess(data));
  } catch (error) {
    dispatch(getMedicalRecordReasonsError(error));
  }
};

export const sendMedicalRecordsRequestFax =
  (values: MedicalRequestFormValues, onSuccess?: (data: any) => void, onError?: (error: any) => void) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(sendMedicalRecordFaxLoading());
      const data = processData(values);
      const response = await sendMedicalRecordsFaxApi({
        ...data,
        current_date: format(new Date(), 'yyyy-MM-dd'),
      });
      //  here
      dispatch(sendMedicalRecordFaxSuccess({ ...response.data }));
      dispatch(addAttachmentSuccess({ ...response.data, referralId: data.referralId }));
      onSuccess && onSuccess(response.data);
    } catch (error) {
      const errorMessage = backendErrorHandler(error);
      showErrorMessage(errorMessage);
      dispatch(sendMedicalRecordFaxError(error));
      onError && onError(error);
    }
  };

export const resendMedicalRecordsRequestFax =
  (
    values: MedicalRequestFormValues,
    originalData: Partial<MedicalRequestFormValues>,
    onSuccess?: (data: any) => void,
    onError?: (error: any) => void,
  ) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(sendMedicalRecordFaxLoading());
      const data = processData(values);
      const response = isEqual(values, originalData)
        ? await resendMedicalRecordsFaxWithoutEditsApi(data) // RM-1459
        : await resendMedicalRecordsFaxWithEditsApi({ ...data, current_date: format(new Date(), 'yyyy-MM-dd') }); // RM-1456
      dispatch(sendMedicalRecordFaxSuccess({ ...response.data }));
      dispatch(addAttachmentSuccess({ ...response.data, referralId: data.referralId, faxId: data.faxId }));
      onSuccess && onSuccess(response.data);
    } catch (error) {
      const errorMessage = backendErrorHandler(error);
      showErrorMessage(errorMessage);
      dispatch(sendMedicalRecordFaxError(error));
      onError && onError(error);
    }
  };
