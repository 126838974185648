import React, { ChangeEvent, KeyboardEvent, FC, useCallback, useEffect, useRef, useState } from 'react';
import {
  Badge,
  Box,
  Chip,
  CircularProgress,
  IconButton,
  Menu,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { useStyles } from './ReferralStyles';
import {
  getIdsFromArray,
  getReferralsStatusColors,
  getReferralTypeToRender,
  hasArrayDifference,
  ignoreTimezoneDate,
  referralTypeOptions,
  shouldDispatchFilterAction,
  tableColumnStyles,
  Timer,
  isAfterDate,
  getReferralOptions,
  userFriendlyTimestamp,
  getTaskColors,
} from '../../globalUtils/utils';
import {
  useGetAssigneeOptions,
  useGetMedicationsOptions,
  useGetReferralStatusesOptions,
  useProviderHooks,
  useScrollHooks,
} from '../../globalUtils/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { ReferralFilters, RootState } from '../../redux/types';
import { fetchActiveAndArchivedReferralsAction, getReferralsCountsAction } from '../../redux/actions/referralsActions';
import MenuItemSearchComponent from '../../components/MenuItemSearchComponent/MenuItemSearchComponent';
import { updateReferralFilters } from '../../redux/actions/referralProvidersAction';
import CustomChip from '../../components/CustomChip/CustomChip';
import Avatar from '../../components/Avatar/Avatar';
import { Search } from '../../components/Search';
import { format } from 'date-fns';
import ellipsis from '../../images/arrowUp.svg';
import faPaperClip from '../../images/faPaperClip.svg';
import { getClinicsAction } from '../../redux/actions/clinicActions';
import { DateRangePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { statuses } from './Referrals';
import ReferralsSkeletonLoader, { ReferralsRowsSkeleton } from './ReferralsSkeletonLoader';
import {
  setArchivedReferralsGlobalLoading,
  setActiveReferralsGlobalLoading,
} from '../../redux/actionCreators/referralActionCreators';
import EmptyResults from '../../components/EmptyResults/EmptyResults';
import EmptyReferralResultsIcon from '../../images/emptyReferralsResults.svg';
import TableHeader from '../../components/TableHeader/TableHeader';
import { InputBase } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AnimatedLoading from '../../components/AnimatedLoading/AnimatedLoading';
import NonScrollableMenu from '../../components/Menu/NonScrollableMenu';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { useSearchParams } from 'react-router-dom';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import { startCase, toLower } from 'lodash';

interface Props {
  data: any[];
  onSelectRow: (row: any) => void;
  status: string;
  hideHighlights?: boolean;
  globalLoading?: boolean;
  hasFetchedSavedFilters: boolean;
}

const ReferralsTable: FC<Props> = (props) => {
  const { data, onSelectRow, status, hideHighlights, globalLoading, hasFetchedSavedFilters } = props;
  const classes = useStyles();
  const {
    medications: { data: medicationsData },
    referrals: {
      filters,
      referralStatuses: { archivedStatuses, activeStatuses },
      archivedReferralsList: { page: archivedReferralsPage, loading: archivedLoading, hasMore: archivedHasMore },
      activeReferralsList: { page: activeReferralsPage, loading: activeLoading, hasMore: activeHasMore },
      editReferral: { loading: editReferralLoading, referralId: idOfReferralBeingEdited },
    },
  } = useSelector((state: RootState) => state);
  const [assigneeFilterMenuAnchorElement, setAssigneeFilterMenuAnchorElement] = React.useState<null | HTMLElement>(
    null,
  );
  const [intakeSpecialistAnchorElement, setIntakeSpecialistMenuAnchorElement] = React.useState<null | HTMLElement>(
    null,
  );
  const [medicationFilterAnchorElement, setMedicationFilterAnchorElement] = useState<null | HTMLElement>(null);
  const [dateOfBirthAnchorElement, setDateOfBirthAnchorElement] = useState<null | HTMLElement>(null);
  const [nextFollowUpFilterAnchor, setNextFollowUpFilterAnchor] = useState<boolean>(false);
  const [createdAtFilterAnchor, setCreatedAtFilterAnchor] = useState<boolean>(false);
  const [providerFilterAnchorElement, setProviderFilterMenuAnchorElement] = useState<null | HTMLElement>(null);
  const [insuranceFilterAnchorElement, setInsuranceFilterAnchorElement] = useState<null | HTMLElement>(null);
  const [referralStatusesFilterAnchorElement, setReferralStatusesFilterAnchorElement] = useState<null | HTMLElement>(
    null,
  );
  const [referralTypeAnchorElement, setReferralTypeAnchorElement] = useState<null | HTMLElement>(null);
  const dispatch = useDispatch();
  const assigneeOptions: any[] = useGetAssigneeOptions({ hideUnassigned: true });
  const medicationOptions: any[] = useGetMedicationsOptions();
  let referralStatusesOptions: any[] = useGetReferralStatusesOptions();
  const referralStatusOptions: any[] = getReferralOptions(status, referralStatusesOptions);
  const [selectedAssignees, setSelectedAssignees] = useState<any[]>([]);
  const [selectedIntakeSpecialists, setSelectedIntakeSpecialists] = useState<any[]>([]);
  const [selectedProviders, setSelectedProviders] = useState<any[]>([]);
  const [selectedTypes, setSelectedTypes] = useState<any[]>([]);
  const [selectedMedications, setSelectedMedications] = useState<any[]>([]);
  const [selectedReferralStatuses, setSelectedReferralStatuses] = useState<any[]>([]);
  const defaultMedications = filters.medications || [];
  const defaultAssignees = filters.assignedUsers || [];
  const defaultProviders = filters.providers || [];
  const defaultStatuses = filters.statuses || [];
  const defaultIntakeSpecialists = filters.intakeSpecialists || [];
  const referralSubtypes = filters?.referralSubTypes || [];
  const defaultReferralSubTypes = [...referralSubtypes];
  const { fromCreatedAt, fromNextFollowupDate, toCreatedAt, toNextFollowupDate, primaryInsuranceCarrier } = filters;
  const [emptyStateMessage, setEmptyStateMessage] = useState<string>(
    `You don't have any ${status === statuses.ARCHIVED ? 'archived' : status.toLowerCase()} referrals right now`,
  );
  const [insuranceSearchVal, setInsuranceSearchVal] = useState(primaryInsuranceCarrier || '');

  useEffect(() => {
    if (
      filters.assignedUsers?.length ||
      filters.clinics?.length ||
      filters.medications?.length ||
      filters.providers?.length ||
      filters.referralSubTypes?.length ||
      filters.referralTypes?.length ||
      filters.regions?.length ||
      filters.search ||
      filters.statuses?.length ||
      filters.toCreatedAt ||
      filters.toNextFollowupDate
    ) {
      setEmptyStateMessage('There are no results for your combination of filters');
    }
  }, [filters]);

  // if (status === statuses.ACTIVE) {
  //   const archivedStatuses = [
  //     referralFilterStatusesOptions.notCompleted.toLowerCase(),
  //     referralFilterStatusesOptions.discarded.toLowerCase(),
  //     referralFilterStatusesOptions.scheduled.toLowerCase(),
  //   ];
  //   const activeReferralStatusOptions = referralStatusOptions.map((option) => {
  //     return archivedStatuses?.includes(option?.label?.toLowerCase()) ? { ...option, disabled: true } : option;
  //   });
  //   referralStatusOptions = activeReferralStatusOptions;
  // } else if (status === statuses.ARCHIVED) {
  //   const activeStatuses = [
  //     referralFilterStatusesOptions.draft.toLowerCase(),
  //     referralFilterStatusesOptions.onHold.toLowerCase(),
  //     referralFilterStatusesOptions.open.toLowerCase(),
  //     referralFilterStatusesOptions.readyToSchedule.toLowerCase(),
  //   ];
  //   referralStatusOptions = referralStatusOptions.map((option) => {
  //     return activeStatuses?.includes(option?.label?.toLowerCase()) ? { ...option, disabled: true } : option;
  //   });
  // }

  const clickOpenAssigneeFiltersIcon = (event: React.MouseEvent<HTMLElement>) => {
    setAssigneeFilterMenuAnchorElement(event.currentTarget);
  };

  const clickIntakeSpecialistFiltersIcon = (event: React.MouseEvent<HTMLElement>) => {
    setIntakeSpecialistMenuAnchorElement(event.currentTarget);
  };
  const clickOpenProviderFiltersIcon = (event: React.MouseEvent<HTMLElement>) => {
    setProviderFilterMenuAnchorElement(event.currentTarget);
  };

  const clickOpenInsuranceFiltersIcon = (event: React.MouseEvent<HTMLElement>) => {
    setInsuranceFilterAnchorElement(event.currentTarget);
  };

  const clickOpenMedicationFilterIcon = (event: React.MouseEvent<HTMLElement>) => {
    setMedicationFilterAnchorElement(event.currentTarget);
  };

  const clickOpenReferralStatusIcon = (event: React.MouseEvent<HTMLElement>) => {
    setReferralStatusesFilterAnchorElement(event.currentTarget);
  };

  const clickOpenReferralType = (event: React.MouseEvent<HTMLElement>) => {
    setReferralTypeAnchorElement(event.currentTarget);
  };

  const handleAssigneeFilterClose = (selectedAssignees: string[], filters: ReferralFilters) => {
    const assignedUsers: (string | null)[] = selectedAssignees.map((assignee: string) => (assignee ? assignee : null));
    setAssigneeFilterMenuAnchorElement(null);

    if (shouldDispatchFilterAction(['assignedUsers'], filters, { assignedUsers })) {
      dispatch(
        updateReferralFilters({
          assignedUsers,
        }),
      );
    }
  };

  const handleIntakeSpecialistFilterClose = (selectedAssignees: string[], filters: ReferralFilters) => {
    const intakeSpecialists: (string | null)[] = selectedAssignees.map((assignee: string) =>
      assignee ? assignee : null,
    );
    setIntakeSpecialistMenuAnchorElement(null);

    if (shouldDispatchFilterAction(['intakeSpecialists'], filters, { intakeSpecialists })) {
      dispatch(
        updateReferralFilters({
          intakeSpecialists,
        }),
      );
    }
  };

  const handleProviderFilterClose = (selectedProviders: string[], filters: ReferralFilters) => {
    const providers: (string | null)[] = selectedProviders.map((provider: string) => (provider ? provider : null));
    setProviderFilterMenuAnchorElement(null);

    if (shouldDispatchFilterAction(['providers'], filters, { providers })) {
      dispatch(
        updateReferralFilters({
          providers,
        }),
      );
    }
  };

  const handleMedicationFilterClose = (medications: string[], filters: ReferralFilters) => {
    setMedicationFilterAnchorElement(null);

    if (shouldDispatchFilterAction(['medications'], filters, { medications })) {
      dispatch(
        updateReferralFilters({
          medications: selectedMedications,
        }),
      );
    }
  };

  const onCloseReferralType = (selectedTypes: any[], filters: ReferralFilters) => {
    setReferralTypeAnchorElement(null);
    const params: any = {
      referralTypes: [],
      referralSubTypes: [],
    };
    selectedTypes.forEach((item: string) => {
      if (item === 'Normal Referral') {
        params.referralTypes = [...params.referralTypes, 'Referral'];
        params.referralSubTypes = [...params.referralSubTypes, 'Normal Referral'];
      }
      if (item === 'Normal Renewal') {
        params.referralTypes = [...params.referralTypes, 'Renewal'];
        params.referralSubTypes = [...params.referralSubTypes, 'Normal Renewal'];
      }
      if (item === 'Drug Change') {
        params.referralTypes = [...params.referralTypes, 'Referral'];
        params.referralSubTypes = [...params.referralSubTypes, 'Drug Change'];
      }
      if (item === 'Dose Change') {
        params.referralTypes = [...params.referralTypes, 'Renewal'];
        params.referralSubTypes = [...params.referralSubTypes, 'Dose Change'];
      }
    });

    const payload = {
      ...params,
      referralTypes: [...new Set(params.referralTypes)],
      referralSubTypes: [...new Set(params.referralSubTypes)],
    };

    if (shouldDispatchFilterAction(['referralTypes', 'referralSubTypes'], filters, payload)) {
      dispatch(updateReferralFilters(payload));
    }
  };

  const handleReferralStatusesFilterClose = (statuses: string[], filters: ReferralFilters) => {
    setReferralStatusesFilterAnchorElement(null);

    if (shouldDispatchFilterAction(['statuses'], filters, { statuses })) {
      dispatch(
        updateReferralFilters({
          statuses,
        }),
      );
    }
  };

  const handleSortClick = (fieldName: string) => {
    if (filters?.sortField === fieldName) {
      changeSortOrder(filters?.sortDirection?.toLowerCase());
    } else {
      setSortField(fieldName);
    }
  };

  const setSortField = (fieldName: string) => {
    dispatch(
      updateReferralFilters({
        sortField: fieldName,
      }),
    );
  };

  const getSortProps = (fieldName: string) => {
    return {
      active: filters?.sortField === fieldName,
      direction: filters?.sortDirection?.toLowerCase(),
      onClick: () => handleSortClick(fieldName),
    };
  };

  const [searchParams] = useSearchParams();
  const currentTab = searchParams.get('tab');

  const loadMore = useCallback(() => {
    const isArchivedTab: boolean = status.toUpperCase() === statuses.ARCHIVED;

    dispatch(
      fetchActiveAndArchivedReferralsAction(
        isArchivedTab,
        {
          ...filters,
          page: (isArchivedTab ? archivedReferralsPage : activeReferralsPage) + 1,
          pageSize: 26,
          sortField: filters.sortField,
          statuses: filters.statuses.length
            ? filters.statuses
            : getIdsFromArray(isArchivedTab ? archivedStatuses : activeStatuses),
          // when on urgent tab, include priority to only fetch urgent referrals
          ...(currentTab === 'URGENT' && { priority: 'Urgent' }),
        },
        getIdsFromArray(activeStatuses),
        getIdsFromArray(archivedStatuses),
      ),
    );
  }, [
    activeReferralsPage,
    activeStatuses,
    archivedReferralsPage,
    archivedStatuses,
    currentTab,
    dispatch,
    filters,
    status,
  ]);

  useEffect(() => {
    const isArchivedTab: boolean = status.toUpperCase() === statuses.ARCHIVED;
    dispatch(isArchivedTab ? setArchivedReferralsGlobalLoading(true) : setActiveReferralsGlobalLoading(true));

    if (activeStatuses?.length && archivedStatuses?.length && hasFetchedSavedFilters) {
      dispatch(getReferralsCountsAction(filters, getIdsFromArray(activeStatuses), getIdsFromArray(archivedStatuses)));

      dispatch(
        fetchActiveAndArchivedReferralsAction(
          isArchivedTab,
          {
            ...filters,
            sortField: filters?.sortField || 'createdAt',
            pageSize: 26,
            statuses: filters?.statuses?.length
              ? filters.statuses
              : getIdsFromArray(isArchivedTab ? archivedStatuses : activeStatuses),
            // when on urgent tab, include priority to only fetch urgent referrals
            ...(currentTab === 'URGENT' && { priority: 'Urgent' }),
          },
          getIdsFromArray(activeStatuses),
          getIdsFromArray(archivedStatuses),
          () =>
            dispatch(isArchivedTab ? setArchivedReferralsGlobalLoading(false) : setActiveReferralsGlobalLoading(false)),
        ),
      );
    }
  }, [status, dispatch, filters, archivedStatuses, activeStatuses, hasFetchedSavedFilters, currentTab]);

  useEffect(() => {
    dispatch(getClinicsAction());
  }, [dispatch]);

  const tableEl = useRef<HTMLDivElement | null>(null) as any;
  useScrollHooks({
    tableEl,
    hasMore: status?.toUpperCase() !== statuses.ACTIVE ? archivedHasMore : activeHasMore,
    loading: status?.toUpperCase() !== statuses.ACTIVE ? archivedLoading : activeLoading,
    loadMore,
  });

  const changeSortOrder = (currentSortOrder: 'asc' | 'desc'): void => {
    if (currentSortOrder === 'asc') {
      dispatch(updateReferralFilters({ sortDirection: 'DESC' }));
    } else {
      dispatch(updateReferralFilters({ sortDirection: 'ASC' }));
    }
  };

  const onChangeReferralType = (value: any) => {
    setSelectedTypes(value);
  };

  const [regionsAnchor, setRegionsAnchorEl] = useState<HTMLButtonElement | null>(null);
  const regions = useSelector((state: RootState) => state.regions.data);
  const clinics = useSelector((state: RootState) => state.clinics.data);
  const regionOptions = regions.map((region) => ({
    label: region.name,
    value: region.id,
    searchString: region.name,
  }));

  const clinicOptions = clinics.map((clinic: any) => ({
    label: <Typography>{clinic.name}</Typography>,
    value: clinic.id,
    searchString: clinic.name,
    secondaryLabel: (
      <Typography style={{ textTransform: 'none', color: 'rgba(0, 0, 0, 0.38)' }}>
        {clinic.referralsCount} referrals
      </Typography>
    ),
    groupName: clinic?.region?.name?.toUpperCase(),
  }));

  const [regionFilters, setRegions] = useState<string[]>([]);
  const [clinicFilters, setClinicFilters] = useState<string[]>([]);

  const [clinicsAnchor, setClinicsAnchor] = useState<HTMLButtonElement | null>(null);

  const openClinicsFilterIcon = (e: React.MouseEvent<HTMLButtonElement>) => {
    setClinicsAnchor(e.currentTarget);
  };

  const closeClinicFilters = (clinics: string[], filters: ReferralFilters) => {
    if (shouldDispatchFilterAction(['clinics'], filters, { clinics })) {
      dispatch(
        updateReferralFilters({
          clinics,
        }),
      );
    }

    setClinicsAnchor(null);
  };

  const onCloseReferralRegion = (regions: string[], filters: ReferralFilters) => {
    if (shouldDispatchFilterAction(['regions'], filters, { regions })) {
      dispatch(
        updateReferralFilters({
          regions,
        }),
      );
    }

    setRegionsAnchorEl(null);
  };

  const [nextFollowUp, setNextFollowUp] = useState<any>([
    fromNextFollowupDate ? ignoreTimezoneDate(fromNextFollowupDate) : null,
    toNextFollowupDate ? ignoreTimezoneDate(toNextFollowupDate) : null,
  ]);

  const onCloseNextFollowUpFilter = (nextFollowUp: any[], filters: ReferralFilters) => {
    const fromNextFollowupDate = format(nextFollowUp[0] || new Date(), 'yyyy-MM-dd') + 'T00:00:00.000Z';
    const toNextFollowupDate = format(nextFollowUp[1] || new Date(), 'yyyy-MM-dd') + 'T23:59:59.000Z';
    const shouldDispatchAction: boolean =
      nextFollowUp[0] &&
      hasArrayDifference(
        [fromNextFollowupDate, toNextFollowupDate],
        [filters?.fromNextFollowupDate, filters?.toNextFollowupDate],
      );

    if (shouldDispatchAction) {
      dispatch(
        updateReferralFilters({
          fromNextFollowupDate,
          toNextFollowupDate,
        }),
      );
    }

    setNextFollowUpFilterAnchor(false);
  };

  const [createdDate, setCreatedDate] = useState<any>([
    fromCreatedAt ? ignoreTimezoneDate(fromCreatedAt) : null,
    toCreatedAt ? ignoreTimezoneDate(toCreatedAt) : null,
  ]);

  useEffect(() => {
    if (!toCreatedAt && !fromCreatedAt) {
      setCreatedDate([null, null]);
    }
  }, [fromCreatedAt, toCreatedAt]);

  useEffect(() => {
    if (!toNextFollowupDate && !fromNextFollowupDate) {
      setNextFollowUp([null, null]);
    }
  }, [toNextFollowupDate, fromNextFollowupDate]);

  useEffect(() => {
    setInsuranceSearchVal(primaryInsuranceCarrier || '');
  }, [primaryInsuranceCarrier]);

  const onCloseCreatedDateFilter = (createdDate: any, filters: ReferralFilters) => {
    const fromCreatedAt = format(createdDate[0] || new Date(), 'yyyy-MM-dd') + 'T00:00:00.000Z';
    const toCreatedAt = format(createdDate[1] || new Date(), 'yyyy-MM-dd') + 'T23:59:59.000Z';

    const shouldDispatchFilter: boolean = hasArrayDifference(
      [fromCreatedAt, toCreatedAt],
      [filters?.fromCreatedAt, filters?.toCreatedAt],
    );

    if (shouldDispatchFilter && createdDate[0]) {
      dispatch(
        updateReferralFilters({
          fromCreatedAt,
          toCreatedAt,
        }),
      );
    }

    setCreatedAtFilterAnchor(false);
  };

  const currentMonth = new Date().getMonth();
  const lastMonthDate = new Date().setMonth(currentMonth - 1);

  const {
    handleSearch,
    loading: providerLoading,
    providerOptions: options,
    hideNoResults,
  } = useProviderHooks(undefined, true);
  const providerOptions = options.map((item: any) => ({ ...item, value: item }));

  const onProviderSearch = (e: ChangeEvent<HTMLInputElement>) => handleSearch(e.target.value);
  const [dateOfBirthFilterOpen, setDateOfBirthFilterOpen] = useState(false);
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [dateOfBirthErrors, setDateOfBirtErrors] = useState(false);

  const onChangeByDateFilter = (date: Date | null) => {
    if (!date || date?.getFullYear() < 1800) {
      return;
    }
    try {
      if (date) {
        setDateOfBirtErrors(false);
        dispatch(
          updateReferralFilters({
            dateOfBirth: format(date, 'yyyy-MM-dd'),
          }),
        );
      }
    } catch (e) {
      setDateOfBirtErrors(true);
    }
  };
  const handleEnterKeyPressOnInsurance = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      dispatch(
        updateReferralFilters({
          primaryInsuranceCarrier: insuranceSearchVal,
        }),
      );
    }
  };
  const handleInsuranceFilterClose = () => {
    setInsuranceFilterAnchorElement(null);
    if (insuranceSearchVal?.length) {
      dispatch(
        updateReferralFilters({
          primaryInsuranceCarrier: insuranceSearchVal,
        }),
      );
    }
  };
  const handleSearchInsuranceChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInsuranceSearchVal(event.target.value);
  };

  const onClearSearch = () => {
    setInsuranceSearchVal('');
    dispatch(
      updateReferralFilters({
        primaryInsuranceCarrier: '',
      }),
    );
  };

  return (
    <Box ref={tableEl} style={{ height: '100%', overflow: 'scroll' }}>
      <Table
        stickyHeader
        className={classes.table}
        aria-label="simple table"
        style={{ width: 'auto', tableLayout: 'auto' }}
      >
        <TableHead>
          <TableRow>
            <TableCell
              style={{ ...tableColumnStyles, borderBottom: '0px !important', zIndex: 1099 }}
              classes={{ root: classes.stickyLeft }}
            >
              <Box
                className={classes.shadowLeftHead}
                display="flex"
                flex={1}
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="subtitle2">Patient</Typography>
              </Box>
            </TableCell>
            <TableCell classes={{ root: classes.rowRoot }} style={{ ...tableColumnStyles }}>
              <Box flex={1} display="flex" alignItems="center" justifyContent="space-between">
                <Box flex={1}>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns as any}
                    localeText={{ start: 'Check-in', end: 'Check-out' }}
                  >
                    <DateRangePicker
                      value={createdDate}
                      closeOnSelect={false}
                      onChange={(newValue) => {
                        setCreatedDate(newValue);
                      }}
                      defaultCalendarMonth={lastMonthDate}
                      open={createdAtFilterAnchor}
                      disableFuture
                      onOpen={() => setCreatedAtFilterAnchor(true)}
                      onClose={() => onCloseCreatedDateFilter(createdDate, filters)}
                      renderInput={() => (
                        <Box
                          display="flex"
                          style={{ width: '100%', flex: 1 }}
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <TableHeader
                            title="Created"
                            titleProps={{
                              variant: 'subtitle2',
                            }}
                            onClickFilter={() => setCreatedAtFilterAnchor(true)}
                            filterIconType={filters?.fromCreatedAt || filters?.toCreatedAt ? 'primary' : 'default'}
                            displayFilter
                            displaySort
                            sortProperties={getSortProps('createdAt')}
                          />
                        </Box>
                      )}
                    />
                  </LocalizationProvider>
                </Box>
              </Box>
            </TableCell>
            <TableCell classes={{ root: classes.rowRoot }} style={{ ...tableColumnStyles }}>
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box flex={1}>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns as any}
                    localeText={{ start: 'Check-in', end: 'Check-out' }}
                  >
                    <DateRangePicker
                      value={nextFollowUp}
                      closeOnSelect={false}
                      onChange={(newValue) => {
                        setNextFollowUp(newValue);
                      }}
                      defaultCalendarMonth={lastMonthDate}
                      open={nextFollowUpFilterAnchor}
                      onOpen={() => setNextFollowUpFilterAnchor(true)}
                      onClose={() => onCloseNextFollowUpFilter(nextFollowUp, filters)}
                      renderInput={() => (
                        <Box
                          display="flex"
                          style={{ width: '100%' }}
                          alignItems="center"
                          flex={1}
                          justifyContent="space-between"
                        >
                          <TableHeader
                            title="Next Follow Up"
                            titleProps={{
                              variant: 'subtitle2',
                              className: classes.headerName,
                              style: {
                                width: 110,
                              },
                            }}
                            filtersSectionProps={{}}
                            hideSort
                            onClickFilter={() => setNextFollowUpFilterAnchor(true)}
                            filterIconType={
                              filters?.fromNextFollowupDate || filters?.toNextFollowupDate ? 'primary' : 'default'
                            }
                            displayFilter
                          />
                        </Box>
                      )}
                    />
                  </LocalizationProvider>
                </Box>
              </Box>
            </TableCell>
            <TableCell classes={{ root: classes.rowRoot }}>
              <Box ref={setDateOfBirthAnchorElement} display="flex" alignItems="center" justifyContent="space-between">
                <TableHeader
                  filterIconType={filters?.dateOfBirth || filters?.dateOfBirth ? 'primary' : 'default'}
                  filtersCount={0}
                  displayFilter
                  onClickFilter={() => setDateOfBirthFilterOpen(true)}
                  title="Date of Birth"
                  hideSort
                  titleProps={{
                    className: classes.headerName,
                    variant: 'subtitle2',
                    style: { width: 90 },
                  }}
                />
              </Box>
              <Box style={{ position: 'relative' }}>
                <Menu
                  onClose={() => setDateOfBirthFilterOpen(false)}
                  anchorEl={dateOfBirthAnchorElement}
                  open={dateOfBirthFilterOpen}
                >
                  <Box style={{ padding: 16, width: 320, boxSizing: 'border-box', position: 'relative' }}>
                    <DesktopDatePicker
                      views={['year', 'month', 'day']}
                      onClose={() => {
                        setDateOfBirthFilterOpen(false);
                        setDatePickerOpen(false);
                      }}
                      open={datePickerOpen && dateOfBirthFilterOpen}
                      onChange={onChangeByDateFilter}
                      value={filters.dateOfBirth ? ignoreTimezoneDate(filters.dateOfBirth) : null}
                      renderInput={(props: any) => {
                        return (
                          <InputBase
                            {...props}
                            endAdornment={
                              <CalendarMonthIcon
                                onClick={() => setDatePickerOpen(true)}
                                style={{
                                  fontSize: 20,
                                  cursor: 'pointer',
                                  color: dateOfBirthErrors ? 'red' : 'rgba(0, 0, 0, 0.6)',
                                }}
                              />
                            }
                            style={{
                              borderRadius: 8,
                              background: dateOfBirthErrors ? 'rgb(254, 235, 238)' : 'rgba(0, 0, 0, 0.06)',
                              padding: 8,
                              width: '100%',
                            }}
                          />
                        );
                      }}
                    />
                  </Box>
                </Menu>
              </Box>
            </TableCell>
            <TableCell classes={{ root: classes.rowRoot }} style={{ ...tableColumnStyles }}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                style={{ borderBottom: '0px solid transparent !important', minWidth: '150px' }}
              >
                <TableHeader
                  filterIconType={filters?.medications?.length >= 1 ? 'primary' : 'default'}
                  filtersCount={filters?.medications?.length}
                  displayFilter
                  onClickFilter={clickOpenMedicationFilterIcon}
                  title="Medication"
                  hideSort
                  titleProps={{
                    className: classes.headerName,
                    variant: 'subtitle2',
                  }}
                />
                <NonScrollableMenu
                  disableAutoFocusItem
                  anchorEl={medicationFilterAnchorElement}
                  open={Boolean(medicationFilterAnchorElement)}
                  onClose={() => handleMedicationFilterClose(selectedMedications, filters)}
                >
                  <MenuItemSearchComponent
                    groupSelectable
                    initialValues={defaultMedications}
                    onChange={(value) => setSelectedMedications(value)}
                    showAvatar={false}
                    options={medicationOptions}
                    limit={9}
                    placeHolder="Search medication"
                    style={{ minWidth: 358 }}
                    showByGroup
                    setActionButtonOnTop
                    menuItemsContentStyles={{
                      maxHeight: 500,
                    }}
                  />
                </NonScrollableMenu>
              </Box>
            </TableCell>
            <TableCell classes={{ root: classes.rowRoot }} style={{ ...tableColumnStyles }}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                style={{ borderBottom: '0px solid transparent !important' }}
              >
                <TableHeader
                  title="Status"
                  filterIconType={filters?.statuses?.length >= 1 ? 'primary' : 'default'}
                  titleProps={{
                    variant: 'subtitle2',
                    className: classes.headerName,
                  }}
                  hideSort
                  onClickFilter={clickOpenReferralStatusIcon}
                  displayFilter
                  filtersCount={filters?.statuses?.length}
                />
                <NonScrollableMenu
                  disableAutoFocusItem
                  anchorEl={referralStatusesFilterAnchorElement}
                  open={Boolean(referralStatusesFilterAnchorElement)}
                  onClose={() => handleReferralStatusesFilterClose(selectedReferralStatuses, filters)}
                >
                  <MenuItemSearchComponent
                    initialValues={defaultStatuses}
                    onChange={(value) => setSelectedReferralStatuses(value)}
                    showAvatar={false}
                    options={referralStatusOptions}
                    limit={9}
                    placeHolder="Search Status"
                    style={{ width: 358 }}
                    setActionButtonOnTop
                  />
                </NonScrollableMenu>
              </Box>
            </TableCell>
            <TableCell classes={{ root: classes.rowRoot }} style={{ ...tableColumnStyles }}>
              <Box alignItems="center" display="flex">
                <TableHeader
                  filterIconType={filters?.regions?.length >= 1 ? 'primary' : 'default'}
                  filtersCount={filters?.regions?.length}
                  displayFilter
                  onClickFilter={(e: any) => setRegionsAnchorEl(e.currentTarget)}
                  title="Region"
                  hideSort
                  titleProps={{
                    variant: 'subtitle2',
                    className: classes.headerName,
                  }}
                />
                <NonScrollableMenu
                  onClose={() => onCloseReferralRegion(regionFilters, filters)}
                  open={Boolean(regionsAnchor)}
                  anchorEl={regionsAnchor}
                >
                  <MenuItemSearchComponent
                    initialValues={filters.regions}
                    onChange={(value: string[]) => setRegions(value)}
                    showAvatar={false}
                    options={regionOptions}
                    style={{ width: 292 }}
                    limit={8}
                    setActionButtonOnTop
                  />
                </NonScrollableMenu>
              </Box>
            </TableCell>
            <TableCell classes={{ root: classes.rowRoot }} style={tableColumnStyles}>
              <Typography variant="subtitle2" className={classes.headerName}>
                Progress
              </Typography>
            </TableCell>
            <TableCell classes={{ root: classes.rowRoot }} style={{ ...tableColumnStyles }}>
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <TableHeader
                  filterIconType={filters?.referralSubTypes?.length >= 1 ? 'primary' : 'default'}
                  filtersCount={filters?.referralSubTypes?.length}
                  displayFilter
                  onClickFilter={clickOpenReferralType}
                  title="Type"
                  hideSort
                  titleProps={{
                    variant: 'subtitle2',
                    className: classes.headerName,
                  }}
                />
                <NonScrollableMenu
                  disableAutoFocusItem
                  anchorEl={referralTypeAnchorElement}
                  open={Boolean(referralTypeAnchorElement)}
                  onClose={() => onCloseReferralType(selectedTypes, filters)}
                >
                  <MenuItemSearchComponent
                    initialValues={defaultReferralSubTypes}
                    onChange={(value) => onChangeReferralType(value)}
                    showAvatar={false}
                    options={referralTypeOptions}
                    limit={9}
                    hideSearch
                    showByGroup
                    style={{ width: 358 }}
                    setActionButtonOnTop
                  />
                </NonScrollableMenu>
              </Box>
            </TableCell>
            <TableCell classes={{ root: classes.rowRoot }} style={{ ...tableColumnStyles, paddingRight: 0 }}>
              <Box display="flex" alignItems="center" justifyContent="space-between" style={{ width: '100%' }}>
                <TableHeader
                  filterIconType={filters?.primaryInsuranceCarrier ? 'primary' : 'default'}
                  title="Primary Insurance"
                  titleProps={{
                    variant: 'subtitle2',
                    className: classes.headerName,
                    whiteSpace: 'nowrap',
                    style: { paddingRight: 16 },
                  }}
                  hideSort
                  displayFilter
                  onClickFilter={clickOpenInsuranceFiltersIcon}
                  sortProperties={getSortProps('primaryInsuranceCarrier')}
                />
                <NonScrollableMenu
                  disableAutoFocusItem
                  anchorEl={insuranceFilterAnchorElement}
                  open={Boolean(insuranceFilterAnchorElement)}
                  onClose={handleInsuranceFilterClose}
                  onKeyPress={handleEnterKeyPressOnInsurance}
                >
                  <Search
                    placeholder="Search by insurance"
                    value={insuranceSearchVal}
                    onChange={handleSearchInsuranceChange}
                    showClearSearchIcon={insuranceSearchVal?.length > 0}
                    onClearSearch={onClearSearch}
                  />
                </NonScrollableMenu>
              </Box>
            </TableCell>
            <TableCell classes={{ root: classes.rowRoot }} style={{ ...tableColumnStyles }}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                style={{ borderBottom: '0px solid transparent !important' }}
              >
                <TableHeader
                  filterIconType={filters?.providers?.length >= 1 ? 'primary' : 'default'}
                  filtersCount={filters?.providers?.length}
                  displayFilter
                  onClickFilter={clickOpenProviderFiltersIcon}
                  title="Provider"
                  hideSort
                  titleProps={{
                    variant: 'subtitle2',
                    className: classes.headerName,
                  }}
                />
                <NonScrollableMenu
                  disableAutoFocusItem
                  anchorEl={providerFilterAnchorElement}
                  open={Boolean(providerFilterAnchorElement)}
                  onClose={() => handleProviderFilterClose(selectedProviders, filters)}
                >
                  {/* providers */}
                  <MenuItemSearchComponent
                    initialValues={defaultProviders}
                    onChange={(value) => setSelectedProviders(value)}
                    showAvatar={false}
                    options={providerOptions}
                    limit={9}
                    searchLoading={providerLoading}
                    placeHolder="Search Provider"
                    style={{ width: 358 }}
                    menuItemsContentStyles={{ maxHeight: 400 }}
                    onSearchChange={onProviderSearch}
                    setActionButtonOnTop
                    hideNoResults={hideNoResults}
                  />
                </NonScrollableMenu>
              </Box>
            </TableCell>
            <TableCell classes={{ root: classes.rowRoot }} style={{ ...tableColumnStyles }}>
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <TableHeader
                  filterIconType={filters?.clinics?.length >= 1 ? 'primary' : 'default'}
                  filtersCount={filters?.clinics?.length}
                  displayFilter
                  onClickFilter={openClinicsFilterIcon}
                  title="Clinic"
                  hideSort
                  titleProps={{
                    variant: 'subtitle2',
                    className: classes.headerName,
                  }}
                />
                <Box>
                  <NonScrollableMenu
                    onClose={() => closeClinicFilters(clinicFilters, filters)}
                    open={Boolean(clinicsAnchor)}
                    anchorEl={clinicsAnchor}
                  >
                    <MenuItemSearchComponent
                      initialValues={filters.clinics}
                      onChange={(value: string[]) => setClinicFilters(value)}
                      showAvatar={false}
                      showByGroup
                      options={clinicOptions}
                      style={{ width: 292 }}
                      limit={4}
                      setActionButtonOnTop
                      menuItemsContentStyles={{
                        maxHeight: 500,
                      }}
                    />
                  </NonScrollableMenu>
                </Box>
              </Box>
            </TableCell>
            <TableCell classes={{ root: classes.rowRoot }} style={{ ...tableColumnStyles }}>
              <Box display="flex" alignItems="center" style={{ borderBottom: '0px solid transparent !important' }}>
                <TableHeader
                  filterIconType={filters?.assignedUsers?.length >= 1 ? 'primary' : 'default'}
                  filtersCount={filters?.assignedUsers?.length}
                  displayFilter
                  onClickFilter={clickOpenAssigneeFiltersIcon}
                  title="Assignee"
                  hideSort
                  titleProps={{
                    variant: 'subtitle2',
                    className: classes.headerName,
                  }}
                />
                <NonScrollableMenu
                  disableAutoFocusItem
                  anchorEl={assigneeFilterMenuAnchorElement}
                  open={Boolean(assigneeFilterMenuAnchorElement)}
                  onClose={() => handleAssigneeFilterClose(selectedAssignees, filters)}
                >
                  <MenuItemSearchComponent
                    initialValues={defaultAssignees.map((assignee: any) =>
                      typeof assignee === 'string' ? assignee : assignee?.id,
                    )}
                    onChange={(value) => setSelectedAssignees(value)}
                    showAvatar
                    options={assigneeOptions}
                    limit={9}
                    placeHolder="Search Assignee"
                    style={{ width: 358 }}
                    setActionButtonOnTop
                    menuItemsContentStyles={{
                      maxHeight: 500,
                    }}
                  />
                </NonScrollableMenu>
              </Box>
            </TableCell>
            <TableCell classes={{ root: classes.stickyRightHead }} style={{ ...tableColumnStyles }}>
              <Box
                display="flex"
                alignItems="center"
                style={{ borderBottom: '0px solid transparent !important' }}
                className={classes.shadowRightHead}
              >
                <TableHeader
                  filterIconType={filters?.intakeSpecialists?.length >= 1 ? 'primary' : 'default'}
                  filtersCount={filters?.intakeSpecialists?.length}
                  displayFilter
                  onClickFilter={clickIntakeSpecialistFiltersIcon}
                  title="Intake Specialist"
                  hideSort
                  titleProps={{
                    variant: 'subtitle2',
                  }}
                />
                <NonScrollableMenu
                  disableAutoFocusItem
                  anchorEl={intakeSpecialistAnchorElement}
                  open={Boolean(intakeSpecialistAnchorElement)}
                  onClose={() => handleIntakeSpecialistFilterClose(selectedIntakeSpecialists, filters)}
                >
                  <MenuItemSearchComponent
                    initialValues={defaultIntakeSpecialists.map((assignee: any) =>
                      typeof assignee === 'string' ? assignee : assignee?.id,
                    )}
                    onChange={(value) => setSelectedIntakeSpecialists(value)}
                    showAvatar
                    options={assigneeOptions}
                    limit={9}
                    placeHolder="Search Intake Specialist"
                    style={{ width: 358 }}
                    setActionButtonOnTop
                    menuItemsContentStyles={{
                      maxHeight: 500,
                    }}
                  />
                </NonScrollableMenu>
              </Box>
            </TableCell>
          </TableRow>
        </TableHead>
        {globalLoading ? (
          <ReferralsSkeletonLoader numberOfRows={10} numberOfColumns={16} />
        ) : (
          <TableBody>
            {data.map((item, index) => {
              const referralTypeToRender = getReferralTypeToRender(item);
              const unseenAttachmentsCount = item.attachments.filter((x: any) => !x.seen).length;
              const tierName: string | undefined =
                item?.medicationId &&
                medicationsData.find((medication) => medication?.id === item?.medicationId)?.drugTier?.name;

              const referralStatusUpdatedAt = userFriendlyTimestamp(item?.statusUpdatedAt, {
                roundOffLessThan2: true,
                hideTime: true,
                persistTimezone: false,
              });

              return (
                <TableRow
                  onClick={() => onSelectRow(item)}
                  style={{ overflowX: 'scroll', cursor: 'pointer' }}
                  key={index}
                  className={classes.row}
                >
                  <TableCell
                    style={{
                      background: isAfterDate(item?.nextFollowupDate) && !hideHighlights ? '#FFF3E0' : '',
                    }}
                    classes={{ root: classes.stickyLeft }}
                    id="stickyLeft"
                    className={classes.column}
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      style={{ height: '100%' }}
                      justifyContent="space-between"
                      className={classes.shadowLeft}
                    >
                      <Box sx={{ textOverflow: 'ellipsis', width: 200, overflow: 'hidden' }}>
                        {item?.patient?.first_name + ' ' + item?.patient?.last_name}
                      </Box>
                      <Box className={classes.patientNameRightElements}>
                        {unseenAttachmentsCount && !hideHighlights ? (
                          <Box className={classes.box} data-id="attachmentBadge">
                            <Tooltip
                              placement="top"
                              title={`${unseenAttachmentsCount} new ${unseenAttachmentsCount === 1 ? 'file' : 'files'}`}
                            >
                              <Badge badgeContent={unseenAttachmentsCount} color="primary">
                                <img src={faPaperClip} alt="" />
                              </Badge>
                            </Tooltip>
                          </Box>
                        ) : (
                          <></>
                        )}

                        {item.priority === 'Urgent' && (
                          <Tooltip placement="top" title="Urgent Referral" data-id="urgentBadge">
                            <Box className={classes.ellipsis}>
                              <img src={ellipsis} alt="" />
                            </Box>
                          </Tooltip>
                        )}

                        <Box>
                          <IconButton
                            onClick={() =>
                              setTimeout(() => {
                                // Wait until panel opens (triggered by row click)
                                window.location.hash = '#comments';
                              }, 100)
                            }
                          >
                            <CommentOutlinedIcon />
                          </IconButton>
                        </Box>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell className={classes.column}>{format(new Date(item?.createdAt), 'LLL d, yyyy')}</TableCell>
                  <TableCell className={classes.column}>
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                      {item?.nextFollowupDate ? format(ignoreTimezoneDate(item?.nextFollowupDate), 'LLL d, yyyy') : '-'}
                      {isAfterDate(item?.nextFollowupDate) && !hideHighlights && (
                        <Timer hideResult hours={0} date={item?.nextFollowupDate} />
                      )}
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box>
                      {item?.patient?.date_of_birth
                        ? format(ignoreTimezoneDate(item?.patient?.date_of_birth), 'MM/dd/yyyy')
                        : '-'}
                    </Box>
                  </TableCell>
                  <TableCell className={classes.column}>
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                      <Typography variant="body2">{item?.medication?.name || '-'}</Typography>
                      <Typography variant="body2" style={{ marginLeft: 16 }} color="textSecondary">
                        {tierName?.replace('Referral', '')?.replace('Renewal', '')}
                      </Typography>
                    </Box>
                  </TableCell>
                  {/* status */}
                  <TableCell className={classes.column}>
                    {item?.status?.title ? (
                      <Tooltip title={referralStatusUpdatedAt} placement="top">
                        <span>
                          <CustomChip
                            size="small"
                            label={item?.status?.title}
                            icon={<img alt="" src={getReferralsStatusColors(item?.status?.title).iconImage} />}
                            {...getReferralsStatusColors(item?.status?.title)}
                          />
                        </span>
                      </Tooltip>
                    ) : (
                      '-'
                    )}
                  </TableCell>
                  <TableCell className={classes.column}>{item?.region?.name || '-'}</TableCell>
                  <TableCell className={classes.column} width={'10%'} style={{ paddingRight: 0, whiteSpace: 'nowrap' }}>
                    {item.tasks.map((task: any, index: number) => {
                      const iconImage = getTaskColors(task?.status?.name).iconImage;
                      return (
                        <Tooltip
                          key={index}
                          arrow
                          title={
                            `${task?.title} ${
                              task?.status?.name ? `| ${startCase(toLower(task?.status?.name))}` : ''
                            }` || ''
                          }
                          placement="top"
                          classes={{
                            popper: classes.popper,
                            tooltipPlacementTop: classes.popper,
                            tooltip: classes.tooltip,
                          }}
                        >
                          <IconButton
                            disableRipple
                            size="small"
                            key={task.id}
                            style={{ position: 'inherit', padding: 0 }}
                          >
                            <img src={iconImage} alt="" />
                          </IconButton>
                        </Tooltip>
                      );
                    })}
                  </TableCell>
                  <TableCell className={classes.column}>
                    <Chip label={referralTypeToRender.label} color={referralTypeToRender.color} variant="outlined" />
                  </TableCell>
                  <TableCell className={classes.column}>
                    {item.primaryInsuranceCarrier
                      ? `${item?.insurance?.name || 'Other'}-${item.primaryInsuranceCarrier}`
                      : item?.insurance?.name || 'Other'}
                  </TableCell>
                  <TableCell className={classes.column}>
                    <div style={{ display: 'flex' }}>
                      <div>
                        {`${item?.provider?.firstName || '-'} ${item?.provider?.lastName || ''}${
                          item?.provider?.credentials ? ', ' + item?.provider?.credentials : ''
                        }`}
                      </div>

                      {item?.provider?.isTemporary && (
                        <Tooltip title="Temporary Provider" placement="top" data-id="tempProviderBadge">
                          <Chip
                            size="small"
                            label="TP"
                            classes={{ label: classes.chipLabel }}
                            style={{ color: '#ED6C02', backgroundColor: 'rgba(237, 108, 2, 0.12)', marginLeft: 4 }}
                          />
                        </Tooltip>
                      )}
                    </div>
                  </TableCell>
                  <TableCell className={classes.column}>{item?.clinic?.name || '-'}</TableCell>
                  <TableCell
                    width={'10%'}
                    style={{
                      paddingTop: '0px',
                      paddingBottom: '0px !important',
                      flex: 1,
                      alignItems: 'center',
                      padding: 0,
                      paddingLeft: 16,
                      paddingRight: 16,
                    }}
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      style={{
                        padding: 0,
                        boxSizing: 'border-box',
                        height: '100%',
                        top: 0,
                        bottom: 0,
                        width: '100%',
                      }}
                    >
                      {editReferralLoading && idOfReferralBeingEdited === item?.id ? (
                        <>
                          <CircularProgress size={25} style={{ marginRight: 8 }} thickness={8} value={95} />
                          <AnimatedLoading text="Updating ..." variant="caption" />
                        </>
                      ) : (
                        <>
                          <Avatar
                            name={`${item?.assignedUser?.firstName || '-'} ${item?.assignedUser?.lastName || '-'}`}
                            style={{ marginRight: 8 }}
                          />
                          {`${item?.assignedUser?.firstName || '-'} ${item?.assignedUser?.lastName || '-'}`}
                        </>
                      )}
                    </Box>
                  </TableCell>
                  <TableCell className={classes.column} classes={{ root: classes.stickyRight }} id="stickyRight">
                    <Box display="flex" alignItems="center" className={classes.shadowRight}>
                      <Avatar
                        name={`${item?.intakeSpecialist?.firstName || '-'} ${item?.intakeSpecialist?.lastName || '-'}`}
                        style={{ marginRight: 8 }}
                      />
                      {`${item?.intakeSpecialist?.firstName || '-'} ${item?.intakeSpecialist?.lastName || '-'}`}
                    </Box>
                  </TableCell>
                </TableRow>
              );
            })}
            {(archivedLoading || activeLoading) && !globalLoading && (
              <ReferralsRowsSkeleton numberOfRows={3} numberOfColumns={16} />
            )}
          </TableBody>
        )}
      </Table>
      {!globalLoading && !archivedLoading && !activeLoading && !data.length && (
        <EmptyResults icon={EmptyReferralResultsIcon} information={emptyStateMessage} />
      )}
    </Box>
  );
};

export default ReferralsTable;
