import { Typography, TypographyProps } from '@mui/material';
import React from 'react';
import { useStyles } from './AnimatedLoadingStyles';

interface Props extends TypographyProps {
  text: string;
}
const AnimatedLoading: React.FC<Props> = (props) => {
  const { text, ...restProps } = props;
  const classes = useStyles();

  return (
    <Typography className={classes.loading} {...restProps}>
      {text}
    </Typography>
  );
};

export default AnimatedLoading;
