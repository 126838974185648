import { ProviderCommRequest, ProviderCommunicationApi } from '../provider-communication-presenter';
import axios from 'axios';

const baseUrl = `${process.env.REACT_APP_API_URL}`;

export class AxiosProviderCommunicationApi implements ProviderCommunicationApi {
  async preview(request: ProviderCommRequest) {
    const result = await axios({
      url: `${baseUrl}/faxes/provider-comm/preview`,
      method: 'POST',
      responseType: 'blob',
      data: request,
    });
    return result.data;
  }

  async send(request: ProviderCommRequest) {
    const result = await axios({
      url: `${baseUrl}/faxes/provider-comm/send`,
      method: 'POST',
      data: request,
    });
    return result.data;
  }
}
