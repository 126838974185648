import axios, { AxiosResponse } from 'axios';
const BASE_URL = process.env.REACT_APP_API_URL;

export interface UserPreferenceFilterParam {
  field: string;
  value?: string | string[] | any;
  fromValue?: string;
  toValue?: string;
}

export interface UserPreferenceParams {
  gridName: string;
  sort?: {
    field: string;
    direction: string;
  };
  filters?: UserPreferenceFilterParam[];
}

export const updateFiltersPreferencesApi = async (payload: UserPreferenceParams): Promise<AxiosResponse<any, any>> => {
  return axios.patch(`${BASE_URL}/user-preferences/grids`, payload);
};

export const getUserPreferencesApi = async (params: { gridName: string }): Promise<AxiosResponse<any, any>> => {
  return axios.post(`${BASE_URL}/user-preferences/grids`, params);
};
