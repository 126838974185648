import { startOfWeek, endOfDay, isValid, startOfDay, addDays } from 'date-fns';
import { useState, useCallback, useEffect } from 'react';
import { isNullEmptyOrUndefined } from '../../globalUtils/utils';
import { MessageServer } from './message-server/message-server';
import { DateRange } from '@mui/lab';
import { TextMessage } from './text-message';

export function SmsHistoryController(messageServer: MessageServer) {
  return function useSmsHistoryController(props: { active: boolean }) {
    const [history, setHistory] = useState<TextMessage[]>([]);
    const [filteredHistory, setFilteredHistory] = useState<TextMessage[]>([]);
    const [wasActivated, setWasActivated] = useState(false);
    const [phoneFilter, setPhoneFilter] = useState<string>('');
    const [dateValue, setDateValue] = useState<DateRange<Date>>([startOfWeek(new Date()), new Date()]);
    const [datesValid, setDatesValid] = useState<boolean>(false);
    const [datesHaveErrors, setDatesHaveError] = useState<boolean>(false);
    const [calendarPickerOpen, setCalendarPickerOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const validateDate = useCallback((date: Date | null): boolean => {
      if (date?.toString()?.toLowerCase() === 'invalid date') {
        return false;
      }

      if (date !== null && date > endOfDay(new Date())) {
        return false;
      }

      return isNullEmptyOrUndefined(date) || isValid(date);
    }, []);
    useEffect(() => {
      if (!calendarPickerOpen && datesValid) {
        const fromDate = dateValue[0] !== null ? startOfDay(dateValue[0]) : dateValue[0];
        const toDate = dateValue[1] !== null ? startOfDay(addDays(dateValue[1], 1)) : dateValue[1];
        if (fromDate && toDate && wasActivated) {
          setLoading(true);
          setHistory([]);
          messageServer.getHistory(fromDate, toDate).then((history) => {
            setHistory(history);
            setLoading(false);
          });
        }
      }
    }, [dateValue, calendarPickerOpen, datesValid, wasActivated]);

    useEffect(() => {
      const dateValid: boolean = validateDate(dateValue[0]) && validateDate(dateValue[1]);

      setDatesValid(dateValid);
      setDatesHaveError(!dateValid);
    }, [dateValue, validateDate]);

    useEffect(() => {
      let phoneNumber = phoneFilter.replace(/\D/g, '');
      setFilteredHistory(
        history.filter(
          (historyItem) => historyItem.from?.includes(phoneNumber) || historyItem.to.includes(phoneNumber),
        ),
      );
    }, [history, phoneFilter]);

    useEffect(() => {
      if (props.active) {
        setWasActivated(true);
      }
    }, [props.active]);

    return {
      phoneFilter: {
        value: phoneFilter,
        set: setPhoneFilter,
        length: phoneFilter.length,
      },
      dateRange: {
        value: dateValue,
        set: setDateValue,
      },
      datesValid: {
        value: datesValid,
        set: setDatesValid,
      },
      datesHaveErrors: {
        value: datesHaveErrors,
        set: setDatesHaveError,
      },
      calendarPickerOpen: {
        value: calendarPickerOpen,
        set: setCalendarPickerOpen,
      },
      history: {
        value: filteredHistory,
        length: filteredHistory.length,
      },
      loading: {
        value: loading,
        set: setLoading,
      },
    };
  };
}

export type SmsHistoryControllerType = ReturnType<ReturnType<typeof SmsHistoryController>>;
