import { Box, TextField } from '@mui/material';
import { rootInputStyles } from '../ReasonDialog/ReasonDialogStyles';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import React, { useState } from 'react';

export function DateField(props: {
  label: string;
  value: Date | null;
  onChange: (value: Date | null) => void;
  disablePast?: boolean;
}) {
  const [pickerOpen, setPickerOpen] = useState(false);
  return (
    <DesktopDatePicker
      views={['day']}
      label={<Box style={{ paddingBottom: 16, marginTop: 0 }}>{props.label}</Box>}
      renderInput={(startProps: any) => (
        <Box
          style={{
            width: '100%',
            height: 52,
            backgroundColor: 'rgba(0,0,0,0.04)',
            padding: `8px 16px 16px`,
            borderRadius: 8,
            boxSizing: 'border-box',
          }}
        >
          <TextField
            {...startProps}
            type="date"
            suffix={<div>Test</div>}
            size="small"
            multiline
            rows={1}
            variant="standard"
            fullWidth
            InputProps={{
              disableUnderline: true,
              sx: { ...rootInputStyles, '& label': { top: -10 } },
              renderSuffix: () => (
                <Box display="flex" height="100%" style={{ marginTop: -16 }}>
                  <CalendarMonthIcon style={{ color: '#0000008A' }} />
                </Box>
              ),
            }}
            onClick={() => setPickerOpen(true)}
            onChange={() => setPickerOpen(false)}
          />
        </Box>
      )}
      onChange={props.onChange}
      value={props.value}
      open={pickerOpen}
      onClose={() => setPickerOpen(false)}
      disablePast={props.disablePast}
    />
  );
}
