import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => {
  return {
    dropzoneWrapper: {
      borderRadius: 4,
      paddingBottom: 8,
    },

    dropzone: {
      textAlign: 'center',
      border: '1px dashed rgba(0, 0, 0, 0.18) ',
    },

    dropZoneDownload: {
      paddingRight: 12,
    },
  };
});
