import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { onClickNotificationItem, userFriendlyTimestamp } from '../../globalUtils/utils';
import { Notification } from '../../redux/types';
import { LoaderIcon, ReadIcon, UnreadIcon } from './NotificationIcons';
import { useStyles } from './notificationsStyles';
import { getNotificationFormatting } from '../../features/Notification/format-notification';

interface Props {
  notification: Notification;
  onClose: () => void;
  referralStatuses: any[];
}

const NotificationItem: React.FC<Props> = (props) => {
  const { notification, onClose } = props;
  const classes = useStyles();
  const navigate = useNavigate();
  const [hovered, setHovered] = useState<boolean>(false);
  const formattedNotification = getNotificationFormatting(notification);
  const dispatch = useDispatch();

  return (
    <Box
      display="flex"
      className={classes.notificationItemContainer}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      data-id="notificationItem"
    >
      <Box
        className={classes.avatarContainer}
        onClick={() => onClickNotificationItem(notification, dispatch, navigate, onClose)}
      >
        {formattedNotification.icon}
      </Box>
      <Box display="flex" justifyContent="space-between" className={classes.notificationContentContainer}>
        <Box
          display="flex"
          flexDirection="column"
          className={classes.notificationItemTitleContainer}
          onClick={() => onClickNotificationItem(notification, dispatch, navigate, onClose)}
        >
          <Box display="flex">
            <Typography className={classes.itemTitleText}>
              <span data-id="itemTitle" className={classes.itemTitle}>
                {notification?.title}
              </span>
              <span className={classes.timeStampText} data-id="itemTimeStamp">
                {' '}
                {notification?.createdAt && userFriendlyTimestamp(notification?.createdAt)}
              </span>
            </Typography>
          </Box>
          <Box display="flex" style={{ paddingTop: 4 }}>
            <Box className={classes.notificationMessageContainer}>
              <Typography
                data-id="notificationMessage"
                className={classes.notificationMessage}
                dangerouslySetInnerHTML={{ __html: formattedNotification.text }}
              />
            </Box>
          </Box>
        </Box>
        <Box className={classes.unreadNotificationIconContainer}>
          {notification?.loading ? (
            <LoaderIcon />
          ) : !notification?.reviewed ? (
            <UnreadIcon notification={notification} notificationItemHovered={hovered} />
          ) : (
            <ReadIcon notification={notification} notificationItemHovered={hovered} />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default NotificationItem;
