import React, { ReactNode } from 'react';
import { ListItemText, MenuItem, Typography } from '@mui/material';
import referralStatusNotCompleted from '../../images/referralStatusNotCompleted.svg';
import referralStatusOnHold from '../../images/referralStatusOnHold.svg';
import referralStatusOpen from '../../images/referralStatusOpen.svg';
import referralStatusReadyToSchedule from '../../images/referralStatusReadyToSchedule.svg';
import referralStatusScheduled from '../../images/referralStatusScheduled.svg';
import { Box } from '@mui/system';
import useStyles from './simpleStatusStyles';
import DoneIcon from '@mui/icons-material/Done';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import MoreInfoTooltip from '../ReferralView/MoreInfoTooltip';
import { TooltipWrapper } from '../Tooltips/TooltipWrapper';

interface Props {
  onClose: () => void;
  referral: any;
  updateReferralStatus: (referral: any, statusTitle: string) => void;
  tooltipNode?: ReactNode;
}

interface Status {
  label: string;
  icon: string;
  disabled: boolean;
  showDoneIcon: boolean;
  tooltipNode?: ReactNode;
}

const SimpleStatusChange: React.FC<Props> = (props) => {
  const classes = useStyles();
  const { onClose, referral, updateReferralStatus, tooltipNode } = props;

  const onSelectStatus = (referral: any, statusName: string, disableTransition?: boolean): void => {
    if (disableTransition) {
      return onClose();
    }
    updateReferralStatus(referral, statusName);
    onClose();
  };

  const getStatuses = (referral?: any): Status[] => {
    const referralStatusIsDiscarded: boolean = ['discarded'].includes(referral?.status?.title?.toLowerCase());
    const referralStatusIsReadyToSchedule: boolean = ['ready to schedule'].includes(
      referral?.status?.title?.toLowerCase(),
    );

    const statuses: Status[] = [
      { label: 'Open', icon: referralStatusOpen },
      { label: 'On Hold', icon: referralStatusOnHold },
      {
        label: 'Ready to Schedule',
        icon: referralStatusReadyToSchedule,
        tooltipNode,
      },
      {
        label: 'Scheduled',
        icon: referralStatusScheduled,
        ...(!referralStatusIsReadyToSchedule && {
          tooltipNode: (
            <MoreInfoTooltip
              infoText={
                <>
                  Transition to Scheduled is only available to referrals that are in{' '}
                  <strong>'Ready to Schedule'</strong> status already.
                </>
              }
            />
          ),
        }),
      },
      { label: 'Not Completed', icon: referralStatusNotCompleted, disabled: referralStatusIsDiscarded },
    ].map((item) => ({
      ...item,
      disabled: item?.disabled
        ? item.disabled
        : item.label.toLowerCase().includes(referral?.status?.title?.toLowerCase()),
      showDoneIcon: item.label.toLowerCase().includes(referral?.status?.title?.toLowerCase()),
    }));

    return statuses;
  };

  return (
    <Box className={classes.parent}>
      {getStatuses(referral).map((status) => (
        <TooltipWrapper key={status.label} wrap={!!status.tooltipNode} title={status.tooltipNode || <></>}>
          <MenuItem
            disabled={status.disabled}
            key={status.label}
            onClick={() => onSelectStatus(referral, status.label, status.disabled || !!status.tooltipNode)}
          >
            <ListItemText>
              <Box
                style={{ opacity: status.tooltipNode ? 0.38 : 1 }}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box display="flex" alignItems="center">
                  <Box display="flex" alignItems="center">
                    <img src={status.icon} alt="" />
                  </Box>
                  <Box display="flex" alignItems="center" style={{ textAlign: 'left', paddingLeft: 18 }}>
                    <Typography>{status.label}</Typography>
                  </Box>
                </Box>
                <Box>
                  {status.showDoneIcon && <DoneIcon />}
                  {!status.showDoneIcon && status.tooltipNode && (
                    <ErrorOutlineOutlinedIcon style={{ height: 20, width: 20 }} />
                  )}
                </Box>
              </Box>
            </ListItemText>
          </MenuItem>
        </TooltipWrapper>
      ))}
    </Box>
  );
};

export default SimpleStatusChange;
