import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => {
  return {
    paddingBox: {
      padding: 24,
      paddingBottom: 0,
    },
    buttonSpace: {
      marginLeft: 16,
    },
    menuItemCheckbox: {
      width: 32,
      height: 32,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    checkboxRoot: {
      height: 20,
      width: 20,
    },
    textFieldBox: {
      padding: 8,
      position: 'sticky',
      top: 0,
    },
    textField: {
      width: '100%',
    },
    avatarBox: {
      marginRight: 16,
      marginLeft: 16,
    },
    listItemAvatar: {
      height: `32px !important`,
      width: `32px !important`,
    },
    tabList: {
      background: theme.palette.background.paper,
      paddingLeft: 40,
      height: 56,
      alignItems: 'flex-end',
    },
    tableRow: {
      cursor: 'pointer',
    },
    rowRoot: {
      background: `${theme.palette.background.paper} !important`,
      borderBottom: 'none !important',
    },
    caption: {
      marginLeft: 32,
      lineAlign: 1.6,
    },
    afterTab: {
      background: theme.palette.background.paper,
      flex: 1,
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    label: {
      marginRight: 32,
      width: 110,
    },
    avatar: {
      height: `32px !important`,
      width: `32px !important`,
      marginRight: 8,
    },
    svg: {
      marginRight: 16,
    },
    open: {
      padding: `40px 120px`,
      maxHeight: 'calc(100vh - 148px)',
      overflowY: 'scroll' as 'scroll',
    },
    pageLabel: {
      marginBottom: 28,
    },
    firstTable: {
      marginTop: 16,
    },
    drawer: {
      width: '50%',
      // paddingTop: 16
    },
    darkPrimary: {
      backgroundColor: `${theme.palette.primary.dark} !important`,
    },
    disabledLabel: {
      opacity: '0.38 !important',
    },
    formLabelRoot: {
      width: '100%',
      marginRight: '0 !important',
    },
    formLabel: {
      width: '100%',
    },
  };
});
