export const FETCH_RECEIVED_LOADING = 'FETCH_RECEIVED_LOADING';
export const LOAD_RECEIVED_TO_STORE = 'LOAD_RECEIVED_TO_STORE';
export const LOAD_RECEIVED_ERROR = 'LOAD_RECEIVED_ERROR';
export const SET_INBOX_RECEIVED_SORT_DIRECTION = 'SET_INBOX_RECEIVED_SORT_DIRECTION';
export const SET_ARCHIVED_INBOX_SORT_DIRECTION = 'SET_ARCHIVED_INBOX_SORT_DIRECTION';

export const SET_INBOX_ACTIVE_ROW = 'SET_INBOX_ACTIVE_ROW';
export const INBOX_ITEM_LOADING = 'INBOX_ITEM_LOADING';
export const INBOX_ITEM_ERROR = 'INBOX_ITEM_ERROR';
export const ADD_ACTIVE_ROW_COMMENTS = 'ADD_ACTIVE_ROW_COMMENTS';

export const UPDATE_GLOBAL_INBOX_LOADING = 'UPDATE_GLOBAL_LOADING';

export const FETCH_SENT_LOADING = 'FETCH_SENT_LOADING';
export const LOAD_SENT_TO_STORE = 'LOAD_SENT_TO_STORE';
export const LOAD_SENT_ERROR = 'LOAD_SENT_ERROR';

export const FETCH_ARCHIVED_LOADING = 'FETCH_ARCHIVED_LOADING';
export const LOAD_ARCHIVED_TO_STORE = 'LOAD_ARCHIVED_TO_STORE';
export const LOAD_ARCHIVED_ERROR = 'LOAD_ARCHIVED_ERROR';
export const UPDATE_INBOX_FILTERS = 'UPDATE_INBOX_FILTERS';

export const FETCH_REGIONS_LOADING = 'FETCH_REGIONS_LOADING';
export const LOAD_REGIONS_TO_STORE = 'LOAD_REGIONS_TO_STORE';
export const LOAD_REGIONS_ERROR = 'LOAD_REGIONS_ERROR';

export const UPDATE_INBOX_ACTION_LOADING = 'UPDATE_INBOX_ACTION_LOADING';
export const LOAD_USER_ACTION_RESPONSE_TO_STORE = 'LOAD_USER_ACTION_RESPONSE_TO_STORE';
export const UPDATE_INBOX_ACTION_ERROR = 'UPDATE_INBOX_ACTION_ERROR';

export const UPDATE_LIST_ITEMS = 'UPDATE_LIST_ITEMS';
export const LOAD_NEW_INBOX_ITEM_TO_STORE = 'LOAD_NEW_INBOX_ITEM_TO_STORE';

export const SET_ACTIVE_DRAWER = 'SET_ACTIVE_DRAWER';

export const FORWARD_INBOX_TO_ASANA = 'FORWARD_INBOX_TO_ASANA';
export const FORWARD_INBOX_TO_ASANA_SUCCESS = 'FORWARD_INBOX_TO_ASANA_SUCCESS';
export const FORWARD_INBOX_TO_ASANA_ERROR = 'FORWARD_INBOX_TO_ASANA_ERROR';
export const CLEAR_FORWARD_TO_ASANA = 'CLEAR_FORWARD_TO_ASANA';

export const ADD_TO_EXISTING_REFERRAL = 'ADD_TO_EXISTING_REFERRAL';
export const ADD_TO_EXISTING_REFERRAL_SUCCESS = 'ADD_TO_EXISTING_REFERRAL_SUCCESS';
export const ADD_TO_EXISTING_REFERRAL_ERROR = 'ADD_TO_EXISTING_REFERRAL_ERROR';
export const CLEAR_ADD_TO_EXISTING_REFERRAL = 'CLEAR_ADD_TO_EXISTING_REFERRAL';

export const UPDATE_INBOX_RECEIVED_TOTAL_COUNT = 'UPDATE_INBOX_RECEIVED_TOTAL_COUNT';
export const UPDATE_INBOX_ARCHIVED_TOTAL_COUNT = 'UPDATE_INBOX_ARCHIVED_TOTAL_COUNT';
