import { ProviderCommunicationState, ProviderCommunicationStorage } from '../provider-communication-presenter';

export class InMemoryProviderCommunicationStorage implements ProviderCommunicationStorage {
  state?: ProviderCommunicationState;

  async get() {
    if (!this.state) {
      throw new Error('No state saved');
    }
    return this.state;
  }

  async set(state: ProviderCommunicationState) {
    this.state = state;
  }
}
