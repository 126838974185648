import { useState } from 'react';
import { events } from '../index';
import { DashboardPatientInfo } from '@features/Omnichannel/dashboard-patient-info';
import { Conversation } from '@features/Omnichannel/conversation';
import parsePhoneNumber, { PhoneNumber } from 'libphonenumber-js';

export function DashboardController() {
  return function useDashboardController() {
    const [selectedPatient, setSelectedPatient] = useState<DashboardPatientInfo | undefined>();
    const [selectedPhoneNumber, setSelectedPhoneNumber] = useState<PhoneNumber | undefined>();

    events.useParticipantSelectedFromComposeDialogEventListener((e) => {
      setSelectedPatient(e.patient as DashboardPatientInfo);
      setSelectedPhoneNumber(e.phoneNumber);
    });

    return {
      selectedPhoneNumber: {
        value: selectedPhoneNumber,
        set: setSelectedPhoneNumber,
      },
      selectedPatient: {
        value: selectedPatient,
        set: setSelectedPatient,
      },
      handleConversationSelected: (conversation: Conversation | undefined) => {
        if (conversation) {
          setSelectedPatient(conversation.patient);
          setSelectedPhoneNumber(parsePhoneNumber(conversation.participantPhoneNumber || '', 'US'));
        } else {
          setSelectedPatient(undefined);
          setSelectedPhoneNumber(undefined);
        }
      },
    };
  };
}
