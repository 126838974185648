import { BaseTextFieldProps, Box, Button, CircularProgress, InputAdornment, Stack, TextField } from '@mui/material';
import React from 'react';
import { SendSmsController } from '../send-sms-controller';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import { TemplateSelect } from './TemplateSelect';

export interface Props extends BaseTextFieldProps {
  view: ReturnType<ReturnType<typeof SendSmsController>>;
  showSendButton?: boolean;
}

export function SendSmsComponent(props: Props) {
  const { view, showSendButton = true, ...textProps } = props;

  return (
    <Stack direction="column" gap="16px">
      <TemplateSelect templates={view.templates} />
      <TextField
        id="sms_message"
        label="Message"
        variant="filled"
        value={view.message.value}
        multiline
        rows={3}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => view.message.set(e.target.value)}
        InputProps={{
          endAdornment: showSendButton && (
            <InputAdornment position="end">
              <Button variant="contained" onClick={() => view.sendButton.click()}>
                Send &nbsp;
                {(view.sendButton.loading && <CircularProgress style={{ color: 'white' }} size={16} />) || (
                  <SendOutlinedIcon />
                )}
              </Button>
            </InputAdornment>
          ),
        }}
        {...textProps}
      />
      {view.status.visible && <Box>{view.status.message}</Box>}
      {view.error.visible && <Box sx={{ color: 'red' }}>{view.error.message}</Box>}
    </Stack>
  );
}
